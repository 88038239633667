import gql from 'graphql-tag';

const QUERY_GET_BUSINESS_PLAN_MEMBERSHIPS = gql`

	query GetBusinessPlanMembershipTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getBusinessPlanMembershipTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				firstname
				lastname
				accountEmail
				membershipID
				plan
			}
		}
	}

`;

export default QUERY_GET_BUSINESS_PLAN_MEMBERSHIPS;