import React, { Component } from 'react';
import axios from 'axios';
import { Button, Table, Input } from 'semantic-ui-react';
import { withApollo } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import RunQuery from './RunQuery';
import CreateToken from './CreateToken';

const updateNationalLocations = '/api/support/updateNationalLocations';
const downloadStoreCSV = '/api/support/downloadStoreCSV';

class Tools extends Component {
	state = {
		loading: false,
		data: null,
		graphQLError: null,

		uid: '',
		password: '',
	}

	resetData = () => {
		this.setState({ data: null });
	}

	updateNationalLocations = async () => {
		this.resetData();
		await this.setState({ loading: true });
		const response = await axios.get(updateNationalLocations);
		await this.setState({ loading: false, data: response.data });
	}

	downloadStoreCSV = async () => {
		this.resetData();
		await this.setState({ loading: true });
		const response = await axios.get(downloadStoreCSV);
		await this.setState({ loading: false, data: response.data });
	}

	updateStoreSchemas = () => {
		this.setState({ loading: true, graphQLError: null });
		this.props.client.mutate({
			mutation: gql`
				mutation ModifyStoreSchema {
					modifyStoreSchema
				}
			`,
		}).then((v1,v2,v3) => {
			this.setState({ data: 'Store Schema Successfully Changed! Thanks.', loading: false });
		}).catch(reason => {
			this.setState({ graphQLError: reason, loading: false });
		});
	}

	updateSales = () => {
		this.setState({ loading: true, graphQLError: null });
		this.props.client.mutate({
			mutation: gql`
				mutation ModifySaleSchema {
					modifySaleSchema
				}
			`,
		}).then((v1,v2,v3) => {
			this.setState({ data: 'Sales Successfully Changed! Thanks.', loading: false });
		}).catch(reason => {
			this.setState({ graphQLError: reason, loading: false });
		});
	}

	renderGraphQLError = () => {
		if(this.state.graphQLError) {
			return (
				<div>{`${this.state.graphQLError}`}</div>
			);
		}
	}

	renderButton = (description, text, onClick, disabled=false) => {
		return (
			<Table.Row>
				<Table.Cell>{description}</Table.Cell>
				<Table.Cell>
					<Button onClick={onClick} disabled={disabled}>
						{text}
					</Button>
				</Table.Cell>
			</Table.Row>
		);
	}

	changePassword = () => {
		const { uid, password } = this.state;
		if(uid && password) {

			this.setState({ loading: true, graphQLError: null });
			this.props.client.mutate({
				variables: {
					uid, password
				},
				mutation: gql`
					mutation DeveloperChangePassword($uid: String!, $password: String!) {
						developerChangePassword(uid: $uid, password: $password)
					}
				`,
			}).then(() => {
				this.setState({ data: 'Password Successfully Changed! Thanks.', loading: false });
			}).catch(reason => {
				this.setState({ graphQLError: reason, loading: false });
			});


		}
	}

	render() {
		return (
			<div>
				<h1 style={{ marginTop: '10px' }}>Developer Tools</h1>
				{ this.state.loading && <h1>Loading...</h1> }
				{ !this.state.loading &&
					<div>
						{ this.renderGraphQLError() }
						{ this.state.data && <div dangerouslySetInnerHTML={{ __html: this.state.data}}></div>}

						<Table celled padded style={{ marginTop: '20px' }}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Description</Table.HeaderCell>
									<Table.HeaderCell>Action</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								{this.renderButton('Redo the Schema for National Locations', 'Update National Locations', this.updateNationalLocations, true)}
								{this.renderButton('Test: Download a CSV of all the information of stores', 'Download CSV', this.downloadStoreCSV, true)}
								{this.renderButton('Redo Schema for Store Info & Locations', 'Update Store Schema', this.updateStoreSchemas, true)}
								{this.renderButton('Redo Schema for Sales - Membership Update', 'Update Sale Schema', this.updateSales)}

								<Table.Row>
									<Table.Cell>
										<Input
											placeholder="UID"
											onChange={(event, data) => this.setState({ uid: data.value })}
											value={this.state.uid}
										/>
										<Input
											placeholder="Password"
											onChange={(event, data) => this.setState({ password: data.value })}
											value={this.state.password}
										/>
									</Table.Cell>
									<Table.Cell>
										<Button onClick={this.changePassword}>
											Change Password
										</Button>
									</Table.Cell>
								</Table.Row>

								<CreateToken />

							</Table.Body>

						</Table>
						<RunQuery />
					</div>
				}
			</div>
		);
	}
}

export default withApollo(Tools);