/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import mutationChangeLocationAddress from '../../../../mutations/changeLocationAddress';
import withStateMutation from '../../../../queries/withStateMutation';
import GraphQLError from '../../../Message/GraphQLError';
import queryGetStore from '../../../../queries/getStore';
import SelectInput from '../../../FinalForm/SelectInput';
import { countries, states } from '../../../../config/supported_areas';

class ChangeAddress extends Component {

	onSubmit = (variables) => {
		this.props.mutate({
			variables: {
				...variables,
				id: this.props.data.id
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	renderAddress = () => {
		const { street1, street2, city, state, zip, country } = _.get(this.props, 'data.locationInformation', {}) || {};
		return(
			<div style={{ color: '#3498db', cursor: 'pointer' }}>
				<div>{street1}</div>
				<div>{street2 && street2}</div>
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	}

	renderAddressForm = () => {
		const { street1, street2, city, state, zip, country } = _.get(this.props, 'data.locationInformation', {}) || {};

		return(
			<FinalForm
				onSubmit={this.onSubmit}
				validate={validate}
				initialValues={{ street1, street2, city, state, zip, country }}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextInput name="street1" placeholder="Street 1" label="Street" required />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name="street2" placeholder="Street 2" label={false} />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name="city" placeholder="City" required />
							<SelectInput name="state" placeholder="State" required options={states} />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name="zip" placeholder="Zipcode" required />
							<SelectInput name="country" placeholder="Country" required options={countries} />
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						<Button type="submit">Submit Changes</Button>
					</Form>
				)}
			/>
		);
	}

	render() {
		return (
			<Popup
				trigger={this.renderAddress()}
				content={this.renderAddressForm()}
				on='click'
			/>
		);
	}
}

const validate = (values) => {
	const errors = {};

	if(!values.street1) {
		errors.street1 = 'Required';
	}

	if(!values.city) {
		errors.city = 'Required';
	}

	if(!values.zip) {
		errors.zip = 'Required';
	}

	if(!values.state) {
		errors.state = 'Required';
	}

	if(!values.country) {
		errors.country = 'Required';
	}

	return errors;
};

export default compose(
	graphql(mutationChangeLocationAddress),
	withStateMutation()
)(ChangeAddress);