import React, { useState, useEffect } from 'react';
import DeleteModal from '../../../../../common/DeleteModal/DeleteModal';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_REMOVE_MEMBERSHIP_FROM_BUSINESS_PLAN from './removeMembershipFromBusinessPlan';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';

const DeleteMembership = ({ ids, refetch, onClose }) => {
	const [deleteMembership, {
		data,
		loading,
		error
	}] = useMutation(MUTATION_REMOVE_MEMBERSHIP_FROM_BUSINESS_PLAN);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if(_.get(data, 'removeMembershipFromBusinessPlan')) {
			setOpen(false);
			onClose();
		}
	}, [data]);

	return (
		<>
			<DeleteModal
				open={open}
				loading={loading}
				onClose={() => setOpen(false)}
				onDelete={() => {
					deleteMembership({
						variables: ids,
						awaitRefetchQueries: true,
						refetchQueries: refetch,
					});
				}}
			/>
			<Button
				content="Remove Membership"
				color="red"
				onClick={() => setOpen(true)}
			/>
		</>
	);
};

export default DeleteMembership;