import React, {Component} from 'react';
import axios from 'axios';
import Loading from '../../Loading';
import { Menu, Segment } from 'semantic-ui-react';

class Sale extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: null,
			activeItem: 'saleinfo',

		};

		this.getValues = this.getValues.bind(this);
	}

	componentDidMount() {
		this.getValues();
	}

	async getValues() {
		this.setState({loading: true});

		const request = await axios.get(`/graphql?query={
			sale(id: "${this.props.id}") {
				id 
				customer {
					firstname  
					lastname 
					email 
					zip 
					accountType 
				}
				total 
				date 
				stripeCustomerID 
				stripeSubscriptionID 
				shippingAddress {
					name 
					street1 
					street2 
					city 
					state 
					zip 
					country 
				}

				firstname 
				lastname 
				email 
				phone 
				valid 
				membershipID 

				additionalFamilyMembers {
					name 
				}
			}
		}`);

		this.setState({loading: false, data: request.data.data});
	}

	handleItemClick(value) {
		this.setState({activeItem: value});
	}

	renderCustomerInfo() {
		const data = this.state.data.sale;

		if(this.state.activeItem === 'cinfo') {
			return(
				<div>
					<h3>Acount Information</h3>
					<hr />
					<p><strong>Name: </strong>{`${data.customer.firstname} ${data.customer.lastname}`}</p>
					<p><strong>Email: </strong>{data.customer.email}</p>
					<p><strong>Zipcode: </strong>{data.customer.zip}</p>
					<p><strong>Representative: </strong>{(data.customer.accountType > 0) ? 'Yes' : 'No'}</p>
				</div>
			);
		}
	}

	renderSaleInfo() {
		const data = this.state.data.sale;

		if(this.state.activeItem === 'saleinfo') {
			return(
				<div>
					<h3>Sale Information</h3>
					<hr />
					<p><strong>Date: </strong>{(new Date(data.date)).toDateString()}</p>
					<p><strong>ID: </strong>{data.id}</p>
					<p><strong>Membership #: </strong>{data.membershipID}</p>
					<p><strong>Stripe Customer ID: </strong>{data.stripeCustomerID}</p>
					<p><strong>Stripe Subscription ID: </strong>{data.stripeSubscriptionID}</p>
					<p><strong>Total Charged: </strong>{data.total}</p>
				</div>
			);
		}
	}

	renderMembers(arr) {
		return(
			arr.map(ob => (<p key={ob.name}>{ob.name}</p>))
		);
	}

	renderAdditionalFamilyMembers()
	{
		const members = this.state.data.sale.additionalFamilyMembers;
		if(members.length > 0)
		{
			return(
				<div>
					<h5>Addtional Family Members</h5>
					{this.renderMembers(members)}
				</div>
			);
		}
	}

	renderShippingInfo() {
		const data = this.state.data.sale;
		var email = data.email || 'N/A';
		var phone = data.phone || 'N/A';
		if(this.state.activeItem === 'shippinginfo') {
			return(
				<div>
					<h3>Shipping Information</h3>
					<hr />
					<p>{data.firstname} {data.lastname}</p>
					<p>{data.shippingAddress.street1}</p>
					<p>{data.shippingAddress.street2 || ''}</p>
					<p>{data.shippingAddress.city}, {data.shippingAddress.state} {data.shippingAddress.zip} {data.shippingAddress.country}</p>
					<p><strong>Phone:</strong> {phone}</p>
					<p><strong>Email:</strong> {email}</p>
					{this.renderAdditionalFamilyMembers()}
				</div>
			);
		}
	}

	renderSegment() {
		return(
			<div>
				{this.renderCustomerInfo()}
				{this.renderSaleInfo()}
				{this.renderShippingInfo()}
			</div>
		);
	}

	renderCustomerItem() {
		const { activeItem } = this.state;
		if(this.state.data.sale.customer) {
			return(
				<Menu.Item name='Account Info' active={activeItem === 'cinfo'} onClick={() => this.handleItemClick('cinfo')} />
			);
		}
	}

	render() {
		const { activeItem, loading, data } = this.state;

		if(loading || !data) {
			return(<Loading />);
		}

		return(
			<div>
				<Menu attached='top' tabular>
					<Menu.Item name='Sale Info' active={activeItem === 'saleinfo'} onClick={() => this.handleItemClick('saleinfo')} />
					{this.renderCustomerItem()}
					<Menu.Item name='Shipping Info' active={activeItem === 'shippinginfo'} onClick={() => this.handleItemClick('shippinginfo')} />
				</Menu>

				<Segment attached='bottom'>
					{this.renderSegment()}
				</Segment>
			</div>
		);
	}
}

export default Sale;
