import React from 'react';
import { Modal } from 'semantic-ui-react';
import Store from './Store';

const StoreModal = ({ id, onClose }) => {
	return (
		<Modal 
			open={!!id}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
			header={<Modal.Header>Store</Modal.Header>}
			centered={false}
			content={(
				<Modal.Content>
					<Store 
						id={id}
						onComplete={onClose}
					/>
				</Modal.Content>
			)}
		/>
	);
};

export default StoreModal;