import React from 'react';
import styles from './EditMembership.module.scss';
import { Table } from 'semantic-ui-react';
import { DetailsRow } from './helpers';
import _ from 'lodash';

const Order = ({ data }) => {
	const {
		id,
		referenceName,
		total,
		date,
		requestedPhysicalCard,
		physicalCardSent,
	} = data;

	return (
		<div className={styles.sale}>
			<a
				className={styles.id}
				href={`/admin/orders/${id}`}
				target="_blank"
				rel="noopener noreferrer"
			>{id}</a>
			<div className={styles.col}>
				<Table striped definition>
					<DetailsRow
						property="Date"
						value={date}
						date
					/>
					<DetailsRow
						property="Total"
						value={`$${(total || 0).toFixed(2)}`}
					/>
					<DetailsRow
						property="Reference"
						value={referenceName || 'N/A'}
					/>
					<DetailsRow
						property="Requested Physical Card?"
						value={requestedPhysicalCard}
						date
					/>
					<DetailsRow
						property="Physical Card Sent?"
						value={physicalCardSent}
						date
						condition={!!requestedPhysicalCard}
					/>
				</Table>
			</div>
		</div>
	);
};

const Orders = ({ membership }) => {
	const orders = _.get(membership, 'orders', []) || [];
	return (
		<div className={styles.container}>
			{orders.map(order => <Order key={order.id} data={order} />)}
		</div>
	);
};

export default Orders;