/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Table, Segment } from 'semantic-ui-react';


const Location = ({ data, index, parentid }) => {
	const { location } = data;

	const TextRow = ({ title, value, renderValue }) => {
		const v = _.get(data, `${value}`, 'N/A') || 'N/A';
		return (
			<Table.Row>
				<Table.Cell>{title}</Table.Cell>
				<Table.Cell>{renderValue ? renderValue(v === 'N/A' ? false : v) : v}</Table.Cell>
			</Table.Row>
		);
	};

	const RenderAddress = () => {
		const { street1, street2, city, state, zip, country } = _.get(data, 'locationInformation', {});
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<div className="store__location changeInformation">
			<Table striped definition attached='top'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							<h3>Location #{index}</h3>
						</Table.HeaderCell>
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{TextRow({ title: 'Contact Name', value: 'locationInformation.name' })}
					{TextRow({ title: 'Contact Phone', value: 'locationInformation.phone' })}

					<Table.Row>
						<Table.Cell>Coordinates</Table.Cell>
						<Table.Cell>{location.coordinates[1]}, {location.coordinates[0]}</Table.Cell>
					</Table.Row>

					<Table.Row>
						<Table.Cell>Address</Table.Cell>
						<Table.Cell>{RenderAddress()}</Table.Cell>
					</Table.Row>

				</Table.Body>
			</Table>
		</div>
	);
};

export default Location;