import React, { Component, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { ChangeDateFieldUser, ChangeTextFieldUser, ChangeSelectFieldUser, ChangeCheckboxFieldUser } from './UserChangeFields';
import queryGetUserAdmin from '../../../../queries/getUserAdmin';
import SimpleSelect from '../../../Form/SimpleSelect';
import queryCurrentUser from '../../../../queries/user';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import ChangeParent from '../../Reps/ChangeParent';

class BasicInfoTab extends Component {

	renderTextItem = (property, value) => {
		return (
			<Table.Row>
				<Table.Cell>{property}</Table.Cell>
				<Table.Cell>{value}</Table.Cell>
			</Table.Row>
		);
	}

	refetchQueries = () => {
		return [{
			query: queryGetUserAdmin,
			variables: { id: this.props.user.id }
		}];
	}

	getValues = () => {
		this.props.onSuccessTemp && this.props.onSuccessTemp();
	}

	renderChangeName = (refetch) => {
		const { user } = this.props;
		const { id } = user;
		return (
			<Fragment>
				<Table.Row>
					<Table.Cell>Name</Table.Cell>
					<Table.Cell>
						<ChangeTextFieldUser
							label={'Firstname'}
							value={user.firstname}
							property={'firstname'}
							refetch={refetch}
							id={id}
							basic
						/>
						{' '}
						<span className="changeInformation__value__red">
							<ChangeTextFieldUser
								label={'Lastname'}
								value={user.lastname}
								property={'lastname'}
								refetch={refetch}
								id={id}
								basic
							/>
						</span>
					</Table.Cell>
				</Table.Row>
			</Fragment>
		);
	}

	render() {
		const { user } = this.props;	
		console.log(user);
		if(!user) {
			return <div>No Information of this User</div>;
		}
		const { id } = user;
		const refetch = this.refetchQueries();

		if(this.props.data.loading || !this.props.data.user) {
			return <div></div>;
		}

		const adminLevel = _.get(this.props, 'data.user.adminLevel', 0);

		const accountType = _.get(this.props, 'user.accountType', 0);

		var referenceName = null;
		if(user.parent) {
			if(user.parent.firstname) {
				referenceName = `${user.parent.firstname} ${user.parent.lastname}`;
			}
		}

		return (
			<div style={{ margin: '10px' }}>
				<Table definition striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell />
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{this.renderChangeName(refetch)}
						<ChangeTextFieldUser
							label={'Phone #'}
							value={formatPhone(user.phone)}
							property={'phone'}
							refetch={refetch}
							id={id}
							table
						/>
						{this.renderTextItem('Email', user.email)}
						{
							user.accountType > 0 &&
							this.renderTextItem('Birthday', user.birthday)
						}
						{
							user.accountType > 0 &&
							this.renderTextItem('Educator ID', user.representativeID)
						}
						<ChangeTextFieldUser
							label={'Zipcode'}
							value={user.zip}
							property={'zip'}
							refetch={refetch}
							id={id}
							table
						/>
						<ChangeSelectFieldUser
							label={'Account Type'}
							value={user.accountType}
							property={'accountType'}
							refetch={refetch}
							id={id}
							options={AccountTypeOptions}
							table
						/>
						<ChangeCheckboxFieldUser
							label="Is Friend"
							value={!!user.friend}
							property="friend"
							refetch={refetch}
							id={id}
							table
						/>
						<ChangeCheckboxFieldUser
							label="Is Influencer"
							value={!!user.influencer}
							property="influencer"
							refetch={refetch}
							id={id}
							table
						/>
						{
							user.accountType >= 1 &&
							<ChangeCheckboxFieldUser
								label="Skip Commission Requirements"
								value={!!user.skipCommissionReqs}
								property="skipCommissionReqs"
								refetch={refetch}
								id={id}
								table
							/>
						}
						{
							user.accountType >= 1 &&
							<ChangeSelectFieldUser
								label={'Educator Level'}
								value={_.get(user, 'representativeLevel.typeID', 0)}
								property={'representativeLevel'}
								refetch={refetch}
								id={id}
								options={RepresentativeLevelOptions}
								table
							/>
						}
						{
							(user.accountType >= 10 && adminLevel >= 3) &&
							<ChangeSelectFieldUser
								label={'Admin Level'}
								value={user.adminLevel}
								property={'adminLevel'}
								refetch={refetch}
								id={id}
								options={AdminLevelOptions}
								table
							/>
						}

						{
							(user.accountType >= 10 && adminLevel >= 3) &&
							<ChangeSelectFieldUser
								label={'Employee'}
								value={user.employee}
								property={'employee'}
								refetch={refetch}
								id={id}
								options={EmployeeOptions}
								table
							/>
						}

						{
							(user.accountType >= 10 && adminLevel >= 3 && user.employee) &&
							<ChangeSelectFieldUser
								label={'Employee Manager'}
								value={user.eManager}
								property={'eManager'}
								refetch={refetch}
								id={id}
								options={EmployeeOptions}
								table
							/>
						}

						<ChangeTextFieldUser
							label={'Reference Code'}
							value={user.referenceCode}
							property={'referenceCode'}
							refetch={refetch}
							id={id}
							table
						/>
						{
							accountType > 0 &&
							(<Table.Row>
								<Table.Cell>Parent</Table.Cell>
								<Table.Cell>
									<ChangeParent def={referenceName} id={id} type={0} onSuccess={() => this.getValues()}/>
								</Table.Cell>
							</Table.Row>)
						}
                        {
							user.accountType >= 1 &&
							<ChangeCheckboxFieldUser
								label="Donating Proceeds?"
								value={!!user.donating}
								property="donating"
								refetch={refetch}
								id={id}
								table
							/>
						}
					</Table.Body>

				</Table>
			</div>
		);
	}
}

export default compose (
	graphql(queryCurrentUser)
)(BasicInfoTab);


const AccountTypeOptions = [
	{ value: 0, label: 'Customer' },
	{ value: 1, label: 'Influencer' },
	{ value: 2, label: 'Loyalty Provider' },
	{ value: 3, label: 'Educator' },
	{ value: 10, label: 'Admin' },
	{ value: 42, label: 'Developer' },
];

const RepresentativeLevelOptions = [
	{ value: 0, label: 'Educator' },
	{ value: 1, label: 'Manager' },
	{ value: 2, label: 'Authority' },
	{ value: 3, label: 'Leader' },
];

const AdminLevelOptions = [
	{ value: 1, label: '1 - Low Level'},
	{ value: 2, label: '2 - Mid Level'},
	{ value: 3, label: '3 - Max Level'},
];

const EmployeeOptions = [
	{ value: true, label: 'True' },
	{ value: false, label: 'False' },
];

function formatPhone(phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = (match[1] ? '+1 ' : '')
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return phoneNumberString;
}