import React from 'react';
import { Field } from 'react-final-form';
import { Form, Input, Button } from 'semantic-ui-react';


const FSFamilyMember = ({ placeholder, name, label, size, action=()=>{} }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<Input name={name} type={'text'} placeholder={placeholder} action={true} size={size} { ...input } >
						<input { ...input } />
						<Button onClick={action} icon='trash' />
					</Input>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSFamilyMember;