import gql from 'graphql-tag';

const queryGetUserTimecardTable = gql`
	query GetUserTimecardTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getUserTimecards(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				user
				in
				out
				paused {
					id
					start
					end
					reason
				}
				isOnBreak
				totalTime
				totalBreakTime
				totalPaidTime
			}
		}
	}

`;

export default queryGetUserTimecardTable;