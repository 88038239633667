import React, { useCallback } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import SelectInput from '../../FinalForm/SelectInput';

const SelectMonth = ({ onSubmit, month, year }) => {

	return (
		<Segment>
			<h4>Select a month to see it's report</h4>
			<FinalForm
				validate={validate}
				onSubmit={onSubmit}
				initialValues={{
					month,
					year
				}}
				render={({ handleSubmit, valid }) => (
					<Form onSubmit={handleSubmit}>
						<Form.Group widths="equal">
							<SelectInput
								name="month"
								options={months}
								placeholder="Month"
							/>
							<SelectInput
								name="year"
								options={calcYears()}
								placeholder="Year"
							/>
						</Form.Group>
						<Button disabled={!valid} fluid color="green">View Report</Button>
					</Form>
				)}
			/>
		</Segment>
	);
};

const validate = (values) => {
	const errors = {};
	if(values.month === null) {
		errors.month = 'Required Field';
	}
	if(!values.year) {
		errors.year = 'Required Field';
	}
	return errors;
};

const months = [
	{ value: 0, label: 'January', },
	{ value: 1, label: 'February', },
	{ value: 2, label: 'March', },
	{ value: 3, label: 'April', },
	{ value: 4, label: 'May', },
	{ value: 5, label: 'June', },
	{ value: 6, label: 'July', },
	{ value: 7, label: 'August', },
	{ value: 8, label: 'September', },
	{ value: 9, label: 'October', },
	{ value: 10, label: 'November', },
	{ value: 11, label: 'December', },
];

const calcYears = () => {
	let years = [];
	for(let i = 2017; i <= new Date().getFullYear(); i++) {
		years.push({ value: i, label: i });
	}
	return years;
};

export default SelectMonth;