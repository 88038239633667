import React, { useRef, useLayoutEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../../../../queries/user';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import GraphQLError from '../../../../Message/GraphQLError';
import gql from 'graphql-tag';

const query = gql`
	query GetCustomToken($id: String!) {
		getCustomToken(id: $id)
	}
`;

const Token = ({ id }) => {
	const { data } = useQuery(QUERY_CURRENT_USER);
	const hasAccess = _.get(data, 'user.accessTokens', false);
	const [getCustomToken, { data: tData, loading, error }] = useLazyQuery(query);
	const ref = useRef();

	useLayoutEffect(() => {
		if(ref.current) {
			ref.current.style.height = '1px';
			ref.current.style.height = (25+ref.current.scrollHeight)+'px';
		}
	}, [tData]);

	if(!hasAccess) {
		return <div></div>;
	}

	const submit = () => {
		getCustomToken({ variables: { id }});
	};

	const copy = (e) => {
		ref.current.select();
		document.execCommand('copy');
		e.target.focus();
	};

	return (
		<div style={{ marginTop: '25px' }}>
			<Button color="blue" loading={loading} disabled={loading} onClick={submit}>Get Login Token</Button>
			<GraphQLError error={error} />
			<div>
				<textarea 
					contentEditable={false}
					ref={ref}
					style={{ 
						wordBreak: 'break-all',
						border: 'none',
						backgroundColor: 'transparent',
						resize: 'none',
						outline: 'none',
						overflow: 'auto',
						width: '100%'
					}}
					value={_.get(tData, 'getCustomToken')}
				/>
			</div>
			{
				!!_.get(tData, 'getCustomToken') && (
					<Button onClick={copy} color="green" id="copy">Copy To Clipboard</Button>
				)
			}
		</div>
	);
};

export default Token;