import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import deleteStoreMutation from '../../../../mutations/deleteStore';

class Tools extends Component {
	delete = () => {
		this.props.mutate({
			variables: {
				id: this.props.data.id
			}
		}).then(() => {
			this.props.close && this.props.close();
		});
	}

	render() {
		return (
			<div>
				<Button color="red" onClick={this.delete}>Delete Store</Button>
			</div>
		);
	}
}

export default graphql(deleteStoreMutation)(Tools);