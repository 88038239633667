import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment-timezone';
import _ from 'lodash';
import axios from 'axios';
import { Modal, Segment, Menu, Icon, List, Divider } from 'semantic-ui-react';
import QUERY_GET_SHIPPING_ADDRESS_OF_REPRESENTATIVE from '../../../../queries/getShippingAddressOfRepresentative';
import Loading from '../../../Loading';
import Sales from '../../../Office/Sales/Sales';
import OrderTable from '../../../OrderTable/OrderTable';

const reasonText = {
	0: () => 'The representative has no downline and therefore cannot receive downline commission.',
	1: () => 'This is the representative\'s first month. Representatives are ineligable to receive downline commission their first month.',
	2: (needs) => `The representative does not met the quota for one sale every month since they signed up. They were off by $${needs.toFixed(2)}`,
};

const RepModal = ({ info, onClose, month, year }) => {
	return (
		<Modal 
			open={!!info}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
			dimmer="blurring"
		>
			{!!info && <Content info={info} month={month} year={year} />}
		</Modal>
	);
};

const Content = ({ info, month, year }) => {
	const [tab, setTab] = useState('info');
	const { begin, end } = getMonthDates(month, year);
	console.log('THE DATES', begin, end);
	const [printLoading, setPrintLoading] = useState(false);

	const print = async () => {
		if(!printLoading) {
			setPrintLoading(true);
			try {
				const res = await axios.post('/api/admin/monthlysalesreport/representative', { reportID: info.id });
				var w = window.open();
				w.document.write(res.data);
			} catch (error) {
				alert('Could not generate document');
			}
			setPrintLoading(false);
		}
	};

	return(
		<>
			<Modal.Header className="tableHeaderContainer">
				<h2>Viewing Report for {' '}
					<span style={{ textTransform: 'none'}}>{info.name}</span>
				</h2>
			</Modal.Header>
			<Modal.Content>
				<Menu attached="top" size="huge">
					<Menu.Item
						name='Info'
						active={tab === 'info'}
						onClick={() => setTab('info')}
					/>
					<Menu.Item
						name='Sales'
						active={tab === 'sales'}
						onClick={() => setTab('sales')}
					/>
					<Menu.Menu position='right'>
						<Menu.Item
							name='print'
							onClick={print}
						>
							<Icon name='print' />
						</Menu.Item>
					</Menu.Menu>
				</Menu>
				<Segment attached="bottom">
					{tab === 'info' &&
						<>
							<ShippingAddress userID={info.user} name={info.name} payType={info.payType} />
							<Reasons reasons={info.reasons} needs={info.needs} />
						</>
					}	
					{tab === 'sales' &&
						// <Sales 
						// 	id={info.user}
						// 	fixedHeight={300}
						// 	date={[begin, end]}
						// />
						<OrderTable
							// ref={tableRef}
							fixedParams={[
								{ id: 'account', value: info.user },
								{ id: 'fromDate', value: begin.valueOf() },
								{ id: 'toDate', value: end.valueOf() },
							]}
							// hideCols={['id', 'reference']}
						/>
					}
				</Segment>
			</Modal.Content>
		</>
	);
};

const Reasons = ({ reasons, needs }) => {

	if(!reasons || !reasons.length) {
		return null;
	}

	const makeItem = (reason) => {
		return (
			<List.Item>
				<List.Icon name='exclamation' size='large' verticalAlign='middle' />
				<List.Content>
					<List.Description>{reasonText[reason](needs)}</List.Description>
				</List.Content>
			</List.Item>
		);
	};

	return (
		<div>
			<Divider horizontal>Ineligablity for downline commission reasons</Divider>
			<List divided relaxed>
				{reasons.map(r => makeItem(r))}
			</List>
		</div>
	);
};

const ShippingAddress = ({ userID, name, payType }) => {
	const { data, loading } = useQuery(QUERY_GET_SHIPPING_ADDRESS_OF_REPRESENTATIVE, {
		variables: { userID }, 
	});

	if(payType === 1) {
		return (
			<div>The representative requested to be payed by direct deposit. The neccessary information is included in the ACH file.</div>
		);
	}

	const address = _.get(data, 'getShippingAddressOfRepresentative', {});

	const renderFormated = () => {
		const { street1, street2, city, state, zip, country} = address;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{name}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<div>
			<h2>Shipping Address</h2>
			<p>
				{loading && <Loading /> }
				{!loading && renderFormated() }
			</p>
		</div>
	);
};

function getMonthDates(month, year) {
	const middle = moment().set('year', year).set('month', month).set('date', 15).tz('America/Boise');
	const begin = moment(middle).startOf('month').toDate();
	const end = moment(middle).endOf('month').toDate();
	return { begin, end, middle };
}

RepModal.propTypes = {
	info: PropTypes.object,
	onClose: PropTypes.func,
};

RepModal.defaultProps = {
	info: null,
	onClose: () => {},
};

export default RepModal;