import gql from 'graphql-tag';

const QUERY_GET_FOLDER = gql`
	query GetFolder($id: String, $slug: String, $tag: String) {
		getFolder(id: $id, slug: $slug, tag: $tag) {
			id
			name
			slug
			order
			color
			files {
				id
				name
				description
				path
				filename
				mimetype
				external
				link
				order
			}
			folders {
				id
				name
				slug
				order
				color
				files {
					id
				}
			}
		}
	}
`;

export default QUERY_GET_FOLDER;