import React from 'react';
import BusinessPlanMembershipsTable from './BusinessPlanMembershipsTable';
import { Button, Segment } from 'semantic-ui-react';
import AddMembership from './AddMembership';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../../queries/user';
import _ from 'lodash';

const Memberships = () => {
	let tableRef = React.useRef(null);
	const { data, loading } = useQuery(QUERY_CURRENT_USER);

	const user = _.get(data, 'user');

	if(loading) { return null; }

	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Segment.Group stacked>
				<Segment color='green' className="tableHeaderContainer">
					<AddMembership
						id={user.hasBusinessPlan}
						onComplete={() => {
							if(tableRef && tableRef.current) {
								tableRef.current.refresh();
							}
						}}
					/>
					<h2>Memberships</h2>
					<Button
						onClick={() => {
							if(tableRef && tableRef.current) {
								tableRef.current.refresh();
							}
						}}
						content={<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true" />}
					/>
				</Segment>
				<Segment placeholder>
					<BusinessPlanMembershipsTable
						ref={tableRef}
						path="/business/memberships"
						fixedParams={[{ id: 'businessPlan', value: user.hasBusinessPlan }]}
						businessPlanID={user.hasBusinessPlan}
					/>
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default Memberships;