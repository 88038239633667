import React from 'react';
import { DetailsTable, DetailsRow } from '../../../../Table';

const FamilyInfo = ({ membership }) => {

	const { spouseFirstName, spouseLastName, additionalFamilyMembers } = membership;

	const members = additionalFamilyMembers || [];

	return (
		<div>
			<DetailsTable noHeader>
				<DetailsRow
					property="Spouse's Name"
					value={`${spouseFirstName} ${spouseLastName}`}
				/>
				<DetailsRow
					property="Family Members"
					value={members.reduce((prev, { name }, index) => {
						return prev + (index ? ', ' : '') + name;
					}, '')}
				/>
			</DetailsTable>
		</div>
	);
};

export default FamilyInfo;