import React from 'react';
import _ from 'lodash';
import querySearchCorporatePlans from '../../../../queries/searchCorporatePlans';
import styles from './corporate.module.scss';
import AsyncSelect from 'react-select/async';
import SellCorporateForm from './SellCorporateForm';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApolloClient } from '@apollo/react-hooks';
import { StripeProvider, Elements } from 'react-stripe-elements';

const SellCorporateMembership = () => {
	const client = useApolloClient();
	const [result, setResult] = React.useState(null);
	const [selected, setSelected] = React.useState(null);

	const fetchPlans = async (v) => {
		const result = await client.query({
			query: querySearchCorporatePlans,
			variables: { query: v },
		});
		return _.get(result, 'data.searchCorporatePlans', [])
			.map(ob => ({ label: `${ob.name} - ${ob.email}`, value: ob.id, ...ob }));
	};

	const openReceipt = () => {
		const id = _.get(result, 'id', null);
		if(id) {
			const production = process.env.NODE_ENV === 'production';
			const url = production
				? 'https://office.deluxenetworkprogram.com/api/receipt?id=' + id
				: 'http://localhost:5002/api/receipt?id=' + id;
			window.open(url, '_blank');
		}
	};

	if(result) {
		return (
			<div className={styles.complete}>
				<span>
					<FontAwesomeIcon icon={['fas', 'thumbs-up']} />
				</span>
				<h2>Purchase Complete</h2>
				<p>An email was sent out to the purchasing business</p>
				<div>
					<Button 
						color='blue'
						onClick={() => setResult(null)}
						content="Make another purchase"
					/>
					<Button 
						onClick={openReceipt}
						content="View Receipt"
					/>
				</div>
			</div>
		);
	}

	return (
		<div className="animated fadetwoIn dnp-page">
			<div className={styles.container}>
				<h1>Sell a Corporate Membership</h1>
				<div style={{ marginBottom: '20px' }}>
					<h2>Select a Corporate Plan</h2>
					<AsyncSelect
						cacheOptions
						onChange={(value) => setSelected(value)}
						loadOptions={fetchPlans}
						noOptionsMessage={() => 'Search by business name or email'}
					/>
				</div>
				{(selected && selected.id) && (
					<div>
						<h4 style={{ textAlign: 'center' }}>Purchasing Membership for {selected.name}</h4>
						<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
							<Elements>
								<SellCorporateForm 
									id={selected.id}
									onComplete={result => setResult(result)}
								/>
							</Elements>
						</StripeProvider>
					</div>
				)}
			</div>
		</div>
	);
};

export default SellCorporateMembership;