import React from 'react';
import { OrderDetailsTable, OrderDetailsRow } from '../helpers';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';

const productNames = {
	0: 'Individual Card',
	1: 'Family Card',
	3: 'Corporate Individual Card',
	4: 'Corporate Family Card',
	5: 'Group Corporate',
	[-1]: 'Mixed',
};

const BasicInfo = ({ data }) => {
	return (
		<div>
			<OrderDetailsTable>
				<OrderDetailsRow
					property={'Customer Name'}
					value={`${data.orderInformation.firstname || ''} ${data.orderInformation.lastname || ''}`}
				/>

				<OrderDetailsRow
					property={'Email'}
					value={data.orderInformation.email}
				/>

				<OrderDetailsRow
					property={'Phone'}
					value={data.orderInformation.phone}
				/>

				<OrderDetailsRow
					property={'Account Email'}
					value={_.get(data, 'account.email', 'N/A')}
				/>

				<OrderDetailsRow
					property={'Database ID'}
					value={data.id}
				/>

				<OrderDetailsRow
					property={'Product Type'}
					value={productNames[data.product] || 'Other'}
				/>

				<OrderDetailsRow
					property={'Order Total'}
					value={`$${data.total.toFixed(2)}`}
				/>

				<OrderDetailsRow
					property={'Status'}
					value={data.refunded ? 'REFUNDED' : (data.stripe && data.stripe.id ? data.stripe.status : 'Paid')}
				/>

				<OrderDetailsRow
					property={'Purchase Date'}
					value={moment(data.date).format('MMMM Do YYYY, h:mm:ss a')}
				/>

				<OrderDetailsRow
					property={'Requested Physical Product'}
					value={data.requestedPhysicalCard ? moment(data.requestedPhysicalCard).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
				/>

				<OrderDetailsRow
					property={'Physical Product Sent'}
					value={data.physicalCardSent ? moment(data.physicalCardSent).format('MMMM Do YYYY, h:mm:ss a') : 'N/A'}
				/>

			</OrderDetailsTable>
			<Button
				as="a"
				content="Open Receipt"
				href={`/api/receipt?id=${data.id}`}
				target="_blank"
			/>
		</div>
	);
};

export default BasicInfo;