import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import SocialMedia from './SocialMedia';
import { Container } from 'semantic-ui-react';
import { Route, Switch } from 'react-router-dom';
import OrderConfirmation from './OrderConfirmation';

const SocialMediaForm = () => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<SocialMedia />
			</Elements>
		</StripeProvider>
	);
};

const SociaMediaWrapper = () => {
	return (
		<Container style={{ marginTop: '20px' }}>
			<Switch>
				<Route exact path="/additions" component={SocialMediaForm} />
				<Route exact path="/additions/confirmation" component={OrderConfirmation} />
			</Switch>
		</Container>
	);
};

export default SociaMediaWrapper;