import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Modal, Form, Divider, Button } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FSCheckbox from '../FinalForm/Checkbox';
import TextInput from '../FinalForm/TextInput';
import styles from './checkoutForm.module.scss';
import FSReference from '../FinalForm/FSReference';
import FSState from '../FinalForm/FSState';
import FSCountry from '../FinalForm/FSCountry';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';
import _ from 'lodash';
import FSPromoCode from '../FinalForm/FSPromoCode';
import QUERY_GET_CONTRACT from '../../queries/getContract';
import Loading from '../Loading';
import Checkbox from '../FinalForm/StyledCheckbox';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import FSStripe from '../FinalForm/FSStripe';
import checkoutValidationSchema from './validationSchema';
import validator from '../../utils/validator';
import MUTATION_SELL_CARD from '../../mutations/sellCard';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import FSFamilyMember from '../FinalForm/FSFamilyMember';
import GraphQLError from '../Message/GraphQLError';
import QUERY_DOES_ACCOUNT_EXIST from '../../queries/doesAccountExist';

const CheckoutForm = ({ plan, stripe, history }) => {
  // { amount_off, percent_off, valid, id }
  const { data, loading } = useQuery(QUERY_CURRENT_USER);
  const [sellCard, { data: mutationData, loading: mutationLoading, error }] =
    useMutation(MUTATION_SELL_CARD, {
      errorPolicy: 'all',
    });

  useEffect(() => {
    if (_.get(mutationData, 'sellCard.id')) {
      const id = _.get(mutationData, 'sellCard.id');
      history.push('/tools/sell/confirmation?id=' + id);
    }
  }, [mutationData]);
  // if(loading) { return <div></div>; }

  return (
    <div className={styles.container}>
      <div className={styles.headerImage}>
        <img src={plan.img} />
        <div />
        <h3>
          Selling
          <br />
          {plan.name} Plan
        </h3>
      </div>
      <FinalForm
        validate={validator(checkoutValidationSchema)}
        mutators={{
          ...arrayMutators,
          setFree: (args, state, utils) => {
            utils.changeValue(state, 'isFree', () => args[0]);
          },
        }}
        onSubmit={({
          stripeName,
          stripeZip,
          reference,
          isFree,
          ...variables
        }) => {
          if (!isFree) {
            stripe
              .createToken({
                name: stripeName,
                address_zip: stripeZip,
              })
              .then(({ token, error }) => {
                if (error || !token.id) {
                  alert('Card Failure');
                } else {
                  sellCard({
                    variables: {
                      token: token.id,
                      plan: plan.type,
                      reference: _.get(reference, 'value', null),
                      ...variables,
                    },
                  });
                }
              });
          } else {
            sellCard({
              variables: {
                token: 'none',
                plan: plan.type,
                reference: _.get(reference, 'value', null),
                ...variables,
              },
            });
          }
        }}
        initialValues={{
          reference: _.get(data, 'user.id')
            ? {
                value: data.user.id,
                label: `${data.user.firstname} ${data.user.lastname} - ${data.user.email}`,
              }
            : null,
          country: 'US',
          contractAgree: false,
          createAccount: true,
        }}
        keepDirtyOnReinitialize={true}
        render={({
          handleSubmit,
          valid,
          values,
          form: {
            mutators: { push, setFree },
          },
        }) => (
          <CForm
            plan={plan}
            mutationLoading={mutationLoading}
            handleSubmit={handleSubmit}
            push={push}
            valid={valid}
            error={error}
            values={values}
            setFree={setFree}
          />
        )}
      />
    </div>
  );
};

const CForm = ({
  plan,
  mutationLoading,
  handleSubmit,
  push,
  valid,
  error,
  values,
  setFree,
}) => {
  const [promocode, setPromocode] = useState(null);
  const [price, setPrice] = useState(plan.price);
  const [checkEmail, { data, loading }] = useLazyQuery(
    QUERY_DOES_ACCOUNT_EXIST,
    {
      fetchPolicy: 'network-only',
    }
  );

  useMemo(() => {
    let price = plan.price * 100 + (values.requestedPhysicalCard ? 1000 : 0);
    if (_.get(promocode, 'valid')) {
      if (_.get(promocode, 'amount_off')) {
        price = Math.max(price - _.get(promocode, 'amount_off', 0), 0);
      } else if (_.get(promocode, 'percent_off')) {
        price = price * (1 - _.get(promocode, 'percent_off') / 100);
      }
    }
    const isFree = price / 100 === 0;
    let rprice = price / 100;
    if (isFree) {
      rprice = 0;
    }
    setPrice(rprice);
    setFree(isFree);
  }, [promocode, values.requestedPhysicalCard]);

  const doesAccountExist = _.get(data, 'doesAccountExist', false);

  return (
    <Form onSubmit={handleSubmit} loading={mutationLoading}>
      <div className={styles.sectionHeader}>Contact Information</div>
      <ContactInformationSection checkEmail={checkEmail} />
      {plan.type === 1 && (
        <Fragment>
          <div className={styles.sectionHeader}>Family Members Information</div>
          <FamilyMembersSection addMember={push} />
        </Fragment>
      )}
      <div className={styles.sectionHeader}>Additional Information</div>
      <AdditionalInformationSection
        setPromocode={(info) => setPromocode(info)}
      />
      {values.email && (
        <div className={styles.sectionHeader}>
          {doesAccountExist ? 'Link Account' : 'Create Account'}
        </div>
      )}
      {values.email && (
        <CreateAccountSection
          email={values.email}
          doesAccountExist={doesAccountExist}
        />
      )}
      <div className={styles.sectionHeader}>Payment Information</div>
      <PaymentInformationSection />
      {/* <div>{JSON.stringify(values)}</div>
			<div>{JSON.stringify(errors)}</div> */}
      <div className={styles.finalPrice}>
        <span>Final Price: </span>
        <span>${price.toFixed(2)}</span>
      </div>
      <GraphQLError error={error} />
      <Button disabled={!valid} fluid color='green' content='Submit Purchase' />
    </Form>
  );
};

const ContactInformationSection = ({ checkEmail }) => {
  return (
    <div className={styles.section}>
      <Form.Group widths='equal'>
        <TextInput
          name='firstname'
          placeholder='First Name'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'user']} className='inputIcon' />
          }
          size='large'
        />
        <TextInput
          name='lastname'
          placeholder='Last Name'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'user']} className='inputIcon' />
          }
          size='large'
        />
      </Form.Group>
      <Form.Group widths='equal'>
        <TextInput
          name='phone'
          placeholder='Phone'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'phone']} className='inputIcon' />
          }
          size='large'
        />
        <TextInput
          name='email'
          placeholder='Email'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'envelope']} className='inputIcon' />
          }
          size='large'
          onBlur={(e) => {
            checkEmail({ variables: { email: e.target.value } });
          }}
        />
      </Form.Group>
      <FSCheckbox
        name='requestedPhysicalCard'
        label={'Does the customer want a physical card? ($10 charge)'}
        style={{ fontSize: '15px' }}
        toggle
      />
      <Form.Group widths='equal'>
        <TextInput
          name='street1'
          placeholder='Street 1'
          label='Street'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon
              icon={['fas', 'map-marker']}
              className='inputIcon'
            />
          }
          size='large'
        />
      </Form.Group>
      <Form.Group widths='equal'>
        <TextInput
          name='street2'
          placeholder='Street 2'
          label={false}
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon
              icon={['fas', 'map-marker']}
              className='inputIcon'
            />
          }
          size='large'
        />
      </Form.Group>
      <Form.Group widths='equal'>
        <TextInput
          name='city'
          placeholder='City'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'city']} className='inputIcon' />
          }
          size='large'
        />
        <FSState
          name='state'
          placeholder='State'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon
              icon={['fas', 'map-marked']}
              className='inputIcon'
            />
          }
          size='large'
        />
      </Form.Group>
      <Form.Group widths='equal'>
        <TextInput
          name='zip'
          placeholder='Zipcode'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'location']} className='inputIcon' />
          }
          size='large'
        />
        <FSCountry
          name='country'
          placeholder='Country'
          required
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon
              icon={['fas', 'globe-americas']}
              className='inputIcon'
            />
          }
          size='large'
        />
      </Form.Group>
    </div>
  );
};

const FamilyMembersSection = ({ addMember }) => {
  return (
    <div className={styles.section}>
      <Form.Group widths='equal'>
        <TextInput
          name='spouseFirstName'
          placeholder='Spouse First Name'
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'user']} className='inputIcon' />
          }
          size='large'
        />
        <TextInput
          name='spouseLastName'
          placeholder='Spouse Last Name'
          iconPosition='left'
          fontAwesomeIcon={
            <FontAwesomeIcon icon={['fas', 'user']} className='inputIcon' />
          }
          size='large'
        />
      </Form.Group>

      <FieldArray name='members'>
        {({ fields }) =>
          fields.map((name, index) => (
            <div key={name} className={styles.memberContainer}>
              <FSFamilyMember
                name={`${name}.name`}
                placeholder='Full Name'
                label={`Family Member #${index + 1}`}
                action={() => fields.remove(index)}
              />
            </div>
          ))
        }
      </FieldArray>
      <div className={styles.right}>
        <Button type='button' onClick={() => addMember('members')}>
          Add Family Member
        </Button>
      </div>
    </div>
  );
};

const AdditionalInformationSection = ({ setPromocode = () => {} }) => {
  const { data, loading } = useQuery(QUERY_GET_CONTRACT, {
    variables: { type: 'MEMBER' },
  });

  return (
    <div className={styles.section}>
      <FSReference label='Search for Reference' name='reference' />
      <FSPromoCode
        name='discountCode'
        label='Promo Code'
        onApplied={(info) => setPromocode(info)}
        placeholder='Enter Promo Code here'
      />
      {loading && (
        <div className={styles.contract}>
          <Loading />
        </div>
      )}
      {!loading && (
        <div
          className={styles.contract}
          dangerouslySetInnerHTML={{ __html: _.get(data, 'getContract') }}
        />
      )}
      <Checkbox
        name='contractAgree'
        label='Does the customer agree to the terms & conditions listed above?'
      />
    </div>
  );
};

const CreateAccountSection = ({ email, doesAccountExist }) => {
  if (doesAccountExist) {
    return (
      <div className={styles.section}>
        <p>
          We have found an account for <strong>{email}</strong>
        </p>
        <p>
          Would you like to link the sale to this account? It would allow the
          customer to access their membership online, view deals, and more.
        </p>
        <FSCheckbox
          name='createAccount'
          label={'Auto-Link Aaccount'}
          style={{ fontSize: '15px' }}
          toggle
        />
      </div>
    );
  } else {
    return (
      <div className={styles.section}>
        <p>
          Would you like to auto-create an account for <strong>{email}</strong>?
          <br />
          An email will be sent with a temporary password and a email reset
          link.
        </p>
        <p>
          <strong>We encourge every DNP customer to have an account.</strong>
          <br />
          It provides many benefits such as being able to access your membership
          online.
        </p>
        <FSCheckbox
          name='createAccount'
          label={'Auto-Create Account'}
          style={{ fontSize: '15px' }}
          toggle
        />
      </div>
    );
  }
};

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const _PaymentInformationSection = () => {
  return (
    <div className={styles.section}>
      <div className='stripe'>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>
              Name on Card
              <Field
                name='stripeName'
                component='input'
                style={{ width: '100%' }}
                placeholder='Cardholder Name'
              />
            </label>
          </Form.Field>

          <Form.Field>
            <label>
              Card #
              <FSStripe
                component={CardNumberElement}
                {...createOptions(20)}
                name='stripeCard'
              />
            </label>
          </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field>
            <label>
              EXP
              <FSStripe
                {...createOptions(20)}
                component={CardExpiryElement}
                name='stripeExpiry'
              />
            </label>
          </Form.Field>

          <Form.Field>
            <label>
              CVC
              <FSStripe
                component={CardCvcElement}
                {...createOptions(20)}
                name='stripeCVC'
              />
            </label>
          </Form.Field>

          <Form.Field>
            <label>
              Zip
              <Field
                name='stripeZip'
                component='input'
                style={{ width: '100%' }}
                placeholder='Zip Code'
              />
            </label>
          </Form.Field>
        </Form.Group>
      </div>
      <div className={styles.poweredBy}>
        <a
          href='https://www.stripe.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={require('../../img/powered_by_stripe.svg')} />
        </a>
      </div>
    </div>
  );
};

const PaymentInformationSection = _PaymentInformationSection;

export { PaymentInformationSection };

export default injectStripe(CheckoutForm);
