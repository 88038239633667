import gql from 'graphql-tag';

const MUTATION_ADD_FOLDER = gql`
	mutation AddFolder($name: String!, $parentSlug: String, $tag: String) {
		addFolder(name: $name, parentSlug: $parentSlug, tag: $tag) {
			id
		}
	}

`;

export default MUTATION_ADD_FOLDER;