

const getProductID = (id) => {
	if(id === 'price_HNWB7nbO3SAGRk' || id === 'price_HNWCVqM0YLObXq' || id === 'price_1Hby7aFuKgqmG3zq7M9K63nb' || id === 'price_1Hby5mJcMYxdjFEVmVHdI4rq') {
		return 6;
	} else if (id === 'price_HNWB5PJj9bRNFi' || id === 'price_HNWCyA0cvOcSMc' || id === 'price_1Hby7kFuKgqmG3zqeRnoNiMN' || id === 'price_1Hby5vJcMYxdjFEViam8kXAi') {
		return 7;
	} else if (id === 'price_1Ifb1LFuKgqmG3zq8fltf1nP' || id === 'price_1Ifax7JcMYxdjFEVjDMXFoBR') {
		return 8;
	} else if (id === 'price_1Ifb1LFuKgqmG3zq7awbru0T' || id === 'price_1Ifax7JcMYxdjFEVGu6fstmr') {
		return 9;
	}

	return id;
};

export default getProductID;