import React, { useState } from 'react';
import { Menu, Segment, Table } from 'semantic-ui-react';
import gql from 'graphql-tag';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Sales from '../Office/Sales/Sales';
import Loading from '../Loading';
import OrderTable from '../OrderTable/OrderTable';

const QUERY_DOWNLINE_INFO = gql`
	query DownlineInfo($id: String, $month: Int!, $year: Int!) {
		user(firebaseID: $id) {
			id
			email
			currentMonthSales
			lastMonthSales
			SalesInfo(month: $month, year: $year) {
				allSalesTotal
				Commission {
					isEligible
				}
			}
		}
	}
`;

const BasicInfo = ({ user }) => {

	const allSales = _.get(user, 'SalesInfo.allSalesTotal', 0) || 0;
	const lastMonthSales = _.get(user, 'lastMonthSales', 0) || 0;
	const currentMonthSales = _.get(user, 'currentMonthSales', 0) || 0;

	const renderRow = (title, value) => {
		return (
			<Table.Row>
				<Table.Cell>{title}</Table.Cell>
				<Table.Cell>{value}</Table.Cell>
			</Table.Row>
		);
	};

	return (
		<Table celled striped definition>
			<Table.Body>
				{renderRow('Email', user.email)}
				{renderRow('Last Month Sales', '$' + lastMonthSales.toFixed(2) )}
				{renderRow('This Month Sales', '$' + currentMonthSales.toFixed(2) )}
				{renderRow('All Sales', '$' + allSales.toFixed(2) )}
			</Table.Body>
		</Table>
	);
};

const DownlineInfo = ({ id }) => {
	const date = new Date();
	const { data, loading } = useQuery(QUERY_DOWNLINE_INFO, {
		variables: {
			id,
			month: date.getMonth(),
			year: date.getFullYear(),
		},
	});

	console.log(data);

	const [tab, setTab] = useState('info');

	const user = _.get(data, 'user', {});

	return (
		<div>
			<Menu attached="top" className="scrollMenu">
				<Menu.Item name='Info' active={tab === 'info'} onClick={() => setTab('info')} />
				<Menu.Item name='Sales' active={tab === 'sales'} onClick={() => setTab('sales')} />
			</Menu>

			<Segment attached='bottom'>
				{loading ? <Loading /> :
					(<>
						{tab === 'info' && <BasicInfo user={user} />}
						{tab === 'sales' && (<div>
							{/* <Sales id={this.props.id} fixedHeight={300} /> */}
							<OrderTable
								fixedParams={[
									{ id: 'referenceID', value: id },
								]}
								hideCols={['reference']}
							/>
						</div>
						)}
					</>)
				}
			</Segment>
		</div>
	);
};

DownlineInfo.propTypes = {
	id: PropTypes.string.isRequired,
};

export default DownlineInfo;