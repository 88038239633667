import ChangeTextField from '../../../ChangeField/ChangeTextField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import modifyLocationMutation from '../../../../mutations/modifyLocation';

export const ChangeTextFieldLocation = compose(
	graphql(modifyLocationMutation),
	withStateMutation(),
)(ChangeTextField);

export const ChangeSelectFieldLocation = compose(
	graphql(modifyLocationMutation),
	withStateMutation(),
)(ChangeSelectField);
