/* eslint no-unused-vars: 0 no-console: 0 */
import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';

const GeneralInfo = ({ data }) => {

	const TextRow = ({ title, value, renderValue }) => {
		const v = _.get(data, `${value}`, 'N/A') || 'N/A';
		return (
			<Table.Row>
				<Table.Cell>{title}</Table.Cell>
				<Table.Cell>{renderValue ? renderValue(v) : v}</Table.Cell>
			</Table.Row>
		);
	};

	return (
		<div>
			<Table definition striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{TextRow({ title: 'Business Name', value: 'businessName' })}
					{TextRow({ title: 'Store Type', value: 'type' })}
					{TextRow({ title: 'Contact Name', value: 'contactname' })}
					{TextRow({ title: 'Contact Email', value: 'contactemail' })}
					{TextRow({ title: 'Contact Phone', value: 'contactphone' })}
					{TextRow({ title: 'Reference', value: 'parent', renderValue: (v) => v === 'N/A' ? 'N/A' : `${_.get(v, 'firstname')} ${_.get(v, 'lastname')}`})}
					{TextRow({ title: 'Account Holder', value: 'user', renderValue: (v) => v === 'N/A' ? 'N/A' : `${_.get(v, 'firstname')} ${_.get(v, 'lastname')}`})}

					{TextRow({ title: 'Database ID', value: 'id' })}
					{TextRow({ title: 'National Location', value: 'nationalLocation', renderValue: (v) => v === 'N/A' ? 'false' : 'true' })}
					{TextRow({ title: 'Reference', value: 'reference' })}

				</Table.Body>
			</Table>
		</div>
	);
};

export default GeneralInfo;