import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Segment } from 'semantic-ui-react';
import img from '../../img/dashboard_welcome.svg';

class WelcomeWidget extends Component {
	render() {
		return(
			<div>
				<Card>
					<CardBody>
						<CardTitle style={{textAlign: 'center', fontSize: '30px'}}>Welcome to the DNP Back Office</CardTitle>
					</CardBody>
					<img src={img} style={{width: '100%'}} className="img-fluid" alt="Welcome" draggable={false} />
					{/* <CardBody>

						<h4>Message from Corporate:</h4>
						<Segment>
							<p style={{textAlign: 'center', fontSize: '25px'}}>{this.props.message || 'No Message Set'}</p>
						</Segment>

					</CardBody> */}
				</Card>
			</div>
		);
	}
}

export default WelcomeWidget;
