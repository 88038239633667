import React from 'react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Form as FinalForm } from 'react-final-form';
import { Segment, Form, Button } from 'semantic-ui-react';
import FSSelectInput from '../FinalForm/SelectInput';
import CheckForm from './CheckForm';
import DirectDepositForm from './DirectDepositForm';
import MUTATION_CHANGE_PAYMENT_INFORMATION from '../../mutations/changePaymentInformation';
import QUERY_GET_USER_FOR_PAYMENT from './getUser';
import Loading from '../Loading';
import GraphQLError from '../Message/GraphQLError';

const typeOptions = [
	{ label: 'Check', value: 0 },
	{ label: 'Direct Deposit', value: 1 }
];

const Payment = () => {
	const [changePaymentInformation, { loading: mLoading, error, data: mData }] = useMutation(MUTATION_CHANGE_PAYMENT_INFORMATION);
	const { loading, data } = useQuery(QUERY_GET_USER_FOR_PAYMENT);

	if(loading) {
		return <Loading />;
	}

	const onSubmit = (variables) => {
		changePaymentInformation({ variables }).then(() => alert('Payment Changed Successfully'));
	};

	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group style={{ maxWidth: '900px', width: '100%', margin: '0 auto' }}>
				<Segment className="tableHeaderContainer">
					<h2>Payment Options</h2>
					<div />
				</Segment>
				<Segment>
					<FinalForm
						validate={validate}
						initialValues={{
							type: _.get(data, 'user.paymentType', 0),
							..._.get(data, 'user.representativeAddress', {}),
							..._.get(data, 'user.directDepositInfo', {}),
						}}
						onSubmit={onSubmit}
						render={({ values, handleSubmit, valid }) => (
							<Form onSubmit={handleSubmit} loading={mLoading}>
								<GraphQLError error={error} />
								<FSSelectInput name="type" options={typeOptions} label="Payment Type" />
								{ values.type === 0 && <CheckForm /> }
								{ values.type === 1 && <DirectDepositForm /> }
								<Button 
									type="submit"
									content="Submit"
									fluid
									color={valid ? 'green' : 'grey'}
									disabled={!valid}
								/>
							</Form>
						)}
					/>
				</Segment>
			</Segment.Group>
		</div>
	);
};

function validate(form) {
	const errors = {};

	if(form.type === null) {
		errors.type = 'Required Field';
	}

	if(form.type === 0) {

		if(!form.street1) {
			errors.street1 = 'Required Field';
		}
		if(!form.city) {
			errors.city = 'Required Field';
		}
		if(!form.state) {
			errors.state = 'Required Field';
		}
		if(!form.zip) {
			errors.zip = 'Required Field';
		}
		if(!form.country) {
			errors.country = 'Required Field';
		}

	} else if(form.type === 1) {

		if(!form.name) {
			errors.name = 'Required Field';
		}
		if(!form.rtn) {
			errors.rtn = 'Required Field';
		}
		if(!form.acn) {
			errors.acn = 'Required Field';
		}
		if(!form.at === 0 || !form.at === 1) {
			errors.at = 'Required Field';
		}
		if(!form.bName) {
			errors.bName = 'Required Field';
		}

	}

	return errors;
}

export default Payment;
