import React, {Component} from 'react';
import { Popup } from 'semantic-ui-react';
import axios from 'axios';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

class CommissionWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			isEligible: false,
			reasons: null,
		};

		this.renderTeamSales = this.renderTeamSales.bind(this);
		this.renderNo = this.renderNo.bind(this);
	}

	componentDidMount() {
		this.getValues();
	}

	async getValues() {
		this.setState({loading: true});

		var date = new Date();
		date.setMonth(date.getMonth() + 1);

		const data = await axios.get(`/graphql?query={
      user{
        SalesInfo(month: ${date.getMonth()} year: ${date.getFullYear()}) {
          Commission {
            isEligible
            reasons {
              childCount
              firstMonth
              sales
            }
          }
        }
      }
    }`);
		const { isEligible, reasons } = data.data.data.user.SalesInfo.Commission;

		this.setState({isEligible, reasons, loading: false});
	}

	renderAllGood() {
		const { isEligible } = this.props.commission;
		if(isEligible && this.state.isEligible) {
			return(
				<div>
          You will be receiving commission this month, and you are all ready for next month!
				</div>
			);
		}
	}

	renderChildCount(c) {
		if(c) {
			return(
				<li>Invite someone to become a representative!</li>
			);
		}
	}

	renderSales(s) {
		if(s != 0) {
			return(
				<li>Make ${s.toFixed(2)} worth of sales</li>
			);
		}
	}

	renderPoints(f, c, s) {
		if(f) {
			return(
				<li>You cannot receive downline commission your first month</li>
			);
		} else {
			return(
				<div>
					{this.renderChildCount(c)}
					{this.renderSales(s)}
				</div>
			);
		}

	}

	renderNoCurrent() {
		const { isEligible, reasons } = this.props.commission;

		if(isEligible) {
			return(
				<div style={{textAlign: 'center'}}>You have met the requirements to receive commission this month</div>
			);
		} else {
			return(
				<div>
          To receive downline commission this month, complete the following:
					<ul>
						{this.renderPoints(reasons.firstMonth, reasons.childCount, reasons.sales)}
					</ul>
				</div>
			);
		}
	}

	renderNoNext() {
		const isEligible = this.state.isEligible;
		const reasons = this.state.reasons;

		if(isEligible) {
			return(
				<div style={{textAlign: 'center'}}>You have met the requirements to receive commission next month.</div>
			);
		} else {
			return(
				<div>
          You are not eligible for commission next month.
					<ul>
						{this.renderPoints(reasons.firstMonth, reasons.childCount, reasons.sales)}
					</ul>
				</div>
			);
		}
	}

	renderNo() {
		const { isEligible } = this.props.commission;
		const nextMonth = this.state.isEligible;

		if(!isEligible || !nextMonth) {
			return(
				<div>
					{this.renderNoCurrent()}
					<hr />
					{this.renderNoNext()}
				</div>
			);
		}
	}

	renderReasons() {
		return(
			<div>
				{this.renderAllGood()}
				{this.renderNo()}
			</div>
		);
	}

	renderTeamSales() {

		if(this.props.accountType === 1) {
			return(<div></div>);
		}

		var { team, isEligible, } = this.props.commission;
		const isEligibleFuture = this.state.isEligible;


		const style1 = {color: 'black'};
		const style2 = {color: 'orange'};
		const style3 = {color: 'red'};

		var applyStyle = ( isEligible && isEligibleFuture ) ? style1 : false;

		if(!applyStyle) {
			applyStyle = isEligible ? style2 : style3;
		}

		if(!isEligible) {
			team = 0;
		}

		return(
			<Popup 
				wide
				basic
				trigger= {
					<li>
						<strong style={applyStyle}>{`$${team.toFixed(2)}`}</strong>
						<span>Team Sales</span>
					</li>
				}
				content={this.renderReasons()}
			/>
		);
	}

	render() {

		const date = new Date();

		var { personal, team, isEligible } = this.props.commission;

		if(!isEligible || this.props.accountType === 1) {
			team = 0;
		}

		const total = personal + team;

		if(this.state.loading) {
			return(
				<div></div>
			);
		}

		return (
			<div className='social-box commission'>
				<div>
					<h3>Sharing</h3>
					<h4>{months[date.getMonth()]} - {date.getUTCFullYear()}</h4>
				</div>

				<i className='fa fa-usd'></i>
				<ul>
					<li>
						<strong>{`$${personal.toFixed(2)}`}</strong>
						<span>Personal Sales</span>
					</li>
					{this.renderTeamSales()}
				</ul>


				<div className="bottom">
					<div className="bottom divider">

					</div>
				</div>

				<div className="bottom">
					<strong>{`$${total.toFixed(2)}`}</strong>
					<span>Total</span>
				</div>

			</div>
		);
	}
}

export default CommissionWidget;
