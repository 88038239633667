import React from 'react';
import _ from 'lodash';
import { Table, Divider } from 'semantic-ui-react';
import queryGetUserAdmin from '../../../../queries/getUserAdmin';
import { ChangeTextFieldUser, ChangeSelectFieldUser } from './UserChangeFields';
import { states, countries } from '../../../../config/supported_areas';

const AddressPage = ({ user }) => {

	console.log(user);

	if(!user)
		return <div>No Information of this User</div>

	const refetch = [{
		query: queryGetUserAdmin,
		variables: { id: user?.id }
	}];

	const renderFormated = () => {
		const { firstname, lastname, representativeAddress: { street1, street2, city, state, zip, country } } = user;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{firstname} {lastname}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<div className="admin-sale-info">
			<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Address</h4>
			<hr />

			<Table definition striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<ChangeTextFieldUser
						label={'Street 1'}
						value={user.representativeAddress.street1}
						property={'representativeAddress.street1'}
						refetch={refetch}
						id={user.id}
						table
					/>

					<ChangeTextFieldUser
						label={'Street 2'}
						value={user.representativeAddress.street2}
						property={'representativeAddress.street2'}
						refetch={refetch}
						id={user.id}
						table
					/>

					<ChangeTextFieldUser
						label={'City'}
						value={user.representativeAddress.city}
						property={'representativeAddress.city'}
						refetch={refetch}
						id={user.id}
						table
					/>

					<ChangeSelectFieldUser
						options={states}
						label={'State'}
						value={user.representativeAddress.state}
						property={'representativeAddress.state'}
						refetch={refetch}
						id={user.id}
						table
					/>

					<ChangeTextFieldUser
						label={'Zip'}
						value={user.representativeAddress.zip}
						property={'representativeAddress.zip'}
						refetch={refetch}
						id={user.id}
						table
					/>

					<ChangeSelectFieldUser
						options={countries}
						label={'Country'}
						value={user.representativeAddress.country}
						property={'representativeAddress.country'}
						refetch={refetch}
						id={user.id}
						table
					/>
				</Table.Body>
			</Table>
			<Divider horizontal>Formated Address</Divider>
			{renderFormated()}
		</div>
	);
};

export default AddressPage;