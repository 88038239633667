import gql from 'graphql-tag';

const queryGetSaleAdmin = gql`

	query queryGetSaleAdmin($id: String!) {
		getSaleAdmin(id: $id) {
			id 
			customer {
				id 
				firstname
				lastname
				email
				zip
				accountType
			}
			spouseFirstName
			spouseLastName
			total
			date
			plan
			stripeCustomerID
			stripeSubscriptionID
			stripeInvoiceID
			stripeChargeID
			shippingAddress {
				street1
				street2
				city
				state
				zip
				country
			}
			reference {
				id
				firstname
				lastname
			}
			firstname
			lastname
			email
			phone
			valid
			membershipID
			renewal
			additionalFamilyMembers {
				name
			}
			requestedPhysicalCard
			physicalCardSent
		}
	}

`;

export default queryGetSaleAdmin;