import React from 'react';
import { Field } from 'react-final-form';
import { Form  } from 'semantic-ui-react';
import SimpleSelect from '../Form/SimpleSelect';

const FSSelectInput = ({ options, name, required, label, placeholder, containerStyle, ...rest }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div style={containerStyle}>
						<SimpleSelect
							options={options}
							value={input.value}
							onChange={value => {
								if(value) {
									input.onChange(value.value);
								} else {
									input.onChange(null);
								}
							}}
							searchable={true}
							{...rest}
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSSelectInput;