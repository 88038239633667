import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './auth_reducer';
import userInfo from './user_info';
import socket_reducer from './socket_reducer';

export default combineReducers({
	auth: authReducer,
	userInfo: userInfo,
	form: reduxForm,
	socket: socket_reducer
});
