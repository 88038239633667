import gql from 'graphql-tag';

const QUERY_IS_PROMO_CODE_VALID = gql`

	query IsPromoCodeValid($id: String!) {
		isPromoCodeValid(id: $id) {
			id
			percent_off
			amount_off
			valid
		}
	}

`;

export default QUERY_IS_PROMO_CODE_VALID;