import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { DetailsTable } from '../../Table';
import DetailsRow from '../../Table/DetailsRow';
import _ from 'lodash';
import ChangeSource from './ChangeSource';
import RetryPayment from './RetryPayment';

const CardDetails = ({ plan, refetch }) => {
	const {
		last4,
		exp_month,
		exp_year,
		brand,
		name,
	} = _.get(plan, 'stripe.Customer.default_source', {}) || {};

	return (
		<Segment.Group>
			<Segment color="purple">
				<Header as="h3" textAlign="center">
				Payment Information
				</Header>
			</Segment>
			<Segment secondary>
				<DetailsTable noHeader>
					<DetailsRow
						property="Name"
						value={name}
					/>
					<DetailsRow
						property="Last 4"
						value={last4}
					/>
					<DetailsRow
						property="EXP"
						value={`${exp_month}/${exp_year}`}
					/>
					<DetailsRow
						property="Brand"
						value={brand}
					/>
				</DetailsTable>
			</Segment>
			<Segment className="containerRight">
				<RetryPayment plan={plan} />
				<ChangeSource id={plan.id} refetch={refetch} />
			</Segment>
		</Segment.Group>
	);
};

export default CardDetails;