import gql from 'graphql-tag';


const MUTATION_EDIT_FOLDER = gql`

	mutation EditFolder($id: String!, $name: String!, $color: String) {
		editFolder(id: $id, name: $name, color: $color) {
			id
			name
			color
			slug
		}
	}

`;

export default MUTATION_EDIT_FOLDER;