/* eslint-disable react/display-name */
import React from 'react';
import _ from 'lodash';
import GeneralInfo from './GeneralInfo';
import Account from './Account';
import Status from './Status';
import Group from './Group/';
import Edit from './Edit/';
import Orders from './Orders';
import Loading from '../../../Loading';
import { Menu, Segment, Label } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_MEMBERSHIP from '../../../../queries/getMembership';


const statusColor = {
	'active' : 'blue',
	'unpaid' : 'orange',
	'past_due' : 'orange',
	'canceled' : 'red',
	'expired': 'red',
};

const EditMembership = ({ id, onClose }) => {
	const [activeItem, setActiveItem] = React.useState('general');
	const { data, loading } = useQuery(QUERY_GET_MEMBERSHIP, {
		variables: { id },
	});
	const membership = _.get(data, 'getMembership', {});

	const content = React.useRef({
		'general' : (m) => <GeneralInfo membership={m} />,
		'account' : (m) => <Account membership={m} />,
		'status' : (m) => <Status membership={m} onClose={onClose} />,
		'edit' : (m) => <Edit membership={m} />,
		'group' : (m) => <Group membership={m} />,
		'orders' : (m) => <Orders membership={m} />,
		'unknown' : () => <div>Page not found</div>,
	}).current;

	// Make sure the data is loading...
	if(loading) {
		return <Loading />;
	} else if (!_.get(data, 'getMembership.id')) {
		return <div>Could not load content...</div>;
	}

	const renderTab = (name, value, label=null, condition=true) => {
		if(!condition) { return null; }
		return (
			<Menu.Item
				name={value}
				active={activeItem === value}
				onClick={() => setActiveItem(value)}
			>
				{name}
				{label && label}
			</Menu.Item>
		);
	};
	
	return (
		<div>
			<Menu attached='top' tabular>
				{renderTab('General Info', 'general')}
				{renderTab(
					'Account',
					'account',
					membership.accountEmail ? <Label color='green'>Connected</Label> : <Label color='red'>No Account</Label>
				)}
				{renderTab(
					'Status',
					'status',
					<Label color={membership.lastInvoiceRefunded ? 'orange' : statusColor[membership.updatedStatus] || 'grey'}>{membership.lastInvoiceRefunded ? 'REFUNDED' : membership.updatedStatus}</Label>
				)}
				{renderTab('Edit', 'edit')}
				{renderTab('Group', 'group', null, membership.plan === 5)}
				{renderTab('Orders', 'orders', <Label color='grey'>{membership.orders ? membership.orders.length : 0}</Label>)}
			</Menu>

			<Segment attached='bottom'>
				{content[activeItem] ? content[activeItem](membership) : content['unknown']()}
			</Segment>
		</div>
	);
};

export default EditMembership;