import React, { Component } from 'react';
import { Segment, Button, Divider } from 'semantic-ui-react';
import mutationDeletePromoCode from '../../../mutations/deletePromoCode';
import withStateMutation from '../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

class Code extends Component {

	deletePromoCode = async () => {
		const { id } = this.props.data.original;
		try {
			await this.props.mutate({
				variables: { id },
			});
			this.props.refresh && this.props.refresh();
		} catch (error) {
			//
		}
	}

	render() {
		return (
			<Segment raised stacked>
				<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Actions</h4>
				<Button color='red' size='small' onClick={this.deletePromoCode}>Delete Promo Code</Button>
			</Segment>
		);
	}
}

export default compose (
	graphql(mutationDeletePromoCode),
	withStateMutation(),
)(Code);