import React, { useState } from 'react';
import { List, Button, Popup, Segment } from 'semantic-ui-react';
import MemberPopup from './MemberPopup';
import AddMember from './AddMember';
import GraphQLError from '../../../../Message/GraphQLError';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_MODIFY_MEMBERSHIP from '../../../../../mutations/modifyMembership';
import QUERY_GET_MEMBERSHIP from '../../../../../queries/getMembership';


const AdditionalFamilyMembers = ({ id, data=[] }) => {
	const [modifyMembership, { loading, error }] = useMutation(MUTATION_MODIFY_MEMBERSHIP);
	const [nopen, setNOPEN] = useState(false);
	const [popen, setPOPEN] = useState(false);
	const [index, setIndex] = useState(null);
	const [name, setName] = useState('');

	const getArray = () => {
		return (data || []).map(ob => {
			return ob.name; 
		});
	};

	const submit = (arr) => {
		modifyMembership({
			variables: {
				additionalFamilyMembers: arr,
				id,
				property: 'additionalFamilyMembers'
			},
			refetchQueries: [{
				query: QUERY_GET_MEMBERSHIP,
				variables: { id }
			}]
		});
	};

	const addMember = (name) => {
		const arr = getArray();
		arr.push(name);
		setNOPEN(false);
		submit(arr);
	};

	const changeName = (newName) => {
		const arr = getArray();
		arr[index] = newName;
		setPOPEN(false); setIndex(null); setName(null);
		submit(arr);
	}

	const deleteMember = () => {
		const arr = getArray();
		arr.splice(index, 1);
		setPOPEN(false); setIndex(null); setName(null);
		submit(arr);
	}

	const renderEditForm = () => {
		return (
			<div>
				<MemberPopup value={name} onComplete={changeName} />
				<Button color='red' onClick={deleteMember} inverted content="Delete" />
			</div>
		);
	}

	const handleMemberClick = (i, name) => {
		setName(name);
		setIndex(i);
		setPOPEN(v => i === index ? !v : true);
	}

	const renderData = () => {
		if(data) {
			return data.map((obj, index) => {
				return (
					<List.Item as='a' onClick={() => handleMemberClick(index, obj.name)} key={`${obj.name}${index}`}>
						<List.Icon name='user' />
						<List.Content>
							<List.Header>{obj.name}</List.Header>
						</List.Content>
					</List.Item>
				);
			});
		}
	}

	return (
		<Segment loading={loading}>
				<GraphQLError error={error}>There was an issue</GraphQLError>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Popup
						content={<AddMember onComplete={addMember} />}
						trigger={(
							<Button
								onClick={() => setNOPEN(v => !v)}
								content="Add Family Member"
							/>
						)}
						open={nopen}
					/>
				</div>
				<Popup
					content={renderEditForm()}
					trigger={(
						<List divided relaxed verticalAlign='middle'>
							{renderData()}
						</List>
					)}
					open={popen}
				/>
			</Segment>
	);
};

export default AdditionalFamilyMembers;