import React from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_RETRY_PAYMENT from '../../../mutations/retryPayment';
import { QUERY_BUSINESS_PLAN_USER } from './Home';

const RetryPayment = ({ plan }) => {
	const [retryPayment, { loading }] = useMutation(MUTATION_RETRY_PAYMENT);

	if(_.get(plan, 'stripe.status') !== 'past_due') {
		return null;
	}

	const onSubmit = () => {
		retryPayment({
			variables: { id: plan.id, type: 1 },
			awaitRefetchQueries: true,
			refetchQueries: [{ query: QUERY_BUSINESS_PLAN_USER }],
		}).catch(error => {
			const message = _.get(error, 'graphQLErrors[0].message', 'Please contact customer support');
			alert(message);
		});
	};

	return (
		<>
			<Button
				content="Retry Payment"
				color="red"
				onClick={onSubmit}
				loading={loading}
				disabled={loading}
			/>
		</>
	);
};

export default RetryPayment;