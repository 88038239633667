import React from 'react';
import UsersTableContainer from './UsersTableContainer';
import { Segment, Button } from 'semantic-ui-react';
import { CreateUserModal } from '../common/CreateUser';

const AdminUsersPage = () => {
	let tableRef = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<CreateUserModal open={open} onClose={() => setOpen(false)} />
			<Segment.Group stacked>
				<Segment color='purple' className="tableHeaderContainer">
					<Button
						onClick={() => setOpen(v => !v)}
						content={<i className="fa fa-plus fa-lg filterIcon" aria-hidden="true" />}
					/>
					<h2>Users Management</h2>
					<Button
						onClick={() => {
							if(tableRef && tableRef.current) {
								tableRef.current.refresh();
							}
						}}
						content={<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true" />}
					/>
				</Segment>
				<Segment placeholder>
					<UsersTableContainer
						ref={tableRef}
						path="/admin/users"
					/>
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default AdminUsersPage;