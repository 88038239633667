import gql from 'graphql-tag';

const QUERY_GET_ORDER = gql`
	query GetOrder($id: String!) {
		getOrder(id: $id) {
			id
			account {
				id
				email
			}
			orderInformation {
				firstname
				lastname
				email
				phone
				shippingAddress {
					name
					street1
					street2
					city
					state
					zip
					country
				}
			}
			product
			total
			date
			requestedPhysicalCard
			physicalCardSent

			refunded

			stripe {
				id
				status
			}

			membershipInformation {
				ids
				memberships {
					id
					membershipID
					firstname
					lastname
					email
					phone
					additionalFamilyMembers {
						name
					}
					spouseFirstName
					spouseLastName
					plan
				}
				plans {
					individual
					family
					corporateIndividual
					corporateFamily
				}
			}
		}
	}
`;

export default QUERY_GET_ORDER;