import React, { useState, Fragment } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import styles from './sidebar.module.scss';
import links from './links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import cx from 'classnames';


const Sidebar = ({ user, open, closeSideBar=()=>{} }) => {
	const accountType = _.get(user, 'accountType', 0);
	const adminLevel = _.get(user, 'adminLevel', 1);

	return (
		<div className={cx(styles.container, { [styles.open]: open })}>
			<div className={styles.closeIcon} onClick={closeSideBar}>
				<FontAwesomeIcon icon={['fas', 'times']} size="2x" />
			</div>
			<div className={styles.topContent}>
				<div className={styles.logo}>
					<img src={require('../../img/logo.png')} />
				</div>
				<div className={styles.nav}>
					{renderSections({ adminLevel, accountType, open, user })}
				</div>
			</div>
			<div className={styles.bottomContent}>
			</div>
		</div>
	);
};

const canView = (section, accountType, user) => {
	if(section.accountLevelMin <= accountType) {
		return true;
	} else if(section.disableForFriends) {
		return false;
	} else if (section.accountLevelMin === 1 && (user.friend || user.influencer)) {
		return true;
	}
	return false;
};


const renderSections = ({ accountType, adminLevel, open, user }) => {
	return links.map(section => {
		if(section.hasBusinessPlan) {
			if(!user.hasBusinessPlan && user.accountType < 10) {
				return null;
			}
		}
		if(canView(section, accountType, user)) {
			return (
				<div key={section.name} className={styles.section}>
					<div className={styles.sectionHeader}>
						<div className={styles.sectionName}>
							<div>{section.name}</div>
						</div>
						<div className={styles.sectionDivider} />
					</div>
					<div className={styles.links}>
						{section.items.map(link => {
							let shouldRender = true;
							if(section.hasBusinessPlan && !user.hasBusinessPlan && !link.skipRequirement) {
								shouldRender = false;
							} else if (link.disableForFriends && user.accountType <= 0) {
								shouldRender = false;
							}
							return renderLink({ link, accountType, adminLevel, open, shouldRender });
						})}
					</div>
				</div>
			);
		} else {
			return null;
		}
	});
};

const renderLink = (info) => {
	const { link: { children }, accountType, adminLevel, open, shouldRender } = info;

	if(!shouldRender) {
		return null;
	}
	
	if(children && children.length) {
		return open ? <LinkDropdown {...info} open={open} /> : <LinkMenu {...info} open={open} />;
	}


	return (
		<Link {...info} />
	);
};

const _LinkMenu = ({ link: { name, url, icon, children, minLevel, accounts }, adminLevel, accountType, location, history }) => { 

	if(minLevel > adminLevel) {
		return null;
	}

	if(accounts && accounts.length && !accounts.includes(accountType)) {
		return null;
	}

	let activeRoute = activeParentRoute(url, location);

	return (
		<Popup
			trigger={
				<div className={styles.link}>
					{ activeRoute && <span className={styles.activeBar} /> }
					<FontAwesomeIcon icon={icon} className={styles.linkIcon} />
				</div>
			}
			position="right top"
			closeOnDocumentClick
			contentStyle={{
				padding: '0',
				border: 'none',
				borderRadius: 2,
				backgroundColor: '#f9fafc',
				boxShadow: 'rgba(0, 0, 0, 0.2) 0px 9px 10px',
				zIndex: '100',
				width: 'auto',
			}}
			arrow={false}
			on="hover"
			offsetX={0}
		>
			<div className={styles.menu}>
				<div className={styles.menuName}>{name}</div>
				<div className={styles.menuList}>
					{children.map(clink => {
						return (
							<Link
								key={clink.url}
								link={clink}
								text={true}
								highlight={false}
							/>
						);
					})}
				</div>
			</div>
		</Popup>
	);
};

const LinkMenu = withRouter(_LinkMenu);

const _LinkDropdown = ({ link: { name, url, icon, children, minLevel, accounts, ...link}, accountType, adminLevel, location }) => {
	const [open, setOpen] = useState(false);
	let activeRoute = false;

	if(minLevel > adminLevel) {
		return null;
	}

	if(accounts && accounts.length && !accounts.includes(accountType)) {
		return null;
	}

	if(!open) {
		activeRoute = activeParentRoute(url, location);
	}

	return (
		<Fragment>
			<div className={cx(styles.link, styles.text)} onClick={() => setOpen(!open)}>
				{ activeRoute && <span className={styles.activeBar} /> }
				<FontAwesomeIcon icon={icon} className={styles.linkIcon} />
				<div style={{ position: 'relative' }}>{name}</div>
				<FontAwesomeIcon icon={['fas', 'angle-left']} className={cx({ [styles.dOpen]: open })} />
			</div>
			<div className={styles.dropdownLinks}>
				{ open && children.map(child => {
					return(
						<Link
							key={child.url}
							link={child}
							text={true}
							style={{ paddingLeft: '30px' }}
						/>
					);
				}) }
			</div>
		</Fragment>
	);
};

const LinkDropdown = withRouter(_LinkDropdown);

const activeRoute = (routeName, location) => {
	return location.pathname.replace('/', '') === routeName.replace('/', '');
};

const activeParentRoute = (routeName, location) => {
	return location.pathname.replace('/', '').startsWith(routeName.replace('/', ''));
};

const _Link = ({ link: { name, url, icon, minLevel, accounts, ...link }, history, accountType, adminLevel, text=false, open, location, style, highlight=true }) => {

	if(minLevel > adminLevel) {
		return null;
	}

	if(accounts && accounts.length && !accounts.includes(accountType)) {
		return null;
	}

	if(!text && !open) {
		return (
			<Popup
				trigger={
					<div style={{ width: '100%' }}>
						<RouterLink className={cx(styles.link, { [styles.text] : !!text })} to={url} style={style}>
							{ activeRoute(url, location) && highlight && <span className={styles.activeBar} /> }
							<FontAwesomeIcon icon={icon} className={styles.linkIcon} />
							{(text || open) && <div style={{ position: 'relative' }}>{name}</div> }
						</RouterLink>
					</div>
				}
				position="right top"
				closeOnDocumentClick
				contentStyle={{
					padding: '0',
					border: 'none',
					borderRadius: 2,
					backgroundColor: '#f9fafc',
					boxShadow: 'none',
					zIndex: '100',
					width: 'auto',
				}}
				arrow={false}
				on="hover"
				offsetX={0}
			>
				<div className={styles.menu} style={{ cursor: 'pointer' }} onClick={() => history.push(url)}>
					<div className={styles.menuName}>{name}</div>
				</div>
			</Popup>
		);
	}

	return(
		<RouterLink className={cx(styles.link, { [styles.text] : !!text })} to={url} style={style}>
			{ activeRoute(url, location) && highlight && <span className={styles.activeBar} /> }
			<FontAwesomeIcon icon={icon} className={styles.linkIcon} />
			{(text || open) && <div style={{ position: 'relative' }}>{name}</div> }
		</RouterLink>
	);
};

const Link = withRouter(_Link);



export default Sidebar;
