import React, { Component } from 'react';
import Location from './Location';
import AddLocation from './AddLocation';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';

class LocationsList extends Component {

	renderLocations = () => {
		const locations = _.get(this.props, 'data.locations', []);
		return locations.map((location, index) => {
			return <Location key={location.id} data={location} index={index+1} parentid={this.props.data.id} />;
		});
	}

	renderNationalLocation = () => {
		return (
			<Segment placeholder textAlign="center">
				<h4>This Store is currently a National Location</h4>
				<p>If you add a location to it, it will no longer be a national location.</p>
				<AddLocation id={this.props.data.id} />
			</Segment>
		);
	}

	render() {
		return (
			<div>
				{
					this.props.data.storeLocationsCount ?
						<div>
							{this.renderLocations()}
							<AddLocation id={this.props.data.id} />
						</div> :
						this.renderNationalLocation()
				}
			</div>
		);
	}
}

export default LocationsList;