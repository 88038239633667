import gql from 'graphql-tag';

const QUERY_GET_BUSINESS_PLAN = gql`
	query GetOrderAdmin($id: String!) {
		getBusinessPlan(id: $id) {
			id
			businessname

			account {
				id
				firstname
				lastname
				email
			}

			accountEmail

			reference {
				id
				firstname
				lastname
				email
			}

			plan

			storeID

			info {
				street1
				street2
				city
				state
				zip
				country
				phone
			}

			presentation {
				presentationContactName
				presentationTime
				presentationContactEmail
				presentationFuture
				opted
			}

			socialMedia {
				facebook
				instagram
				twitter
				snapchat
				pinterest
				linkedin
				socialMediaInfo
				opted
			}

			latestOrderNumber
			lastInvoiceRefunded

			stripe {
				id
				latest_invoice
				current_period_start
				current_period_end
				canceled_at
				cancel_at_period_end
				status
				customer
				Customer {
					default_source {
						id
						last4
						exp_year
						exp_month
						brand
						name
					}
				}
			}
		}
	}
`;

export default QUERY_GET_BUSINESS_PLAN;