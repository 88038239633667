import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as actions from '../../../actions';

import Page401 from '../../Page401';
import Page404 from '../../Page404';
import Loading from '../../Loading';
import TestArea from './TestArea.jsx';
import Tools from './Tools';



function DeveloperRoute ({component: Component, accountType, ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => (accountType >= 42) === true ? <Component {...props} /> : <Page401 />}
		/>
	);
}

class DeveloperTools extends Component {

	/*Load all of the info we will need for every route.
    - User Information
  */
	componentDidMount() {
		this.props.fetchUserInfo();
	}

	render() {

		if(!this.props.userInfo.firstname) {
			return(<Loading />);
		}

		return(
			<Switch>
				{/*Load routes here for the Backoffice*/}
				{/*Admin Protected Routes**/}
				<DeveloperRoute exact accountType={this.props.userInfo.accountType} path='/developer/testarea/current' component={TestArea} />
				<DeveloperRoute exact accountType={this.props.userInfo.accountType} path='/developer/resources/tools' component={Tools} />
				<Route component={Page404} />
			</Switch>
		);
	}
}

function mapStateToProps({userInfo}) {
	return { userInfo };
}


export default connect(mapStateToProps, actions)(DeveloperTools);