import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { Button } from 'semantic-ui-react';
import queryGetTrainingOrder from '../../../queries/getTrainingOrder';
import _ from 'lodash';
import TrainingItem from './TrainingItem';
import setTrainingOrderMutation from '../../../mutations/setTrainingOrder';
import withStateMutation from '../../../queries/withStateMutation';

class TrainingList extends Component {
	state = {
		list: null,
	}

	moveCard = (dragIndex, hoverIndex) => {
		const movedItem = this.state.list.find((item, index) => index === dragIndex);
		const remainingItems = this.state.list.filter((item, index) => index !== dragIndex);
  
		const reorderedItems = [
			...remainingItems.slice(0, hoverIndex),
			movedItem,
			...remainingItems.slice(hoverIndex)
		];

		console.log('Reordered', reorderedItems);

		this.setState({ list: reorderedItems });
	
	}

	componentDidUpdate(prevProps) {
		const list = _.get(this.props, 'data.getTrainingOrder.value', null);
		const prevList = _.get(prevProps, 'data.getTrainingOrder.value', null);

		if(prevList !== list) {
			this.setState({
				list
			});
		}
	}

	save = () => {
		console.log('Changing order to', this.state.list);
		this.props.mutate({
			variables: { list: this.state.list },
			refetchQueries: [{
				query: queryGetTrainingOrder,
			}],
		});
	}

	renderSaveButton = () => {
		const list = _.get(this.props, 'data.getTrainingOrder.value', null);
		if(this.state.list !== list) {
			return (
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 10 }}>
					<Button color='green' onClick={this.save}>Save Changes</Button>
				</div>
			);
		}
	}

	render() {

		if(this.props.mutateLoading) {
			return <div>Loading...</div>;
		}

		if(this.props.data.loading) {
			return <div></div>;
		}

		if(!this.state.list && !this.props.data.loading) {
			return (
				<div>
					<h3>No Training Videos!</h3>
					<p>Add one to get started</p>
				</div>
			);
		}

		return (
			<div>
				{this.renderSaveButton()}
				{this.state.list.map((id, i) => {
					return <TrainingItem key={id} id={id} index={i} moveCard={this.moveCard} />;
				})}
			</div>
		);
	}
}

export default compose(
	graphql(queryGetTrainingOrder),
	graphql(setTrainingOrderMutation),
	withStateMutation(),
)(TrainingList);