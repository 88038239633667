import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const Lottie = ({ options, setPlayer=()=>{} }) => {
	const container = useRef(null);
	
	useEffect(() => {
		if(container) {
			console.log('Loading Animation');
			const animation = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				...options,
			});
			setPlayer(animation);
		}
	}, [container]);

	return (
		<div ref={container} style={{ width: '100%', height: '100%' }} />
	);
};

export default Lottie;