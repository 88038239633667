import gql from 'graphql-tag';


const queryGetUserSale = gql`
	query GetUserSale($id: String!) {
		sale(id: $id) {
			id 
			customer {
				firstname  
				lastname 
				email 
				zip 
				accountType 
			}
			total 
			date 
			stripeCustomerID 
			stripeSubscriptionID 
			shippingAddress {
				name 
				street1 
				street2 
				city 
				state 
				zip 
				country 
			}

			firstname 
			lastname 
			email 
			phone 
			valid 
			membershipID 

			additionalFamilyMembers {
				name 
			}
		}
	}

`;

export default queryGetUserSale;