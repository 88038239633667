import gql from 'graphql-tag';

const QUERY_GET_REFERRAL_TABLE = gql`
	query GetReferralTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getReferralTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				email
				sent
				type
				completed
				user {
					firstname
					lastname
				}
			}
		}
	}
`;

export default QUERY_GET_REFERRAL_TABLE;