import gql from 'graphql-tag';

const QUERY_GET_TREE_GRAPH_DATA = gql`

	query GetTreeGraphData($id: String!, $month: Int!, $year: Int!) {
		getTreeGraphData(id: $id) {
			id
			firstname
			lastname
			email
			currentMonthSales
			SalesInfo(month: $month, year: $year) {
				allSalesTotal
				Commission {
					isEligible
				}
			}
			parent {
				id
				firstname
				lastname
				email
				parent {
					id
					firstname
					lastname
					email
				}
			}
			downline {
				id
				firstname
				lastname
				email
				currentMonthSales
				SalesInfo(month: $month, year: $year) {
					allSalesTotal
					Commission {
						isEligible
					}
				}
				downline {
					id
					firstname
					lastname
					email
					currentMonthSales
					SalesInfo(month: $month, year: $year) {
						allSalesTotal
						Commission {
							isEligible
						}
					}
					downline {
						id
						firstname
						lastname
						email
						currentMonthSales
						SalesInfo(month: $month, year: $year) {
							allSalesTotal
							Commission {
								isEligible
							}
						}
						downline {
							id
							firstname
							lastname
							email
							currentMonthSales
							SalesInfo(month: $month, year: $year) {
								allSalesTotal
								Commission {
									isEligible
								}
							}
							downline {
								id
								firstname
								lastname
								email
								currentMonthSales
								SalesInfo(month: $month, year: $year) {
									allSalesTotal
									Commission {
										isEligible
									}
								}
								downline {
									id
									firstname
									lastname
									email
									currentMonthSales
									SalesInfo(month: $month, year: $year) {
										allSalesTotal
										Commission {
											isEligible
										}
									}
									downline {
										id
										firstname
										lastname
										email
										currentMonthSales
										SalesInfo(month: $month, year: $year) {
											allSalesTotal
											Commission {
												isEligible
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default QUERY_GET_TREE_GRAPH_DATA;