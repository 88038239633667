import React from 'react';
import { Form } from 'semantic-ui-react';

const Checkbox = ({ label, input: { value, onChange }, meta: { touched, error }, ...rest }) => {
	return(
		<Form.Checkbox
			label={label} 
			checked={value}
			onChange={(e, { checked }) => { onChange(checked); }}
			error={touched && error} {...rest}
		/>
	);
};

export default Checkbox;