import React from 'react';

const TaxInformation = () => {
	return (
		<div>
			hello
		</div>
	);
};

export default TaxInformation;