import React from 'react';
import { TextArea, Button, Divider } from 'semantic-ui-react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const RunQuery = () => {
	const client = useApolloClient();
	const [text, setText] = React.useState('');
	const [result, setResult] = React.useState('');

	const onRunQuery = () => {
		try {
			const query = gql`${text}`;
			client.query({ query }).then((result) => {
				setResult(result);
				console.log(JSON.stringify(result, null, '\t'));
			}).catch(error => setResult(error));
		} catch (error) {
			setResult(error);
		}
	};
	
	return (
		<div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '10px', position: 'relative' }}>
			<div style={{ }}>
				<TextArea 
					style={{ width: '100%' }}
					value={text}
					onChange={(q) => setText(q.target.value)}
				/>
				<Button 
					content="Submit"
					color="blue"
					fluid
					onClick={onRunQuery}
				/>
			</div>
			<Divider horizontal style={{ margin: '25px 0' }}>
				RESULTS
			</Divider>
			<div style={{  }}>
				{JSON.stringify(result, null, '\t')}
			</div>
		</div>
	);
};

export default RunQuery;