import gql from 'graphql-tag';

const MUTATION_SELL_CARD = gql`
  mutation SellCard(
    $plan: Int
    $firstname: String!
    $lastname: String!
    $street1: String
    $street2: String
    $city: String
    $zip: String
    $state: String
    $country: String
    $phone: String!
    $email: String
    $spouseFirstName: String
    $spouseLastName: String
    $members: [FamilyMemberInput]
    $reference: String
    $contractAgree: Boolean!
    $token: String!
    $requestedPhysicalCard: Boolean
    $discountCode: String
    $createAccount: Boolean
  ) {
    sellCard(
      plan: $plan
      firstname: $firstname
      lastname: $lastname
      street1: $street1
      street2: $street2
      city: $city
      zip: $zip
      state: $state
      country: $country
      phone: $phone
      email: $email
      spouseFirstName: $spouseFirstName
      spouseLastName: $spouseLastName
      members: $members
      reference: $reference
      contractAgree: $contractAgree
      token: $token
      requestedPhysicalCard: $requestedPhysicalCard
      discountCode: $discountCode
      createAccount: $createAccount
    ) {
      id
    }
  }
`;

export default MUTATION_SELL_CARD;
