import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import queryGetSetting from '../../../queries/getSetting';
import _ from 'lodash';
import ChangeWebinarLinkForm from './ChangeWebinarLinkForm';

class ChangeWebinarLink extends Component {
	render() {
		const link = _.get(this.props.data, 'getSetting.value', null);
		return (
			<div>
				<ChangeWebinarLinkForm webinarlink={link} loading={this.props.data.loading} />
			</div>
		);
	}
}


export default compose(
	graphql(queryGetSetting, {
		options: () => { return { variables: { key: 'webinarlink' } }; }
	}),
)(ChangeWebinarLink);