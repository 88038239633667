import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import modifySaleAdminMutation from '../../../../mutations/modifySaleAdmin';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';

export const ChangeTextFieldSale = compose(
	graphql(modifySaleAdminMutation),
	withStateMutation(),
)(ChangeTextField);

export const ChangeDateFieldSale = compose(
	graphql(modifySaleAdminMutation),
	withStateMutation(),
)(ChangeDateField);

export const ChangeSelectFieldSale = compose(
	graphql(modifySaleAdminMutation),
	withStateMutation(),
)(ChangeSelectField);