/* eslint no-console: 0 */
import firebase from 'firebase/app';
import 'firebase/auth';

var config;
if (process.env.NODE_ENV === 'production') {
	console.log('Using Production Firebase - React');
	config = {
		apiKey: 'AIzaSyCHPqHOyK5Eym8TUdtlv0wNPMhtoeMH7J4',
		authDomain: 'website-3c23a.firebaseapp.com',
		databaseURL: 'https://website-3c23a.firebaseio.com',
		projectId: 'website-3c23a',
		storageBucket: 'website-3c23a.appspot.com',
		messagingSenderId: '358639322269'
	};

	firebase.initializeApp(config);
} else {
	console.log('Using Test Server Firebase - React');
	config = {
		apiKey: 'AIzaSyBy46rmly4WqcfJLubOtvL8EnL7RZPHtro',
		authDomain: 'backoffice-dnp.firebaseapp.com',
		databaseURL: 'https://backoffice-dnp.firebaseio.com',
		projectId: 'backoffice-dnp',
		storageBucket: 'backoffice-dnp.appspot.com',
		messagingSenderId: '1061511127860'
	};

	firebase.initializeApp(config);
}

export const firebaseAuth = firebase.auth;
