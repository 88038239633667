import gql from 'graphql-tag';

const queryGetWebinarCodes = gql`

	query GetWebinarCodes {
		getWebinarCodes {
			id
			code
			name
			email
			identifier
			dateCreated
			dateExpired
			dateStartedWatching
			dateFinishedWatching
			dateWatched
			formSelection
			accountAssociated {
				id
				firstname
				lastname
				businessName
			}
			createdBy {
				firstname
				lastname
			}
			followup
			incentiveNeeded
			incentiveFulfilled
			form {
				businessName
				name
				email
				phone
				restaurant
			}
		}
	}

`;

export default queryGetWebinarCodes;