import React from 'react';
import { Segment, Header, Grid, Button } from 'semantic-ui-react';
import { DetailsTable, DetailsRow } from '../../Table';
import moment from 'moment';
import CancelMembership from './CancelMembership';
import { QUERY_BUSINESS_PLAN_USER } from './Home';

const SubscriptionInfo = ({ plan }) => {
	const stripe = plan.stripe || {};
	const {
		current_period_start,
		current_period_end,
		ended_at,
		canceled_at,
		status,
	} = stripe;

	const { latestOrderNumber } = plan;

	return (
		<Segment.Group>
			<Segment secondary color="green">
				<Header as="h3" textAlign="center">
					Subscription Info
				</Header>
			</Segment>
			<Segment>
				<DetailsTable noHeader>
					<DetailsRow
						property="Status"
						value={status + (canceled_at ? ' but canceled' : '')}
					/>
					<DetailsRow
						property="Last Charge Date"
						value={moment(current_period_start).format('MMMM Do YYYY')}
					/>
					<DetailsRow
						property="Next Charge Date"
						value={moment(current_period_end).format('MMMM Do YYYY')}
					/>
					<DetailsRow
						property="Canceled"
						value={moment(canceled_at).format('MMMM Do YYYY')}
						condition={!!canceled_at}
					/>
					<DetailsRow
						property="Ended At"
						value={moment(ended_at).format('MMMM Do YYYY')}
						condition={!!ended_at}
					/>
				</DetailsTable>
			</Segment>
			<Segment>
				<Grid stackable>
					<Grid.Column width="6">
						<Button
							fluid
							content="View Last Receipt"
							color="blue"
							as="a"
							href={`/api/receipt?id=${latestOrderNumber}`}
							target="_blank"
						/>
					</Grid.Column>
					<Grid.Column width="4" />
					<Grid.Column width="6">
						{ !canceled_at && <CancelMembership id={plan.id} refetch={[{ query: QUERY_BUSINESS_PLAN_USER }]} /> }
					</Grid.Column>
				</Grid>
			</Segment>
		</Segment.Group>
	);
};

export default SubscriptionInfo;