import gql from 'graphql-tag';

const queryGetPromoCode = gql`
  query GetPromoCode($id: String!) {
    getPromoCode(id: $id) {
      id
      object
      amount_off
      created
      currency
      duration
      duration_in_months
      livemode
      max_redemptions
      metadata {
        createdBy {
          firstname
          lastname
        }
      }
      name
      percent_off
      redeem_by
      times_redeemed
      valid
    }
  }
`;

export default queryGetPromoCode;
