import React from 'react';
import CardDetails from '../../../BusinessPlan/Home/CardDetails';

const PaymentInfo = ({ data, refetch }) => {
	return (
		<CardDetails 
			plan={data}
			refetch={refetch}
		/>
	);
};

export default PaymentInfo;