import React, {Component} from 'react';
import { Button, Form, Grid, Header, Message, Segment, Icon } from 'semantic-ui-react';
import { firebaseAuth } from '../config/firebase';
import '../style/login.css';
import PasswordResetModal from './User/Complex/PasswordResetModal';

const LoginWithToken = () => {
	const [text, setText] = React.useState('');
	const [error, setError] = React.useState('');

	const onSubmit = () => {
		firebaseAuth().signInWithCustomToken(text).catch(error => {
			setError(JSON.stringify(error));
		});
		// firebaseAuth().signInWithEmailAndPassword(username, password).catch(error => {
		// 	this.setState({failed: true, clicked: false, error: error.code});
		// });
	};

	return(
		<div className='login-form'>
			<p>{error}</p>
			<Grid
				textAlign='center'
				style={{ height: '100%' }}
				verticalAlign='middle'
			>
				<Grid.Column style={{ maxWidth: 450 }}>
					<Segment>
						<Header as='h2' color='blue' textAlign='center'>
							{/**<Image src='/logo.png' />**/}
            Log-in to your account
						</Header>
						<Form size='large'>
							<Form.Input
								fluid
								icon='user'
								iconPosition='left'
								placeholder='Token'
								value={text}
								onChange={(e) => setText(e.target.value)}
							/>
							<Button color='blue' fluid size='large' onClick={onSubmit}>Login</Button>
							<PasswordResetModal />
						</Form>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};


export default LoginWithToken;
