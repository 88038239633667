import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import mutationModifyUserAdmin from '../../../../mutations/modifyUserAdmin';
import ChangeCheckboxField from '../../../ChangeField/ChangeCheckbox';

export const ChangeTextFieldUser = compose(
	graphql(mutationModifyUserAdmin),
	withStateMutation(),
)(ChangeTextField);

export const ChangeDateFieldUser = compose(
	graphql(mutationModifyUserAdmin),
	withStateMutation(),
)(ChangeDateField);

export const ChangeCheckboxFieldUser = compose(
	graphql(mutationModifyUserAdmin),
	withStateMutation(),
)(ChangeCheckboxField);

export const ChangeSelectFieldUser = compose(
	graphql(mutationModifyUserAdmin),
	withStateMutation(),
)(ChangeSelectField);