import React, { Component } from 'react';
import { List, Button, Popup, Segment } from 'semantic-ui-react';
import modifySaleAdminMutation from '../../../../mutations/modifySaleAdmin';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import MemberPopup from './MemberPopup';
import AddMember from './AddMember';
import GraphQLError from '../../../Message/GraphQLError';
import withStateMutation from '../../../../queries/withStateMutation';
import queryGetSaleAdmin from '../../../../queries/getSaleAdmin';

class AdditionalFamilyMembers extends Component {
	state = {
		context: null,
		popen: false,
		index: null,
		name: null,
		nopen: null,
	}

	submit = (arr) => {
		this.props.mutate({
			variables: {
				additionalFamilyMembers: arr,
				id: this.props.id,
				property: 'additionalFamilyMembers'
			},
			refetchQueries: [{
				query: queryGetSaleAdmin,
				variables: { id: this.props.id }
			}]
		});
	}

	getArray = () => {
		const data = this.props.data || [];
		return data.map(ob => {
			return ob.name; 
		});
	}

	changeName = (newName) => {
		const arr = this.getArray();
		arr[this.state.index] = newName;
		this.setState({ popen: false, name: null, index: null });
		this.submit(arr);
	}

	deleteMember = () => {
		const arr = this.getArray();
		arr.splice(this.state.index, 1);
		this.setState({ popen: false, name: null, index: null });
		this.submit(arr);
	}

	addMember = (name) => {
		const arr = this.getArray();
		arr.push(name);
		this.setState({ nopen: false });
		this.submit(arr);
	}

	renderEditForm = () => {
		return (
			<div>
				<MemberPopup value={this.state.name} onComplete={this.changeName} />
				<Button color='red' onClick={this.deleteMember} inverted>Delete</Button>
			</div>
		);
	}

	handleMemberClick = (index, name) => {
		if(this.state.index === index) {
			this.setState({
				index,
				popen: !this.state.popen,
				name,
			});
		} else {
			this.setState({
				index,
				popen: true,
				name,
			});
		}
	}

	renderData = () => {
		const { data } = this.props;
		if(data) {
			return data.map((obj, index) => {
				return (
					<List.Item as='a' onClick={() => this.handleMemberClick(index, obj.name)} key={`${obj.name}${index}`}>
						<List.Icon name='user' />
						<List.Content>
							<List.Header>{obj.name}</List.Header>
						</List.Content>
					</List.Item>
				);
			});
		}
	}

	render() {
		return (
			<Segment loading={this.props.mutateLoading}>
				<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Popup
						content={<AddMember onComplete={this.addMember} />}
						trigger={(
							<Button onClick={() => this.setState({ nopen: !this.state.nopen})}>Add Family Member</Button>
						)}
						open={this.state.nopen}
					/>
				</div>
				<Popup
					content={this.renderEditForm()}
					trigger={(
						<List divided relaxed verticalAlign='middle'>
							{this.renderData()}
						</List>
					)}
					open={this.state.popen}
				/>
			</Segment>
		);
	}
}

export default compose(
	graphql(modifySaleAdminMutation),
	withStateMutation(),
)(AdditionalFamilyMembers);