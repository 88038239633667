import React from 'react';
import { Field } from 'react-final-form';
import { Form  } from 'semantic-ui-react';
import Select from 'react-select';

function convertToSimple(arr) {
	return arr ? arr.map(v => typeof v === 'object' ? v.value : v) : null;
}

function convertToComplex(options, value) {
	return value ? value.map(l => options.find(v => v.value === l)) : null;
}

const FSMultiSelectInput = ({ options, name, required, label, placeholder, containerStyle }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div style={containerStyle}>
						<Select
							options={options}
							value={convertToComplex(options, input.value)}
							onChange={value => input.onChange(convertToSimple(value))}
							searchable={true}
							isMulti
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSMultiSelectInput;