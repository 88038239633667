import React from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import BasicInfo from './BasicInfo';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_BUSINESS_PLAN from './getBusinessPlan';
import Loading from '../../../Loading';
import _ from 'lodash';
import StripeInfo from './StripeInfo';
import PaymentInfo from './PaymentInfo';
import PresentationInfo from './PresentationInfo';
import SocialMediaInfo from './SocialMediaInfo';

const BusinessPlanContent = ({ id }) => {
	const { data, loading } = useQuery(QUERY_GET_BUSINESS_PLAN, {
		variables: { id },
	});

	const [activeItem, setActiveItem] = React.useState('info');

	if(loading) {
		return <Loading />;
	}

	if(!_.get(data, 'getBusinessPlan.id')) {
		return <div>Error loading data. Please try again.</div>;
	}

	const refetch = [{
		query: QUERY_GET_BUSINESS_PLAN,
		variables: { id }
	}];

	const presentation = _.get(data, 'getBusinessPlan.presentation.opted', false);
	const socialMedia = _.get(data, 'getBusinessPlan.socialMedia.opted', false);

	return (
		<div>
			<Menu attached='top' tabular>
				<Menu.Item 
					name='Info'
					active={activeItem === 'info'}
					onClick={() => setActiveItem('info')}
				/>
				<Menu.Item 
					name='Stripe'
					active={activeItem === 'stripe'}
					onClick={() => setActiveItem('stripe')}
				/>
				<Menu.Item 
					name='Payment'
					active={activeItem === 'payment'}
					onClick={() => setActiveItem('payment')}
				/>
				{ presentation && <Menu.Item 
					name='Presentation'
					active={activeItem === 'presentation'}
					onClick={() => setActiveItem('presentation')}
				/> }
				{ socialMedia && <Menu.Item 
					name='Social Media'
					active={activeItem === 'socialMedia'}
					onClick={() => setActiveItem('socialMedia')}
				/> }
			</Menu>

			<Segment attached='bottom'>
				{ activeItem === 'info' && (
					<BasicInfo
						data={data.getBusinessPlan}
						refetch={refetch}
					/>
				)}
				{ activeItem === 'stripe' && (
					<StripeInfo
						data={data.getBusinessPlan}
						refetch={refetch}
					/>
				)}
				{ activeItem === 'payment' && (
					<PaymentInfo
						data={data.getBusinessPlan}
						refetch={refetch}
					/>
				)}
				{ activeItem === 'presentation' && (
					<PresentationInfo
						data={data.getBusinessPlan}
						refetch={refetch}
					/>
				)}
				{ activeItem === 'socialMedia' && (
					<SocialMediaInfo
						data={data.getBusinessPlan}
						refetch={refetch}
					/>
				)}
			</Segment>
		</div>
	);
};

export default BusinessPlanContent;