import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../FinalForm/TextInput';
import RadioGroup from '../../FinalForm/RadioGroup';
import DateInput from '../../FinalForm/DateInput';
import Checkbox from '../../FinalForm/StyledCheckbox';
import Condition from '../../FinalForm/Condition';
import SelectInput from '../../FinalForm/SelectInput';
import GraphQLError from '../../Message/GraphQLError';
import mutationAddPromoCode from '../../../mutations/addPromoCode';
import withStateMutation from '../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

const options = [
	{ label: 'Forever', value: 'forever' },
	{ label: 'Once', value: 'once' },
];

class AddPromoCode extends Component {

	onSubmit = async (variables) => {
		const newValues = {
			...variables,
			percent_off: variables.discount_type === 'percentage' ? variables.percent_off : null,
			amount_off: variables.discount_type === 'fixed' ? variables.amount_off : null,
			redeem_by: variables.shouldLimitDate ? variables.redeem_by : null,
			max_redemptions: variables.shouldLimitTotalTimes ? variables.max_redemptions : null,
		};

		this.props.mutate({
			variables: newValues,
		}).then((t, err) => {
			if(!this.props.mutateError) {
				this.props.completed && this.props.completed();
			}
		});
	}

	render() {
		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					initialValues={{
						discount_type: 'percentage',
						duration: 'forever'
					}}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
							<Form.Group widths='equal'>
								<TextInput name='id' placeholder="What the user will type in to redeem the discount" label="Code" required />
								<TextInput name='name' placeholder="Nickname for the Promo Code" label="Nickname" required />
							</Form.Group>
							<RadioGroup
								name='discount_type'
								title='Type'
								list={[
									{ label: 'Percentage discount', value: 'percentage' },
									{ label: 'Fixed amount discount', value: 'fixed' },
								]}
							/>

							<Condition when="discount_type" is="percentage">
								<TextInput name='percent_off' placeholder="Percentage off" label="Percentage off" required icon="percent" />
							</Condition>

							<Condition when="discount_type" is="fixed">
								<TextInput name='amount_off' placeholder="Discount amount" label="Discount amount" required iconPosition="left" icon="dollar sign" />
							</Condition>

							<SelectInput name='duration' options={options} placeholder="Duration" required />
							
							<Form.Group widths='equal'>
								<Checkbox name='shouldLimitDate' label='Set expiration Date' />

								<Condition when="shouldLimitDate" is={true}>
									<DateInput
										name='redeem_by'
										placeholder="Redeem By"
										label="Redeem By End of Day"
										fluid
									/>
								</Condition>
							</Form.Group>

							<Form.Group widths='equal'>
								<Checkbox name='shouldLimitTotalTimes' label='Limit the total number of times this coupon can be redeemed' />

								<Condition when="shouldLimitTotalTimes" is={true}>
									<TextInput
										name='max_redemptions'
										placeholder="Max Redemptions"
										label="Max Redemptions"
									/>
								</Condition>
							</Form.Group>

							<Button fluid type='submit'>Submit Promo Code</Button>

							<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						</Form>
					)}
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.id) {
		errors.id = 'Required Field';
	}

	if(!values.duration) {
		errors.duration = 'Required Field';
	}

	if(values.discount_type === 'fixed') {
		var r = /^[0-9]+(\.[0-9][0-9])?$/;
		if(!values.amount_off) {
			errors.amount_off = 'Please enter an amount';
		} else if(!r.test(values.amount_off)) {
			errors.amount_off = 'Invalid Dollar Amount';
		}
	} else if (values.discount_type === 'percentage') {
		if(!values.percent_off) {
			errors.percent_off = 'Please enter a percentage';
		} else if(isNaN(values.percent_off)) {
			errors.percent_off = 'Not a #';
		} else if(values.percent_off % 1 !== 0) {
			errors.percent_off = 'Please enter a whole #';
		} else if(values.percent_off > 100) {
			errors.percent_off = 'Value must be equal to or less than 100';
		} else if(values.percent_off < 1) {
			errors.percent_off = 'Value must be greater than 0';
		} 
	}

	if(values.shouldLimitDate) {
		if(!values.redeem_by) {
			errors.redeem_by = 'Required Field';
		}
	}

	if(values.shouldLimitTotalTimes) {
		if(!values.max_redemptions) {
			errors.max_redemptions = 'Please enter a #';
		} else if(isNaN(values.max_redemptions)) {
			errors.max_redemptions = 'Invalid #';
		} else if(values.max_redemptions % 1 !== 0) {
			errors.max_redemptions = 'Invalid #';
		} else if(values.max_redemptions < 1) {
			errors.max_redemptions = 'Must be a positive #';
		}
	}

	return errors;
}

export default compose(
	graphql(mutationAddPromoCode),
	withStateMutation(),
)(AddPromoCode);