import gql from 'graphql-tag';

const QUERY_CURRENT_USER = gql`
	query CurrentUser {
		user {
			id 
			firebaseID 
			firstname 
			lastname 
			phone 
			email 
			zip 
			adminLevel 
			accountType 
			referenceCode 
			activeRepresentative 
			businessName
			employee
			eManager
			accessTokens
			friend
			influencer
			w9 {
				termsDate 
			}
			hasBusinessPlan
		}
	}

`;

export default QUERY_CURRENT_USER;