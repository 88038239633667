import React from 'react';
import { Field } from 'react-final-form';
import { Input, Button, Form } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CustomField.module.scss';




const CustomField = ({ name, title, price, icon, iconColor, description, pic }) => {
	const [quantity, setQuantity] = React.useState(1);
	

	return (
		<Field name={name}>
			{({ input, meta }) => (
				<div className={styles.container}>	
					<div className={styles.topContainer}>
						<img src={pic}></img>
						<p className={styles.title}>{title}</p>
						<p className={styles.price}>${price}</p>
						{/* 						
						<p>Current Quantity: {input.value}</p> */}
						
					</div>

					<div className={styles.middleContainer}>
						<ul>
							<li>{description}</li>
						</ul>
					</div>	
				
					<div className={styles.bottomContainer}>
						<Form.Group widths="equal">
							<Form.Field>
								<Input
									value={quantity}
									onChange={i => {
										if(/^[0-9]+$/.test(i.target.value) || !i.target.value) {
											setQuantity(i.target.value ? parseInt(i.target.value) : 0);
										}
									}}
									action
									
								>
									<input style={{
										width: '100%',
										maxWidth: '100%',
										flex: 'unset',
									}}/>
									<Button
										onClick={() => input.onChange(quantity)}
										type="button"
										placeholder="Quantity"
										content={input.value ? 'Change' : 'Add'}
									/>
								</Input>

							</Form.Field>
						</Form.Group>						
					</div>
				</div>
			)}
		</Field>
	);		
};
		
export default CustomField;