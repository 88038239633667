import React, { Component } from 'react';
import { Segment, Button, Header, Popup } from 'semantic-ui-react';
import AddNewCodeForm from './AddNewCodeForm';
import WebinarCodeTable from './WebinarCodeTable';
import ChangeWebinarLink from './ChangeWebinarLink';

class Webinar extends Component {
	state = {
		addCodePopup: false,
	}
	render() {
		return (
			<div className="animated fadetwoIn dnp-page">
				<div className="ui container" style={{ marginTop: 10, }}>
					<Header as='h1' textAlign='center'>Manage Webinar</Header>
					<Segment style={{ borderRadius: 2, 'box-shadow': 'none', '-webkit-box-shadow': 'none' }}>
						<ChangeWebinarLink />

						<hr />

						<Popup
							trigger={<Button onClick={() => this.setState({ addCodePopup: !this.state.addCodePopup })} color='green'>Add Webinar Code</Button>}
							content={<AddNewCodeForm onComplete={() => this.setState({ addCodePopup: false })}/>}
							open={this.state.addCodePopup}
							on='click'
						/>

					</Segment>
				</div>

				<div style={{ marginTop: 10 }}>
					<WebinarCodeTable />
				</div>
			</div>
		);
	}
}

export default Webinar;