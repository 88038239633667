import React from 'react';
import { Form } from 'semantic-ui-react';
import FSSelectInput from '../FinalForm/SelectInput';
import FSTextInput from '../FinalForm/TextInput';

const accountTypeOptions = [
	{ label: 'Checkings', value: 0 },
	{ label: 'Savings', value: 1 }
];

const DirectDepositForm = () => {
	return (
		<>
			<Form.Group widths='equal'>
				<FSTextInput
					name="name"
					label="Full Name"
					required
				/>
				<FSTextInput
					name="bName"
					label="Bank Name"
					required
				/>
				<FSSelectInput
					name="at"
					options={accountTypeOptions}
					label="Account Type"
					placeholder="Account Type"
					required
				/>
			</Form.Group>
			<Form.Group widths='equal'>
				<FSTextInput
					name="rtn"
					label="Routing Number"
					required
				/>
				<FSTextInput
					name="acn"
					label="Bank Account Number"
					required
				/>
			</Form.Group>
		</>
	);
};

export default DirectDepositForm;