import gql from 'graphql-tag';

const mutationClockOut = gql`

	mutation ClockOut {
		clockOut {
			id
			user
			in
			out
			paused {
				id
				start
				end
				reason
			}
			isOnBreak
			totalTime
			totalBreakTime
			totalPaidTime
		}
	}

`;

export default mutationClockOut;