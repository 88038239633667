import React, { Component } from 'react';
import queryGetTrainingOrder from '../../queries/getTrainingOrder';
import queryGetWatchedTraining from '../../queries/getWatchedTraining';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import TrainingItem from './TrainingItem';


function isWatched(id, watchedList) {
	if(watchedList) {
		const el = watchedList.find(element => {
			return element.trainingID === id;
		});
		return el ? !!el.dateCompleted : false;
	} else {
		return false;
	}
}

class TrainingList extends Component {

	onSelection = (id) => {
		this.props.onSelection && this.props.onSelection(id);
	}

	render() {
		const list = _.get(this.props, 'order.getTrainingOrder.value', null);
		const watchedList = _.get(this.props, 'watched.getWatchedTraining.training', []);

		
		if(!list) {
			return <div></div>;
		}

		let nextVideo = false;

		return (
			<div style={{ padding: 10 }}>
				<h2 style={{ textAlign: 'center' }}>Training Videos</h2>
				<div className="training__list">
					<div className="training__list__data">
						{
							list.map((id, index) => {
								const watched = isWatched(id, watchedList);
								// const watched = true;

								// let disabled = true;
								let disabled = false;

								if(!watched && !nextVideo) {
									nextVideo = id;
								}

								if(nextVideo === id) {
									disabled = false;
								}

								if(watched) {
									disabled = false;
								}
								
								return <TrainingItem
									id={id}
									key={id}
									onSelection={this.props.onSelection}
									selected={this.props.selected === id}
									// isWatched={ watched }
									// disabled={ disabled }
									isWatched={true}
									disabled={false}
								/>;
							})
						}
					</div>
				</div>
			</div>
		);
	}
}

export default compose(
	graphql(queryGetTrainingOrder, {
		name: 'order'
	}),
	graphql(queryGetWatchedTraining, {
		name: 'watched'
	}),
)(TrainingList);