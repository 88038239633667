import React, { Component } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import modifyWebinarCodeMutation from '../../../mutations/modifyWebinarCode';
import withStateMutation from '../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

class Information extends Component {
	state = {
		iCompleted: false,
	}

	getStatus = (d) => {
		const { dateExpired, dateStartedWatching, dateFinishedWatching, incentiveNeeded, incentiveFulfilled } = d;
		let expired = false;
		if(dateExpired) {
			const expires = new Date(dateExpired).valueOf();
			expired = (expires - new Date().valueOf()) < 0;
		}

		if(expired && !incentiveNeeded) {
			return 'Expired';
		} else if (incentiveFulfilled) {
			return 'Completed';
		} else if (incentiveNeeded) {
			return 'Incentive Needed';
		} else if (dateFinishedWatching) {
			return 'Finished Watching';
		} else if (dateStartedWatching) {
			return 'Started Watching';
		} else {
			return 'Waiting...';
		}
	}

	markComplete = (d) => {
		this.props.mutate({
			variables: {
				code: d.code,
				incentiveFulfilled: true,
			}
		}).then(() => {
			this.setState({ iCompleted: true });
		});
	}

	renderMarkIncentiveAsComplete = (d) => {
		if(d.incentiveNeeded && !this.state.iCompleted && !d.incentiveFulfilled) {
			return (
				<Button size='mini' compact style={{ marginLeft: 10 }} onClick={() => this.markComplete(d)}>Mark as Fulfilled</Button>
			);
		}
	}

	renderSurveyInfo = (d) => {
		if(d.form) {
			if(d.form.businessName) {
				const { businessName, name, email, phone, restaurant } = d.form;
				return (
					<div>
						<hr />
						<h4>Survey Information</h4>
						<p><strong>Business Name: </strong>{businessName}</p>
						<p><strong>Name: </strong>{name}</p>
						<p><strong>Email: </strong>{email}</p>
						<p><strong>Phone: </strong>{phone}</p>
						<p><strong>Restaurant: </strong>{restaurant}</p>
					</div>
				);
			}
		}
	}

	renderAccount = (d) => {
		if(d.accountAssociated && d.incentiveNeeded) {
			const { id, firstname, lastname, businessName } = d.accountAssociated;
			return (
				<div>
					<hr />
					<h4>Associated Account</h4>
					<p><strong>Account ID: </strong>{id}</p>
					<p><strong>Name: </strong>{firstname} {lastname}</p>
					<p><strong>Business Name: </strong>{businessName}</p>
				</div>
			);
		}
	}

	render() {
		const data = this.props.data.original;
		const status = this.getStatus(data);
		return (
			<Segment style={{ borderRadius: 0, 'box-shadow': 'none', '-webkit-box-shadow': 'none' }}>
				<p><strong>Webinar Code ID: </strong>{data.id}</p>
				<p style={{ fontSize: 15 }}><strong>Status: </strong>{status} {this.renderMarkIncentiveAsComplete(data)}</p>
				<p><strong>Form Selection:</strong> {data.formSelection}</p>
				{this.renderSurveyInfo(data)}
				{this.renderAccount(data)}
			</Segment>
		);
	}
}

export default compose(
	graphql(modifyWebinarCodeMutation),
	withStateMutation()
)(Information);