import gql from 'graphql-tag';

const mutationModifyUserAdmin = gql`

	mutation modifyUserAdmin($id: String!, $firstname: String, $lastname: String, $zip: String, $accountType: Int, $adminLevel: Int, $representativeLevel: Int, $referenceCode: String, $parent: String, $w9: W9Input, $phone: String, $directDepositInfo: DirectDepositInput, $paymentType: Int, $employee: Boolean, $eManager: Boolean, $representativeAddress: ShippingAddress, $influencer: Boolean, $skipCommissionReqs: Boolean, $friend: Boolean, $donating: Boolean) {
		modifyUserAdmin(id: $id, firstname: $firstname, lastname: $lastname, zip: $zip, accountType: $accountType, adminLevel: $adminLevel, representativeLevel: $representativeLevel, referenceCode: $referenceCode, parent: $parent, w9: $w9, phone: $phone, directDepositInfo: $directDepositInfo, paymentType: $paymentType, employee: $employee, eManager: $eManager, representativeAddress: $representativeAddress, influencer: $influencer, skipCommissionReqs: $skipCommissionReqs, friend: $friend, donating: $donating) {
			id
		}
	}

`;

export default mutationModifyUserAdmin;