import React from 'react';
import _ from 'lodash';
import { Field } from 'react-final-form';
import { Form  } from 'semantic-ui-react';
import SimpleSelect from '../Form/SimpleSelect';
import { graphql } from '@apollo/react-hoc';
import querySearchUser from '../../queries/userSearch';

const SelectInput = ({ name, required, label, placeholder, containerStyle, data }) => {
	const options = formatOptions(data.searchUsers || []);
	console.log(data);

	const throttled = _.throttle((value) => {
		data.refetch({ name: value });
	}, 2000);

	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div style={containerStyle}>
						<SimpleSelect
							options={options}
							value={input.value}
							onChange={value => {
								if(value) {
									input.onChange(value.value);
								} else {
									input.onChange(null);
								}
							}}
							searchable={true}
							onInputChange={(value) => { value && throttled(value);}}
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

function formatOptions(arr) {
	return arr.map(user => {
		return {
			label: `${user.email} - ${user.firstname} ${user.lastname}`,
			value: user.id,
		};
	});
}

export default graphql(querySearchUser, {
	options: () => {
		return {
			variables: {
				name: ''
			}
		};
	}
})(SelectInput);