import gql from 'graphql-tag';

const MUTATION_ADD_FILE = gql`
	mutation AddFile($file: Upload, $name: String!, $description: String, $url: String, $type: String!, $slug: String!) {
		addFile(file: $file, name: $name, description: $description, url: $url, type: $type, slug: $slug) {
			id
		}
	}

`;

export default MUTATION_ADD_FILE;