import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, Form, Button, Message, Segment, Icon, Modal } from 'semantic-ui-react';
import { TextField } from '../../Form/CustomFields';
import { ReferenceField, ReferenceFieldAsyncValidate } from '../../Form/ReferenceField';
import { UserField, UserFieldAsyncValidate } from '../../Form/UserField';
import Error from '../../Message/Error';
import CreateAccountModal from '../../User/Complex/CreateAccountModal';
import ReactSelectField from '../../Form/ReactSelectField';
import { states, countries } from '../../../config/supported_areas';

class AddCorporatePlan extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			loading: false,
			finished: false,
			error: null,
			confirmAddSaleModal: false,
			values: null
		};
	}

	async sendToServer(values)
	{
		await this.setState({loading: true, error: null, finished: false});

		try {
			await axios.post('/api/admin/addcorporateplan', values);
			await this.setState({loading: false, finished: true});
			this.setState({ show: true });
			this.props.reset();
		} catch(error) {
			var errorText = error.response.data.message || 'There was an error. Please try again or contact customer support.';
			this.setState({error: errorText, loading: false, finished: false});
		}

		this.onConfirmAddSaleModalClose();

		this.setState({loading: false});
	}

	renderFinished = () => {
		if(this.state.finished)
		{
			return(
				<Message positive onDismiss={() => this.setState({finished: false})}>
					<Message.Header>Corporate Plan Added Successfully!</Message.Header>
				</Message>
			);
		}
	}

	onConfirmAddSaleModalClose = () => {
		this.setState({ confirmAddSaleModal: false });
	}

	renderConfirmAddSale = () => {
		const { values } = this.state;
		return(
			<Modal size="tiny" open={this.state.confirmAddSaleModal} onClose={this.onConfirmAddSaleModalClose}>
				<Modal.Header>Are you sure?</Modal.Header>
				<Modal.Content>
					<p><strong>Are you sure you want to add this corporate plan?</strong></p>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={this.onConfirmAddSaleModalClose} className="btn-custom-deny">No</Button>
					<Button onClick={() => this.sendToServer(values)} className="btn-custom-confirm">Yes</Button>
				</Modal.Actions>
			</Modal>
		);
	}

	render() {
		return(
			<div style={{flex: '1'}}>
				{this.renderConfirmAddSale()}
				<Grid style={{marginTop: '20px'}}>
					<Grid.Row centered>
						<Grid.Column width={13}>
							<Segment attached='top'>
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
									<h2>Add Corporate Plan</h2>
									<div style={{display: 'flex'}}>
										<CreateAccountModal icon />
										<Button type='button' onClick={() => this.props.reset()} icon className='btn-custom-negative'>
											<Icon name='trash outline' size='large'/>
										</Button>
									</div>
								</div>
							</Segment>
							<Segment fluid attached>
								<Form onSubmit={this.props.handleSubmit(values => this.setState({values, confirmAddSaleModal: true}))} loading={this.state.loading}>
									<Form.Group widths='equal'>
										<Field name="account"              component={UserField}      label="Account"   required />
										<Field name="reference"            component={ReferenceField} label="Reference" required />
									</Form.Group>

									<Form.Group widths='equal'>
										<Field name="name"     component={TextField} label="Company Name" required />
										<Field name="code" component={TextField} label="Company Code" required />
									</Form.Group>

									<Form.Group widths='equal'>
										<Field name="email"   component={TextField} label="Email" required />
										<Field name="phone"    component={TextField} label="Phone" required />
									</Form.Group>
									<hr />
									<div>
										<h3>Company Address</h3>
										<Field name="street1"   component={TextField} placeholder="Street 1" label="Street Address" required/>
										<Field name="street2"   component={TextField} placeholder="Street 2" />

										<Form.Group widths='equal'>
											<Field name="city"      component={TextField} label="City" required />
											<Field name="state"     component={ReactSelectField} label="State" required options={states} />
										</Form.Group>

										<Form.Group widths='equal'>
											<Field name="zip"       component={TextField} label="Zip" required />
											<Field name="country"   component={ReactSelectField} label="Country" required options={countries} />
										</Form.Group>
									</div>
									{this.renderFinished()}
									<Error error={this.state.error}>The plan was not added!</Error>
									<Button className="btn-custom-primary" fluid type="submit">Add Corporate Plan</Button>
								</Form>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

function validate(form) {
	var errors = {};

	if(!form.name) {
		errors.name = 'Required Field';
	}

	if(!form.code) {
		errors.code = 'Required Field';
	}

	if(!form.phone) {
		errors.phone = 'Required Field';
	}

	if(!form.email) {
		errors.email = 'Required Field';
	}

	if(!form.reference) {
		errors.reference = 'Required Field';
	}

	if(!form.account) {
		errors.account = 'Required Field';
	}

	if(!form.street1) {
		errors.street1 = 'Required Field';
	}

	if(!form.city) {
		errors.city= 'Required Field';
	}

	if(!form.state) {
		errors.state = 'Required Field';
	}

	if(!form.zip) {
		errors.zip = 'Required Field';
	}

	if(!form.country) {
		errors.country = 'Required Field';
	}

	return errors;
}

const asyncValidate = async (values, dispatch, props, field) => {
	if(field === 'customer' && values.customer) {
		return axios.post('/api/addsale/check', { type: 1, id: values.customer })
			.then((res) => {
				if(!res.data.exists) {
					const previousErrors = props.asyncErrors;
					throw Object.assign({}, previousErrors, { customer: 'Cannot find account' });
				}
			});
	}

	ReferenceFieldAsyncValidate(values, dispatch, props, field);

	UserFieldAsyncValidate(values, dispatch, props, field);

	// if(field === 'membership' && values.membership) {
	//   return axios.post('/api/addsale/check', { type: 3, id: values.membership })
	//     .then((res) => {
	//       console.log(res);
	//       if(!res.data.exists) {
	//         const previousErrors = props.asyncErrors;
	//         throw Object.assign({}, previousErrors, { membership: 'Can not find membership' })
	//       }
	//     });
	// }
};

function mapStateToProps({ form }) {
	return(
		{
			myform: form.addSaleAdminForm
		}
	);
}

export default reduxForm({
	destroyOnUnmount: true,
	validate,
	asyncValidate,
	asyncBlurFields: ['reference', 'membership', 'user'],
	form: 'addCorporatePlanAdminForm'
})(connect(mapStateToProps)(AddCorporatePlan));