import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Button, Table } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import GraphQLError from '../Message/GraphQLError';
import TextInput from '../FinalForm/TextInput';
import Checkbox from '../FinalForm/StyledCheckbox';

const ChangeCheckboxField = ({
	label, property, table, basic, value, mutate, mutateLoading, mutateError, refetch, id, additionalProperties={}, changeValues={}
}) => {

	const formatted = value || false;

	const onSubmit = (values) => {
		const newValue = _.get(values, property);
		if(newValue !== null && value !== newValue) {
			const newValues = _.set({ property, id,
			}, property, newValue);

			mutate({
				variables: { ...newValues, ...additionalProperties },
				refetchQueries: refetch,
			});
		}
	};

	const renderTrigger = () => {
		const style = {};
		if(table || basic) {
			style.marginLeft = 0;
		}
		let v = String(formatted) || 'false';
		v = changeValues[v] ? changeValues[v] : v;
		return (
			<span style={style} className="changeInformation__value">{v}</span>
		);
	};

	const renderForm = () => {
		const initialValues = _.set({}, property, value);
		return(
			<FinalForm
				onSubmit={onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={mutateLoading}>
						<Form.Group widths='equal'>
							<Checkbox
								name={property}
								label={label}
								required
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Button
								fluid
								content="Change"
								type="submit"
							/>
						</Form.Group>
						<GraphQLError error={mutateError}>There was an issue</GraphQLError>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</Form>
				)}
			/>
		);
	};

	const renderPopup = () => {
		return (
			<Popup
				trigger={renderTrigger()}
				content={renderForm()}
				on='click'
			/>
		);
	};

	if(table) {
		return (
			<Table.Row>
				<Table.Cell>{label}</Table.Cell>
				<Table.Cell>{renderPopup()}</Table.Cell>
			</Table.Row>
		);
	}

	return (
		<p>
			<strong>{label}:</strong>
			{renderPopup()}
		</p>
	);
};

export default ChangeCheckboxField;
