import React from 'react';
import { Segment, Button } from 'semantic-ui-react';
import AddFolder from './AddFolder';
import _ from 'lodash';
import AddFile from './AddFile';
import DeleteFolder from './DeleteFolder';
import { useQuery, useMutation } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EditFolder from './EditFolder';
import { useParams } from 'react-router-dom';
import DocumentList from './DocumentList';
import MUTATION_ORDER_RESOURCES from './graphql/orderResources';

const getFile = (route='') => {
	const sp = route.split('/');
	const last = sp[sp.length - 1];

	if(/^[a-fA-F0-9]{24}$/.test(last)) {
		return last;
	}

	return '';
};

const Documents = ({ tag, baseURL='/tools/resources' }) => {
	console.log(baseURL);
	const [ordering, setOrdering] = React.useState(false);
	const { data, loading } = useQuery(QUERY_CURRENT_USER);
	const admin = loading ? false : (_.get(data, 'user.accountType', 0) >= 10);
	const [orderResources, { loading: mLoading, error }] = useMutation(MUTATION_ORDER_RESOURCES);
	const listRef = React.useRef();
	const { route: fullSlug='' } = useParams();
	const fileID = getFile(fullSlug);
	const slug = fullSlug.replace(fileID ? '/' + fileID : '', '');

	React.useEffect(() => {
		setOrdering(false);
	}, [slug]);

	React.useEffect(() => {
		const e = _.get(error, 'graphQLErrors.0.message');
		if (e) {
			alert(e);
			setOrdering(true);
		}
	}, [error]);

	const reorder = (slug, ids) => {
		orderResources({
			variables: { slug, ids },
			refetchQueries: listRef && listRef.current && listRef.current.getQuery(),
			awaitRefetchQueries: true,
		}).then(() => listRef && listRef.current && listRef.current.setOrder());
	};

	return (
		<div className="animated fadetwoIn dnp-page">
			<DndProvider backend={HTML5Backend}>
				<Segment.Group className="flex1">
					<Segment className="tableHeaderContainer" style={{ flexWrap: 'wrap' }}>
						<h2 style={{ flex: 1 }}>Documents</h2>
						<div>
							{ admin && !ordering && slug && <AddFile {...{ slug, tag, baseURL }} /> }
							{ admin && !ordering && <AddFolder {...{ slug, tag, baseURL }} /> }
							{ admin && !ordering && slug && <EditFolder {...{ slug, tag, baseURL }} /> }
							{ admin && (
								<Button 
									content={ordering ? 'Done' : (slug ? 'Order Files' : 'Order Folders')}
									color="purple"
									onClick={() => setOrdering(v => !v)}
									loading={mLoading}
									disabled={mLoading}
								/>
							)}
							{ (slug && admin && !ordering) && <DeleteFolder {...{ slug, tag, baseURL }} /> }
						</div>
					</Segment>
					<Segment className="flex1" id="gContainer">
						<DocumentList
							{...{
								ref: listRef,
								slug,
								ordering,
								reorder,
								admin,
								fileID,
								tag,
								baseURL
							}}
						/>
					</Segment>
				</Segment.Group>
			</DndProvider>
		</div>
	);
};

export default Documents;