import React from 'react';
import DetailsTable from '../../../../Table/DetailsTable';
import { DetailsRow } from '../../../../Table';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import DeleteMembership from './helpers/DeleteMembership';

const plans = {
	3: 'Individual Plan',
	4: 'Family Plan'
};

const BasicInfo = ({ membership, ids, refetch, onClose }) => {
	const { firstname, lastname, plan, membershipID, customer } = membership;
	const email = _.get(customer, 'email', 'No Connected Account');

	return (
		<div>
			<DetailsTable noHeader>
				<DetailsRow
					property="Member's First Name"
					value={firstname}
				/>
				<DetailsRow
					property="Member's Last Name"
					value={lastname}
				/>
				<DetailsRow
					property="Plan Name"
					value={plans[plan] || 'Unknown'}
				/>
				<DetailsRow
					property="Membership ID"
					value={membershipID}
				/>
				<DetailsRow
					property="Account Email"
					value={email}
				/>
			</DetailsTable>
			<div className="containerRight">
				<DeleteMembership
					{...{ ids, refetch, onClose }}
				/>
			</div>
		</div>
	);
};

export default BasicInfo;