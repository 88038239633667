import gql from 'graphql-tag';

const queryGetTrainingAdmin = gql`

	query GetTraining($id: String!) {
		getTraining(id: $id) {
			id
			created
			type
			title
			youtubeID
			description
			createdBy {
				firstname
				lastname
			}
		}
	}

`;

export default queryGetTrainingAdmin;