import gql from 'graphql-tag';

const queryGetUsersAdmin = gql`
  query queryGetUsersAdmin(
    $skip: Int!
    $limit: Int!
    $sortBy: [SortBy]
    $filtered: [TableFilter]
  ) {
    getUsersAdmin(
      skip: $skip
      limit: $limit
      sortBy: $sortBy
      filtered: $filtered
    ) {
      count
      users {
        id
        firstname
        lastname
        email
        accountType
        influencer
        friend
        representativeID
        representativeLevel {
          typeID
          name
        }
      }
    }
  }
`;

export default queryGetUsersAdmin;
