import React, { Component } from 'react';
import { Popup, Button, Header, Form } from 'semantic-ui-react';
import axios from 'axios';
import { ReferenceField, ReferenceFieldAsyncValidate } from '../../Form/ReferenceField';
import { reduxForm, Field } from 'redux-form';

class ChangeParent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			loading: false,
			searchQuery: null,
			options: [],
			value: null,
			open: false,
		};

	}

	async changeServerValues(v) {
		this.setState({loading: true});

		const values = {
			id: this.props.id,
			values: {
				accountID: v.account
			}
		};

		try {
			await axios.post('/api/admin/managers/change', values);
			if(this.props.onSuccess) {this.props.onSuccess();}
			this.setState({open: false, loading: false});
		} catch (error) {
			return;
		}
	}

	renderPopup() {
		return(
			<div>
				<Form onSubmit={this.props.handleSubmit(values => this.changeServerValues(values))} loading={this.state.loading}>
					<Field name="account" component={ReferenceField} label="Account" required />
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<Button type='submit' style={{marginTop: '10px'}}>Change</Button>
					</div>
				</Form>
			</div>
		);
	}

	renderText() {
		return(
			<Header color="blue" onClick={() => this.setState({open: !this.state.open})} style={{display: 'inline'}} as='h4'>{this.props.def || 'N/A'}</Header>
		);
	}

	render() {
		return(
			<Popup
				trigger={this.renderText()}
				content={this.renderPopup()}
				on='click'
				flowing
				open={this.state.open}
			/>
		);
	}

}

export default reduxForm({
	destroyOnUnmount: true,
	ReferenceFieldAsyncValidate,
	asyncBlurFields: ['reference'],
	form: 'changeAccountForm'
})(ChangeParent);
