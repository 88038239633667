import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_MARK_REFERRAL_COMPLETE from '../../../mutations/markReferralComplete';

const ConfirmModal = ({ info, onClose, onMarkedComplete }) => {
	const [markComplete, { data, loading, error }] = useMutation(MUTATION_MARK_REFERRAL_COMPLETE, {

	});

	const onMarkComplete = async () => {
		await markComplete({ variables: { id: info.id }});
		onMarkedComplete && onMarkedComplete();
	};

	return (
		<Modal 
			open={!!info}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
			size="tiny"
		>
			<Modal.Header>
				<h2>Mark referral as completed?</h2>
			</Modal.Header>
			<Modal.Description>
				<p style={{ fontSize: '18px', letterSpacing: '1px', margin: '20px 15px', }}>Do you want to mark the description as completed?</p>
			</Modal.Description>
			<Modal.Actions>
				<Button color='red' onClick={onClose}>
					<Icon name='remove' /> No
				</Button>
				<Button color='green' onClick={onMarkComplete}>
					<Icon name='checkmark' /> Yes
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default ConfirmModal;