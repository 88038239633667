import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './header.module.scss';
import Popup from 'reactjs-popup';
import { withRouter } from 'react-router-dom';
import { firebaseAuth } from '../../config/firebase';
import _ from 'lodash';

const Header = ({ onHamburgerClick = () => {}, history, user }) => {
	return (
		<div className={styles.container}>
			<div className={styles.hamburger} onClick={onHamburgerClick}>
				<FontAwesomeIcon icon={['fas', 'bars']} size="1x" />
			</div>
			<div className={styles.rest}>
				Deluxe Network Program
			</div>
			<Popup
				trigger={
					<div className={styles.dropdown}>
						{_.get(user, 'firstname')} { _.get(user, 'lastname')}
						<FontAwesomeIcon icon={['fas', 'angle-left']} className={styles.linkIcon} />
					</div>
				}
				position="bottom right"
				contentStyle={{
					padding: '0',
					border: '1px solid rgba(0,0,0,.15)',
					borderRadius: 5,
					backgroundColor: '#f9fafc',
					boxShadow: '0px 9px 10px rgba(0,0,0,.2)',
					zIndex: '100',
					minWidth: '200px',
					width: 'auto',
				}}
				arrow={false}
				on={['click']}
			>
				<div className={styles.menu}>
					<div className={styles.menuOption} onClick={() => history.push('/settings')}>Settings</div>
					<div className={styles.menuOption} onClick={() => firebaseAuth().signOut()}>Logout</div>
				</div>
			</Popup>
		</div>
	);
};

export default withRouter(Header);