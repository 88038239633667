/* eslint react/display-name: 0 */
import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';
import { msToTime } from '../timeUtils';

const columns = ({ state, changeDate, triggerDatePopup }) => [
	{
		Header: 'In Time',
		id: 'in',
		filterable: true,
		minWidth: 150,
		accessor: d => `${moment(d.in).tz('America/Boise').format('MMMM Do YYYY, h:mm:ss a z')}`,
		foldable: true,
		Filter: ({ onChange }) => renderDateFilter({ onChange, state, triggerDatePopup, changeDate, property: 'in'})
	},
	{
		Header: 'Out Time',
		id: 'out',
		filterable: true,
		minWidth: 150,
		accessor: d => `${moment(d.out).tz('America/Boise').format('MMMM Do YYYY, h:mm:ss a z')}`,
		foldable: true,
		Filter: ({ onChange }) => renderDateFilter({ onChange, state, triggerDatePopup, changeDate, property: 'out'})
	},
	{
		Header: 'On Break',
		id: 'isOnBreak',
		filterable: false,
		minWidth: 150,
		accessor: d => `${d.isOnBreak || false}`,
		foldable: true,
	},
	{
		Header: 'Total Break Time',
		id: 'totalBreakTime',
		filterable: false,
		minWidth: 150,
		accessor: d => `${msToTime(d.totalBreakTime)}`,
		foldable: true,
	},
	{
		Header: 'Total Paid Time',
		id: 'totalPaidTime',
		filterable: false,
		minWidth: 150,
		accessor: d => `${msToTime(d.totalPaidTime)}`,
		foldable: true,
	},
	{
		Header: 'Total Time',
		id: 'totalTime',
		filterable: false,
		minWidth: 150,
		accessor: d => `${msToTime(d.totalTime)}`,
		foldable: true,
	},
];

function renderDateFilter({ onChange, state, triggerDatePopup, changeDate, property }) {

	const formatDate = (date, start=true) => {
		let d = moment(date).tz('America/Boise');
		d = start ? moment(d).startOf('day') : moment(d).endOf('day');
		return d;
	};

	return (
		<div style={{ textAlign: 'left' }}>
			<Popup
				trigger={
					<Button
						onClick={() => triggerDatePopup(property + '_popup')}
						fluid
					>
					Filter Date
					</Button>
				}
				open={state[property + '_popup']}
				content={(
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<DatePicker
							isClearable={true}
							placeholderText="Date From"
							selected={state[property + '_start_date']}
							selectsStart
							endDate={state[property + '_end_date']}
							showMonthDropdown
							showYearDropdown
							customInput={<Input/>}
							onChange={value => { changeDate(property + '_start_date', value); onChange(value);}}
						/>
						<div style={{ margin: '0 5px' }}>Until</div>
						<DatePicker
							isClearable={true}
							placeholderText="Date To"
							selected={state[property + '_end_date']}
							selectsEnd
							startDate={state[property + '_start_date']}
							showMonthDropdown
							showYearDropdown
							customInput={<Input/>}
							onChange={value => { changeDate(property + '_end_date', value, false); onChange(value);}}
						/>
					</div>
				)}
			/>
		</div>
	);
}

export default columns;