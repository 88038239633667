import _ from 'lodash';

export default (values) => {
	const errors = {};

	if(!values.id) {
		errors.id = 'Required Field';
	}

	if(!values.plans) {
		errors.plans = 'Must have at least 1 plan to purchase';
	} else {
		errors.plans = [];
		values.plans.forEach((plan, i) => {
			if(!plan) {
				// errors.plans = 'Information must be filled out.';
			} else {
				errors.plans[i] = {};
				if(!plan.firstname) {
					errors.plans[i].firstname = 'Required Field';
				}

				if(!plan.lastname) {
					errors.plans[i].lastname = 'Required Field';
				}

				if(!plan.phone) {
					errors.plans[i].phone = 'Required Field';
				}

				if(plan.ownAddress) {
					if(!plan.street1) {
						errors.plans[i].street1 = 'Required Field';
					}
					if(!plan.city) {
						errors.plans[i].city = 'Required Field';
					}
					if(!plan.state) {
						errors.plans[i].state = 'Required Field';
					}
					if(!plan.country) {
						errors.plans[i].country = 'Required Field';
					}
					if(!plan.zip) {
						errors.plans[i].zip = 'Required Field';
					}
				}

				if(plan.familyPlan) {
					if(plan.family) {
						errors.plans[i].family = [];
						plan.family.forEach((member,i2) => {
							if(!member.name) {
								errors.plans[i].family[i2] = { name: 'Required Field' };
							}
						});
					}
				}

			}
		});
	}

	if(!values.stripeName) {
		errors.stripeName = 'Required Field';
	}

	if(!values.stripeCard) {
		errors.stripeCard = 'Required Field';
	} else if (!_.get(values, 'stripeCard.complete')) {
		errors.stripeCard = 'Please complete this field';
	}

	if(!values.stripeExpiry) {
		errors.stripeExpiry = 'Required Field';
	} else if (!_.get(values, 'stripeExpiry.complete')) {
		errors.stripeExpiry = 'Please complete this field';
	}

	if(!values.stripeCVC) {
		errors.stripeCVC = 'Required Field';
	} else if (!_.get(values, 'stripeCVC.complete')) {
		errors.stripeCVC = 'Please complete this field';
	}

	if(!values.stripeZip) {
		errors.stripeZip = 'Required Field';
	}

	return errors;
};