import React  from 'react';
import { Field } from 'react-final-form';
import { Form, Checkbox as SemanticCheckbox } from 'semantic-ui-react';

const Checkbox = ({ name, label }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field error={!!meta.error}>
					<SemanticCheckbox
						name={name}
						label={label}
						checked={!!input.value}
						onChange={(e, data) => input.onChange(data.checked)}
						id={`${name}`}
					/>
				</Form.Field>
			)}
		</Field>
	);
};

export default Checkbox;