/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import { Form, Input } from 'semantic-ui-react';

class FSFileInput extends Component {

	onChange = ({ target: { validity, files: [file] } }, input) => {
		console.log(file);
		input.onChange(file);
	}

	render() {
		const { placeholder, name, label, required, validate } = this.props;
		return (
			<Field name={name} validate={validate}>
				{({ input, meta }) => (
					<Form.Field required={!!required} error={meta.error && meta.touched}>
						{label !== false && <label>{label || placeholder}</label>}
						<Input name={name} type="file" placeholder={placeholder} onChange={stuff => this.onChange(stuff, input)} />
						{meta.error && meta.touched && <span className="error">{meta.error}</span>}
					</Form.Field>
				)}
			</Field>
		);
	}
}

export default FSFileInput;