/* eslint no-console: 0 */
import React, { Component } from 'react';
import { Modal, Header, Button, Input, Message } from 'semantic-ui-react';
import axios from 'axios';

class ChangeEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newEmail: '',
			success: false,
			error: null,
			loading: false,
		};
		this.onChangeNewEmail = this.onChangeNewEmail.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this.onClose = this.onClose.bind(this);
	}

	onChangeNewEmail(event) {
		this.setState({newEmail: event.target.value});
	}

	async onSubmit() {
		console.log('Email', this.state.newEmail);
		this.setState({error: null, loading: true});
		if(this.state.newEmail) {
			let url = this.props.uid ? '/api/admin/modify/change_email' : '/api/account/settings/change_email';
			let req = { email: this.state.newEmail };

			if(this.props.uid) {
				req.id = this.props.uid;
			}

			try {
				await axios.post(url, req);
				this.setState({ success: true, loading: false });
			} catch (error) {
				this.setState({error: 'Email could not be changed', loading: false});
			}

		} else {
			this.setState({error: 'Invalid Email', loading: false});
		}
	}

	renderError() {
		if(this.state.error) {
			return(
				<Message negative>
					<Message.Header>Error</Message.Header>
					<p>Please try again or contact customer support.</p>
					<p>{this.state.error || ''}</p>
				</Message>
			);
		}
	}

	renderContent() {
		if(this.state.success) {
			return(
				<Modal.Description>
					<div>
					Email Changed Successfully
					</div>
				</Modal.Description>
			);
		} else {
			return(
				<Modal.Description>
					<Header>Change Email</Header>
					{this.renderError()}
					<Input placeholder="New Email" action>
						<input value={this.state.newEmail} onChange={this.onChangeNewEmail} />
						<Button type="button" onClick={() => this.onSubmit()} loading={this.state.loading} >Submit</Button>
					</Input>
				</Modal.Description>
			);
		}
	}

	onClose() {
		this.setState({success: false, newEmail: '', error: null});
		if(this.props.onSuccess) {
			this.props.onSuccess();
		}
	}

	render() {

		return (
			<Modal trigger={<Button>Change Email</Button>} closeIcon size="mini" onClose={() => this.onClose()}>
				<Modal.Content>
					{this.renderContent()}
				</Modal.Content>
			</Modal>
		);
	}
}

export default ChangeEmail;