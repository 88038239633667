import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import generateColumns from './cols';
import ReactTable from 'react-table';
import _ from 'lodash';
import QUERY_GET_USERS_ADMIN from '../../../queries/getUsersAdmin';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import UserInfoModal from './UserInfo/UserInfoModal';

const UsersTableContainer = forwardRef(
  ({ fixedParams, hideCols = [], path }, ref) => {
    const [pages, setPages] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [fetchQuery, setFetchQuery] = useState(false);
    const [datePopups, setDatePopups] = useState({});
    const [dates, setDates] = useState({});
    // const [modalInfo, setModalInfo] = useState(null);
    const [vars, setVars] = useState({});
    const history = useHistory();
    const [modalID, setModalID] = useState();
    const params = useParams();

    useEffect(() => {
      setModalID(_.get(params, 'id'));
    }, [params]);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        refetch(vars);
      },
    }));

    const [loadInitialData, { data: qData, loading, called, refetch }] =
      useLazyQuery(QUERY_GET_USERS_ADMIN, {});

    const { users: data, count } = _.get(qData, 'getUsersAdmin', {
      count: 0,
      users: [],
    }) || { data: [], count: 0 };

    useEffect(() => {
      if (fetchQuery) {
        loadInitialData({
          variables: fetchQuery,
        });
      }
    }, [fetchQuery]);

    useEffect(() => {
      setPages(Math.ceil(count / pageSize));
    }, [count, pageSize]);

    const onFetchData = ({ pageSize: pSize, page, sorted, filtered }) => {
      const date = [];
      for (let ob in dates) {
        if (dates[ob]) {
          date.push({ id: ob, value: dates[ob].valueOf() });
        }
      }
      const required = [];
      if (fixedParams && Array.isArray(fixedParams)) {
        required.push(...fixedParams);
      }
      const variables = {
        limit: pSize,
        skip: page * pSize,
        sortBy: sorted,
        filtered: [...filtered, ...date, ...required],
      };
      setVars(variables);
      if (!called) {
        setFetchQuery(variables);
      } else {
        setPageSize(pSize);
        refetch(variables);
      }
    };

    const rowProps = (state, rowInfo) => {
      return {
        onClick: (e, handleOriginal) => {
          const info = _.get(rowInfo, 'original');
          if (info) {
            if (path) {
              history.push(`${path}/${info.id}`);
            } else {
              setModalID(info.id);
            }
          }
          handleOriginal && handleOriginal();
        },
        className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
      };
    };

    return (
      <div className='animated fadetwoIn'>
        <UserInfoModal
          id={modalID}
          onClose={() => {
            refetch();
            setModalID();
            if (path) {
              history.push(path);
            }
          }}
        />
        <ReactTable
          data={data}
          columns={generateColumns()}
          pageSizeOptions={[10, 15, 20, 50, 100]}
          defaultPageSize={pageSize}
          pages={pages}
          onFetchData={onFetchData}
          manual
          defaultSorted={[
            {
              id: 'date',
              desc: true,
            },
          ]}
          loading={loading}
          getTrProps={rowProps}
        />
        <h4 style={{ textAlign: 'center', marginTop: 10 }}>
          Total Users: {count}
        </h4>
      </div>
    );
  }
);

export default UsersTableContainer;
