import React from 'react';
import styles from './Documents.module.scss';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

const Folder = ({ folder, history, onDropped, ordering, baseURL }) => {
	const { id, name, slug, files, color } = folder;
	const num_of_files = files ? files.length : 0;
	const ref = React.useRef();

	const [{ isDragging }, connectDrag] = useDrag({
		item: { id, type: 'FOLDER_COMPONENT' },
		canDrag: () => ordering,
		collect: monitor => {
			return {
				isDragging: monitor.isDragging()
			};
		}
	});

	const [, connectDrop] = useDrop({
		accept: 'FOLDER_COMPONENT',
		hover(item) {
			item.id !== id && onDropped && onDropped(item.id, id);
		},
	});

	connectDrag(ref);
	connectDrop(ref);
	
	const opacity = isDragging ? 0.5 : 1;
	const containerStyle = { opacity };

	return (
		<div
			className={cx(styles.folderContainer, 'animated fadetwoIn')}
			style={containerStyle}
			ref={ref}
			onClick={() => {
				if(!ordering) {
					console.log(baseURL);
					history.push(`${baseURL}/${slug}`);
				}
			}}
		>
			<FontAwesomeIcon
				icon={['fas', 'folder']}
				className={styles.folderIcon}
				color={color || '#000000'}
			/>
			<h5>{name}</h5>
			<span>{num_of_files} {num_of_files === 1 ? 'file' : 'files'}</span>
		</div>
	);
};

export default Folder;