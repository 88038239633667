import React from 'react';
import { Modal } from 'semantic-ui-react';
import SeminarModalContent from './SeminarModalContent';

const SeminarModal = ({ info, onClose, onRefresh }) => {
	console.log(info);
	return (
		<Modal
			open={!!info}
			onClose={onClose}
			closeIcon
		>
			{!!info &&
			<>
			<Modal.Header>Seminar Request for {info.name}</Modal.Header>
			<Modal.Content>
				<SeminarModalContent 
					{...info}
					onClose={onClose}
					onRefresh={onRefresh}
				/>
			</Modal.Content>
			</>}
		</Modal>
	);
};

export default SeminarModal;