import React, { useState } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import DetailsTable from '../../../../Table/DetailsTable';
import DetailsRow from '../../../../Table/DetailsRow';
import settings from '../../../../../config/settings';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CANCEL_SUB_CORPORATE_MEMBERSHIP from '../../../../../mutations/cancelSubCorporateMembership';
import GraphQLError from '../../../../Message/GraphQLError';
import QUERY_GET_MEMBERSHIP from '../../../../../queries/getMembership';
import DeleteModal from '../../../../common/DeleteModal/DeleteModal';

const Membership = ({ data, secondary, baseID }) => {
	const { id, firstname, lastname, membershipID, canceled } = data;
	const url = `${settings.baseUIUrl}/admin/memberships/${id}`;
	const [cancelMembership, { loading, error }] = useMutation(MUTATION_CANCEL_SUB_CORPORATE_MEMBERSHIP);
	const [open, setOpen] = useState(false);

	return (
		<>
		<DeleteModal
			open={open}
			onClose={() => setOpen(false)}
			onDelete={async () => {
				await cancelMembership({
					variables: { id },
					awaitRefetchQueries: true,
					refetchQueries: [{
						query: QUERY_GET_MEMBERSHIP,
						variables: { id: baseID },
					}],
				});
				setOpen(false);
			}}
			loading={loading}
		/>
		<Segment secondary={secondary}>
			<DetailsTable noHeader>
				<DetailsRow
					property="Membership ID"
					value={membershipID}
				/>
				<DetailsRow
					property="Name"
					value={`${firstname} ${lastname}`}
				/>
				<DetailsRow
					property="Set to Renew?"
					value={canceled ? 'No, Its Cancled' : 'Yes'}
				/>
			</DetailsTable>
			<GraphQLError error={error} />
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{ !canceled && <Button
					color="red"
					content="Cancel Membership"
					onClick={() => setOpen(true)}
					loading={loading}
					disabled={loading}
				/> }
				<div style={{ flex: 1 }} />
				<Button
					color="blue"
					content="View Membership"
					as="a"
					target="_blank"
					href={url}
				/>
			</div>
		</Segment>
		</>
	);
};

const GroupList = ({ baseID, memberships }) => {
	return (
		<div>
			{memberships.map((membership, index) => (
				<Membership
					key={membership.id}
					data={membership}
					secondary={index % 2 === 0}
					baseID={baseID}
				/>
			))}
		</div>
	);
};

export default GroupList;