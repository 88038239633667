import React from 'react';
import gql from 'graphql-tag';
import { Button } from 'semantic-ui-react';
import DeleteModal from '../../../common/DeleteModal';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const DELETE_BUSINESS_PLAN = gql`
    mutation DeleteBusinessPlan($id: String!) {
        deleteBusinessPlan(id: $id)
    }
`;

const DeleteBusinessPlan = ({ id, refetch }) => {
	const [deleteModal, setDeleteModal] = React.useState(false);
	const [deletePlan, { loading, error, data }] = useMutation(DELETE_BUSINESS_PLAN);
	const history = useHistory();

	React.useEffect(() => {
		if(data && data.deleteBusinessPlan) {
			setDeleteModal(false);
			history.push('/admin/businessplans');
		}
	}, [data]);

	return (
        <>
            <DeleteModal
            	open={deleteModal}
            	onClose={() => setDeleteModal(false)}
            	onDelete={() => {
            		deletePlan({
            			variables: { id }
            		});
            	}}
            	{...{ loading, error }}
            />
            <Button
            	color="red"
            	content="Delete Business Plan"
            	onClick={() => setDeleteModal(true)}
            />
        </>
	);
};

export default DeleteBusinessPlan;