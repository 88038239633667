import React, { Component } from 'react';
import PasswordReset from '../Basic/PasswordReset';
import { Modal, Transition } from 'semantic-ui-react';

const modalHeaderStyle = {
	//background: '#1e3c72',
	//background: '-webkit-linear-gradient(to right, #1e3c72, #2a5298)',
	background: 'linear-gradient(to right, #1e3c72, #2a5298)',
	minHeight: '80px', color: '#f2f2f2',
	textAlign: 'center',
	borderTopLeftRadius: '2px',
	borderTopRightRadius: '2px',
	borderBottom: '0'
};

class PasswordResetModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			visible: false,
		};

		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	async handleOpen() {
		await this.setState({open: true});
		this.setState({visible: true});
	}

	handleClose() {
		this.setState({visible: false});
	}

	handleVisible() {
		if(!this.state.visible) {
			this.setState({open: false});
		}
	}

	renderButton() {
		return(
			<h4 className="password-reset-link" onClick={() => this.handleOpen()} href="#">Forgot Password?</h4>
		);
	}

	render() {
		return(
			<div>
				{this.renderButton()}
				<Transition animation='fly down' duration={800} visible={this.state.visible} onComplete={() => this.handleVisible()}>
					<Modal
						closeIcon
						open={this.state.open}
						onClose={this.handleClose}
						style={{borderRadius: '2px'}}
						size="tiny"
					>
						<Modal.Header style={modalHeaderStyle}><h2 style={{fontWeight: '800'}}>Reset Your Password</h2></Modal.Header>
						<Modal.Content style={{
							backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',}}>
							<PasswordReset />
						</Modal.Content>
					</Modal>
				</Transition>
			</div>
		);
	}
}

export default PasswordResetModal;
