import React, {Component} from 'react';
import { Button, Form, Grid, Message, Icon } from 'semantic-ui-react';
import { firebaseAuth } from '../../../config/firebase';
import '../../../style/login.css';
import CreateAccountModal from '../Complex/CreateAccountModal';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {failed: false, clicked: false, error: '', email: '', password: ''};
		this.login = this.login.bind(this);

		this.handleChangeEmail = this.handleChangeEmail.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
	}

	async login(username, password) {
		this.setState({clicked: true, failed: false});
		const user = await firebaseAuth().signInWithEmailAndPassword(username, password).catch(error => {
			this.setState({failed: true, clicked: false, error: error.code});
		});

		if(user) {
			this.setState({clicked: false});
			if(this.props.onLoginIn) {
				this.props.onLoginIn();
			}
			if( this.props.onSuccess ) { this.props.onSuccess(); }
		}
	}

	handleChangeEmail(event) {
		this.setState({email: event.target.value});
	}

	handleChangePassword(event) {
		this.setState({password: event.target.value});
	}

	handleSubmit(event) {
		this.login(this.state.email, this.state.password);
		event.preventDefault();
	}

	handleDismiss() {
		this.setState({failed: false, clicked: false});
	}

	renderMessage() {
		if(this.state.clicked) {
			return(
				<Message icon onDismiss={this.handleDismiss}>
					<Icon name='circle notched' loading />
					<Message.Content>
						<Message.Header>Just one second</Message.Header>
            We are logging you in.
					</Message.Content>
				</Message>
			);
		}

		if(this.state.failed) {
			var error_message = 'An unknown error occurred! Please contact support for help.';

			if(this.state.error === 'auth/wrong-password') {
				error_message = 'You typed in the wrong password! Please try again.';
			} else if (this.state.error === 'auth/too-many-requests') {
				error_message = 'We have blocked all requests from this device due to unusual activity. Try again later.';
			} else if (this.state.error === 'auth/invalid-email') {
				error_message = 'Incorrect email entered. Please check your spelling!';
			}

			return(
				<Message negative onDismiss={this.handleDismiss}>
					<Message.Header>There was an error logging you in!</Message.Header>
					<p>{error_message}</p>
				</Message>
			);
		}
	}


	render() {
		const t = this.state.clicked ? true : null;

		return(
			<div className='login-form'>
				<Grid textAlign='center' verticalAlign='middle'>
					<Grid.Column style={{maxWidth: '500px'}}>
						{this.renderMessage()}


						<Form size='large'>
							<Form.Input
								fluid
								icon='user'
								iconPosition='left'
								placeholder='E-mail address'
								value={this.state.email}
								onChange={this.handleChangeEmail} />
							<Form.Input
								fluid
								icon='lock'
								iconPosition='left'
								placeholder='Password'
								type='password'
								value={this.state.password}
								onChange={this.handleChangePassword} />
							<Button loading={t} color='blue' fluid size='large' onClick={this.handleSubmit} icon labelPosition='right'>Login <Icon name='right arrow' /></Button>
							<hr />
							<h4>Forgot Password?</h4>
						</Form>
						<CreateAccountModal link />

					</Grid.Column>
				</Grid>
			</div>
		);
	}
}



export default Login;
