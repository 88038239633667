/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import SimpleSelect from '../../../Form/SimpleSelect';

const payTypes = [
	'Check',
	'Direct Deposit',
];

const PayTypeOptions = [
	{ value: 0, label: 'Check' },
	{ value: 1, label: 'Direct Deposit' },
];

const DonatingOptions = [
	{ value: false, label: 'no' },
	{ value: true, label: 'yes' },
];

const PercentageOptions = [
	{ value: 0.04, label: '4%' },
	{ value: 0.05, label: '5%' },
	{ value: 0.06, label: '6%' },
	{ value: 0.07, label: '7%' },
];

const columns = () => [
	{
		Header: 'Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => d.name,
		foldable: true
	},
	{
		Header: 'Personal',
		id: 'personal',
		minWidth: 150,
		accessor: d => `$${_.get(d, 'totals.personal.commission', 0).toFixed(2)}`
	},
	{
		Header: 'Team',
		id: 'team',
		minWidth: 150,
		accessor: d => `$${_.get(d, 'totals.team.commission', 0).toFixed(2)}`
	},
	{
		Header: 'Total',
		id: 'commission',
		minWidth: 150,
		accessor: d => `$${_.get(d, 'totals.commission', 0).toFixed(2)}`
	},
	{
		Header: 'Downline Percentage',
		id: 'percentage',
		minWidth: 150,
		accessor: d => `${Math.round(_.get(d, 'percentage', 0.04)*100)}%`,
		headerStyle: {
			overflow: 'visible',
		},
		filterable: true,
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={PercentageOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => { value !== null ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Pay Type',
		id: 'payType',
		minWidth: 150,
		filterable: true,
		accessor: d => payTypes[d.payType || 0],
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={PayTypeOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => { value !== null ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Donating Proceeds?',
		id: 'donating',
		minWidth: 100,
		filterable: true,
		accessor: d => d.donating ? 'yes' : 'no',
		headerStyle: {
			overflow: 'visible',
		},
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={DonatingOptions}
					isClearable
					value={filter ? filter.value : null}
					onChange={value => { value !== null ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	}
];

export default columns;