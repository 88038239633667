import React from 'react';
import qs from 'qs';
import styles from '../SellMemberships/orderConfirmation.module.scss';
import Lottie from '../common/Lottie.js';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_ORDER_CONFIRMATION from '../../queries/getOrderConfirmation';
import Loading from '../Loading';
import _ from 'lodash';
import moment from 'moment';
import { Button } from 'semantic-ui-react';

const defaultOptions = {
	loop: false,
	autoplay: true, 
	animationData: require('../SellMemberships/order_complete.json'),
};

const OrderConfirmation = ({ location: { search }}) => {
	const { id } = qs.parse(search.replace('?', ''));
	const { data, loading } = useQuery(QUERY_GET_ORDER_CONFIRMATION, {
		variables: { id }
	});

	if(loading) { return <div><Loading /></div>; }

	if(!id || !_.get(data, 'getOrder.id')) {
		return <div>Invalid ID</div>;
	}

	const sale = _.get(data, 'getOrder', {});
	const order = _.get(sale, 'orderInformation', {});

	return (
		<div className={styles.container}>
			<div className={styles.lottieContainer}>
				<div className={styles.lottie}>
					<Lottie options={defaultOptions} />
				</div>
			</div>
			<h3>Order Complete</h3>
			<h4>{moment(sale.date).format('MMM Do YYYY, h:mm:ss a')}</h4>
			<p>{order.firstname} {order.lastname} should receive an email at {order.email} with the order details</p>
			<Button
				onClick={() => {
					const production = process.env.NODE_ENV === 'production';
					const url = production
						? 'https://office.deluxenetworkprogram.com/api/receipt?id=' + id
						: 'http://localhost:5002/api/receipt?id=' + id;
					window.open(url, '_blank');
				}}
				content="View/Print Receipt"
			/>
		</div>
	);
};

export default OrderConfirmation;