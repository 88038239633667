/* eslint no-unused-vars: 0 no-console: 0 */
import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';


import { Form } from 'semantic-ui-react';

class DateInput extends Component {

	render() {
		const { placeholder, name, label, required, validate } = this.props;
		return (
			<Field name={name} validate={validate}>
				{({ input, meta }) => (
					<Form.Field required={!!required} error={meta.error && meta.touched}>
						{label !== false && <label>{label || placeholder}</label>}
						<div className="field">
							<DatePicker
								placeholderText={placeholder}
								selected={input.value ? new moment(input.value) : new moment()}
								isClearable={true}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								{ ...input }
							/>
						</div>
						{meta.error && meta.touched && <span className="error">{meta.error}</span>}
					</Form.Field>
				)}
			</Field>
		);
	}
}

export default DateInput;