import gql from 'graphql-tag';

const QUERY_GET_USER_COMMISSION_REPORTS = gql`

	query GetUserCommissionReports($reportID: String!, $skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getUserCommissionReports(reportID: $reportID, skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				name
				totals {
					commission
					personal {
						commission
					}
					team {
						commission
					}
				}
				reasons
				needs
				user
				percentage
				payType
                donating
			}
		}
	}

`;

export default QUERY_GET_USER_COMMISSION_REPORTS;