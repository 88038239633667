import React, { Component } from 'react';
import { Segment, Button, Message, Menu, Grid } from 'semantic-ui-react';
import ReferenceCode from './Basic/ReferenceCode';
import { firebaseAuth } from '../../config/firebase';
import { connect } from 'react-redux';
import ChangeEmail from '../Popups/ChangeEmail';
import TaxInformation from './TaxInformation';
import BusinessList from './BusinessList';

class UserSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordMessage: null,
			activeItem: 'General Settings',
		};

		this.resetPassword = this.resetPassword.bind(this);
		this.handleDismissPasswordMessage = this.handleDismissPasswordMessage.bind(this);
		this.handleItemClick = this.handleItemClick.bind(this);
	}

	async resetPassword() {
		if(this.props.userInfo.email) {

			try {
				await firebaseAuth().sendPasswordResetEmail(this.props.userInfo.email);
				this.setState({passwordMessage: 1});
			} catch (error) {
				this.setState({passwordMessage: 2});
			}

		}
	}

	handleDismissPasswordMessage() {
		this.setState({passwordMessage: null});
	}

	renderPasswordMessage() {
		if(this.state.passwordMessage === 1) {
			return(
				<Message
					onDismiss={this.handleDismissPasswordMessage}
					header='Success'
					content='The password email was sent! Check your email to reset your password.'
					positive
				/>
			);
		} else if(this.state.passwordMessage === 2) {
			return (
				<Message
					onDismiss={this.handleDismissPasswordMessage}
					header='Error'
					content='There was an error sending the password reset email!. Try again.'
					negative
				/>
			);
		}
	}

	renderGeneralSettings() {
		if(this.state.activeItem === 'General Settings')
		{
			return(
				<div>
					<span>Reference Code: </span><ReferenceCode />
					<hr />
					<div>{this.renderPasswordMessage()}</div>
					<div><Button onClick={() => this.resetPassword()}>Send Password Reset Email</Button></div>
					<br />
					<ChangeEmail />
				</div>
			);
		} else if (this.state.activeItem === 'tax') {
			return <TaxInformation />;
		} else if (this.state.activeItem === 'Business List') {
			return <BusinessList />;
		}

		return null;
	}

	handleItemClick(e, { name }) {
		this.setState({ activeItem: name });
	}

	render() {
		const { activeItem } = this.state;
		return(
			<Grid style={{ margin: '10px', flex: '1', display: 'block' }} stackable>
				<Grid.Row textAlign="center" centered>
					<h2>Settings</h2>
				</Grid.Row>
				<Grid.Column width={3}>
					<Menu pointing vertical fluid>
						<Menu.Item header>Menu</Menu.Item>
						<Menu.Item name='General Settings' active={activeItem === 'General Settings'} onClick={this.handleItemClick} />
						<Menu.Item name='Tax Information' active={activeItem === 'tax'} onClick={this.handleItemClick} />
						<Menu.Item name='Business List' active={activeItem === 'Business List'} onClick={this.handleItemClick} />
						{/* <Menu.Item name='Modify w-9' active={activeItem === 'Modify w-9'} onClick={this.handleItemClick} /> */}
					</Menu>
				</Grid.Column>

				<Grid.Column width={13}>
					<Segment>
						{this.renderGeneralSettings()}
					</Segment>
				</Grid.Column>
			</Grid>
		);
	}
}

function mapStateToProps({userInfo}) {
	return({userInfo});
}

export default connect(mapStateToProps)(UserSettings);
