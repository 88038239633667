import React, { useEffect } from 'react';
import { Header, Divider, Button, Form } from 'semantic-ui-react';
import stylez from './checkoutForm.module.scss';
import styles from './SocialMedia.module.scss';
import { Form as FinalForm, Field } from 'react-final-form';
import FSStripe from '../FinalForm/FSStripe';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import CustomField from './CustomField';
import { useMutation, useQuery } from '@apollo/react-hooks';
import MUTATION_PURCHASE_PRODUCTS from './purchaseProducts';
import GraphQLError from '../Message/GraphQLError';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import plans from '../../config/plans';
import FSReference from '../FinalForm/FSReference';
import Checkbox from '../FinalForm/StyledCheckbox';
import QUERY_GET_CONTRACT from '../../queries/getContract';
import Loading from '../Loading/index';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import areaPic from '../../img/BusinessAdditions/area_social_media_addon.jpg';
import groupPic from '../../img/BusinessAdditions/group_social_media_addon.jpg';
import nationalPic from '../../img/BusinessAdditions/national_social_media_addon.jpg';
// import premierPic from '../../img/BusinessAdditions/premier_social_media_addon.jpg';
import premierPic from '../../img/BusinessAdditions/individual_podcast.png';
import presentationPic from '../../img/BusinessAdditions/presentation_addon.jpg';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const OrderSummary = ({ values = {} }) => {
  const {
    basicSocialMedia = 0,
    premiumSocialMedia = 0,
    presentationFee = 0,
    localListing = 0,
    nationalListing = 0,
  } = values;

  const basicPrice = basicSocialMedia * plans.basicSocialMedia.price;
  const premiumPrice = premiumSocialMedia * plans.premiumSocialMedia.price;
  const presentationPrice = presentationFee * plans.presentationFee.price;
  const localListingPrice = localListing * plans.localListing.price;
  const nationalListingPrice = nationalListing * plans.nationalListing.price;
  const total =
    basicPrice +
    premiumPrice +
    presentationPrice +
    localListingPrice +
    nationalListingPrice;

  if (
    !basicSocialMedia &&
    !premiumSocialMedia &&
    !presentationFee &&
    !localListing &&
    !nationalListing
  ) {
    return (
      <div className={styles.orderSummaryContainer}>
        <h2>Order Summary</h2>
        <hr />
        <div className={styles.orderSummarySubSection}>
          <p>No Products Selected</p>
        </div>
        <hr />
        <div className={styles.orderSummarySubSection}>
          <p>Total</p>
          <p>$0.00</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.orderSummaryContainer}>
      <h2>Order Summary</h2>
      <hr />

      {!!basicSocialMedia && (
        <div className={styles.orderSummarySubSection}>
          <p>
            <strong>{basicSocialMedia}</strong> x Basic-Group
          </p>
          <p>${basicPrice.toFixed(2)}</p>
        </div>
      )}

      {!!premiumSocialMedia && (
        <div className={styles.orderSummarySubSection}>
          <p>
            <strong>{premiumSocialMedia}</strong> x Premium-Individual
          </p>
          <p>${premiumPrice.toFixed(2)}</p>
        </div>
      )}

      {!!presentationFee && (
        <div className={styles.orderSummarySubSection}>
          <p>
            <strong>{presentationFee}</strong> x Presentation Fee
          </p>
          <p>${presentationPrice.toFixed(2)}</p>
        </div>
      )}

      {!!localListing && (
        <div className={styles.orderSummarySubSection}>
          <p>
            <strong>{localListing}</strong> x Local Listing
          </p>
          <p>${localListingPrice.toFixed(2)}</p>
        </div>
      )}

      {!!nationalListing && (
        <div className={styles.orderSummarySubSection}>
          <p>
            <strong>{nationalListing}</strong> x National Listing
          </p>
          <p>${nationalListingPrice.toFixed(2)}</p>
        </div>
      )}

      <hr />
      <div className={styles.orderSummarySubSection}>
        <p>Total</p>
        <p>${total.toFixed(2)}</p>
      </div>
    </div>
  );
};

const Agreement = ({ name, type }) => {
  const { data, loading } = useQuery(QUERY_GET_CONTRACT, {
    variables: { type },
  });

  return (
    <div className={stylez.section} style={{ marginBottom: '10px' }}>
      {loading && (
        <div className={stylez.contract}>
          <Loading />
        </div>
      )}
      {!loading && (
        <div
          className={stylez.contract}
          dangerouslySetInnerHTML={{ __html: _.get(data, 'getContract') }}
        />
      )}
      <Checkbox
        name={name}
        label='Do you agree to the terms & conditions listed above?'
      />
    </div>
  );
};

const ReferenceSection = () => {
  return (
    <div className={stylez.section} style={{ marginBottom: '10px' }}>
      <h3>Enter the name of the person who referred you</h3>
      <FSReference label='Search for Reference' name='reference' />
      {/* <FSPromoCode
				name="discountCode"
				label="Promo Code"
				onApplied={info => setPromocode(info)}
				placeholder="Enter Promo Code here"
			/> */}
    </div>
  );
};

const SocialMedia = ({ stripe }) => {
  const [purchaseProducts, { data, loading, error }] = useMutation(
    MUTATION_PURCHASE_PRODUCTS
  );
  const history = useHistory();

  useEffect(() => {
    if (_.get(data, 'purchaseProducts.id')) {
      const id = _.get(data, 'purchaseProducts.id');
      history.push('/additions/confirmation?id=' + id);
    }
  }, [data]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header as='h1' textAlign='center'>
          Social Media Listings
        </Header>
        <p style={{ textAlign: 'center' }}>
          Here, you can buy a social media listing for your business.
          <br />
        </p>
        <Divider horizontal>Select a plan to buy</Divider>
      </div>

      <div className={styles.ff}>
        <FinalForm
          validate={validator(schema)}
          onSubmit={({
            stripeName,
            stripeZip,
            stripeCVC,
            stripeCard,
            stripeExpiry,
            reference,
            ...productsObj
          }) => {
            if (
              (!stripeName, !stripeZip, !stripeCVC, !stripeCard, !stripeExpiry)
            ) {
              alert('Please fill out the card information');
            }
            console.log('THE REFERENCE', reference);
            stripe
              .createToken({
                name: stripeName,
                address_zip: stripeZip,
              })
              .then(({ token, error }) => {
                if (error || !token.id) {
                  alert('Card Failure');
                } else {
                  let products = [];
                  for (let key in productsObj) {
                    const quantity = parseInt(productsObj[key]);
                    if (quantity) {
                      products.push({
                        id: key,
                        quantity,
                      });
                    }
                  }
                  if (products && products.length) {
                    purchaseProducts({
                      variables: {
                        token: token.id,
                        products,
                        reference: _.get(reference, 'value', null),
                      },
                    });
                  } else {
                    alert('Please choose a product');
                  }
                }
              });
          }}
          render={({ handleSubmit, values }) => (
            <Form
              onSubmit={handleSubmit}
              className={styles.formContents}
              loading={loading}
            >
              <div className={styles.productsContainer}>
                <CustomField
                  name={plans.basicSocialMedia.id}
                  title={plans.basicSocialMedia.name}
                  price={plans.basicSocialMedia.price.toFixed(2)}
                  pic={groupPic}
                  description='You will have a personal post by one of our community influencers. They will post about your business from the information you provide in the back office.  They may also include any positive personal experiences they may have with your business.  They will share on their personal pages and groups (including our P4P group going to our community)'
                />
                <CustomField
                  name={plans.premiumSocialMedia.id}
                  title={plans.premiumSocialMedia.name}
                  price={plans.premiumSocialMedia.price.toFixed(2)}
                  pic={premierPic}
                  description='One of our experienced podcast hosts will conduct a show directly about YOU!  This will be a 20–30-minute show where you can discuss issues that would be of interest to our community.  Once you have completed the purchase you will email a sheet to fill out where you are going to be able to select a time and topic to discuss.  This message will be broadcasted on IHeart, Spotify, and throughout our communities.'
                />
                <CustomField
                  name={plans.presentationFee.id}
                  title={plans.presentationFee.name}
                  price={plans.presentationFee.price.toFixed(2)}
                  pic={presentationPic}
                  description='This will be an add-on to the annual free presentation that you can receive with your PLUS membership. You can choose to add an additional presentation during the year to get more information out to the community in your current area. Your presentation will be a 5–10-minute presentation.  The networking and workshop presentation will be 10 minutes. It will be 5 minutes at the community event but, entire community is invited to attend, and you will also lead a discussion after your presentation for an additional 10 minutes.  During community news it will also only be 5 minutes but, people will share a post about what you discussed to get your message out'
                />
                <CustomField
                  name={plans.localListing.id}
                  title={plans.localListing.name}
                  price={plans.localListing.price.toFixed(2)}
                  pic={areaPic}
                  description='This will be a post that is placed on your P4P local social media page. This will be an exclusive listing with only your business being listed. We will highlight your post live in the community meeting and the development group.  You will submit the post you would like us to share and broadcast to the community'
                />
                <CustomField
                  name={plans.nationalListing.id}
                  title={plans.nationalListing.name}
                  price={plans.nationalListing.price.toFixed(2)}
                  pic={nationalPic}
                  description='This will be a post that is placed on P4P’s National/Focal social media page. This will be an exclusive listing with only your business being listed. We will highlight your post live in the community meeting and the development group.  You will submit the post you would like us to share and broadcast to the community'
                />
              </div>

              {(!!values.basicSocialMedia || !!values.premiumSocialMedia) && (
                <Agreement name='socialMediaAgree' type='SOCIALMEDIAPOSTING' />
              )}

              {!!values.presentationFee && (
                <Agreement name='presentationFeeAgree' type='PRESENTATIONFEE' />
              )}

              {(!!values.localListing || !!values.nationalListing) && (
                <Agreement
                  name='dnpSocialMediaAgree'
                  type='DNPSOCIALMEDIAPOSTING'
                />
              )}

              {/* <AdditionalInformationSection />

							<div style={{ marginTop: 10 }} /> */}

              <ReferenceSection />

              <div className={stylez.section}>
                <div className='stripe'>
                  <Form.Group widths='equal'>
                    <Form.Field>
                      <label>
                        Name on Card
                        <Field
                          name='stripeName'
                          component='input'
                          style={{ width: '100%' }}
                          placeholder='Cardholder Name'
                        />
                      </label>
                    </Form.Field>

                    <Form.Field>
                      <label>
                        Card #
                        <FSStripe
                          component={CardNumberElement}
                          {...createOptions(20)}
                          name='stripeCard'
                        />
                      </label>
                    </Form.Field>
                  </Form.Group>

                  <Form.Group widths='equal'>
                    <Form.Field>
                      <label>
                        EXP
                        <FSStripe
                          {...createOptions(20)}
                          component={CardExpiryElement}
                          name='stripeExpiry'
                        />
                      </label>
                    </Form.Field>

                    <Form.Field>
                      <label>
                        CVC
                        <FSStripe
                          component={CardCvcElement}
                          {...createOptions(20)}
                          name='stripeCVC'
                        />
                      </label>
                    </Form.Field>

                    <Form.Field>
                      <label>
                        Zip
                        <Field
                          name='stripeZip'
                          component='input'
                          style={{ width: '100%' }}
                          placeholder='Zip Code'
                        />
                      </label>
                    </Form.Field>
                  </Form.Group>
                </div>
                <div className={stylez.poweredBy}>
                  <a
                    href='https://www.stripe.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={require('../../img/powered_by_stripe.svg')} />
                  </a>
                </div>
              </div>
              <OrderSummary values={values} />
              <GraphQLError error={error} />
              <Form.Group
                widths='equal'
                style={{ justifyContent: 'center', marginTop: '20px' }}
              >
                <Button
                  type='submit'
                  fluid
                  content='Purchase'
                  style={{ maxWidth: '400px' }}
                />
              </Form.Group>
            </Form>
          )}
        />
      </div>
    </div>
  );
};

const schema = Yup.object().shape({
  presentationFeeAgree: Yup.boolean().when('presentationFee', {
    is: (q) => !!q,
    then: Yup.boolean().equals([true], 'Please accept'),
  }),
  socialMediaAgree: Yup.boolean().when(
    ['basicSocialMedia', 'premiumSocialMedia'],
    {
      is: (q1, q2) => !!q1 || !!q2,
      then: Yup.boolean().equals([true], 'Please accept'),
    }
  ),
  dnpSocialMediaAgree: Yup.boolean().when(['localListing', 'nationalListing'], {
    is: (q1, q2) => !!q1 || !!q2,
    then: Yup.boolean().equals([true], 'Please accept'),
  }),

  // Stripe Information
  stripeName: Yup.string().required('Required Field'),
  stripeCard: Yup.object()
    .required()
    .shape({
      complete: Yup.boolean()
        .required()
        .oneOf([true], 'Please Complete this Field'),
    }),
  stripeExpiry: Yup.object()
    .required()
    .shape({
      complete: Yup.boolean()
        .required()
        .oneOf([true], 'Please Complete this Field'),
    }),
  stripeCVC: Yup.object()
    .required()
    .shape({
      complete: Yup.boolean()
        .required()
        .oneOf([true], 'Please Complete this Field'),
    }),
  stripeZip: Yup.string().required('Required Field'),
});

export default injectStripe(SocialMedia);
