import React from 'react';
import { Field } from 'react-final-form';

import { Form, TextArea } from 'semantic-ui-react';

const TextAreaInput = ({ placeholder, name, label, required, ...rest }) => {
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<TextArea name={name} placeholder={placeholder} {...rest} { ...input } />
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default TextAreaInput;