import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page404 from '../Page404';
import ManageBusiness from './ManageBusiness';
import Home from './Home';
import HomeA from './Home/HomeA';

import Memberships from './Memberships';
import Documents from '../Documents';
import Page401 from '../Page401';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _ from 'lodash';

export const QUERY_USER_TYPE = gql `
query UserType($id: String!) {
	getPaypalUser(id: $id) {
		paypal {
			id
		}
	}
}
`

function BusinessPlanRoute({ component: Component, user, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (user && user.hasBusinessPlan) ? <Component user={user} {...props} /> : <Page401 />}
		/>
	);
}

function BusinessPlanOrAdminRoute({ component: Component, user, ...rest }) {
	let hasAccess = (user && user.hasBusinessPlan);

	if(user && user.accountType >= 10) {
		hasAccess = true;
	}

	return (
		<Route
			{...rest}
			render={(props) => hasAccess ? <Component user={user} {...props} {...rest} /> : <Page401 />}
		/>
	);
}

const BusinessPlan = ({ user }) => {
	const { id } = user
	const { data : Data } =  useQuery(QUERY_USER_TYPE,{
	variables: { id }
	});
	const paypalUser = _.get(Data, 'getPaypalUser.paypal.id');
	
	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Switch>
				<BusinessPlanRoute user={user} exact path="/business" component={() =>paypalUser ? <HomeA user={user}/> : <Home user={user} />} />
				<BusinessPlanRoute user={user} exact path="/business/store" component={() => <ManageBusiness user={user} />} />

				<BusinessPlanRoute user={user} exact path="/business/memberships" component={Memberships} />
				<BusinessPlanRoute user={user} path="/business/memberships/:id" component={Memberships} />

				<BusinessPlanOrAdminRoute path="/business/resources/:route+" component={Documents} user={user} tag="business" baseURL="/business/resources" />
				<BusinessPlanOrAdminRoute exact path="/business/resources" component={Documents} user={user} tag="business" baseURL="/business/resources" />

				<BusinessPlanRoute path="/business" component={Page404} />
			</Switch>
		</div>
	);
};

export default BusinessPlan;