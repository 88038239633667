import React from 'react';
import { OrderDetailsTable, OrderDetailsRow } from '../helpers';
import _ from 'lodash';
import {
  ChangeTextFieldOrder,
  ChangeReferenceFieldOrder,
  ChangeDateFieldOrder,
  ChangeCheckboxFieldOrder,
} from '../helpers/OrderChangeFields';
import { Table, Button } from 'semantic-ui-react';
import moment from 'moment';
import RefundOrder from '../helpers/RefundOrder';

const productNames = {
  0: 'Individual Card',
  1: 'Family Card',
  3: 'Corporate Individual Card',
  4: 'Corporate Family Card',
  5: 'Group Corporate',
  6: 'Business Plan - Basic',
  7: 'Business Plan - Premium',
  [-1]: 'Mixed',
};

const BasicInfo = ({ data, refetch }) => {
  return (
    <div>
      <OrderDetailsTable>
        <Table.Row>
          <Table.Cell>Customer Name</Table.Cell>
          <Table.Cell>
            <ChangeTextFieldOrder
              label={'First Name'}
              value={data.orderInformation.firstname}
              property={'firstname'}
              refetch={refetch}
              id={data.id}
              basic
            />{' '}
            <ChangeTextFieldOrder
              label={'Last Name'}
              value={data.orderInformation.lastname}
              property={'lastname'}
              refetch={refetch}
              id={data.id}
              basic
            />
          </Table.Cell>
        </Table.Row>

        <ChangeTextFieldOrder
          label={'Email'}
          value={data.orderInformation.email}
          property={'email'}
          refetch={refetch}
          id={data.id}
          table
        />

        <ChangeTextFieldOrder
          label={'Phone'}
          value={data.orderInformation.phone}
          property={'phone'}
          refetch={refetch}
          id={data.id}
          table
        />

        <ChangeReferenceFieldOrder
          label='Reference'
          value={data.reference}
          property='reference'
          refetch={refetch}
          id={data.id}
          table
        />

        <ChangeReferenceFieldOrder
          label='User Account'
          value={data.account}
          property='account'
          refetch={refetch}
          id={data.id}
          table
          includeAllUsers={true}
        />

        <OrderDetailsRow property={'Database ID'} value={data.id} />

        <OrderDetailsRow
          property={'Product Type'}
          value={productNames[data.product] || 'Other'}
        />

        <OrderDetailsRow
          property={'Order Total'}
          value={`$${data.total.toFixed(2)}`}
        />

        <OrderDetailsRow
          property={'Status'}
          value={
            data.refunded
              ? 'REFUNDED'
              : data.stripe && data.stripe.id
              ? data.stripe.status
              : 'Paid'
          }
        />

        {_.get(data, 'stripe.id', false) ? (
          <OrderDetailsRow
            property={'Purchase Date'}
            value={moment(data.date).format('MMMM Do YYYY, h:mm:ss a')}
          />
        ) : (
          <ChangeDateFieldOrder
            label={'Date'}
            value={moment(data.date)}
            text={moment(data.date).format('MMMM Do YYYY, h:mm:ss a')}
            property={'date'}
            refetch={refetch}
            id={data.id}
            table
          />
        )}

        <ChangeCheckboxFieldOrder
          label='Requested Physical Product'
          value={!!data.requestedPhysicalCard}
          property='requestedPhysicalCard'
          refetch={refetch}
          id={data.id}
          table
        />

        <ChangeCheckboxFieldOrder
          label='Physical Product Sent'
          value={!!data.physicalCardSent}
          property='physicalCardSent'
          refetch={refetch}
          id={data.id}
          table
        />
      </OrderDetailsTable>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          as='a'
          content='Open Receipt'
          href={`/api/receipt?id=${data.id}`}
          target='_blank'
        />

        <RefundOrder order={data} refetch={refetch} date={data.date} />
      </div>
    </div>
  );
};

export default BasicInfo;
