import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_BUSINESS_PLAN_USER from './businessplan';
import Loading from '../../../Loading';
import _ from 'lodash';
import DetailsTable from '../../../Table/DetailsTable';
import { ChangeTextAreaBusinessPlan, ChangeTextFieldBusinessPlan, ChangeCheckboxFieldBusinessPlan } from '../../../BusinessPlanTable/BusinessPlanDetails/helpers/BusinessPlanChangeFields';

const SocialMedia = () => {
	const { data, loading, error, refetch } = useQuery(QUERY_BUSINESS_PLAN_USER);

	if(loading) {
		return <Loading />;
	}

	const {
		facebook,
		instagram,
		twitter,
		snapchat,
		pinterest,
		linkedin,
		socialMediaInfo,
		opted
	} = _.get(data, 'user.businessPlan.socialMedia', {}) || {};

	const id = _.get(data, 'user.businessPlan.id');

	return (
		<DetailsTable noHeader>

			<ChangeCheckboxFieldBusinessPlan
				label="Would you like to participate in our Social Media Program?"
				value={opted}
				property={'socialMedia.opted'}
				refetch={refetch}
				id={id}
				table
				changeValues={{
					'false': 'no',
					'true': 'yes'
				}}
			/>

			{ opted && (
				<>
					<ChangeTextAreaBusinessPlan
						label="Please state any information you might want to be included in your posting"
						value={socialMediaInfo}
						property={'socialMedia.socialMediaInfo'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="Facebook"
						value={facebook}
						property={'socialMedia.facebook'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="Instagram"
						value={instagram}
						property={'socialMedia.instagram'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="Twitter"
						value={twitter}
						property={'socialMedia.twitter'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="Snapchat"
						value={snapchat}
						property={'socialMedia.snapchat'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="Pinterest"
						value={pinterest}
						property={'socialMedia.pinterest'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldBusinessPlan
						label="LinkedIn"
						value={linkedin}
						property={'socialMedia.linkedin'}
						refetch={refetch}
						id={id}
						table
					/>
				</>
			)}

		</DetailsTable>
	);
};

export default SocialMedia;