import React, { Component } from 'react';
import { Popup, Button, Form, Checkbox } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';

const CheckBox = (props) => {
	const label = props.input.value ? 'True' : 'False';
	return(
		<div>
			<Form.Checkbox label={label} checked={props.input.value} onChange={(e, {checked}) => {
				props.input.onChange(checked);
			}}/>
			<Button type="submit" fluid>Change</Button>
		</div>
	);
};


class ChangePopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			open: false,
		};
	}

	async handleSubmit(values) {
		this.setState({loading: true});
		const v = { values: {} };
		v.values[this.props.p] = values.setting;
		v.id = this.props.fid;
		try {
			await axios.post('/api/admin/modify/sale/checkbox', v);
			if(this.props.onSuccess) {this.props.onSuccess();}
			this.setState({loading: false});
		} catch (error) {
			this.setState({loading: false});
		}

	}

	renderPopup() {
		const {loading} = this.state;

		return(
			<div>
				<Form loading={loading} onSubmit={this.props.handleSubmit(values => this.handleSubmit(values))}>
					<Field component={CheckBox} name="setting" label="New Value"/>
				</Form>
			</div>
		);
	}

	renderText() {
		const checkbox = this.props.def ? (<Checkbox label="Yes" checked disabled/>) : (<Checkbox label="No" disabled/>);
		return(
			<span style={{cursor: 'pointer'}} onClick={() => this.setState({open: !this.state.open})} className="disabled-visible-label">{checkbox}</span>
		);
	}

	setDefault() {
		this.props.change('setting', this.props.def);
	}

	render() {
		return(
			<Popup
				trigger={this.renderText()}
				content={this.renderPopup()}
				on='click'
				flowing
				onOpen={() => this.setDefault()}
				open={this.state.open}
			/>
		);
	}
}

ChangePopup = reduxForm({
	form: 'changePopupCheckboxForm'
})(ChangePopup);

export default ChangePopup;
