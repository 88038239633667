import React, { Component } from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../FinalForm/TextInput';
import GraphQLError from '../../Message/GraphQLError';
import TextAreaInput from '../../FinalForm/TextAreaInput';
import withStateMutation from '../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import addTrainingMutation from '../../../mutations/addTraining';
import queryGetTrainingOrder from '../../../queries/getTrainingOrder';
import queryString from 'qs';

function getYoutubeID(url) {
	const s = url.split('?');
	const parse = queryString.parse(s[1]);
	return parse.v;
} 

class AddTrainingVideoForm extends Component {

	onSubmit = (variables) => {
		const youtubeID = getYoutubeID(variables.youtubeID);

		this.props.mutate({
			variables: {
				...variables,
				youtubeID,
			},
			refetchQueries: [{
				query: queryGetTrainingOrder,
			}]
		}).then(() => {
			this.props.onComplete && this.props.onComplete();
		});
	}

	render() {
		return (
			<FinalForm
				onSubmit={this.onSubmit}
				validate={validate}
				initialValues={{ id: this.props.id }}
				render={({ handleSubmit }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>

						<Form.Group widths='equal'>
							<TextInput name='title' placeholder="Video Title" required />
						</Form.Group>

						<Form.Group widths='equal'>
							<TextInput
								name='youtubeID'
								placeholder="Youtube ID"
								required
								popup={true}
								popupProps={{
									header: 'Please paste the full youtube URL',
									content: <div><hr /><p>Example: https://www.youtube.com/watch?v=TxwZgj9_IeY<br />Not: https://youtu.be/TxwZgj9_IeY</p></div>,
									flowing: true,
								}}
							/>
						</Form.Group>

						<Form.Group widths='equal'>
							<TextAreaInput name='description' placeholder="Description" required />
						</Form.Group>

						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						<Button type="submit" fluid>Add Training Video</Button>
					</Form>
				)}
			/>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.youtubeID) {
		errors.youtubeID = 'Required Field';
	}

	if(values.youtubeID) {
		const s = values.youtubeID.split('?');
		console.log(s);
		if(s && s[1]) {
			const parse = queryString.parse(s[1]);
			console.log(parse);
			if(!parse.v) {
				errors.youtubeID = 'Not a valid Youtube URL';
			}
		} else {
			errors.youtubeID = 'Not a valid URL';
		}
	}

	if(!values.description) {
		errors.description = 'Required Field';
	}

	return errors;
}

export default compose (
	graphql(addTrainingMutation),
	withStateMutation(),
)(AddTrainingVideoForm);