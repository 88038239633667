import gql from 'graphql-tag';

const MUTATION_CANCEL_MEMBERSHIP = gql`

	mutation CancelMembership($id: String!) {
		cancelMembership(id: $id)
	}

`;

export default MUTATION_CANCEL_MEMBERSHIP;