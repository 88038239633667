import React, { Component } from 'react';
import ReactTable from 'react-table';
import queryGetWebinarCodes from '../../../queries/getWebinarCodes';
import { graphql } from '@apollo/react-hoc';
import moment from 'moment';
import Information from './Information';

const columns = [
	{
		Header: 'Code',
		accessor: 'code', // String-based value accessors!
		filterable: true,
		minWidth: 150,
	},
	{
		Header: 'Status',
		id: 'status',
		accessor: d => {
			const { dateExpired, dateStartedWatching, dateFinishedWatching, incentiveNeeded, incentiveFulfilled } = d;
			let expired = false;
			if(dateExpired) {
				const expires = new Date(dateExpired).valueOf();
				expired = (expires - new Date().valueOf()) < 0;
			}

			if(expired && !incentiveNeeded) {
				return 'Expired';
			} else if (incentiveFulfilled) {
				return 'Completed';
			} else if (incentiveNeeded) {
				return 'Incentive Needed';
			} else if (dateFinishedWatching) {
				return 'Finished Watching';
			} else if (dateStartedWatching) {
				return 'Started Watching';
			} else {
				return 'Waiting...';
			}
		},
		filterable: true,
		minWidth: 150,
	},
	// {
	// 	Header: 'ID',
	// 	accessor: 'id',
	// },
	{
		Header: 'Name',
		accessor: 'name'
	},
	{
		Header: 'Identifier',
		accessor: 'identifier'
	},
	{
		Header: 'Time Stamps',
		columns: [{
			Header: 'Created On',
			id: 'dateCreated',
			minWidth: 200,
			accessor: d => moment(d.dateCreated).format('MMM Do YYYY, h:mm:ss a')
		},
		{
			Header: 'Expires',
			id: 'dateExpired',
			minWidth: 200,
			accessor: d => {
				if(d.dateExpired){
					return moment(d.dateExpired).format('MMM Do YYYY, h:mm:ss a');
				} else { 
					return 'N/A';
				}
			}
		},
		{
			Header: 'Started Watching',
			id: 'dateStartedWatching',
			minWidth: 200,
			accessor: d => {
				if(d.dateStartedWatching){
					return moment(d.dateStartedWatching).format('MMM Do YYYY, h:mm:ss a');
				} else { 
					return 'N/A';
				}
			}
		},
		{
			Header: 'Finished Watching',
			id: 'dateFinishedWatching',
			minWidth: 200,
			accessor: d => {
				if(d.dateFinishedWatching){
					return moment(d.dateFinishedWatching).format('MMM Do YYYY, h:mm:ss a');
				} else { 
					return 'N/A';
				}
			}
		}]
	}
];

class WebinarCodeTable extends Component {
	render() {
		const data = this.props.data.getWebinarCodes || [];
		return (
			<div>
				<ReactTable
					data={data}
					columns={columns}
					pageSizeOptions={[10,15,20,50,100]}
					defaultPageSize={20}
					style={{  }}
					SubComponent={row => {
						return <Information data={row} />;
					}}
				/>
			</div>
		);
	}
}

export default graphql(queryGetWebinarCodes)(WebinarCodeTable);