/* eslint no-console: 0 */
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { firebaseAuth } from '../config/firebase';
import { defaultDataIdFromObject } from '@apollo/client';


const link = createUploadLink();

const authLink = setContext(async (_, { headers }) => {
	const token = firebaseAuth().currentUser ? await firebaseAuth().currentUser.getIdToken() : null;
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
					),
				);
			if (networkError) console.log(`[Network error]: ${networkError}`);
		}),
		authLink.concat(link),
	]),
	cache: new InMemoryCache({
		dataIdFromObject(responseObject) {
		  switch (responseObject.__typename) {
			case 'Promotions': return `Promotions:${responseObject.upc}`;
			default: return defaultDataIdFromObject(responseObject);
		  }
		}
	  }),
	dataIdFromObject: o => o.id
});

export default client;