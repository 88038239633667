import React from 'react';
import { Table } from 'semantic-ui-react';

const OrderDetailsRow = ({ property, value, condition=true }) => {
	if(!condition) { return null; }

	return (
		<Table.Row>
			<Table.Cell>{property}</Table.Cell>
			<Table.Cell>{value}</Table.Cell>
		</Table.Row>
	);
};

export default OrderDetailsRow;