import React from 'react';
import OrderTable from '../../OrderTable';
import { Segment, Button } from 'semantic-ui-react';

const Orders = (props) => {
	let tableRef = React.useRef(null);
	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Segment.Group stacked>
				<Segment color='green' className="tableHeaderContainer">
					<div />
					<h2>Orders</h2>
					<Button
						onClick={() => {
							if(tableRef && tableRef.current) {
								tableRef.current.refresh();
							}
						}}
						content={<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true" />}
					/>
				</Segment>
				<Segment placeholder>
					<OrderTable
						ref={tableRef}
						path="/admin/orders"
					/>
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default Orders;