import gql from 'graphql-tag';

const modifyPromotionMutation = gql`

	mutation ModifyPromotion($id: String!, $promotionID: String!, $code: String, $info: String, $url: String, $file: Upload, $additionalURL: String, $validUntil: Date, $online: Boolean, $property: String!) {
		modifyPromotion(id: $id, promotionID: $promotionID, code: $code, info: $info, url: $url, file: $file, additionalURL: $additionalURL, validUntil: $validUntil, online: $online, property: $property) {
			id
		}
	}

`;

export default modifyPromotionMutation;