import gql from 'graphql-tag';

const modifySaleAdminMutation = gql`

	mutation modifySaleAdmin($id: String!, $membershipID: String, $firstname: String, $lastname: String, $shippingAddress: ShippingAddress, $email: String, $phone: String, $spouseFirstName: String, $spouseLastName: String, $additionalFamilyMembers: [String], $date: Date, $property: String!) {
		modifySaleAdmin(id: $id, membershipID: $membershipID, firstname: $firstname, lastname: $lastname, shippingAddress: $shippingAddress, email: $email, phone: $phone, spouseFirstName: $spouseFirstName, spouseLastName: $spouseLastName additionalFamilyMembers: $additionalFamilyMembers, date: $date, property: $property) {
			id
		}
	}

`;

export default modifySaleAdminMutation;