import React, { Component } from 'react';
import { Grid, Button, Popup, Input, Select } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import _ from 'lodash';

class FilterPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			email: null,
			startDate: null,
			endDate: null,
			plan: null
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeName = this.onChangeName.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangeStartDate = this.onChangeStartDate.bind(this);
		this.onChangeEndDate = this.onChangeEndDate.bind(this);
		this.onChangePlan = this.onChangePlan.bind(this);

		this.callChange = _.throttle(this.onChange, 1000);
	}

	onChange() {
		const values = this.state;
		if(this.props.onChange) { this.props.onChange(values); }
	}

	async onChangeName(e, {value}) {
		await this.setState({name: value});
		this.callChange();
	}

	async onChangeEmail(e, {value}) {
		await this.setState({email: value});
		this.callChange();
	}

	async onChangeStartDate(date) {
		await this.setState({startDate: date});
		this.callChange();
	}

	async onChangeEndDate(date) {
		await this.setState({endDate: date});
		this.callChange();
	}

	async onChangePlan(e, {value}) {
		await this.setState({plan: value});
		this.callChange();
	}


	filterPopup() {
		return(
			<div>
				<Grid padded stackable>
					<Grid.Row columns='equal'>

						<Grid.Column style={{minWidth: '130px'}}>
							<Input placeholder='Name' fluid value={this.state.name} onChange={(e, d) => this.onChangeName(e, d)}/>
						</Grid.Column>

						<Grid.Column style={{minWidth: '100px'}}>
							<Input placeholder='Email' fluid value={this.state.email} onChange={(e, d) => this.onChangeEmail(e, d)}/>
						</Grid.Column>

						<Grid.Column className="datepicker-width" style={{minWidth: '150px'}}>
							<DatePicker
								utcOffset={0}
								isClearable={true}
								selected={this.state.startDate}
								onChange={this.onChangeStartDate}
								placeholderText="Date From"
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								style={{width: 'inherit'}}
							/>
						</Grid.Column>
						<Grid.Column className="datepicker-width" style={{minWidth: '150px'}}>
							<DatePicker
								utcOffset={0}
								isClearable={true}
								selected={this.state.endDate}
								onChange={this.onChangeEndDate}
								placeholderText="Date To"
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
							/>
						</Grid.Column>
					</Grid.Row>


					<Grid.Row>
						<Grid.Column style={{minWidth: '250px'}}>
							<Select placeholder='Plan Type'
								value={this.state.plan} onChange={(e, d) => this.onChangePlan(e, d)}
								options={[
									{
										key: 'Individual',
										value: 0,
										text: 'Individual'
									},
									{
										key: 'Family',
										value: 1,
										text: 'Family'
									},
									{
										key: 'Corporate',
										value: 2,
										text: 'Corporate'
									},
									{
										key: 'Clear',
										value: null,
										text: 'Clear'
									}
								]} />
						</Grid.Column>
						<Grid.Column style={{minWidth: '150px'}} floated='right'>
							<Button primary fluid onClick={() => this.props.trigger()}>Done</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}


	render() {
		return(
			<Popup
				trigger={<Button
					basic
					onClick={() => this.props.trigger()}
				>
					<i className="fa fa-filter fa-lg filterIcon" aria-hidden="true"></i>
				</Button>}
				content={this.filterPopup()}
				position='bottom left'
				on='click'
				hoverable
				flowing
				open={this.props.open}
			/>
		);
	}
}

export default FilterPopup;
