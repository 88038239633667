import React, { Component } from 'react';
import { Button, Modal, Icon, Grid } from 'semantic-ui-react';
import axios from 'axios';
import { graphql } from '@apollo/react-hoc';
import { withRouter } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import mutationDeleteSale from '../../../../mutations/deleteSale';
import withStateMutation from '../../../../queries/withStateMutation';
import queryCurrentUser from '../../../../queries/user';
import _ from 'lodash';
import ChangeEmail from '../../../Popups/ChangeEmail';
import { firebaseAuth } from '../../../../config/firebase';
import SendEducatorWelcomeEmail from './Tools/SendEducatorWelcomeEmail';
import Token from './Tools/Token';
import swal from 'sweetalert';

class ToolsTab extends Component {
  state = {
    deleteUser: false,
    passwordResetSuccess: false,
    passwordResetLoading: false,
  };

  async printUserInfo() {
    const user = this.props.user;
    console.log("user", user);
    if (!user)
			return <div>No Information of this User</div>;


    if (user.id) {
      const res = await axios.post('/admin/templates/userinfo', {
        id: user.id,
      });
      var w = window.open();
      w.document.write(res.data);
    }
  }

  async sendPasswordResetEmail() {
    const user = this.props.user;
    await this.setState({ passwordResetLoading: true });
    if (user.email) {
      try {
        await firebaseAuth().sendPasswordResetEmail(user.email);
        this.setState({
          passwordResetSuccess: true,
          passwordResetLoading: false,
        });
      } catch (error) {
        return;
      }
    }
  }

  renderToolPrintInfo() {
    if (this.props.user.accountType > 0) {
      return (
        <Button onClick={() => this.printUserInfo()}>
          View/Print User Info
        </Button>
      );
    }
  }

  printReceipt = async () => {
    const data = this.props.sale;
    if (data.id) {
      const res = await axios.post('/admin/templates/receipt', { id: data.id });
      var w = window.open();
      w.document.write(res.data);
    }
  };

  renderPasswordResetIcon() {
    if (this.state.passwordResetSuccess) {
      return (
        <p style={{ display: 'inline' }}>
          {'  '}
          <Icon name='checkmark' color='green' />
        </p>
      );
    }
  }
  renderPasswordResetConfirm() {
    if (this.state.passwordResetSuccess) {
      return <p>Password Reset Email Sent</p>;
    }
  }

  getValues = () => {
    this.props.onSuccess && this.props.onSuccess();
  };

  renderDeleteUserIcon() {
    if (this.state.deleteUser) {
      return (
        <p style={{ display: 'inline' }}>
          {'  '}
          <Icon name='checkmark' color='green' />
        </p>
      );
    }
  }

  renderDeleteUser() {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover the data',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((ok) => {
      if (ok) this.deleteUserCall(this.props.user.id);
    });
  }

  async deleteUserCall(userId) {
    try {
      await axios.delete(`/api/users/delete/${userId}`);
      this.setState({ deleteUser: true });
    } catch (error) {
      return;
    }
  }

  render() {
    const adminLevel = _.get(this.props, 'data.user.adminLevel', 0);

    if (!adminLevel) {
      return <div></div>;
    }

    return (
      <div>
        <Grid columns={3} stackable>
          {this.renderPasswordResetConfirm()}
          <Grid.Column>
            <Button
              loading={this.state.passwordResetLoading}
              onClick={() => this.sendPasswordResetEmail()}
            >
              Send Password Reset{this.renderPasswordResetIcon()}
            </Button>
          </Grid.Column>
          <Grid.Column>
            {this.props?.user?.id && <ChangeEmail
              uid={this.props?.user?.id}
              onSuccess={() => this.getValues()}
            />}
          </Grid.Column>

          {adminLevel >= 3 && (
            <Grid.Column>{this.renderToolPrintInfo()}</Grid.Column>
          )}
          {this?.props?.user?.accountType && this.props?.user?.id >= 1 && (
            <Grid.Column>
              <SendEducatorWelcomeEmail userID={this.props.user.id} />
            </Grid.Column>
          )}

          {adminLevel >= 3 && (
            <Grid.Column>
              <Button onClick={() => this.renderDeleteUser()}>
                Delete{this.renderDeleteUserIcon()}
              </Button>
            </Grid.Column>
          )}
        </Grid>
        {this?.props?.user?.id && <Token id={this.props.user.id} />}
      </div>
    );
  }
}

export default compose(
  graphql(mutationDeleteSale),
  graphql(queryCurrentUser),
  withStateMutation()
)(withRouter(ToolsTab));
