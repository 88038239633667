import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, Form, Divider } from 'semantic-ui-react';
import FSTextInput from '../../../FinalForm/TextInput';
import FSCheckbox from '../../../FinalForm/Checkbox';
import Condition from '../../../FinalForm/Condition';
import FSFamilyMember from '../../../FinalForm/FSFamilyMember';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import styles from './AddMembership.module.scss';
import * as Yup from 'yup';
import validator from '../../../../utils/validator';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_BUSINESS_ADD_MEMBERSHIP from './businessAddMembership';
import GraphQLError from '../../../Message/GraphQLError';

const FamilyMembersSection = ({ addMember }) => {
	return (
		<div className={styles.section}>
			<FieldArray name="members">
				{({ fields }) =>
					fields.map((name, index) => (
						<div key={name} className={styles.memberContainer}>
							<FSFamilyMember
								name={`${name}.name`}
								placeholder="Full Name"
								label={`Family Member #${index + 1}`}
								action={() => fields.remove(index)}
							/>
						</div>
					))
				}
			</FieldArray>
			<div className={styles.right}>
				<Button type='button' onClick={() => addMember('members')}>Add Family Member</Button>
			</div>
		</div>
	);
};


const AddMembershipForm = ({ id, loading, error, onSubmit }) => {
	return (
		<FinalForm
			onSubmit={onSubmit}
			initialValues={{ id }}
			mutators={{ ...arrayMutators }}
			validate={validator(schema)}
			render={({ handleSubmit, values, form: { mutators: { push } } }) => (
				<Form onSubmit={handleSubmit} id="addMembership" loading={loading}>
					<Form.Group widths="equal">
						<FSTextInput 
							name="firstname"
							label="First Name"
							placeholder="First Name"
							required
						/>
						<FSTextInput 
							name="lastname"
							label="Last Name"
							placeholder="Last Name"
							required
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSTextInput 
							name="phone"
							label="Phone #"
							placeholder="Phone #"
							required
						/>
					</Form.Group>

					<Divider horizontal>Email</Divider>
					<p>
						If there is an account with the email you list, the membership will be connected to that account.
						If there is no account created with this email, an email will be sent prompting your employee to
						create an account and the membership will be connected to that account.
					</p>

					<Form.Group widths="equal">
						<FSTextInput 
							name="email"
							label="Email"
							placeholder="E-mail Address"
							required
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSCheckbox
							name="familyPlan"
							label="Convert to Family Plan?"
						/>
					</Form.Group>

					<Condition when="familyPlan" is={true}>
						<Form.Group widths="equal">
							<FSTextInput 
								name="spouseFirstName"
								label="Spouse First Name"
								placeholder="Spouse First Name"
							/>
							<FSTextInput 
								name="spouseLastName"
								label="Spouse Last Name"
								placeholder="Spouse Last Name"
							/>
						</Form.Group>
						<FamilyMembersSection addMember={push} />
					</Condition>
					<GraphQLError error={error} />
				</Form>
			)}
		/>
	);
};

const schema = Yup.object().shape({
	firstname: Yup.string().required('Required Field'),
	lastname: Yup.string().required('Required Field'),
	phone: Yup.string().required('Required Field'),
	email: Yup.string().required('Required Field').email('Invalid Email'),
	members: Yup.array().optional().of(Yup.object().shape({
		name: Yup.string().required('Required Field'),
	})),
});

export default AddMembershipForm;