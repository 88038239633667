import gql from 'graphql-tag';

const queryGetSetting = gql`

	query GetSetting($key: String!) {
		getSetting(key: $key,) {
			id
			key
			value
		}
	}

`;

export default queryGetSetting;