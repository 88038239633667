import React from 'react';
import { Modal } from 'semantic-ui-react';
import BusinessAffiliate from './BusinessAffiliate';

const BusinessAffiliateModal = ({ id, businessname, onClose }) => {
	if(!id) { return null; }
	return (
		<Modal 
			open={!!id}
			closeIcon
			onClose={onClose}
		>
			<Modal.Header>{businessname}</Modal.Header>
			<Modal.Content>
				{id && <BusinessAffiliate id={id} /> }
			</Modal.Content>
		</Modal>
	);
};

export default BusinessAffiliateModal;