import React from 'react';
import { Divider } from 'semantic-ui-react';

const ShippingInfo = ({ data }) => {

	const { firstname, lastname, shippingAddress={} } = data.orderInformation;

	const renderFormated = () => {
		const { street1, street2, city, state, zip, country } = shippingAddress;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{firstname} {lastname}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<div className="admin-sale-info">
			<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Shipping Information</h4>
			<hr />
			<Divider horizontal>Formated Address</Divider>
			{renderFormated()}
		</div>
	);
};

export default ShippingInfo;