import React, { useRef } from 'react';
import SalesTable from './SalesTable';
import { Segment, Button } from 'semantic-ui-react';

const SalesPage = () => {
	const childRef = useRef();

	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group stacked={true}>
				<Segment color='blue' className="tableHeaderContainer">
					<div></div>
					<h2>Personal Sales</h2>
					<Button basic onClick={() => childRef.current.refresh()}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>
				<Segment placeholder>
					<SalesTable ref={childRef} />
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default SalesPage;