import React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from '../Loading';
import styles from './LinkAccount.module.scss';
import DNPLogo from '../../img/logo.png';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button } from 'semantic-ui-react';
import FSTextInput from '../FinalForm/TextInput';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import GraphQLError from '../Message/GraphQLError';

const QUERY_MEMBERSHIP_SETUP = gql`
	query GetMembershipSetup($id: String!) {
		getMembershipSetup(id: $id) {
			id
			email
			customerID
			firstname
			lastname
		}
	}
`;

const MUTATION_CREATE_ACCOUNT = gql`

	mutation CreateAccountFromMembership($firstname: String!, $lastname: String!, $email: String!, $zip: String!, $password: String!, $membershipID: String!) {
		createAccountFromMembership(firstname: $firstname, lastname: $lastname, email: $email, zip: $zip, password: $password, membershipID: $membershipID)
	}

`;

const LinkAccount = (props) => {
	const id = _.get(props, 'match.params.id');
	const { data, loading } = useQuery(QUERY_MEMBERSHIP_SETUP, {
		variables: { id },
	});
	const [createAccount, { data: mData, loading: mLoading, error  }] = useMutation(MUTATION_CREATE_ACCOUNT);

	if(loading) {
		return <Loading />;
	}

	const { id: mID, email, customerID, firstname, lastname } = _.get(data, 'getMembershipSetup', {}) || {};

	if(!id || !mID) { 
		return (
			<div className={styles.center}>
				<img src={DNPLogo} />
				<h2>Invalid Membership ID</h2>
			</div>
		);
	}

	if(customerID) {
		return (
			<div className={styles.center}>
				<img src={DNPLogo} />
				<h2>Membership is already linked to an account</h2>
			</div>
		);
	}

	if(_.get(mData, 'createAccountFromMembership')) {
		return (
			<div className={styles.center}>
				<img src={DNPLogo} />
				<h2>Membership Created!</h2>
				<a href="https://deluxenetworkprogram.com">Go to Main Website</a>
			</div>
		);
	}


	const onSubmit = (variables) => {
		createAccount({
			variables,
		});
	};

	return (
		<div className={styles.top}>
			<div className={styles.fixed}>
				<img src={DNPLogo} />
				<h2>Create & Link Account</h2>
				<FinalForm
					initialValues={{ email, firstname, lastname, membershipID: mID }}
					onSubmit={onSubmit}
					validate={validator(schema)}
					render={({ handleSubmit, valid }) => (
						<Form onSubmit={handleSubmit} loading={mLoading}>
							<Form.Group widths="equal">
								<FSTextInput
									name="firstname"
									label="First Name"
									disabled
								/>
								<FSTextInput
									name="lastname"
									label="Last Name"
									disabled
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<FSTextInput
									name="email"
									label="Email"
									disabled
								/>
								<FSTextInput
									name="zip"
									label="Zip Code"
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<FSTextInput
									name="password"
									label="Password"
									type="password"
								/>
								<FSTextInput
									name="confirm"
									label="Confirm Password"
									type="password"
								/>
							</Form.Group>
							<GraphQLError error={error} />
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								<Button
									color="blue"
									content="Submit"
									loading={mLoading}
									disabled={mLoading || !valid}
								/>
							</div>
						</Form>
					)}
				/>
			</div>
		</div>
	);
};

const schema = Yup.object().shape({
	zip: Yup.string().required('Required Field'),
	password: Yup.string().required('Required Field').min('6', 'Minimum Password Length: 6'),
	confirm: Yup.string().required('Required Field').oneOf([Yup.ref('password')], 'Passwords do not match')
});

export default LinkAccount;