import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import ChangeReferenceField from '../../../ChangeField/ChangeReference';
import ChangeCheckboxField from '../../../ChangeField/ChangeCheckbox';
import gql from 'graphql-tag';

export const MUTATION_MODIFY_ORDER_ADMIN = gql`
	mutation ModifyOrderAdmin($id: String!, $property: String!, $firstname: String, $lastname: String, $reference: String, $email: String, $phone: String, $shippingAddress: ShippingAddress, $date: Date, $requestedPhysicalCard: Boolean, $physicalCardSent: Boolean) {
		modifyOrderAdmin(id: $id, property: $property, firstname: $firstname, lastname: $lastname, reference: $reference, email: $email, phone: $phone, shippingAddress: $shippingAddress, date: $date, requestedPhysicalCard: $requestedPhysicalCard, physicalCardSent: $physicalCardSent)
	}
`;

export const ChangeTextFieldOrder = compose(
	graphql(MUTATION_MODIFY_ORDER_ADMIN),
	withStateMutation(),
)(ChangeTextField);

export const ChangeDateFieldOrder = compose(
	graphql(MUTATION_MODIFY_ORDER_ADMIN),
	withStateMutation(),
)(ChangeDateField);

export const ChangeSelectFieldOrder = compose(
	graphql(MUTATION_MODIFY_ORDER_ADMIN),
	withStateMutation(),
)(ChangeSelectField);

export const ChangeReferenceFieldOrder = compose(
	graphql(MUTATION_MODIFY_ORDER_ADMIN),
	withStateMutation(),
)(ChangeReferenceField);

export const ChangeCheckboxFieldOrder = compose(
	graphql(MUTATION_MODIFY_ORDER_ADMIN),
	withStateMutation(),
)(ChangeCheckboxField);