import gql from 'graphql-tag';

const QUERY_DASHBOARD_USER = gql`
	query DashboardUser($month: Int!, $year: Int!) {
		user {
			id
			firstname
			lastname
			accountType
			representativeLevel {
				typeID
				name
				percentage
			}
			representativeID
			SalesInfo(month: $month, year: $year) {
				childCount
				allSalesTotal
				Commission {
					need
					team
					personal
					isEligible
					reasons {
						childCount
						firstMonth
						sales
					}
				}
				Month {
					sales 
				}
			}

		}
	}

`;

export default QUERY_DASHBOARD_USER;