import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../Loading';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoyaltyForm from './LoyaltyForm';

const QUERY_USER_TYPE = gql`
	query User {
		user {
			id
			accountType
			businessPlan {
				id
			}
		}
	}
`;

const Loyalty = () => {
	const { data, loading, refetch } = useQuery(QUERY_USER_TYPE);

	if(loading) {
		return <Loading />;
	}

	if(_.get(data, 'user.accountType', 0) > 1) {
		return (
			<Segment placeholder style={{ textAlign: 'center', alignItems: 'center' }}>
				<FontAwesomeIcon icon={['fas', 'check']} style={{ color: '#2ecc71', marginBottom: '10px' }} size="3x" />
				<h3>You are signed up to share!</h3>
				<h4>You may access your sharing tools under the navigation links in the sidebar</h4>
			</Segment>
		);
	}

	return (
		<div>
			<h2 className="formHeader">Sharing Program</h2>
			<p className="subheader">
				Join our sharing program and make money on the side! This program allows you to sell The Everything Card to other people and get 20% of the revenue. Create a downline of other educators and businesses and make 5% of what they sell as well!
			</p>
			<Segment>
				<LoyaltyForm user={_.get(data, 'user')} refetch={[{ query: QUERY_USER_TYPE }]}/>
			</Segment>
		</div>
	);
};

export default Loyalty;