import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import modifyStoreMutation from '../../../../mutations/modifyStore';
import ChangeReference from '../../../ChangeField/ChangeReference';
import ChangeMultiSelectField from '../../../ChangeField/ChangeMultiSelectField';
import ChangeCheckboxField from '../../../ChangeField/ChangeCheckbox';

export const ChangeTextFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeTextField);

export const ChangeSelectFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeSelectField);

export const ChangeMultiSelectFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeMultiSelectField);

export const ChangeDateFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeDateField);

export const ChangeCheckboxFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeCheckboxField);

export const ChangeReferenceFieldStore = compose(
	graphql(modifyStoreMutation),
	withStateMutation(),
)(ChangeReference);