import gql from 'graphql-tag';

const QUERY_GET_MEMBERSHIP = gql`

	query GetMembership($id: String!) {
		getMembership(id: $id) {
			id

			customer {
				id
				firstname
				lastname
			}

			reference {
				id
				firstname
				lastname
			}

			plan
			membershipID

			firstname
			lastname
			accountEmail
			email
			phone

			shippingAddress {
				name
				street1
				street2
				city
				state
				country
				zip
			}

			stripeMembership
			canceled

			isCorporatePlan
			paidForByCompany
			corporatePlan {
				id
			}

			orders {
				id
				referenceName
				total
				date
				requestedPhysicalCard
				physicalCardSent
			}

			period_end
			period_start
			updatedStatus
			createdAt

			stripe {
				id
				status
				canceled_at
				ended_at
				customer
				latest_invoice
			}

			spouseFirstName
			spouseLastName
			additionalFamilyMembers {
				name
			}

			subMemberships {
				id
				canceled
				membershipID
				firstname
				lastname
			}

			lastInvoiceRefunded
			
		}
	}

`;

export default QUERY_GET_MEMBERSHIP;