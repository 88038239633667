import React from 'react';
import { Modal, Button, Icon, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DeleteModal.module.scss';
import GraphQLError from '../../Message/GraphQLError';

const DeleteModal = ({
	onDelete,
	onClose,
	open,
	loading,
	text='This action cannot be reversed!',
	error
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
			size='mini'
		>
			<Modal.Content>
				<div className={styles.dSegment} loading={loading} style={{ textAlign: 'center' }}>
					<FontAwesomeIcon icon={['fas','exclamation-square']} size='4x' />
					<h2 style={{ marginTop: '10px', fontSize: '35px' }}>Are you sure?</h2>
					<p style={{ fontSize: '18px'}}>{text}</p>
					<GraphQLError error={error} />
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button color='grey' onClick={onClose} loading={loading} disabled={loading}>
					<Icon name='remove' /> No
				</Button>
				<Button color='red' onClick={onDelete} loading={loading} disabled={loading}>
					<Icon name='checkmark' /> Yes
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default DeleteModal;