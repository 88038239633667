import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { Segment } from 'semantic-ui-react';
import queryGetTrainingAdmin from '../../../queries/getTrainingAdmin';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { DragSource, DropTarget } from 'react-dnd';


/**
 * Implements the drag source contract.
 */
const cardSource = {
	beginDrag(props) {
		console.log('Beginning the drag');
		return {
			id: props.id,
			index: props.index,
			text: props.text
		};
	}
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	};
}

function collect2(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget()
	};
}

const cardTarget = {
	hover(props, monitor, component) {
		if (!component) {
			return null;
		}

		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%
		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		props.moveCard(dragIndex, hoverIndex);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
}
  

class TrainingItem extends Component {

	render() {

		if(this.props.data.loading) {
			return <div></div>;
		}

		if(!this.props.data.getTraining) {
			return <div></div>;
		}

		const { created, createdBy, description, id, title, type, youtubeID } = this.props.data.getTraining;

		const youtubeLink = 'https://www.youtube.com/watch?v=' + youtubeID;

		const { isDragging, connectDragSource, connectDropTarget, text } = this.props;

		return connectDragSource ( connectDropTarget (
			<div style={{ marginBottom: 10 }}>
				<Segment>
					<h3>{title} - <a href={youtubeLink} target="_blank">View Video</a></h3>
					<p>{description}</p>
				</Segment>
			</div>
		));
	}
}

export default compose(
	DropTarget('card', cardTarget, collect2),
	DragSource('card', cardSource, collect),
	graphql(queryGetTrainingAdmin, {
		options: (props) => { return { variables: { id: props.id } }; }
	}),
)(TrainingItem);