import gql from 'graphql-tag';

const QUERY_GET_STORE = gql`

	query GetStore($id: String!) {
		getStore(id: $id) {
			id
			businessname
			url
			type
			category

			reference
			contactname
			contactemail
			contactphone
			logo
			premier
			user {
				id
				firstname
				lastname
				email
			}

			parent {
				id
				firstname
				lastname
				email
			}

			promotionsCount
			promotions {
				id
				code
				info
				url
				file {
					id
					path
					filename
					mimetype
					encoding
					actualPath
					previousFilename
					ext
				}
				additionalURL
				validUntil
				online
			}

			onlineOnly
			nationalLocation

			storeLocationsCount

			locations {
				id

				locationInformation {
					name
					phone
					street1
					street2
					city
					state
					zip
					country
				}

				location {
					type
					coordinates
				}

				searchName
				type
				approved
			}

			termsAgree
			privacyAgree
			approved

		}
	}

`;

export default QUERY_GET_STORE;