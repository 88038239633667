import gql from 'graphql-tag';

const QUERY_GET_SHIPPING_ADDRESS_OF_REPRESENTATIVE = gql`
	query GetShippingAddressOfRepresentative($userID: String!) {
		getShippingAddressOfRepresentative(userID: $userID) {
			street1
			street2
			city
			state
			zip
			country
		}
	}
`;

export default QUERY_GET_SHIPPING_ADDRESS_OF_REPRESENTATIVE;