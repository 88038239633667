import React from 'react';
import ReactSelect from 'react-select';
import SimpleValue from 'react-select-simple-value';
 
const SimpleSelect = ({ options, value, ref, ...props }) => (
	<SimpleValue options={options} value={value}>
		{simpleProps => <ReactSelect ref={ref} {...props} {...simpleProps} />}
	</SimpleValue>
);

export default SimpleSelect;