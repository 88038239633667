import React from 'react';
import { Segment } from 'semantic-ui-react';
import ReferralForm from './ReferralForm';
import styles from './referral.module.scss';

const ReferralPage = () => {
	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Segment.Group>
				<Segment className="tableHeaderContainer">
					<h2>Send Referral</h2>
				</Segment>
				<Segment>
					<p className={styles.description}>You can send a referral to another person here. This will send an automated email from Deluxe Network Program to the recipient based on the template you select. If the person registers as an affiliate or buys the everything card from DNP, we will notify you to tell you that your referral is completed!</p>
				</Segment>
				<ReferralForm />
			</Segment.Group>
		</div>
	);
};

export default ReferralPage;