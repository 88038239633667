import React from 'react';
import { DetailsTable } from '../../../Table';
import { 
	ChangeTextFieldBusinessPlan,
	ChangeTextAreaBusinessPlan,
} from '../helpers/BusinessPlanChangeFields';

const SocialMediaInfo = ({ data, refetch }) => {
	const { socialMedia } = data;
	const {
		facebook,
		instagram,
		twitter,
		snapchat,
		pinterest,
		linkedin,
		socialMediaInfo,
	} = socialMedia;

	return (
		<DetailsTable noHeader>

			<ChangeTextAreaBusinessPlan
				label="Social Media Extra Info"
				value={socialMediaInfo}
				property={'socialMedia.socialMediaInfo'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="Facebook"
				value={facebook}
				property={'socialMedia.facebook'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="Instagram"
				value={instagram}
				property={'socialMedia.instagram'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="Twitter"
				value={twitter}
				property={'socialMedia.twitter'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="Snapchat"
				value={snapchat}
				property={'socialMedia.snapchat'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="Pinterest"
				value={pinterest}
				property={'socialMedia.pinterest'}
				refetch={refetch}
				id={data.id}
				table
			/>

			<ChangeTextFieldBusinessPlan
				label="LinkedIn"
				value={linkedin}
				property={'socialMedia.linkedin'}
				refetch={refetch}
				id={data.id}
				table
			/>

		</DetailsTable>
	);
};

export default SocialMediaInfo;