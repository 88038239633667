import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import AddTrainingVideoForm from './AddTrainingVideoForm';

class AddTrainingVideo extends Component {
	state = {
		modal: false,
	}

	onComplete = () => {
		this.setState({ modal: false });
	}

	render() {
		return (
			<div>
				<Modal
					trigger={<Button icon='add' onClick={() => this.setState({ modal: true })} />}
					open={this.state.modal}
					onClose = {() => this.setState({ modal: false })}
					size='large'
				>
					<Modal.Content>
						<AddTrainingVideoForm onComplete={this.onComplete} />
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}

export default AddTrainingVideo;