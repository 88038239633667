import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Divider, Dimmer, Loader, Button } from 'semantic-ui-react';
import _ from 'lodash';
import FSTextInput from '../../../FinalForm/TextInput';
import FSCheckbox from '../../../FinalForm/Checkbox';
import FSSelectInput from '../../../FinalForm/SelectInput';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styles from './Loyalty.module.scss';
import QUERY_GET_CONTRACT from '../../../../queries/getContract';
import MUTATION_REGISTER_LOYALTY from './registerLoyalty';
import GraphQLError from '../../../Message/GraphQLError';

const IDTypeOptions = [
	{ label: 'Social Security Number (SSN)', value: 'ssn' },
	{ label: 'Employer Identification Number (EIN)', value: 'ein' }
];

const TaxClassificationOptions = [
	{ label: 'Individual/sole proprietor or single-member LLC', value: 'individual' },
	{ label: 'Limited liability company', value: 'llc' },
	{ label: 'C Corporation', value: 'c_corporation' },
	{ label: 'S Corporation', value: 's_corporation' },
	{ label: 'Partnership', value: 'partnership' },
	{ label: 'Trust/estate', value: 'trust_estate' }
];


const LoyaltyForm = ({ refetch, user }) => {
	const { data: contractData, loading: contractLoading } = useQuery(QUERY_GET_CONTRACT, {
		variables: { type: 'REPRESENTATIVE' }
	});
	console.log(user);
	const [registerLoyalty, { loading, error }] = useMutation(MUTATION_REGISTER_LOYALTY);

	const onSubmit = (variables) => {
		registerLoyalty({
			variables,
			awaitRefetchQueries: true,
			refetchQueries: refetch
		});
	};

	return (
		<FinalForm
			onSubmit={onSubmit}
			validate={validate}
			initialValues={{ id: _.get(user, 'businessPlan.id')}}
			render={({ handleSubmit, valid }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<h2 className="formHeader" style={{ color: '#2d3436' }}>W-9 Information</h2>
					<p className="subheader">Please fill out the W-9 Information Below</p>
					<Form.Group widths='equal'>
						<FSCheckbox name="w9.usPerson" label="I am a U.S. Person" required />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name="w9.legalNameOfBusiness" placeholder="Legal Name" required />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSSelectInput
							name="w9.taxClassification"
							label="Federal Tax Classification"
							required
							options={TaxClassificationOptions}
						/>
					</Form.Group>
					<Form.Group widths='equal'>
						<FSSelectInput
							name="w9.idType"
							label="Taxpayer Identification Number Type"
							required
							options={IDTypeOptions}
						/>
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name="w9.idNumber" placeholder="SSN/EIN #" required />
					</Form.Group>

					<Divider horizontal>Sharing Terms</Divider>

					<div>
						<p className={styles.subheader}>By accepting below, you agree to our terms and services</p>
						<div style={{ position: 'relative' }}>
							<div className="contract2" dangerouslySetInnerHTML={{ __html: _.get(contractData, 'getContract') }} />
							<Dimmer active={contractLoading} inverted>
								<Loader inverted/>
							</Dimmer>
						</div>
						<FSCheckbox name="w9.acceptedTerms" label="I understand and agree to the membership terms and the information I put for the W9 above is correct" />
					</div>
					<GraphQLError error={error} />
					<Button
						content="Register to share"
						loading={loading}
						disabled={loading || !valid}
						color={valid ? 'blue' : 'grey'}
						fluid
					/>
				</Form>
			)}
		/>
	);
};

function validate(values) {
	const errors = {};


	if(!values.w9 || !values.w9.usPerson) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.usPerson = 'We only support US persons at this time';
	}

	if(!values.w9 || !values.w9.legalNameOfBusiness) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.legalNameOfBusiness = 'Required Field';
	}

	if(!values.w9 || !values.w9.taxClassification) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.taxClassification = 'Required Field';
	}

	if(!values.w9 || !values.w9.idType) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.idType = 'Required Field';
	}

	if(!values.w9 || !values.w9.idNumber) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.idNumber = 'Required Field';
	}

	if(!values.w9 || !values.w9.acceptedTerms) {
		if (!errors.w9) { errors.w9 = {}; }
		errors.w9.acceptedTerms = 'Please accept the terms';
	}

	return errors;
}

export default LoyaltyForm;