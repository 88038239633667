import React, { Component, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Segment, Button, Modal } from 'semantic-ui-react';
import { withApollo } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import ReactTable from 'react-table';

import columns from './cols';
import queryGetStores from '../../../../queries/getStores';
import Store from '../Store';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import StoreModal from '../Store/StoreModal';

const skipColumns = (cols, toSkip=[]) => {
	for (let col of toSkip) {
		const index = cols.findIndex(obj => obj.id === col);
		if(index !== null) {
			cols.splice(index, 1);  
		}
	}
	return cols;
};

const StoresTableContainer = forwardRef(({ path, hideCols=[] }, ref) => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [datePopups, setDatePopups] = useState({});
	const [dates, setDates] = useState({});
	const [vars, setVars] = useState({});
	const history = useHistory();
	const [modalID, setModalID] = useState();
	const params = useParams();

	useEffect(() => {
		setModalID(_.get(params, 'id'));
	}, [params]);

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refetch(vars);
		},
	}));

	const [loadInitialData, {
		data: qData,
		loading,
		called,
		refetch
	}] = useLazyQuery(queryGetStores, {});

	const { data, count } = _.get(qData, 'getStores', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		const date = [];
		for(let ob in dates) {
			if(dates[ob]) {
				date.push({ id: ob, value: dates[ob].valueOf() });
			}
		}
		const required = [];
		const variables = {
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered: [
				...filtered,
				...date,
				...required,
			],
		};
		setVars(variables);
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				if(info) {
					if(path) {
						history.push(`${path}/${info.id}`);
					} else {
						setModalID(info.id);
					}
				}
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	return (
		<div className="animated fadetwoIn">
			<StoreModal
				id={modalID}
				onClose={() => {
					setModalID();
					if(path) {
						history.push(path);
					}
					refetch(vars);
				}}
			/>
			<ReactTable
				data={data}
				columns={
					skipColumns(columns({}, hideCols))
				}
				pageSizeOptions={[10,15,20,50,100]}
				defaultPageSize={pageSize}
				pages={pages}
				onFetchData={onFetchData}
				manual
				defaultSorted={[{
					id: 'date',
					desc: true
				}]}
				loading={loading}
				getTrProps={rowProps}
			/>
			<h4 style={{ textAlign: 'center', marginTop: 10 }}>
				Total Businesses: {count}
			</h4>
		</div>
	);
});

// class _StoresTableContainer extends Component {
// 	state = {
// 		loading: false,
// 		pages: 0,
// 		index: null,
// 		modal: false,
// 		data: [],
// 		count: 0,
// 		latestQuery: {},
// 		pageSize: 20,
// 	}


// 	fetchData = (state, instance) => {
// 		this.setState({ loading: true });
// 		const query = {
// 			query: queryGetStores,
// 			variables: {
// 				limit: state.pageSize,
// 				skip: state.page * state.pageSize,
// 				sortBy: state.sorted,
// 				filtered: state.filtered,
// 			},
// 			fetchPolicy: 'network-only',
// 		};

// 		this.props.client.query(query)
// 			.then(data => {
// 				const count = _.get(data, 'data.getStores.count', 0);
// 				this.setState({
// 					loading: false,
// 					pages: Math.ceil(count / state.pageSize),
// 					count,
// 					data: _.get(data, 'data.getStores.data', []),
// 					latestQuery: query,
// 					pageSize: state.pageSize,
// 				});
// 			});

// 	}

// 	refresh = () => {
// 		this.setState({ loading: true });
// 		const { latestQuery, pageSize } = this.state;

// 		this.props.client.query(latestQuery)
// 			.then(data => {
// 				const count = _.get(data, 'data.getStores.count', 0);
// 				this.setState({
// 					loading: false,
// 					pages: Math.ceil(count / pageSize),
// 					count,
// 					data: _.get(data, 'data.getStores.data', []),
// 				});
// 			});
// 	}

// 	closeModalAndReload = async () => {
// 		await this.setState({ modal: false, index: null });
// 		this.refresh();
// 	}

// 	renderModal() {
// 		const { modal, index } = this.state;
// 		if(index && modal) {
// 			return (
// 				<Modal open={this.state.modal} onClose={() => this.setState({ modal: false, index: null })} closeIcon>
// 					<Modal.Header>{'Store'}</Modal.Header>
// 					<Modal.Content>
// 						<Store id={index} onComplete={this.closeModalAndReload} />
// 					</Modal.Content>
// 				</Modal>
// 			);
// 		}
// 	}

// 	openStore = (id) => {
// 		this.setState({ index: id, modal: true });
// 	}

// 	render() {
// 		const { loading, pages } = this.state;
// 		return (
// 			<Segment.Group stacked>
// 				{this.renderModal()}
// 				<Segment color='green' className="tableHeaderContainer">
// 					<div></div>
// 					<div style={{ textAlign: 'center' }}>
// 						<h2>Store Management</h2>
// 					</div>
// 					<Button basic onClick={this.refresh}>
// 						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
// 					</Button>
// 				</Segment>
// 				<Segment placeholder>
// 					<ReactTable
// 						data={this.state.data}
// 						columns={columns(this.openStore)}
// 						pageSizeOptions={[10,15,20,50,100]}
// 						defaultPageSize={20}
// 						pages={pages}
// 						onFetchData={this.fetchData}
// 						manual
// 						loading={loading}
// 					/>
// 					<h4 style={{ textAlign: 'center', marginTop: 10 }}>
// 						Total Stores: {this.state.count}
// 					</h4>
// 				</Segment>
// 			</Segment.Group>
// 		);
// 	}
// }


export default StoresTableContainer;