import gql from 'graphql-tag';

const QUERY_GET_MEMBERSHIP_TABLE = gql`

	query GetMembershipsTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getMembershipsTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				plan
				membershipID
				firstname
				lastname
				accountEmail
				stripeMembership
				canceled
				isCorporatePlan

				period_start
				period_end
				createdAt

				stripe {
					current_period_end
					status
				}
				lastInvoiceRefunded
			}
		}
	}

`;

export default QUERY_GET_MEMBERSHIP_TABLE;