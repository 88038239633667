import gql from 'graphql-tag';


const modifyUserTrainingMutation = gql`

	mutation modifyUserTraining($trainingID: String!, $dateStarted: Boolean, $dateCompleted: Boolean)
	{
		modifyUserTraining(trainingID: $trainingID, dateStarted: $dateStarted, dateCompleted: $dateCompleted)
		{
			id
		}
	}

`;

export default modifyUserTrainingMutation;