import React from 'react';
import { Field } from 'react-final-form';
import { Form, Input, Popup, Button, Icon } from 'semantic-ui-react';


const FSStripe = ({ name, component: Component, validate, ...props }) => {
	return (
		<Field name={name} validate={validate}>
			{({ input }) => (
				<Component
					onChange={info => input.onChange(info)}
					{...props}
				/>
			)}
		</Field>
	);
};

export default FSStripe;