import React, { Component } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import FSSelectInput from '../FinalForm/SelectInput';
import FSTextInput from '../FinalForm/TextInput';
import FSTextAreaInput from '../FinalForm/TextAreaInput';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CREATE_TICKET from './createTicket';
import GraphQLError from '../Message/GraphQLError';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FSFileInput from '../FinalForm/FileInput';
import arrayMutators from 'final-form-arrays';
import FSFileInputArray from '../FinalForm/FSFileInputArray';

const TypeOptions = [
	{ id: 0, value: 0, label: 'Bug/Issue/Problem' },
	{ id: 1, value: 1, label: 'Feature Request' },
];

const ContactSupportForm = () => {
	const [createTicket, { data, loading, error }] = useMutation(MUTATION_CREATE_TICKET);
	const [pressUpload, setPressUpload] = React.useState(false);

	React.useEffect(() => {
		if(pressUpload) {
			document.querySelector('#files>div:last-child input').click();
			setPressUpload(false);
		}
	}, [pressUpload]);

	const onSubmit = (values) => {
		createTicket({
			variables: values,
		});
	};

	if(_.get(data, 'createTicket')) {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
				<FontAwesomeIcon 
					icon={['fas', 'thumbs-up']}
					color="#3498db"
					size="5x"
				/>
				<h2 style={{ marginTop: '10px' }}>Ticket Submitted</h2>
				<p style={{ fontSize: '18px' }}>
					Your request has been submitted.<br />
					Please give us some time to review your request.
				</p>
			</div>
		);
	}

	return (
		<FinalForm
			initialValues={{
				type: 0
			}}
			validate={validate}
			mutators={{ ...arrayMutators }}
			onSubmit={onSubmit}
			render={({ handleSubmit, values, valid, form: { mutators: { push } } }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<FSSelectInput
						name="type"
						options={TypeOptions}
						label="Type"
					/>
					<FSTextInput name="subject" label="Subject" />
					<FSTextAreaInput name="description" label="Description" />
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px',  }}>

						<Button
							type="button"
							content="Add File"
							onClick={() => {
								push('files');
								setPressUpload(true);
							}}
						/>
					</div>
					<div id="files">
						<FSFileInputArray name='files' />
					</div>
					<GraphQLError error={error} />
					<Button
						type="submit"
						content="Submit"
						color={valid ? 'green' : 'grey'}
						disabled={loading || !valid}
						loading={loading}
						fluid
					/>
				</Form>
			)}
		/>
	);
};


const ContactSupport = () => {
	const [open, setOpen] = React.useState(false);

	return (
		<>
			<Button
				content="Contact Tech Support"
				onClick={() => setOpen(true)}
				color="purple"
				size="large"
			/>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				closeOnDimmerClick={false}
				closeIcon={true}
				size="small"
				header={<Modal.Header>Contact Tech Support</Modal.Header>}
				content={(
					<Modal.Content>
						<ContactSupportForm />
					</Modal.Content>
				)}
			/>
		</>
	);
};

function validate(values) {
	const errors = {};

	if(!values.subject) {
		errors.subject = 'Required Field';
	}

	if(!values.description) {
		errors.description = 'Required Field';
	}

	if(values.files) {
		let index = 0;
		for(let file of values.files) {
			if(!_.get(file, 'name')) {
				errors[`files[${index}]`] = 'Required Field';
			}
			index++;
		}
	}

	return errors;
}

export default ContactSupport;
