import React from 'react';
import { Segment, Header, Statistic } from 'semantic-ui-react';

const plans = {
	6: 'Basic Plan',
	7: 'Premium Plan',
	9: 'Deluxe Membership',
};

const BusinessInfo = ({ plan: bPlan }) => {
	const { plan, businessname } = bPlan;
	return (
		<Segment.Group>
			<Segment color="red" textAlign="center">
				<Statistic size='tiny'>
					<Statistic.Value>{businessname}</Statistic.Value>
					<Statistic.Label>{plans[plan] || 'Unknown Plan'}</Statistic.Label>
				</Statistic>
			</Segment>
		</Segment.Group>
	);
};

export default BusinessInfo;