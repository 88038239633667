import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Button } from 'semantic-ui-react';
import Condition from '../../../../../FinalForm/Condition';
import Checkbox from '../../../../../FinalForm/StyledCheckbox';
import AdditionalFamilyMembers from '../../../../Sales/SellCorporateMembership/AdditionalFamilyMembers';
import TextInput from '../../../../../FinalForm/TextInput';
import arrayMutators from 'final-form-arrays';
import MUTATION_ADD_PLAN_TO_GROUP from '../../../../../../mutations/addPlanToGroup';
import { useMutation, useQuery } from '@apollo/react-hooks';
import GraphQLError from '../../../../../Message/GraphQLError';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AddMembership.module.scss';
import queryGetMembership from '../../../../../../queries/getMembership';
import SelectInput from '../../../../../FinalForm/SelectInput';
import { countries, states } from '../../../../../../config/supported_areas';
import QUERY_GET_DISCOUNT from './getDiscount';
import Loading from '../../../../../Loading';

const calculatePrice = (price, data) => {
	const percent_off = _.get(data, 'getMembership.discount.coupon.percent_off', null);
	if(percent_off !== null) {
		return (price * ((100-percent_off)/100)).toFixed(2);
	}
	return price.toFixed(2);
};

const getPrice = (data, familyPlan) => {
	const newCorporatePlan = _.get(data, 'getMembership.newCorporatePlan', false);
	return newCorporatePlan ? (familyPlan ? 99.90 : 49.95) : (familyPlan ? 49.90 : 24.95);
};

const MembershipForm = ({ planID }) => {
	const [purchase, { data, loading, error }] = useMutation(MUTATION_ADD_PLAN_TO_GROUP, {
		refetchQueries: [
			{ query: queryGetMembership, variables: { id: planID }},
			'GetMembershipsTable', 'getMembershipsTable',
		]
	});

	const { data: discountData, loading: discountLoading, error: discountError } = useQuery(QUERY_GET_DISCOUNT, {
		variables: { id: planID }
	});

	const onSubmit = React.useCallback(variables => {
		console.log(variables);
		purchase({ variables: {
			employee: variables,
			planID,
		}});
	}, []);

	if(_.get(data, 'addPlanToGroup')) {
		return (
			<div className={styles.complete}>
				<span>
					<FontAwesomeIcon icon={['fas', 'thumbs-up']} />
				</span>
				<h2>Member was Added</h2>
			</div>
		);
	}

	if(discountLoading) {
		return <Loading />;
	}

	return (
		<div>
			<FinalForm
				onSubmit={onSubmit}
				validate={validate}
				mutators={{ ...arrayMutators }}
				render={({ values, handleSubmit }) => (
					<Form onSubmit={e => { e.preventDefault(); return; }} loading={loading}>
						<GraphQLError error={error}>Error</GraphQLError>
						<Form.Group widths='equal'>
							<TextInput name={'firstname'} placeholder="First Name" required />
							<TextInput name={'lastname'} placeholder="Last Name" required />
						</Form.Group>

						<Form.Group widths='equal'>
							<TextInput name={'phone'} placeholder="Phone" required />
							<TextInput name={'email'} placeholder="Email" />
						</Form.Group>

						<Form.Group widths='equal'>
							<Checkbox name={'ownAddress'} label="Ship to an address different than corporate" />
						</Form.Group>

						<Condition when={'ownAddress'} is={true}>								
							<Form.Group widths='equal'>
								<TextInput name={'street1'} placeholder="Street 1" label="Street" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={'street2'} placeholder="Street 2" label={false} />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name={'city'} placeholder="City" required />
								<SelectInput name={'state'} placeholder="State" required options={states} />
							</Form.Group>
							<Form.Group widths='equal'>
								<SelectInput name={'country'} placeholder="Country" required options={countries} />
								<TextInput name={'zip'} placeholder="Zipcode" required />
							</Form.Group>
						</Condition>

						<Form.Group widths='equal'>
							<Checkbox name='familyPlan' label={`Upgrade to family plan - $${calculatePrice( getPrice(discountData, false) , discountData)} Extra`} />
						</Form.Group>

						<Condition when={'familyPlan'} is={true}>
							<Form.Group widths='equal'>
								<TextInput name={'spouseFirstName'} placeholder="Spouse's First Name" />
								<TextInput name={'spouseLastName'} placeholder="Spouse's Last Name" />
							</Form.Group>						
							<AdditionalFamilyMembers name={'family'} />
						</Condition>

						<div className={styles.helperContainer}>
							<p>This is will charge the customer for ${values.familyPlan ? calculatePrice(getPrice(discountData, true), discountData) : calculatePrice(getPrice(discountData, false), discountData)} right now and the new member will be put on the regular subscription schedule. We do not prorate added members to a corporate plan.</p>
						</div>

						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
							<Button color="green" type='button' onClick={handleSubmit}>Submit</Button>
						</div>
					</Form>
				)}
			/>
		</div>
	);
};

function validate(values) {
	const errors = {};

	if(!values.firstname) {
		errors.firstname = 'Required Field';
	}

	if(!values.lastname) {
		errors.lastname = 'Required Field';
	}

	if(!values.phone) {
		errors.phone = 'Required Field';
	}

	if(values.ownAddress) {
		if(!values.street1) {
			errors.street1 = 'Required Field';
		}
		if(!values.city) {
			errors.city = 'Required Field';
		}
		if(!values.state) {
			errors.state = 'Required Field';
		}
		if(!values.country) {
			errors.country = 'Required Field';
		}
		if(!values.zip) {
			errors.zip = 'Required Field';
		}
	}

	if(values.familyPlan) {
		if(values.family) {
			errors.family = [];
			values.family.forEach((member,i2) => {
				if(!member.name) {
					errors.family[i2] = { name: 'Required Field' };
				}
			});
		}
	}

			

	return errors;
}


export default MembershipForm;