import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Segment, Table, Statistic } from 'semantic-ui-react';
import moment from 'moment-timezone';
import QUERY_GET_COMMISSION_REPORT from '../../../queries/getCommissionReport';
import Loading from '../../Loading';
import _ from 'lodash';
import ReportTable from './ReportTable/';
import Actions from './Actions';

const Selected = ({ month, year, onRecalculate }) => {
	const { data, loading } = useQuery(QUERY_GET_COMMISSION_REPORT, {
		variables: { month: +(month), year: +(year) }
	});

	useEffect(() => {
		if(!loading && data && !data.getCommissionReport) {
			onRecalculate();
		}
	}, [loading, data]);

	if (loading) {
		return (
			<Segment>
				<Loading />
			</Segment>
		);
	}

	const d = _.get(data, 'getCommissionReport', null);

	if(!data || !d) {
		return <div>Data not yet generated</div>;
	}

	const { id, calculated, totals={} } = d;

	return (
		<>
			<Segment>
				<h5 >
					<strong style={{ textTransform: 'uppercase', letterSpacing: 1}}>Generated:</strong>{'  '}
					<span className="aSpan" onClick={onRecalculate}>{moment(calculated).tz('America/Boise').format('MMMM Do YYYY, h:mm:ss a z')}</span>
				</h5>
			</Segment>
			<DataTable {...totals} />
			<Actions reportID={id} />
			<ReportTable reportID={id} month={month} year={year} />
		</>
	);
};

const DataTable = ({ 
	sales, commission, cards,
	individual, family, corporate,
	otherSales, basicBusinessPlan, premiumBusinessPlan,
}) => {
	const createRow = (title, value) => {
		return (
			<Statistic className="cStatistic">
				<Statistic.Label>{title}</Statistic.Label>
				<Statistic.Value>{value}</Statistic.Value>
			</Statistic>
		);
	};

	return(
		<Segment textAlign="center">
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<Statistic.Group size='small'>
					{createRow('Total Sales ($)', `$${sales.toFixed(2)}`)}
					{createRow('Commission ($)', `$${commission.toFixed(2)}`)}
					{createRow('Cards Sold', cards)}
					{createRow('Individual', individual)}
					{createRow('Family', family)}
					{createRow('Corporate', corporate)}
					{createRow('Other Sales', otherSales)}
					{createRow('Basic Business', basicBusinessPlan)}
					{createRow('Premium Business', premiumBusinessPlan)}
				</Statistic.Group>
			</div>
		</Segment>
	);
};

export default Selected;