import gql from 'graphql-tag';


const MUTATION_REGISTER_LOYALTY = gql`

	mutation RegisterLoyalty($id: String!, $w9: W9!) {
		registerLoyalty(id: $id, w9: $w9)
	}


`;

export default MUTATION_REGISTER_LOYALTY;