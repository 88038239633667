import gql from 'graphql-tag';

const setTrainingOrderMutation = gql`

	mutation SetTrainingOrder($list: [String]!) {
		setTrainingOrder(list: $list) {
			id
			key
			value
		}
	}

`;

export default setTrainingOrderMutation;