/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';
import SimpleSelect from '../../Form/SimpleSelect';

const typeOptions = [
	{ value: 0, label: 'Educator' },
	{ value: 1, label: 'Business' },
];

const booleanOptions = [
	{ value: false, label: 'No' },
	{ value: true, label: 'Yes' },
];

const typeValues = {
	0: 'Educator',
	1: 'Business',
};

const columns = ({ dates={}, datePopups={}, triggerDatePopup, changeDate  }) => [
	{
		Header: 'Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => d.name,
		foldable: true,
		sortable: true,
	},
	{
		Header: 'Email',
		id: 'email',
		filterable: true,
		minWidth: 150,
		accessor: d => d.email,
		foldable: true,
	},
	{
		Header: 'Phone #',
		id: 'phone',
		filterable: true,
		minWidth: 150,
		accessor: d => d.phone,
		foldable: true,
	},
	{
		Header: 'Type',
		id: 'type',
		filterable: true,
		minWidth: 150,
		accessor: d => typeValues[d.type || 0],
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		},
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={typeOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => { value !== null ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Requested',
		id: 'date',
		filterable: true,
		minWidth: 150,
		accessor: d => moment(d.date).format('MMMM Do YYYY, h:mm:ss a'),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('requested')} fluid>Filter Date</Button>}
					open={datePopups.requested}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.requestedStartDate}
								selectsStart
								endDate={dates.requestedEndDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('requestedStartDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.requestedEndDate}
								selectsEnd
								startDate={dates.requestedStartDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('requestedEndDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div>
	},
	{
		Header: 'Zoom Meeting Time',
		id: 'registeredFor',
		filterable: true,
		minWidth: 150,
		accessor: d => d.registeredFor ? moment(d.registeredFor).format('MMMM Do YYYY, h:mm:ss a') : 'N/A',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('registeredFor')} fluid>Filter Date</Button>}
					open={datePopups.registeredFor}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.registeredForStartDate}
								selectsStart
								endDate={dates.registeredForEndDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('registeredForStartDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.registeredForEndDate}
								selectsEnd
								startDate={dates.registeredForStartDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('registeredForEndDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div>
	},
	{
		Header: 'Completed',
		id: 'completed',
		filterable: true,
		minWidth: 150,
		accessor: d => d.completed ? 'Yes' : 'No',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		},
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => { value !== null ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'ID',
		id: 'id',
		filterable: false,
		minWidth: 150,
		accessor: d => d.id,
		foldable: true,
	},
];

export default columns;