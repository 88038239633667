import gql from 'graphql-tag';

const queryGetTraining = gql`

	query GetTraining($id: String!) {
		getTraining(id: $id) {
			id
			created
			type
			title
			youtubeID
			description
		}
	}

`;

export default queryGetTraining;