import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class Error extends Component {
	render() {
		if(!this.props.error) {
			return null;
		}

		return (
			<Message negative>
				<Message.Header>{this.props.children}</Message.Header>
				<Message.Content>{this.props.error}</Message.Content>
			</Message>
		);
	}
}

export default Error;