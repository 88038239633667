import React, {Component} from 'react';
import Loading from '../../../Loading';
import { Menu, Segment, Label } from 'semantic-ui-react';
import _ from 'lodash';
import { graphql } from '@apollo/react-hoc';
import queryGetStore from '../../../../queries/getStore';
import GeneralInfo from './GeneralInfo';
import PromotionList from './PromotionList';
import LocationsList from './LocationsList';
import Tools from './Tools';

class StoreModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeItem: 'general',
		};
	}

	handleItemClick = (value) => {
		this.setState({ activeItem: value });
	}

	onClose = () => {
		this.props.onComplete && this.props.onComplete();
	}

	renderSegment() {
		const { activeItem } = this.state;
		const { getStore } = this.props.data;
		return(
			<div>
				{ activeItem === 'general' && <GeneralInfo data={getStore} /> }
				{ activeItem === 'promotions' && <PromotionList data={getStore} /> }
				{ activeItem === 'locations' && <LocationsList data={getStore} /> }
				{ activeItem === 'tools' && <Tools data={getStore} close={this.onClose} /> }
			</div>
		);
	}


	render() {
		const { activeItem } = this.state;

		if(_.get(this.props.data, 'loading', false)) {
			return(<Loading />);
		}

		const { promotionsCount, storeLocationsCount } = this.props.data.getStore;

		return(
			<div>
				<Menu attached='top' tabular>
					<Menu.Item name='General Info' active={activeItem === 'general'} onClick={() => this.handleItemClick('general')} />

					<Menu.Item	name='Promotions' active={activeItem === 'promotions'} onClick={() => this.handleItemClick('promotions')}>
						Promotions<Label color={promotionsCount ? 'blue' : 'grey'}>{promotionsCount}</Label>
					</Menu.Item>

					<Menu.Item name='Locations' active={activeItem === 'locations'} onClick={() => this.handleItemClick('locations')}>
						Locations<Label color={storeLocationsCount ? 'green' : 'grey'}>{storeLocationsCount}</Label>
					</Menu.Item>

					<Menu.Item name='Tools' active={activeItem === 'tools'} onClick={() => this.handleItemClick('tools')} />
				</Menu>

				<Segment attached='bottom'>
					{this.renderSegment()}
				</Segment>
			</div>
		);
	}
}

export default graphql(queryGetStore, {
	options: (props) =>  {
		return { variables: { id: props.id } };
	}
})(StoreModal);
