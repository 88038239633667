import React, { Component } from 'react';
import ReactTable from 'react-table';
import { withApollo } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import queryGetUserTimecardTable from '../../../queries/employee/getUserTimecardTable';
import columns from './historyCols';

class ViewHistory extends Component {
	state = {
		loading: false,
		pages: 1,
		data: [],
		count: 0,
		pageSize: 15,


		in_popup: false,
		in_start_date: null,
		in_end_date: null,

		out_popup: false,
		out_start_date: null,
		out_end_date: null,
	}

	changeDate = (property, value, startdate=true) => {
		if(value) {
			this.setState({
				[property] : 
					startdate ?
						value.tz('America/Boise').startOf('day') :
						value.tz('America/Boise').startOf('day')
			});
		} else {
			this.setState({
				[property] : null,
			});
		}
	}

	changeTriggerDatePopup = (property) => {
		this.setState({
			[property] : !this.state[property]
		});
	}

	onFetchData = (state, instance) => {
		this.setState({ loading: true });

		const date = [];
		const {
			in_start_date,
			in_end_date,
			out_start_date,
			out_end_date,
		} = this.state;

		in_start_date && date.push({ id: 'in_start_date', value: in_start_date.valueOf() });
		in_end_date && date.push({ id: 'in_end_date', value: in_end_date.valueOf() });
		out_start_date && date.push({ id: 'out_start_date', value: out_start_date.valueOf() });
		out_end_date && date.push({ id: 'out_end_date', value: out_end_date.valueOf() });

		this.props.client.query({
			query: queryGetUserTimecardTable,
			variables: {
				limit: state.pageSize,
				skip: state.page * state.pageSize,
				sortBy: state.sorted,
				filtered: [
					...state.filtered,
					...date,
				]
			},
			fetchPolicy: 'network-only',
		})
			.then((data) => {
				console.log('I am here', data);
				this.setState({
					loading: false,
					data: _.get(data, 'data.getUserTimecards.data', []),
					count: _.get(data, 'data.getUserTimecards.count', 0),
					pages: Math.ceil( _.get(data, 'data.getUserTimecards.count', 0) / state.pageSize)
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const { loading, pages, data, pageSize } = this.state;

		return (
			<div>
				<ReactTable
					data={data}
					columns={columns({
						state: this.state,
						changeDate: this.changeDate,
						triggerDatePopup: this.changeTriggerDatePopup,
					})}
					pageSizeOptions={[pageSize]}
					pages={pages}
					manual
					loading={loading}
					defaultPageSize={pageSize}
					onFetchData={this.onFetchData}
				/>
				{/* {JSON.stringify(this.props)} */}
			</div>
		);
	}
}

export default compose(
	withApollo
)(ViewHistory);

// export default compose(
// 	graphql(queryGetUserTimecardTable)
// )(ViewHistory);