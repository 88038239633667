import React, { Component } from 'react';
import { Card, Image } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import queryGetTraining from '../../queries/getTraining';
import classnames from 'classnames';


class TrainingItem extends Component {

	onVideoClick = () => {
		const { id } = this.props.data.getTraining;
		this.props.onSelection && this.props.onSelection(id);
	}

	render() {
		
		if(this.props.data.loading) {
			return <div></div>;
		}

		if(!this.props.data.getTraining) {
			return <div></div>;
		}
		
		const { created, createdBy, description, id, title, type, youtubeID } = this.props.data.getTraining;
		const { selected, disabled } = this.props;
		
		const classes = classnames({
			'training__item__selected': selected,
			'training__item__disabled': disabled,
		});

		if(!this.props.disabled) {
			return (
				<Card onClick={this.onVideoClick} className={classes}>
					<Image src={`https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`} />
					<Card.Content>
						<h3>{title}</h3>
					</Card.Content>
				</Card>
			);
		} else {
			return (
				<Card className={classes}>
					<Image src={`https://img.youtube.com/vi/${youtubeID}/hqdefault.jpg`} />
					<Card.Content>
						<h3>{title}</h3>
					</Card.Content>
				</Card>
			);
		}
	}
}

export default compose (
	graphql(queryGetTraining, {
		options: (props) => { return { variables: { id: props.id } }; }
	}),
)(TrainingItem);