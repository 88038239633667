import React, { Component } from 'react';
import { Divider } from 'semantic-ui-react';
import ContactSupport from './ContactSupport';

class Support extends Component {
	render() {
		return(
			<div style={{marginTop: '10px'}} className="animated fadetwoIn flex-container flex-column">
				<h1 style={{textAlign: 'center'}}>Support</h1>
				<p style={{textAlign: 'center'}}>
					Welcome to the support page! <br />
					Here you can request help from our tech team if you are having a technical issue.
				</p>
				<Divider />
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<ContactSupport />
				</div>
			</div>
		);
	}
}

export default Support;
