import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Loading from '../../../Loading';
import { Menu, Segment } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import ToolsTab from './ToolsTab';
import _ from 'lodash';
import queryGetUserAdmin from '../../../../queries/getUserAdmin';
import Tree from '../../../Tree/';
import BasicInfoTab from './BasicInfoTab';
import Sales from '../../../Office/Sales/Sales';
import W9Tab from './W9Tab';
import PaymentInfoTab from './PaymentInfoTab';
import AddressPage from './AddressPage';
import OrderTable from '../../../OrderTable/OrderTable';

class UserInfo extends Component {
	state = {
		loading: false,
		data: null,
		activeItem: 'binfo',
		deleteSaleLoading: false,
		deleteSaleModalOpen: false,
	}

	handleItemClick = (value) => {
		this.setState({ activeItem: value });
	}

	onSuccessTemp = () => {
		this.props.data.refetch({ id: this.props.data.getUserAdmin.id });
	}

	renderTools = () => {
		if(this.state.activeItem === 'tools') {
			return <ToolsTab user={this.props.data.getUserAdmin} />;
		}
	}

	renderTreeGraph = () => {
		if(this.state.activeItem === 'treegraph') {
			let x = document.querySelector('.ui.modal').clientWidth;
			x -= ((15+21)*2);
			x /= 2;
			return(
				<div style={{ minHeight: '500px', display: 'flex' }}>
					<Tree id={this.props.id} x={x} />
				</div>
			);
		}
	}

	renderSaleInfo() {
		if(this.state.activeItem === 'saleinfo') {
			return(
				<div>
					{/* <Sales id={this.props.id} fixedHeight={300} /> */}
					<OrderTable
						fixedParams={[
							{ id: 'referenceID', value: this.props.id },
						]}
						hideCols={['reference']}
					/>
				</div>
			);
		}
	}

	renderBasicInfo = () => {
		if(this.state.activeItem === 'binfo') {
			return (
				<BasicInfoTab user={this.props.data.getUserAdmin} onSuccessTemp={this.onSuccessTemp} />
			);
		}
	}

	renderW9Info = () => {
		if(this.state.activeItem === 'w9') {
			return (
				<W9Tab user={this.props.data.getUserAdmin} />
			);
		}
	}

	renderPaymentInfo = () => {
		if(this.state.activeItem === 'payment') {
			return (
				<PaymentInfoTab user={this.props.data.getUserAdmin} />
			);
		}
	}

	render = () => {
		const { activeItem } = this.state;

		if(this.props.data.loading) {
			return(<Loading />);
		}

		return(
			<div>
				<Menu attached="top" className="scrollMenu">
					<Menu.Item name='Basic Info' active={activeItem === 'binfo'} onClick={() => this.handleItemClick('binfo')} />
					{ /* Tabs for Representatives */
						_.get(this.props, 'data.getUserAdmin.accountType', 0) > 0 &&
						(
							<Fragment>
								<Menu.Item name='Sale Info' active={activeItem === 'saleinfo'} onClick={() => this.handleItemClick('saleinfo')} />
								<Menu.Item name='Tree Graph' active={activeItem === 'treegraph'} onClick={() => this.handleItemClick('treegraph')} />
							</Fragment>
						)
					}
					{ /* Tabs for Representatives || Influencer */
						(_.get(this.props, 'data.getUserAdmin.accountType', 0) > 0 || _.get(this.props, 'data.getUserAdmin.influencer', false))  &&
						(
							<Fragment>
								<Menu.Item name='W9 Info' active={activeItem === 'w9'} onClick={() => this.handleItemClick('w9')} />
								<Menu.Item name='Payment Info' active={activeItem === 'payment'} onClick={() => this.handleItemClick('payment')} />
							</Fragment>
						)
					}
					<Menu.Item name='Address' active={activeItem === 'address'} onClick={() => this.handleItemClick('address')} />
					<Menu.Item name='Tools' active={activeItem === 'tools'} onClick={() => this.handleItemClick('tools')} />
				</Menu>

				<Segment attached='bottom'>
					{this.renderBasicInfo()}
					{this.renderTreeGraph()}
					{this.renderSaleInfo()}
					{this.renderW9Info()}
					{this.renderPaymentInfo()}
					{this.renderTools()}
					{this.state.activeItem === 'address' && <AddressPage user={this.props.data.getUserAdmin} />}
				</Segment>
			</div>
		);
	}
}

UserInfo.propTypes = {
	id: PropTypes.string.isRequired,
};

export default graphql(queryGetUserAdmin, {
	options: (props) => {
		return {
			variables: {
				id: props.id
			}
		};
	}
})(UserInfo);
