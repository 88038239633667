/* eslint no-console: 0 */
import { CONNECT_SOCKET, DISCONNECT_SOCKET } from '../actions/types';

export default function(state = null, action) {
	switch (action.type) {
	case CONNECT_SOCKET:
		console.log('Connecting Socket');
		return action.payload;
	case DISCONNECT_SOCKET:
		console.log('Disconnecting Socket');
		if(state) {
			state.disconnect();
		}
		return null;
	default:
		return state;
	}
}
