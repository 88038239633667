import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../../queries/user';
import Loading from '../../Loading';
import _ from 'lodash';
import OrderContentAdmin from './OrderContentAdmin';
import OrderContentRep from './OrderContentRep';

const OrderDetails = ({ id, onClose }) => {
	const { data, loading } = useQuery(QUERY_CURRENT_USER);

	let Content = <div>There was a problem</div>;

	if(loading) {
		Content = <Loading />;
	}

	const { accountType, adminLevel } = _.get(data, 'user', { accountType: 0, adminLevel: 0 });

	if(accountType >= 1 && accountType < 10) {
		Content = <OrderContentRep {...{ accountType, adminLevel, id }} />;
	}

	if(accountType >= 10) {
		Content = <OrderContentAdmin {...{ accountType, adminLevel, id }} />;
	}

	return (
		<Modal
			open={!!id}
			onClose={onClose}
			header={<Modal.Header>Order Details</Modal.Header>}
			content={<Modal.Content>{Content}</Modal.Content>}
			closeIcon
			closeOnDimmerClick={false}
		/>
	);
};

export default OrderDetails;