import React from 'react';
import { Button } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_SEND_EDUCATOR_WELCOME_EMAIL from '../../../../../mutations/sendEducatorWelcomeEmail';
import GraphQLError from '../../../../Message/GraphQLError';

const SendEducatorWelcomeEmail = ({ userID }) => {

	const [sendEmail, { data, loading, error}] = useMutation(MUTATION_SEND_EDUCATOR_WELCOME_EMAIL, {
		variables: { userID }
	});

	React.useEffect(() => {
		if(data) {
			if(data.sendEducatorWelcomeEmail) {
				alert('Email Sent!');
			}
		}
	}, [data]);

	return (
		<div>
			<GraphQLError error={error}>Could not send email</GraphQLError>
			<Button
				content="Send Educator Welcome Email"
				loading={loading}
				onClick={() => sendEmail()}
			/>
		</div>
	);
};

export default SendEducatorWelcomeEmail;