import React from 'react';
import _ from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import { Modal, Button, Divider, Segment, Form } from 'semantic-ui-react';
import UserInfo from '../../Users/UserInfo';
import styles from './EditMembership.module.scss';
import GraphQLError from '../../../Message/GraphQLError';
import UserField from '../../../FinalForm/UserField';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CHANGE_MEMBERSHIP_ACCOUNT from '../../../../mutations/changeMembershipAccount';
import QUERY_GET_MEMBERSHIP from '../../../../queries/getMembership';

const ChangeAccount = ({ membership }) => {
	const [changeAccount, { loading, error }] = useMutation(MUTATION_CHANGE_MEMBERSHIP_ACCOUNT);

	const onChangeAccount = ({ user }) => {
		if(user) {
			changeAccount({
				variables: {
					membershipID: membership.id,
					accountID: user,
				},
				refetchQueries: [{
					query: QUERY_GET_MEMBERSHIP,
					variables: { id: membership.id },
				}],
			});
		}
	};

	return (
		<FinalForm
			onSubmit={onChangeAccount}
			render={({ handleSubmit }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<GraphQLError error={error}>There was an issue</GraphQLError>
					<Form.Group widths='equal'>
						<UserField
							name='user'
							placeholder="Search for user by name"
							label="Search for a user by name"
							required
						/>
					</Form.Group>
					<Button fluid type='submit'>Change Membership Account</Button>
				</Form>
			)}
		/>
	);
};

const AccountInfo = ({ membership }) => {
	const account = _.get(membership, 'customer', {});
	const id = _.get(membership, 'customer.id');
	const [modal, setModal] = React.useState(false);

	return (
		<div>
			<Modal open={modal} onClose={() => setModal(false)} closeIcon>
				<Modal.Header>{`User - ${id}`}</Modal.Header>
				<Modal.Content>
					<UserInfo id={id} />
				</Modal.Content>
			</Modal>
			<Segment placeholder className={styles.membership}>
				<span>
					<h4>Membership Belongs to:</h4>
					<p onClick={() => setModal(true)}>{account.firstname} {account.lastname}</p>
					<h5>Click the name to view the User</h5>
				</span>
			</Segment>
			<Divider horizontal>CHANGE ACCOUNT OWNER</Divider>
			<ChangeAccount membership={membership} />
		</div>
	);
};

const Account = ({ membership }) => {
	const accountID = _.get(membership, 'customer.id');

	if(accountID) {
		return <AccountInfo membership={membership} />;
	}
	
	return <ChangeAccount membership={membership} />;
};



export default Account;