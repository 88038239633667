const plans = {
	0: {
		name: 'Individual',
		price: 49.95,
		priceText: '$49.95',
		id: 0,
		isFamily: false,
	},
	1: {
		name: 'Family',
		price: 99.90,
		priceText: '$99.90',
		id: 1,
		isFamily: true,
	},
	3: {
		name: 'Individual Card - Corporate',
		price: 29.95,
		priceText: '$29.95',
		id: 3,
		isFamily: false,
	},
	4: {
		name: 'Family Card - Corporate',
		price: 49.90,
		priceText: '$49.90',
		id: 4,
		isFamily: true,
	},
	5: {
		name: 'Group Corporate Plan',
		id: 5,
	},
	6: {
		name: 'Business Plan - Basic',
		price: 49.95,
		priceText: '$49.95',
		id: 6,
		// isFamily: true,
	},
	7: {
		name: 'Business Plan - Premium',
		price: 99.90,
		priceText: '$99.90',
		id: 7,
		// isFamily: true,
	},
	8: {
		name: 'Basic Networking Membership',
		price: 99.90,
		priceText: '$99.90',
		id: 8,
	},
	9: {
		name: 'Premier Networking Membership',
		price: 199.80,
		priceText: '$199.80',
		id: 9,
	},
	basicSocialMedia: {
		id: 'basicSocialMedia',
		price: 49.95,
		name: 'Personal Social Media Post',
	},
	premiumSocialMedia: {
		id: 'premiumSocialMedia',
		price: 49.95,
		name: 'Personal Podcast',
	},
	presentationFee: {
		id: 'presentationFee',
		price: 49.95,
		name: 'Personal Presentation',
	},
	localListing: {
		id: 'localListing',
		price: 49.95,
		name: 'Local Listing',
	},
	nationalListing: {
		id: 'nationalListing',
		price: 49.95,
		name: 'Focal Listing',
	},
	physicalCard: {
		id: 'physicalCard',
		price: 10.00,
		name: 'Physical Card',
	},
};

export default plans;
