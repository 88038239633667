import React from 'react';
import { Field } from 'react-final-form';
import { Form, Input, Popup, Button, Icon } from 'semantic-ui-react';


const FSTextInput = ({
	validateFields,
	placeholder,
	name,
	label,
	required,
	type,
	validate,
	action,
	popup,
	popupProps={},
	icon,
	iconPosition = 'right',
	actionText,
	fontAwesomeIcon,
	size,
	onBlur=()=>{},
	onChange,
	disabled
}) => {
	return (
		<Field name={name} validate={validate} validateFields={validateFields}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					{popup &&
						<Popup
							trigger={<Input name={name} type={type || 'text'} placeholder={placeholder} { ...input } action={action} />}
							on='focus'
							{ ...popupProps }
						/>
					}
					{!popup && (
						<Input name={name} type={type || 'text'} placeholder={placeholder} action={action}  iconPosition={iconPosition} size={size} disabled={disabled}>
							{icon && <Icon name={icon}/> }
							{fontAwesomeIcon && fontAwesomeIcon}
							<input
								{ ...input }
								onChange={(i) => input.onChange(onChange ? onChange(i.target.value) : i.target.value)}
								onBlur={(e) => { input.onBlur(e); onBlur(e); }}
								type={type || 'text'}
							/>
							{action && <Button type='submit'>{actionText || 'Submit'}</Button>}
						</Input>
					)}
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSTextInput;