/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import withStateMutation from '../../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import GraphQLError from '../../../Message/GraphQLError';
import queryGetStore from '../../../../queries/getStore';
import TextAreaInput from '../../../FinalForm/TextAreaInput';
import Checkbox from '../../../FinalForm/Checkbox';
import DateInput from '../../../FinalForm/DateInput';
import moment from 'moment';
import modifyPromotionMutation from '../../../../mutations/modifyPromotion';

class ModifyPromotionPopup extends Component {

	renderText = (property, value) => {
		const { textarea, checkbox, date } = this.props;
		if(checkbox) {
			return <span className="changeInformation__value">{value.toString() || 'false'}</span>;
		} else if (date) {
			let text = 'N/A';
			if(value) {
				text = (new Date(value)).toDateString();
			}
			return <span className="changeInformation__value">{text}</span>;
		}
		return (
			<span className="changeInformation__value">{value || 'N/A'}</span>
		);
	}

	onSubmit = (values) => {
		values[this.props.property] = values[this.props.property] || '';
		this.props.mutate({
			variables: {
				[this.props.property]: '',
				...values,
				'property': this.props.property,
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	renderType = (property) => {
		const { textarea, checkbox, date } = this.props;
		if(textarea) {
			return <TextAreaInput name={property} placeholder="New Value" label="New Value" />;
		}  else if(checkbox) {
			return <Checkbox name={property} placeholder="New Value" label="New Value" />;
		} else if(date) {
			return <DateInput name={property} placeholder="New Value" label="New Value" />;
		}
		return <TextInput name={property} placeholder="New Value" label="New Value" />;
	}

	renderForm = (property, value) => {
		const { textarea } = this.props;
		if(this.props.date) {
			value = new moment(value);
		}
		return(
			<FinalForm
				onSubmit={this.onSubmit}
				initialValues={{ [property] : value, id: this.props.parentid, promotionID: this.props.id }}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							{this.renderType(property)}
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						<Button type="submit">Submit Changes</Button>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</Form>
				)}
			/>
		);
	}

	renderField = (property, value) => {
		return  <Popup
			trigger={this.renderText(property, value)}
			content={this.renderForm(property, value)}
			on='click'
			position='top right'
		/>;
	}

	render() {
		const { children, property } = this.props;
		const redone = _.get(this.props, 'value', '') || '';
		return (
			<p><strong>{children}:</strong>{this.renderField(property, redone)}</p>
		);
	}
}

export default compose(
	graphql(modifyPromotionMutation),
	withStateMutation()
)(ModifyPromotionPopup);