/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import ModifyPromotionPopup from './ModifyPromotionPopup';
import ChangeFile from './ChangeFile';
import { Button, Table, Segment } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import queryGetStore from '../../../../queries/getStore';
import deletePromotionMutation from '../../../../mutations/deletePromotion';
import { ChangeTextFieldPromotion, ChangeSelectFieldPromotion, ChangeDateFieldPromotion, ChangeTextAreaPromotion, ChangeRichTextPromotion } from '../ManageStores/PromotionChangeField';
import moment from 'moment';

class Promotion extends Component {

	delete = () => {
		this.props.mutate({
			variables: {
				id: this.props.parentid,
				promotionID: this.props.data.id
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	refetchQueries = () => {
		return [{
			query: queryGetStore,
			variables: { id: this.props.parentid }
		}];
	}

	render() {
		const { index, parentid } = this.props;
		const { id, additionalURL, code, file, info, online, url, validUntil } = this.props.data;
		const refetch = this.refetchQueries();

		return (
			<div className="store__location changeInformation">
				<Table striped definition attached='top'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<h3>Promotion #{index}</h3>
							</Table.HeaderCell>
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>

						<ChangeTextFieldPromotion
							label="URL"
							value={url}
							property='url'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ promotionID: id }}
							table
						/>

						<ChangeTextFieldPromotion
							label="Additional URL"
							value={additionalURL}
							property='additionalURL'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ promotionID: id }}
							table
						/>

						<ChangeTextFieldPromotion
							label="Promo Code"
							value={code}
							property='code'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ promotionID: id }}
							table
						/>

						<ChangeSelectFieldPromotion
							label={'Online Deal'}
							value={online}
							property={'online'}
							refetch={refetch}
							id={parentid}
							options={TypeOptions}
							additionalProperties={{ promotionID: id }}
							table
						/>

						<ChangeDateFieldPromotion
							label={'Valid Until'}
							value={validUntil ? moment(validUntil) : moment()}
							text={validUntil ? moment(validUntil).format('MMM Do YYYY') : 'N/A'}
							property={'validUntil'}
							refetch={refetch}
							id={parentid}
							additionalProperties={{ promotionID: id }}
							table
						/>


						<ChangeRichTextPromotion
							label="Description"
							value={info}
							property='info'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ promotionID: id }}
							table
						/>

						<ChangeFile value={file} property='file' id={id} parentid={parentid} file>File</ChangeFile>
					</Table.Body>
				</Table>

				<Segment attached>
					<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button color ="red" onClick={this.delete}>Delete</Button>
					</span>
				</Segment>
				
			</div>
		);
	}
}

const TypeOptions = [
	{ label: 'True', value: true },
	{ label: 'False', value: false },
];

export default graphql(deletePromotionMutation)(Promotion);