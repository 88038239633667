import gql from 'graphql-tag';


const QUERY_GET_MEMBERSHIP_FROM_BUSINESS_PLAN = gql`

	query GetMembershipFromBusinessPlan($businessPlanID: String!, $membershipID: String!) {
		getMembershipFromBusinessPlan(businessPlanID: $businessPlanID, membershipID: $membershipID) {
			id
			customer {
				id
				firstname
				lastname
				email
			}
			plan
			membershipID
			firstname
			lastname
			additionalFamilyMembers {
				name
			}
			spouseFirstName
			spouseLastName
		}
	}

`;



export default QUERY_GET_MEMBERSHIP_FROM_BUSINESS_PLAN;