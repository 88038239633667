import React from 'react';
import { Table } from 'semantic-ui-react';

const DetailsRow = ({ property, value, condition=true, html }) => {
	if(!condition) { return null; }

	return (
		<Table.Row>
			<Table.Cell>{property}</Table.Cell>
			<Table.Cell>{html ? <div dangerouslySetInnerHTML={{__html: value}} className="ql-editor no-con" /> : value}</Table.Cell>
		</Table.Row>
	);
};

export default DetailsRow;