import React, { Component } from 'react';
import { Popup, Button, Header, Form, Input } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';

const InputField = ({ label, input, placeholder, meta: { touched, error } }) => (
	<Form.Field required error={touched && !!error}>
		<label>{label || ''}</label>
		<Input placeholder={placeholder} {...input} action>
			<input />
			<Button type="submit">Change</Button>
		</Input>
		<p>{touched && error}</p>
	</Form.Field>
);


class ChangePopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	async handleSubmit(values) {
		const url = this.props.type === 0 ? '/api/admin/changename' : '/api/admin/changenamesale';
		this.setState({loading: true});
		const v = {};
		v[this.props.field] = values.setting.trim();
		v.id = this.props.fid;
		try {
			await axios.post(url, v);
			if(this.props.onSuccess) {this.props.onSuccess();}
			this.setState({loading: false});
		} catch (error) {
			this.setState({loading: false});
		}

	}

	renderPopup() {
		const {loading} = this.state;

		return(
			<div>
				<Form loading={loading} onSubmit={this.props.handleSubmit(values => this.handleSubmit(values))}>
					<Field component={InputField} name="setting" placeholder="Enter Changed Value" label="New Value"/>
				</Form>
			</div>
		);
	}

	renderText() {
		return(
			<Header color="blue" className="change-popup-header" as='h4'>{this.props.default || 'N/A'}</Header>
		);
	}

	setDefault() {
		this.props.change('setting', this.props.default);
	}

	render() {
		return(
			<Popup
				trigger={this.renderText()}
				content={this.renderPopup()}
				on='click'
				flowing
				onOpen={() => this.setDefault()}
			/>
		);
	}
}

function validate(form) {
	const errors = {};

	if(!form.setting) {
		errors.setting = 'Required Field';
	}

	return errors;
}

ChangePopup = reduxForm({
	validate,
	form: 'changePopupForm'
})(ChangePopup);

export default ChangePopup;