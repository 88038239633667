import React, { useState } from 'react';
import D3Tree from 'react-d3-tree';
import _ from 'lodash';
import Loading from '../Loading';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_TREE_GRAPH_DATA from '../../queries/getTreeGraphData';
import UserInformationModal from './UserInformationModal';

function parseDownline(downline) {
	if (downline && downline.length > 0) {
		return downline.map(user => ({
			dataID: user.id,
			name: `${user.firstname} ${user.lastname}`,
			isEligible: _.get(user, 'SalesInfo.Commission.isEligible'),
			children: parseDownline(user.downline),
			currentMonthSales: _.get(user, 'currentMonthSales', 0) || 0,
		}));
	}
	return null;
}

const NodeLabel = ({ nodeData, onClick }) => {
	console.log(nodeData);
	let style = {};

	if(!nodeData.isEligible) {
		style.color = '#d63031';
	} else if (nodeData.currentMonthSales > 49) {
		style.color = '#27ae60';
	}

	return (
		<p 
			className="treegraph__p"
			onClick={() => onClick(nodeData)}
			style={style}
		>
			{nodeData.name}
		</p>
	);
};

const TreeGraph = ({ id, x = 0, style={} }) => {
	const date = new Date();
	const { data, loading } = useQuery(QUERY_GET_TREE_GRAPH_DATA, {
		variables: {
			id,
			month: date.getMonth(),
			year: date.getFullYear(),
		},
	});

	const [modalInformation, setModalInformation] = useState(null);

	const treeData = React.useMemo(() => {
		const initialUser = _.get(data, 'getTreeGraphData');
		if (initialUser) {
			return [{
				dataID: initialUser.id,
				name: `${initialUser.firstname} ${initialUser.lastname}`,
				children: parseDownline(initialUser.downline),
				isEligible: _.get(initialUser, 'SalesInfo.Commission.isEligible'),
				currentMonthSales: _.get(initialUser, 'currentMonthSales', 0) || 0,
			}];
		}
		return [];
	}, [data]);

	if (loading) {
		return <Loading /> ;
	}

	return (
		<div style ={{ flex: 1, ...style }}>
			<UserInformationModal info={modalInformation} onClose={() => setModalInformation(false)}/>
			<D3Tree
				data={treeData}
				orientation="vertical"
				pathFunc="step"
				nodeSize={{
					x: 200,
					y: 140,
				}}
				translate={{
					x,
					y: 25,
				}}
				allowForeignObjects={true}
				nodeLabelComponent={{
					render: <NodeLabel onClick={info => setModalInformation(info)} />,
					foreignObjectWrapper: {
						style: {
							overflow: 'visible',
							transform: 'translate(15px, -10px)',
						},
					},
				}}
				collapsible={false}
				scaleExtent={{
					min: 0.1,
					max: 1.5,
				}}
				styles={{
					nodes: {
						node: {
							circle: {
								fill: '#74b9ff',
								strokeWidth: 1,
							},
						},
						leafNode: {
							circle: {
								fill: '#bdc3c7',
								strokeWidth: 1,
							},
						},
					}
				}}
			/>
		</div>
	);
};

export default TreeGraph;