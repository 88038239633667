import React, { Component } from 'react';
import { Popup, Button, Header, Form } from 'semantic-ui-react';
import axios from 'axios';
import { ReferenceField, ReferenceFieldAsyncValidate } from '../../Form/ReferenceField';
import { reduxForm, Field } from 'redux-form';

const types = [
	{
		link: '/api/admin/changeparent',
	},
	{
		link: '/api/admin/changesalereference',
	},
];

class ChangeParent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			loading: false,
			searchQuery: null,
			options: [],
			value: null,
			open: false,
		};

	}

	changeServerValues = async (v) => {
		this.setState({ loading: true });
		const values = {
			id: this.props.id,
			parent: v.reference
		};

		if(!values.parent || values.parent === values.id) {
			return;
		}

		console.log('Changing...');

		try {
			await axios.post(types[this.props.type || 0].link, values);
			console.log('Done');
			this.setState({ open: false, loading: false });
			this.props.onSuccess && this.props.onSuccess();
		} catch (error) {
			return;
		}
	}

	changeServerValuesNull = async () => {
		this.setState({loading: true});
		const values = {
			id: this.props.id,
			parent: null
		};

		try {
			await axios.post(types[this.props.type || 0].link, values);
			this.setState({open: false,loading: false});
			this.props.onSuccess && this.props.onSuccess();
		} catch (error) {
			return;
		}
	}

	renderPopup = () => {
		return(
			<div>
				<Form onSubmit={this.props.handleSubmit(values => this.changeServerValues(values))} loading={this.state.loading}>
					<Field name="reference" component={ReferenceField} label="Reference" required />
					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<Button type='button' onClick={() => this.changeServerValuesNull()} style={{marginTop: '10px'}}>Remove Parent</Button>
						<Button type='submit' style={{marginTop: '10px'}}>Change</Button>
					</div>
				</Form>
			</div>
		);
	}

	renderText = () => {
		return(
			<Header color="blue" onClick={() => this.setState({open: !this.state.open})} style={{display: 'inline'}} as='h4'>{this.props.def || 'N/A'}</Header>
		);
	}

	render() {
		return(
			<Popup
				trigger={this.renderText()}
				content={this.renderPopup()}
				on='click'
				flowing
				open={this.state.open}
			/>
		);
	}

}

export default reduxForm({
	destroyOnUnmount: true,
	ReferenceFieldAsyncValidate,
	asyncBlurFields: ['reference'],
	form: 'changeParentForm'
})(ChangeParent);