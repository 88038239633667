import React, { Component } from 'react';
import { Segment, Button, Divider, Modal } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import ReactTable from 'react-table';
import _ from 'lodash';
import generateColumns from './cols';
import queryGetPromoCodes from '../../../queries/getPromoCodes';
import Code from './Code';
import AddPromoCode from './AddPromoCode';

// const FoldableTable = FoldableTableHOC(ReactTable);

class PCTable extends Component {
	state = {
		loading: false,
		pages: 1,
		modal: false,
	}

	fetchData = (state, instance) => {
		this.setState({ loading: true });

		this.props.data.refetch({
			limit: state.pageSize,
			skip: state.page * state.pageSize,
		}).then(data => {
			console.log(data);
			const count = _.get(this.props, 'data.getPromoCodes.count', 0);
			this.setState({ loading: false, pages: Math.ceil(count / state.pageSize) });
		});


	}

	refresh = () => {
		this.setState({ loading: true });
		this.props.data.refetch().then(() => {
			this.setState({ loading: false });
		});
	}

	addPromoCode = () => {
		this.setState({ modal: true });
	}

	handleClose = ({ created = false }) => {
		this.setState({ modal: false });
		created && this.refresh();
	}

	renderModal = () => {
		if(this.state.modal) {
			return (
				<Modal
					open={this.state.modal}
					onClose={this.handleClose}
					size='small'
					closeIcon
				>
					<Modal.Header>
						<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Create a Promo Code</h4>
					</Modal.Header>
					<Modal.Content>
						<AddPromoCode completed={() => this.handleClose({ created: true })} />
					</Modal.Content>
				</Modal>
			);
		}
	}

	render() {
		const data = _.get(this.props, 'data.getPromoCodes', []) || [];
		const { loading, pages } = this.state;
		const columns = generateColumns();
		return (
			<Segment.Group stacked>
				{this.renderModal()}
				<Segment color='grey' className="tableHeaderContainer">
					<Button basic onClick={this.addPromoCode}>
						<i className="fa fa-plus fa-lg filterIcon" style={{ color: '#ff6b6b' }} aria-hidden="true"></i>
					</Button>
					<h2>Manage Promo Codes</h2>
					<Button basic onClick={this.refresh}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>
				<Segment placeholder>
					<ReactTable
						data={data}
						columns={columns}
						pageSizeOptions={[10,15,20,50,100]}
						defaultPageSize={20}
						pages={pages}
						onFetchData={this.fetchData}
						loading={loading}
						manual
						SubComponent={row => {
							return <Code data={row} refresh={() => this.refresh()} />;
						}}
					/>
				</Segment>
			</Segment.Group>
		);
	}
}

export default graphql(queryGetPromoCodes, {
	options: () => { return {
		variables: {
			limit: 20,
			skip: 0,
		}
	};}
})(PCTable);