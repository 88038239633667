import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';

const months = [1,2,3,4,5,6,7,8,9,10,11,12];

const formStyle = {
	display: 'flex',
	flexDirection: 'row',
};

class DateSelector extends Component {
	state = {
		month: (new Date()).getMonth()+1,
		year: `${(new Date()).getFullYear()-1}`,
		yearError: false
	}

	onSubmit = (e) => {
		e.preventDefault();
		if(this.state.year.length === 4) {
			this.setState({yearError: false});
			this.props.onSubmit({
				year: this.state.year,
				month: this.state.month
			});
		} else {
			this.setState({yearError: true});
		}
	}

	onMonthChange = (e) => {
		this.setState({month: e.target.value});
	}

	onYearChange = (e) => {
		this.setState({year: e.target.value});
	}

	renderError = () => {
		if(this.state.yearError) {
			return(
				<div style={{color: '#f86c6b'}}>Invalid Year - Must be 4 digits</div>
			);
		}
	}

	render() {
		return (
			<div>
				<Form onSubmit={this.onSubmit}>
					<div style={formStyle}>
						<FormGroup className="mb-2 mr-sm-2 mb-sm-0">
							<Label for="month" className="mr-sm-2">Month </Label>
							<Input type="select" name="month" id="monthSelect" value={this.state.month} onChange={this.onMonthChange}>
								{months.map(month => <option key={month}>{month}</option>)}
							</Input>
						</FormGroup>
						<FormGroup className="mb-2 mr-sm-2 mb-sm-0">
							<Label for="year" className="mr-sm-2">Year </Label>
							<Input type='text' placeholder="YYYY" name="year" value={this.state.year} onChange={this.onYearChange} invalid={this.state.yearError} />
						</FormGroup>
						<span>
							<div style={{height: '50%'}}></div>
							<Button style={{height: '50%'}}>Submit</Button>
						</span>
					</div>
					{this.renderError()}
				</Form>
			</div>
		);
	}
}

export default DateSelector;