import ChangeTextField from '../../../../ChangeField/ChangeTextField';
import ChangeSelectField from '../../../../ChangeField/ChangeSelectField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../../queries/withStateMutation';
// import ChangeDateField from '../../../../ChangeField/ChangeDateField';
import MUTATION_MODIFY_MEMBERSHIP from '../../../../../mutations/modifyMembership';

export const ChangeTextFieldMembership = compose(
	graphql(MUTATION_MODIFY_MEMBERSHIP),
	withStateMutation(),
)(ChangeTextField);

export const ChangeSelectFieldMembership = compose(
	graphql(MUTATION_MODIFY_MEMBERSHIP),
	withStateMutation(),
)(ChangeSelectField);

// export const ChangeDateFieldMembership = compose(
// 	graphql(modifyMembershipMutation),
// 	withStateMutation(),
// )(ChangeDateField);