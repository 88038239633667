/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';

// object
// currency
// livemode

// created
// name
// redeem_by

const columns = () => [
	{
		Header: 'Nickname',
		id: 'name',
		minWidth: 150,
		accessor: d => d.name ? d.name : d.id,
		foldable: true,
	},
	{
		Header: 'Code',
		id: 'id',
		minWidth: 150,
		accessor: d => `${d.id}`,
		foldable: true,
	},
	{
		Header: 'Valid',
		id: 'valid',
		minWidth: 80,
		accessor: d => `${d.valid || ''}`,
		foldable: true,
	},
	{
		Header: 'Amount OFF',
		id: 'amount_off',
		minWidth: 100,
		accessor: d => d.amount_off ? `$${(d.amount_off/100).toFixed(2)}` : '',
		foldable: true,
	},
	{
		Header: 'Percent OFF',
		id: 'percent_off',
		minWidth: 75,
		accessor: d => d.percent_off ? `${d.percent_off}%` : '',
		foldable: true,
	},
	{
		Header: 'Duration',
		id: 'duration',
		minWidth: 150,
		accessor: d => `${d.duration || ''}`,
		foldable: true,
	},
	{
		Header: 'Duration in Months',
		id: 'duration_in_months',
		minWidth: 150,
		accessor: d => `${d.duration_in_months || ''}`,
		foldable: true,
	},
	{
		Header: 'Times Redeemed',
		id: 'times_redeemed',
		minWidth: 150,
		accessor: d => `${d.times_redeemed || 0}`,
		foldable: true,
	},
	{
		Header: 'Max Redemptions',
		id: 'max_redemptions',
		minWidth: 150,
		accessor: d => `${d.max_redemptions || ''}`,
		foldable: true,
	},
	{
		Header: 'Created',
		id: 'created',
		minWidth: 150,
		accessor: d => d.created ? `${moment(d.created).format('MMM Do YYYY')}` : 'N/A',
		foldable: true,
	},
	{
		Header: 'Redeem By',
		id: 'redeem_by',
		minWidth: 150,
		accessor: d => d.redeem_by ? `${moment(d.redeem_by).format('MMM Do YYYY')}` : '',
		foldable: true,
	},
];

export default columns;