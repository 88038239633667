import React from 'react';


const Page401 = () => {
	return(
		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px', flex: '1'}}>
			<div style={{textAlign: 'center'}}>
				<h1>401</h1>
				<h2>You are not authorized to view this page.</h2>
			</div>
		</div>
	);
};

export default Page401;
