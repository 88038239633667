import React from 'react';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

const DetailsRow = ({ property, value, condition=true, date=false }) => {
	if(!condition) { return null; }

	let fValue = value;

	if(date) {
		fValue = value ? moment(value).format('MMMM Do YYYY, h:mm:ss a') : 'N/A';
	}

	return (
		<Table.Row>
			<Table.Cell>{property}</Table.Cell>
			<Table.Cell>{fValue}</Table.Cell>
		</Table.Row>
	);
};

export default DetailsRow;