import React, { Fragment, useState } from 'react';
import { Container, Header, Card, Divider, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckoutForm from './CheckoutForm';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Switch, Route } from 'react-router-dom';
import OrderConfirmation from './OrderConfirmation';

const plans = {
	0: {
		name: 'Individual',
		type: 0,
		sell_path: '/tools/sell/individual',
		price: 49.95,
		img: require('./sell_individual_plan.jpg'),
	},
	1: {
		name: 'Family',
		type: 1,
		sell_path: '/tools/sell/family',
		price: 99.90,
		img: require('./sell_family_plan.jpg'),
	},
};

const SelectMembership = ({ history }) => {
	return (
		<Fragment>
			<Header as='h1' textAlign="center">Sell a Membership</Header>
			<p style={{ textAlign: 'center' }}>
					Here, you can sell a membership to someone.<br />
					They can also buy one themselves from <a href="https://deluxenetworkprogram.com/pricing">our front website</a><br />
					Corporate Plans should be bought from the front website
			</p>
			<Divider horizontal>Select a Membership to Sell</Divider>

			<Card.Group centered>
				<Card raised style={{ borderRadius: '1px' }} centered>
					<Card.Content textAlign='center'>
						<FontAwesomeIcon icon={['fas', 'user-alt']} size='4x' color='#0984e3' />
						<Card.Header style={{ margin: '5px 0' }}>Individual Plan</Card.Header>
						<Card.Meta style={{ fontSize: '20px' }}>$49.95</Card.Meta>
					</Card.Content>
					<Card.Content>
						<Card.Description>
							<ul className="buy-card-features" style={{ fontSize: '15px', margin: '0 0 0 10px', }}>
								<li>Hundreds of locations to save at, nationally and locally</li>
								<li>Includes one person.</li>
							</ul>
						</Card.Description>
					</Card.Content>
					<Card.Content style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
						<Button onClick={() => history.push(plans[0].sell_path)}>Sell Individual Card</Button>
					</Card.Content>
				</Card>

				<Card raised style={{ borderRadius: '1px' }} centered>
					<Card.Content textAlign='center'>
						<FontAwesomeIcon icon={['fas', 'users']} size='4x' color='#f64f64' />
						<Card.Header style={{ margin: '5px 0' }}>Family Plan</Card.Header>
						<Card.Meta style={{ fontSize: '20px' }}>$49.95 x 2</Card.Meta>
					</Card.Content>
					<Card.Content>
						<Card.Description>
							<ul className="buy-card-features" style={{ fontSize: '15px', margin: '0 0 0 10px', }}>
								<li>Sold as two individual plans.</li>
								<li>Second Plan includes spouse and children under 18.</li>
							</ul>
						</Card.Description>
					</Card.Content>
					<Card.Content style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
						<Button onClick={() => history.push(plans[1].sell_path)}>Sell Family Card</Button>
					</Card.Content>
				</Card>
			</Card.Group>
		</Fragment>
	);
};

// const key = selectedPlan === 'test' ? process.env.REACT_APP_STRIPE_TEST_KEY : process.env.REACT_APP_STRIPE_KEY;

const SellIndividual = ({ history }) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<CheckoutForm plan={plans[0]} history={history} />
			</Elements>
		</StripeProvider>
	);
};

const SellFamily = ({ history }) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<CheckoutForm plan={plans[1]} history={history} />
			</Elements>
		</StripeProvider>
	);
};

const SellMemberships = () => {
	return (
		<Container style={{ marginTop: '20px' }}>
			<Switch>
				<Route exact path="/tools/sell" component={SelectMembership} />
				<Route exact path="/tools/sell/individual" component={SellIndividual} />
				<Route exact path="/tools/sell/family" component={SellFamily} />
				<Route exact path="/tools/sell/confirmation" component={OrderConfirmation} />
			</Switch>
		</Container>
	);
};

export default SellMemberships;