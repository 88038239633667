import gql from 'graphql-tag';

const QUERY_GET_STORES = gql`
	query GetStores($parent: String, $skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getStores(parent: $parent, skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				businessname
				type
				premier
				category
				promotionsCount
				nationalLocation
				storeLocationsCount
				promotions {
					id
				}
			}
		}
	}
`;

export default QUERY_GET_STORES;