import React from 'react';


const ShippingAddress = ({firstname, lastname, address: { name, street1, street2, city, state, zip }} ) => {
	return(
		<div>
			<p>
				{name || `${firstname} ${lastname}`}<br />
				{street1} <br />
				{street2 && street2}{street2 && <br/>}
				{city} {state} {zip}
			</p>
			
		</div>
	);
};

export default ShippingAddress;