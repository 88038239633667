const businessGroups = [
  {
    name: 'Health Care',
    items: [
      'Family Practitioner',
      'Paediatrician',
      'Podiatrist',
      'Eye Doctor',
      'Physical Therapy',
      'Occupational Therapy',
      'Speech Therapy',
      'Naturopath',
      'Dentist',
      'Orthodontist',
      'Oral Surgeon',
      'Endodontist',
      'Pediatric Dentist',
      'Orthopaedic',
      'Chiropractor',
      'Acupuncturist',
      'Infertility Treatment',
      'Weight Management',
      'Audiology',
      'Hearing Services',
      'Nursing Facilities',
      'Psychologist',
      'Counselling Services',
      'Other',
    ],
  },
  {
    name: 'Auto',
    items: [
      'Used Car Sales',
      'New Car Dealer/Type',
      'Auto Service',
      'Tire Service',
      'Auto Body Repair',
      'Lube/Oil',
      'Auto Supplies',
      'Auto Body Upgrades',
      'Stereo/Audio Equipment',
      'Windshield Replacement',
      'Car Wash',
      'Custom Detail',
      'Other',
    ],
  },
  {
    name: 'Home',
    items: [
      'Handyman',
      'Plumbing',
      'A/C and Heating',
      'Flooring',
      'Paint',
      'Roofing',
      'Energy Conservation',
      'Yard Care',
      'Pool Services',
      'Pest Services',
      'Sprinkler',
      'Window Installation',
      'Window Cleaning',
      'House Cleaning',
      'Garage/Door',
      'Cement/Driveway',
      'Recreation/Play',
      'Appliances',
      'Electronics',
      'Cabinets',
      'Home Computer',
      'Furniture',
      'Remodel',
      'Water/Fire/Damage Repair',
      'Lighting',
      'Organization',
      'Kitchen Supplies',
      'Garden Supplies',
      'Moving',
      'Other',
    ],
  },
  {
    name: 'Food/Drink',
    items: [
      'Direct Food Market',
      'Produce',
      'Meat Shop',
      'Sandwich Shop',
      'All American Fast Food',
      'Mexican Restaurant',
      'Italian Restaurant',
      'All American Restaurant',
      'Chinese Restaurant',
      'Indian Restaurant',
      'Steakhouse',
      'European Restaurant',
      'Pizza',
      'Casual Restaurant',
      'Fast Food',
      'Fine Dining Restaurant',
      'Catering',
      'Coffee Shop',
      'Soda Shop',
      'Ice Cream',
      'Dessert Shop',
      'Doughnut/Bagel Shop',
      'Other',
    ],
  },
  {
    name: 'Animal',
    items: [
      'Veterinarian Small Animals',
      'Veterinarian Large Animals',
      'Pet Supplies',
      'Pet Groomer',
      'Pet Watcher',
      'Pet Boarding',
      'Horse Riding',
      'Zoo/Visiting',
      'Feed Stores',
      'Pet Training',
      'Other',
    ],
  },
  {
    name: 'Health/Beauty',
    items: [
      'Gym',
      'Health Coach',
      'Health Supplements',
      'Spa',
      'Hair Salon',
      'Barber',
      'Aesthetician',
      'Massage Therapy',
      'Nail Salon',
      'Make-up Supplies',
      'Hair Supplies',
      'Eyebrow/Lash Services',
      'Weight Management',
      'Counselling Services',
      'Nutrition',
      'Other',
    ],
  },
  {
    name: 'Children',
    items: [
      'Dance Classes',
      'Karate Classes',
      'Sport Leagues',
      'Music Lessons',
      'Camps',
      'Daycare',
      'Tutoring',
      'Swimming Lessons',
      'Clothing Store',
      'Art',
      'Theatre',
      'Museum',
      'Activities',
      'Gymnastics',
      'Educational Activities',
      'Other',
    ],
  },
  {
    name: 'Family Fun',
    items: [
      'Roller/Ice Skating',
      'Bowling',
      'Movie Theatre',
      'Trampoline Park',
      'Family Fun',
      'Water Activities',
      'ATV Recreation',
      'Tours',
      'Golf',
      'Party/Events',
      'Other',
    ],
  },
  {
    name: 'Travel',
    items: [
      'Travel Agent',
      'Hotel',
      'Bed and Breakfast',
      'Car Rentals',
      'Amusement',
      'Museums',
      'Tours',
      'Other',
    ],
  },
  {
    name: 'Family/Individual Services',
    items: [
      'Photography',
      'Florist',
      'Wedding/Event Planner',
      'Event Locations',
      'Clothing Store',
      'Lotion/Soap/Candle Store',
      'Local Specialty Store',
      'Dry Cleaners',
      'Craft/Material Store',
      'Game Store',
      'Computer Store',
      'Shoe Store',
      'Jewellery',
      'Other',
    ],
  },
  {
    name: 'Personal Professional Services',
    items: [
      'Accountant',
      'Family Law Attorney',
      'Small Claim Attorney',
      'Accident Law',
      'Business Law',
      'Real Estate',
      'Financial Planners',
      'Insurance Agent',
      'Debt management',
      'Coach',
      'Other',
    ],
  },
  {
    name: 'Business Services',
    items: [
      'Website',
      'Marketing',
      'Law',
      'Business Consultant',
      'Merchant Services',
      'Accounting',
      'Professional Cleaning',
      'Office Supplies',
      'Employment Services',
      'Insurance',
      'Other',
    ],
  },
];

export default businessGroups;
