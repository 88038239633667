import gql from 'graphql-tag';


const MUTATION_EDIT_FILE = gql`

	mutation EditFile($id: String!, $name: String!, $description: String!, $parent: String!) {
		editFile(id: $id, name: $name, description: $description, parent: $parent)
	}

`;

export default MUTATION_EDIT_FILE;