import gql from 'graphql-tag';

const mutationPauseClock = gql`

	mutation PauseClock($reason: String!) {
		pauseClock(reason: $reason) {
			id
			user
			in
			out
			paused {
				id
				start
				end
				reason
			}
			isOnBreak
			totalTime
			totalBreakTime
			totalPaidTime
		}
	}

`;

export default mutationPauseClock;