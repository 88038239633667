// import axios from 'axios';
// import { FETCH_USER, FETCH_SURVEYS } from './types';
import { CHANGE_AUTH, FETCH_USER_INFO, CONNECT_SOCKET, DISCONNECT_SOCKET} from './types';
import axios from 'axios';
import { firebaseAuth } from '../config/firebase';
import openSocket from 'socket.io-client';
// export const fetchUser = () => {
//   return function(dispatch) {
//     axios.get('/api/current_user')
//     .then(res => dispatch({type: FETCH_USER, payload: res}));
//   };
// };

// export const fetchUser = () => async (dispatch) => {
//   const res = await axios.get('/api/current_user');
//   dispatch({type: FETCH_USER, payload: res.data});
// };

export const gqlGetUserInfo = async () => {
	return await axios.get(`/graphql?query={
		user {
			id 
			firebaseID 
			firstname 
			lastname 
			phone 
			email 
			zip 
			adminLevel 
			accountType 
			referenceCode 
			activeRepresentative 
			businessName 
			businessSearchList 
			employee 
			eManager 
			w9 {
				termsDate 
			}
		}
	}`);
}

export const changeAuth = (auth, loading) => async (dispatch) => {

	if(auth) {
		dispatch(fetchUserInfo());
	} else {
		dispatch({ type: FETCH_USER_INFO, payload: {} });
	}

	dispatch({type: CHANGE_AUTH, payload: {auth, loading}});
};

export const fetchUserInfo = () => async (dispatch) => {
	const res = await gqlGetUserInfo();
	dispatch({type: FETCH_USER_INFO, payload: res.data.data.user});
};

export const changeSocket = (connecting = true) => async (dispatch) => {
	if(connecting) {
		const token = await firebaseAuth().currentUser.getIdToken();
		console.log('Getting new Token'); /* eslint no-console: 0*/
		let socket = openSocket('/', {
			query: {
				auth: 'Bearer ' + token
			}
		});
		dispatch({type: CONNECT_SOCKET, payload: socket});
	} else {
		dispatch({type: DISCONNECT_SOCKET, payload: null});
	}
};
