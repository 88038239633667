/* eslint no-console: 0 */
import React, { Component } from 'react';
import axios from 'axios';
import { Button, Input, Segment } from 'semantic-ui-react';
import Navbar from '../Navbar';

class MembershipSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			loading: false,
			value: '',
		};

		this.onSearch = this.onSearch.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
		this.renderResults = this.renderResults.bind(this);
	}

	async onSearch() {
		this.setState({loading: true});
		const res = await axios.post('api/public/check_membership', {id: this.state.value});
		this.setState({ 
			data: res.data,
			loading: false
		});
	}

	onChangeValue(event) {
		this.setState({value: event.target.value});
	}

	renderAdditionalFamilyMembers(arr) {
		if(this.state.data.plan && arr.length > 0) {
			let fam = arr.map((m, index) => {
				return ( <span key={m.name}>{index > 0 && m.name ? ', ' : ''}{m.name}</span> );
			});
			return(
				<span>and has these additional family members listed: <br/> {fam}</span>
			);
		}
	}

	renderResults() {
		let { data } = this.state;
		console.log(data);
		if(data) {
			let status = data.status;
			if(status === 1) {
				return(
					<div>
						<h2>An Everything Card was Found!</h2>
						<p>The membership was purchased on {new Date(data.date).toDateString()} by {data.firstname} {data.lastname}</p>
						<p>It's a {data.plan ? 'Family' : 'Individual'} card {this.renderAdditionalFamilyMembers(data.mbrs)}</p>
					</div>
				);
			} else {
				return(
					<div>
						<h2>We could not find an Everything Card with that #</h2>
					</div>
				);
			}
		}
	}

	render() {
		return (
			<div>
				<Navbar />
				<div className="cardSearchContainer">
					<h1>Check to see if an Everything Card exists</h1>
					<Segment>
						<div>
							<Input placeholder="Enter Card # to Check" action>
								<input onChange={this.onChangeValue} value={this.state.value} />
								<Button type='button' onClick={() => this.onSearch()} loading={this.state.loading}>Search</Button>
							</Input>
							{this.renderResults()}
						</div>
					</Segment>

				</div>
			</div>
		);
	}
}

export default MembershipSearch;