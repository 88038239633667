
export default function getQuery(id = false, zipcodes = false) {
	
	return `/graphql?
		query={
			${id ? `manager(id: "${id}")` : 'managers'} {
				id 
				managerPercentage 
				${zipcodes ? 'zipCodes ' : ' '} 
				accountID {
					id 
					firstname 
					lastname 
				}
			}
		}
	`;
}