/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import ChangeAddress from './ChangeAddress';
import ModifyLocationPopup from './ModifyLocationPopup';
import deleteLocationMutation from '../../../../mutations/deleteLocation';
import { graphql } from '@apollo/react-hoc';
import { Button, Table, Segment } from 'semantic-ui-react';
import queryGetStore from '../../../../queries/getStore';
import { ChangeTextFieldLocation } from '../ManageStores/LocationChangeField';
import _ from 'lodash';


class Location extends Component {

	delete = () => {
		this.props.mutate({
			variables: {
				id: this.props.data.id,
				storeinfoID: this.props.parentid,
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	refetchQueries = () => {
		return [{
			query: queryGetStore,
			variables: { id: this.props.parentid }
		}];
	}

	render() {
		const { index, parentid } = this.props;
		const { name, phone } = _.get(this.props, 'data.locationInformation', {}) || {};
		const { id, location } = this.props.data;

		const refetch = this.refetchQueries();

		return (
			<div className="store__location changeInformation">
				<Table striped definition attached='top'>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<h3>Location #{index}</h3>
							</Table.HeaderCell>
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<ChangeTextFieldLocation
							label="Contact Name"
							value={name}
							property='locationInformation.name'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ locationID: id }}
							table
						/>

						<ChangeTextFieldLocation
							label="Contact Phone"
							value={phone}
							property='locationInformation.phone'
							refetch={refetch}
							id={parentid}
							additionalProperties={{ locationID: id }}
							table
						/>

						<Table.Row>
							<Table.Cell>Coordinates</Table.Cell>
							<Table.Cell>{location.coordinates[1]}, {location.coordinates[0]}</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.Cell>Address</Table.Cell>
							<Table.Cell>
								<ChangeAddress data={this.props.data} parentid={this.props.parentid} />
							</Table.Cell>
						</Table.Row>

					</Table.Body>
				</Table>

				<Segment attached>
					<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button color ="red" onClick={this.delete}>Delete</Button>
					</span>
				</Segment>
			</div>
		);
	}
}

export default graphql(deleteLocationMutation)(Location);