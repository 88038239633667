import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import ViewInfoModal from './ViewInfoModal';

const ViewInfo = ({ id }) => {
	const [modal, setModal] = useState(false);
	return (
		<>
			{ modal && <ViewInfoModal 
				onClose={() => setModal(false)}
				open={modal}
				id={id}
			/>}
			<span>
				<Button
					content="View Info"
					onClick={() => setModal(true)}
				/>
			</span>
		</>
	);
};

export default ViewInfo;