import React from 'react';
import styles from './Documents.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import determineFileType from './determineFileType';
import { useDrag, useDrop } from 'react-dnd';
import cx from 'classnames';

const File = ({ file, onSelect, ordering, onDropped }) => {
	const {
		id,
		name,
		description,
		path,
		filename,
		mimetype,
		external,
		link,
	} = file;

	const { type, icon, color } = determineFileType(mimetype, external, link);
	const ref = React.useRef();

	const [{ isDragging }, connectDrag] = useDrag({
		item: { id, type: 'FOLDER_COMPONENT' },
		canDrag: () => ordering,
		collect: monitor => {
			return {
				isDragging: monitor.isDragging()
			};
		}
	});

	const [, connectDrop] = useDrop({
		accept: 'FOLDER_COMPONENT',
		hover(item) {
			item.id !== id && onDropped && onDropped(item.id, id);
		},
	});

	connectDrag(ref);
	connectDrop(ref);
	
	const opacity = isDragging ? 0.5 : 1;
	const containerStyle = { opacity };

	return (
		<div className={cx(styles.folderContainer, 'animated fadetwoIn')} onClick={() => !ordering && onSelect()} style={containerStyle} ref={ref}>
			<FontAwesomeIcon
				icon={icon}
				style={{ color }}
				className={styles.folderIcon}
			/>
			<h5>{name}</h5>
			<span>{description}</span>
		</div>
	);
};

export default File;