import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import ReactTable from 'react-table';
import columns from './cols';
import { Segment, Button } from 'semantic-ui-react';
import QUERY_GET_SEMINAR_TABLE from '../../../queries/getSeminarTable';
import SeminarModal from './SeminarModal';
// import ConfirmModal from './ConfirmModal';

const SeminarTable = () => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [modalInfo, setModalInfo] = useState(null);
	const [datePopups, setDatePopups] = useState({});
	const [dates, setDates] = useState({});
	const [vars, setVars] = useState({});

	const [loadInitialData, { data: qData, loading, called, refetch }] = useLazyQuery(QUERY_GET_SEMINAR_TABLE, {
		fetchPolicy: 'network-only',
	});

	const { data, count } = _.get(qData, 'getSeminarTable', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(qData && modalInfo) {
			if(modalInfo && modalInfo.id) {
				const n = data.find(({ id }) => id === modalInfo.id);
				setModalInfo(n);
			}
		}
	}, [qData]);

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		const date = [];
		for(let ob in dates) {
			if(dates[ob]) {
				date.push({ id: ob, value: dates[ob].valueOf() });
			}
		}
		const variables = {
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered: [
				...filtered,
				...date,
			],
		};
		setVars(variables);
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const refresh = async () => {
		await refetch(vars);
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				info && openModal(info);
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	const openModal = (info) => {
		setModalInfo(info);
	};

	return (
		<>
			<SeminarModal
				onRefresh={refresh}
				info={modalInfo}
				onClose={() => setModalInfo(null)}
			/>
			<Segment color='teal' className="tableHeaderContainer">
				<div></div>
				<h2>Seminar Requests</h2>
				<Button basic onClick={() => refresh()}>
					<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
				</Button>
			</Segment>
			<Segment>
				<div className="animated fadetwoIn">
					<ReactTable
						data={data}
						columns={columns({
							dates,
							datePopups,
							triggerDatePopup: (id) => setDatePopups(prev => ({ ...prev, [id]: !prev[id] })),
							changeDate: (id, value) => setDates(prev => ({ ...prev, [id] : value})),
						})}
						pageSizeOptions={[10,15,20,50,100]}
						defaultPageSize={pageSize}
						pages={pages}
						onFetchData={onFetchData}
						manual
						loading={loading}
						defaultSorted={[{
							id: 'date',
							desc: true
						}]}
						getTrProps={rowProps}
					/>
					<h4 style={{ textAlign: 'center', marginTop: 10 }}>
						Total Seminar Requests: {count}
					</h4>
				</div>
			</Segment>
		</>
	);
};

export default SeminarTable;