import gql from 'graphql-tag';

const QUERY_GET_ORDER_TABLE = gql`

	query GetOrderTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getOrderTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				total
				product
				date
				requestedPhysicalCard
				physicalCardSent
				referenceName
				orderInformation {
					firstname
					lastname
					email
				}
				refunded
				stripe {
					id
					status
				}
				paypal {
					id
					status
				}
			}
		}
	}

`;

export default QUERY_GET_ORDER_TABLE;