import React, { Component } from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { Checkbox } from 'semantic-ui-react';

const FSCheckbox = ({ name, label, onChange: onCustomChange, className, style, ...rest }) => {
	return (
		<Field name={name}>
			{({ input: { value, onChange }, meta }) => (
				<div className={ classnames('checkbox', {'error' : (meta.error && meta.touched) }) }>
					<Checkbox
						name={name}
						type="checkbox"
						checked={!!value}
						onChange={(e) => {
							onChange(e.target.checked);
							onCustomChange && onCustomChange(e.target.checked);
						}}
						id={`${name}`}
						label={label}
						className={className}
						style={style}
						{...rest}
					/>
				</div>
			)}
		</Field>
	);
}

export default FSCheckbox;