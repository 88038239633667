import React, { Component } from 'react';
import { Popup, Button, Header, Form, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';
import * as actions from '../../../actions/';

const InputField = ({ label, input, placeholder, meta: { touched, error } }) => (
	<Form.Field required error={touched && !!error}>
		<label>{label || ''}</label>
		<Input placeholder={placeholder} {...input} action>
			<input />
			<Button type="submit">Change</Button>
		</Input>
		<p>{touched && error}</p>
	</Form.Field>
);


class ReferenceCode extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	async handleSubmit(values) {
		this.setState({loading: true});
		try {
			await axios.post('/api/settings/referencecode', values);
			this.props.fetchUserInfo();
			this.setState({loading: false});
		} catch (error) {
			this.setState({loading: false});
		}

	}

	renderPopup() {
		const {loading} = this.state;

		return(
			<div>
				<Form loading={loading} onSubmit={this.props.handleSubmit(values => this.handleSubmit(values))}>
					<Field component={InputField} name="code" placeholder="Enter Reference Code" label="New Reference Code"/>
				</Form>
			</div>
		);
	}

	renderText() {
		return(
			<Header style={{display: 'inline'}} as='h4'>{this.props.userInfo.referenceCode || 'Error'}</Header>
		);
	}

	setDefault() {
		if(this.props.userInfo) {
			const { referenceCode } = this.props.userInfo;
			if(referenceCode) {
				this.props.change('code', referenceCode);
			}
		}
	}

	render() {
		return(
			<Popup
				trigger={this.renderText()}
				content={this.renderPopup()}
				on='click'
				flowing
				onOpen={() => this.setDefault()}
			/>
		);
	}
}


function mapStateToProps({userInfo}) {
	return({userInfo});
}

function validateReferenceCode(site) {
	const re = /[^A-Za-z0-9]+/;
	if(re.test(site) === false) {
		return true;
	}
	return false;
}

function validate(form) {
	const errors = {};

	if(!form.code) {
		errors.code = 'Required Field';
	} else if(form.code.length < 4) {
		errors.code = 'Must be 4 characters or longer';
	} else if(!validateReferenceCode(form.code)) {
		errors.code = 'Only Letters and Numbers';
	}

	return errors;
}

function asyncValidate(values, dispatch, props ) {
	return axios.post('/api/settings/validate/referencecode', {code: values.code})
		.then((res) => {
			if(res.data.exists) {
				const previousErrors = props.asyncErrors;
				throw Object.assign({}, previousErrors, {code: 'Reference Code already Exists'});
			}
		});

}

ReferenceCode = reduxForm({
	validate,
	asyncValidate,
	asyncBlurFields: ['code'],
	form: 'changeReferenceCodeForm'
})(ReferenceCode);

export default connect(mapStateToProps, actions)(ReferenceCode);
