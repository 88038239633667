import gql from 'graphql-tag';

const modifyStoreMutation = gql`

	mutation ModifyStore($id: String!, $businessname: String, $url: String, $type: Int, $reference: String, $parent: String, $user: String, $contactname: String, $contactemail: String, $contactphone: String, $ein: String, $property: String!, $category: [Int], $premier: Boolean) {
		modifyStore(id: $id, businessname: $businessname, url: $url, type: $type, reference: $reference, parent: $parent, user: $user, contactname: $contactname, contactemail: $contactemail, contactphone: $contactphone, ein: $ein, property: $property, category: $category, premier: $premier) {
			id
		}
	}

`;

export default modifyStoreMutation;