import gql from 'graphql-tag';

const queryGetSalesAdmin = gql`

	query queryGetSalesAdmin($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getSalesAdmin(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			sales {
				id
				reference {
					id
					firstname
					lastname
				}

				firstname
				lastname
				email
				membershipID
				total
				date
				plan
				requestedPhysicalCard
				physicalCardSent
			}
		}
	}

`;

export default queryGetSalesAdmin;