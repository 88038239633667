import React from 'react';
import Login from '../components/Login';
import Loading from '../components/Loading';
import _ from 'lodash';


function withAuth(WrappedComponent) {
	// eslint-disable-next-line react/display-name
	return ({ called, loading, user, ...props }) => {

		if(loading || !called) {
			return <Loading />;
		}

		if(!_.get(user, 'id')) {
			return <Login />;
		}

		return (
			<WrappedComponent
				{...props}
			/>
		);
	};
}

export default withAuth;