import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_DELETE_FOLDER from './graphql/deleteFolder';
import QUERY_GET_ROOT_FOLDERS from './graphql/getRootFolders';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import DeleteModal from '../common/DeleteModal/DeleteModal';

const DeleteFolder = ({ slug, baseURL }) => {
	const [show, setShow] = React.useState(false);
	const [deleteFolder, { data, loading }] = useMutation(MUTATION_DELETE_FOLDER);
	const history = useHistory();

	React.useEffect(() => {
		if(_.get(data, 'deleteFolder', false)) {
			history.push(baseURL);
		}
	}, [data]);

	return (
		<>
			<DeleteModal
				open={show}
				onClose={() => setShow(false)}
				onDelete={() => {
					setShow(false);
					deleteFolder({
						variables: { slug },
						refetchQueries: [{ query: QUERY_GET_ROOT_FOLDERS }],
					});
				}}
			/>
			<Button
				content={(
					<FontAwesomeIcon
						icon={['fas', 'trash']}
					/>
				)}
				color="red"
				onClick={() => setShow(true)}
				loading={loading}
			/>
		</>
	);
};

export default DeleteFolder;