import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import withStateMutation from '../../../queries/withStateMutation';
import queryGetSetting from '../../../queries/getSetting';
import setSettingMutation from '../../../mutations/setSetting';
import TextInput from '../../FinalForm/TextInput';
import GraphQLError from '../../Message/GraphQLError';

class ChangeWebinarLinkForm extends Component {
	onSubmit = (variables) => {
		this.props.mutate({
			variables: {
				key: 'webinarlink',
				value: variables.webinarlink
			},
			refetchQueries: [{
				query: queryGetSetting,
				variables: { key: 'webinarlink' }
			}]
		});
	}

	render() {
		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					initialValues={{ webinarlink: this.props.webinarlink }}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading || this.props.loading}>
							<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>

							<Form.Group widths='equal'>
								<TextInput fluid name='webinarlink' placeholder="Youtube Webinar Link" label="Youtube Webinar Link" action={<Button type='submit'>Change</Button>} />
							</Form.Group>

						</Form>
					)}
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};
	return errors;
}


export default compose(
	graphql(setSettingMutation),
	withStateMutation(),
)(ChangeWebinarLinkForm);