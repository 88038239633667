import React, { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import { useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import ReactTable from 'react-table';
import QUERY_GET_USER_COMMISSION_REPORTS from '../../../../queries/getUserCommisionReports';
import columns from './cols';
import RepModal from './RepModal';

const ReportTable = ({ reportID, month, year }) => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [modalInfo, setModalInfo] = useState(null);

	const [loadInitialData, { data: qData, loading, called, refetch }] = useLazyQuery(QUERY_GET_USER_COMMISSION_REPORTS, {
		
	});

	const { data, count } = _.get(qData, 'getUserCommissionReports', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		console.log('CALLING FETCH DATA', reportID);
		const variables = {
			reportID,
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered,
		};
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				info && openModal(info);
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	const openModal = (info) => {
		setModalInfo(info);
	};

	return (
		<Segment>
			<RepModal
				info={modalInfo}
				month={month}
				year={year}
				onClose={() => setModalInfo(null)} 
			/>
			<div className="animated fadetwoIn">
				<ReactTable
					data={data}
					columns={columns()}
					pageSizeOptions={[10,15,20,50,100]}
					defaultPageSize={pageSize}
					pages={pages}
					onFetchData={onFetchData}
					manual
					loading={loading}
					getTrProps={rowProps}
					defaultSorted={[{
						id: 'name',
						desc: false
					}]}
				/>
				<h4 style={{ textAlign: 'center', marginTop: 10 }}>
						Total Reports: {count}
				</h4>
			</div>
		</Segment>
	);
};

export default ReportTable;