import React, {Component} from 'react';
import { AutoSizer, Table, Column} from 'react-virtualized';

class SaleTable extends Component {
	render() {
		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						width={width}
						height={this.props.fixedHeight || height}
						headerHeight={20}
						rowHeight={40}
						onRowClick={({event, index, rowData}) => this.props.onClick(event, index, rowData)}
						rowClassName="test"
						rowCount={this.props.data.sales ? this.props.data.sales.length : 0}
						rowGetter={({ index }) => this.props.data.sales[index]}
					>
						<Column
							cellDataGetter={({ rowData }) => {
								var firstname = rowData.firstname || 'N/A';
								var lastname = rowData.lastname || 'N/A';

								return `${firstname} ${lastname}`;
							}}
							label='Name'
							dataKey='firstname'
							width={200}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => {
								var email = rowData.email || 'N/A';
								return `${email}`;
							}}
							label='Email'
							dataKey='email'
							width={300}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => `${rowData.total.toFixed(2)}`}
							label='Total'
							dataKey='firstname'
							width={100}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => `${rowData.membershipID}`}
							label='Membership #'
							dataKey='firstname'
							width={150}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => {
								var d = new Date(rowData.date);
								return d.toDateString();
							}}
							label='Date'
							dataKey='firstname'
							width={300}
						/>
					</Table>
				)}
			</AutoSizer>
		);
	}
}

export default SaleTable;
