import React, { Component } from 'react';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../FinalForm/TextInput';
import GraphQLError from '../../Message/GraphQLError';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../queries/withStateMutation';
import addWebinarCodeMutation from '../../../mutations/addWebinarCode';
import queryGetWebinarCodes from '../../../queries/getWebinarCodes';
import DateInput from '../../FinalForm/DateInput';

class AddNewCodeForm extends Component {

	onSubmit = (variables) => {
		if(variables.dateExpired) {
			variables.dateExpired = variables.dateExpired.tz('America/Boise').startOf('day');
		}
		this.props.mutate({
			variables,
			refetchQueries: [{
				query: queryGetWebinarCodes,
				variables: {  }
			}]
		}).then(() => {
			this.props.onComplete && this.props.onComplete();
		});
	}

	render() {
		return (
			<div>
				<FinalForm
					onSubmit={this.onSubmit}
					validate={validate}
					initialValues={{ id: this.props.id }}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
							<Form.Group widths='equal'>
								<TextInput name='code' placeholder="Code for Webinar" label="Code for Webinar" required />
							</Form.Group>
							<Form.Group widths='equal'>
								<TextInput name='name' placeholder="Name" />
							</Form.Group>

							{/* <Form.Group widths='equal'>
								<TextInput name='email' placeholder="Email" />
							</Form.Group> */}

							<Form.Group widths='equal'>
								<TextInput name='identifier' placeholder="Identifier" />
							</Form.Group>

							<Form.Group widths='equal'>
								<DateInput name='dateExpired' placeholder='Expiration Date' />
							</Form.Group>

							<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
							<Button type="submit">Submit</Button>
						</Form>
					)}
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.code) {
		errors.code = 'Required Field';
	}

	return errors;
}

export default compose(
	graphql(addWebinarCodeMutation),
	withStateMutation()
)(AddNewCodeForm);