import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_BUSINESS_PLAN_USER from './businessplan';
import Loading from '../../../Loading';
import DetailsTable from '../../../Table/DetailsTable';
import _ from 'lodash';
import { ChangeTextFieldBusinessPlan, ChangeSelectFieldBusinessPlan, ChangeCheckboxFieldBusinessPlan } from '../../../BusinessPlanTable/BusinessPlanDetails/helpers/BusinessPlanChangeFields';
import { PresentationFutureOptions } from '../../../BusinessPlanTable/BusinessPlanDetails/BusinessPlanContent/PresentationInfo';


const Presentation = () => {
	const { data, loading, refetch } = useQuery(QUERY_BUSINESS_PLAN_USER);

	if(loading) {
		return <Loading />;
	}

	const {
		presentationContactName,
		presentationTime,
		presentationContactEmail,
		presentationFuture,
		opted,
	} = _.get(data, 'user.businessPlan.presentation', {}) || {};

	const id = _.get(data, 'user.businessPlan.id');

	return (
		<DetailsTable noHeader>
			<ChangeCheckboxFieldBusinessPlan
				label='Do you want to give a presentation?'
				value={opted}
				property={'presentation.opted'}
				refetch={refetch}
				id={id}
				table
				changeValues={{
					'false': 'no',
					'true': 'yes'
				}}
			/>
			{opted && (
				<>
					<ChangeTextFieldBusinessPlan
						label='What is the name of the contact person to arrange the presentation?'
						value={presentationContactName}
						property={'presentation.presentationContactName'}
						refetch={refetch}
						id={id}
						table
					/>
					<ChangeTextFieldBusinessPlan
						label='What is the best e-mail to use to reach the person to arrange the date and time of presentation?'
						value={presentationContactEmail}
						property={'presentation.presentationContactEmail'}
						refetch={refetch}
						id={id}
						table
					/>
					<ChangeTextFieldBusinessPlan
						label='Are there any times during the week that will not work for the presenter?'
						value={presentationTime}
						property={'presentation.presentationTime'}
						refetch={refetch}
						id={id}
						table
					/>
					<ChangeSelectFieldBusinessPlan
						options={PresentationFutureOptions}
						label={'Would you be interested in making more than one presentation this year?'}
						value={presentationFuture}
						property={'presentation.presentationFuture'}
						refetch={refetch}
						id={id}
						table
					/>
				</>
			)}
		</DetailsTable>
	);
};

export default Presentation;