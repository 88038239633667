/* eslint react/display-name: 0 */
import React from 'react';
import SimpleSelect from '../../../Form/SimpleSelect';
import _ from 'lodash';

const options = [
	{ value: 3, label: 'Individual' },
	{ value: 4, label: 'Family' },
];

const createColumns = () => [
	{
		Header: 'Membership ID',
		id: 'membershipID',
		filterable: true,
		minWidth: 100,
		accessor: 'membershipID',
		foldable: true,
	},
	{
		Header: 'Member\'s Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.firstname} ${d.lastname}`,
		foldable: true
	},
	{
		Header: 'Account Email',
		id: 'accountEmail',
		filterable: true,
		minWidth: 150,
		accessor: d => `${_.get(d, 'accountEmail', '') || ''}`,
		foldable: true,
		Cell: props => {
			return (
				<span style={props.value ? {} : {color: 'red'}}>
					{props.value || 'No Connected Account'}
				</span>
			);
		}
	},
	{
		Header: 'Plan',
		id: 'plan',
		filterable: true,
		minWidth: 150,
		accessor: d => formatPlan(d.plan),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
];

function formatPlan(plan) {
	switch(plan) {
	case 3:
		return 'Individual';
	case 4:
		return 'Family';
	default:
		return 'Unknown';
	}
}

export default createColumns;