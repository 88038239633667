import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

class SelectField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: null,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e, { value}) {
		this.props.input.onChange(value);
	}

	render() {
		const { label, options, placeholder, meta: { touched, error } } = this.props;
		return(
			<Form.Field required error={touched && !!error}>
				<label>{label || ''}</label>
				<Dropdown placeholder={placeholder} options={options} search selection onChange={this.handleChange} value={this.props.input.value} />
				<p>{touched && error}</p>
			</Form.Field>
		);
	}
}

export default SelectField;