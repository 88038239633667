import React from 'react';
import { Divider, Icon, Header } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CHECK_MEMBERSHIP_UTIL from '../../queries/checkMembershipUtil';
import _ from 'lodash';
import styles from './verifycard.module.scss';
import moment from 'moment';
import Loading from '../Loading/index';

const plans = [
	'Individual',
	'Family',
	'',
	'Family Individual',
	'Family Corporate'
];

const getStatus = (stripe, period_end) => {
	if(stripe && stripe.status) {
		return stripe.status;
	}
	return period_end < new Date() ? 'Active' : 'Expired';
};

const Row = ({ property, value, icon }) => {
	return(
		<Header as='h2'>
			<Icon name={icon} />
			<Header.Content>
				{property}
				<Header.Subheader style={{ textTransform: 'uppercase', fontSize: '14px' }}>{value}</Header.Subheader>
			</Header.Content>
		</Header>
	);
};

const MembershipResult = ({ membershipID }) => {
	const { data, loading } = useQuery(QUERY_CHECK_MEMBERSHIP_UTIL, {
		variables: { membershipID }
	});

	if(loading) {
		return <Loading />;
	}
	
	const {
		firstname,
		lastname,
		plan,
		stripe,
		period_end,
		spouseFirstName,
		spouseLastName,
		additionalFamilyMembers,
		lastInvoiceRefunded
	} = _.get(data, 'checkMembershipUtil', {});

	const status = getStatus(stripe, period_end);
	const end = (stripe && stripe.current_period_end) ? stripe.current_period_end : period_end;

	

	const renderFamilyPlan = () => {
		if(plan === 1 || plan === 4) {
			return (
				<>
					<Divider horizontal>Additional Family Members</Divider>
					{
						spouseFirstName &&
						<Row
							icon="user"
							property={`${spouseFirstName} ${spouseLastName}`}
							value="Spouse"
						/>
					}
					{
						additionalFamilyMembers && additionalFamilyMembers.length &&
						additionalFamilyMembers.map(member => (
							<Row
								key={`${member.name}`}
								property={`${member.name}`}
								value="Member"
								icon="user"
							/>
						))
					}
				</>
			);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.informationContainer}>
				<h3 className={styles.membershipID}>Membership # {membershipID}</h3>
				<h4 className={styles.status}>{lastInvoiceRefunded ? 'Refunded' : status}</h4>
				<Divider horizontal>Details</Divider>
				{/* {this.renderRow(moment(current_period_end).format('MMMM Do YYYY'), 'Expires', 'clock')} */}
				<Row
					value={'Expires'}
					property={end ? moment(end).format('MMMM Do YYYY') : 'N/A'}
					icon="clock"
				/>
				<Row
					value={'Card Holder Name'}
					property={`${firstname} ${lastname}`}
					icon="user"
				/>
				<Row
					value={'Card Plan'}
					property={plans[plan]}
					icon="id card"
				/>
				{renderFamilyPlan()}
			</div>
		</div>
	);
};

export default MembershipResult;