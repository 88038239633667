import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Modal, Menu, Segment, Table } from 'semantic-ui-react';
import queryGetUserSale from './getSale';
import Loading from '../../../Loading';
import moment from 'moment';
import _ from 'lodash';

const SaleModalContainer = (props) => {
	return (
		<Modal
			open={!!props.id}
			onClose={props.onClose}
			closeIcon
		>
			<Modal.Header>Sale ID - {props.id}</Modal.Header>
			<Modal.Content>
				<Sale {...props} />
			</Modal.Content>
		</Modal>
	);
};

const SaleTable = ({ children }) => {
	return(
		<Table definition striped>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell />
					<Table.HeaderCell>Value</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{children}
			</Table.Body>
		</Table>
	);
};

const Sale = ({ id, onClose }) => {
	const { data, loading } = useQuery(queryGetUserSale,
		{ variables: { id }, fetchPolicy: 'network-only' }
	);
	const [activeItem, setActiveItem] = useState('saleinfo');

	if(loading) {
		return <Loading />;
	}

	const renderRow = (property, value) => {
		return(
			<Table.Row>
				<Table.Cell>{property}</Table.Cell>
				<Table.Cell>{value}</Table.Cell>
			</Table.Row>
		);
	};

	const renderAdditionalFamilyMembers = () => {
		const members = data.sale.additionalFamilyMembers;
		if(members && members.length > 0) {
			return(
				<div>
					<h5>Addtional Family Members</h5>
					{ members.map(ob => (<p key={ob.name}>{ob.name}</p>))}
					{/* {this.renderMembers(members)} */}
				</div>
			);
		}
	};

	const renderSaleInfo = () => {
		if(activeItem === 'saleinfo') {
			return(
				<SaleTable>
					{ renderRow('Date of Sale', moment(data.sale.date).format('MMM Do YYYY, h:mm:ss a')) }
					{ renderRow('ID', data.sale.id) }
					{ renderRow('Membership #', data.sale.membershipID) }
					{ renderRow('Stripe Customer ID', data.sale.stripeCustomerID) }
					{ renderRow('Stripe Subscription ID', data.sale.stripeSubscriptionID) }
					{ renderRow('Total Charged', `$${data.sale.total}`) }
				</SaleTable>
			);
		}
	};

	const renderCustomerInfo = () => {
		if(activeItem === 'cinfo') {
			return(
				<SaleTable>
					{ renderRow('ID', `${data.sale.customer.firstname} ${data.sale.customer.lastname}`) }
					{ renderRow('Email', data.sale.customer.email) }
					{ renderRow('Zipcode', data.sale.customer.zip) }
				</SaleTable>
			);
		}
	};

	const renderShippingInfo = () => {
		if(activeItem === 'shippinginfo') {
			return (
				<div>
					<SaleTable>
						{ renderRow('Name', `${data.sale.firstname} ${data.sale.lastname}`)}
						{ renderRow('Street 1', _.get(data, 'sale.shippingAddress.street1', 'N/A'))}
						{ renderRow('Street 2', _.get(data, 'sale.shippingAddress.street2', 'N/A'))}
						{ renderRow('City', _.get(data, 'sale.shippingAddress.city', 'N/A'))}
						{ renderRow('State', _.get(data, 'sale.shippingAddress.state', 'N/A'))}
						{ renderRow('Zip', _.get(data, 'sale.shippingAddress.zip', 'N/A'))}
						{ renderRow('Email', _.get(data, 'sale.email', 'N/A'))}
						{ renderRow('Phone', _.get(data, 'sale.phone', 'N/A'))}
					</SaleTable>
					{renderAdditionalFamilyMembers()}
				</div>
			);
		}
	};

	return(
		<div>
			<Menu attached='top' tabular>
				<Menu.Item name='Sale Info' active={activeItem === 'saleinfo'} onClick={() => setActiveItem('saleinfo')} />
				{data.sale.customer &&
					<Menu.Item
						name='Account Info'
						active={activeItem === 'cinfo'}
						onClick={() => setActiveItem('cinfo')}
					/>
				}
				<Menu.Item name='Shipping Info' active={activeItem === 'shippinginfo'} onClick={() => setActiveItem('shippinginfo')} />
			</Menu>

			<Segment attached='bottom'>
				{renderSaleInfo()}
				{renderCustomerInfo()}
				{renderShippingInfo()}
			</Segment>
		</div>
	);
};

export default SaleModalContainer;