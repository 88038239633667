import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import { Form, Checkbox as SemanticCheckbox } from 'semantic-ui-react';

function renderList(name, value, onChange, list) {
	return list.map(ob => {
		return (
			<Form.Field key={ob.value}>
				<SemanticCheckbox
					radio
					name={name}
					label={ob.label}
					value={ob.value}
					checked={value === ob.value}
					onChange={(e, data) => { onChange(ob.value) } }
				/>
			</Form.Field>
		);
	})
}

const RadioGroup = ({ name, title, list = [] }) => {
	return (
		<Fragment>
			<h5 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>{title}</h5>
			<Field name={name}>
				{({ input: { value, onChange }, meta }) => (
					renderList(name, value, onChange, list)
				)}
			</Field>
		</Fragment>
	);
};

export default RadioGroup;