import gql from 'graphql-tag';

const deleteStoreMutation = gql`

	mutation DeleteStore($id: String!) {
		deleteStore(id: $id) {
			id
		}
	}

`;

export default deleteStoreMutation;