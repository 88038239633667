import React from 'react';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import BusinessInfo from './BusinessInfo';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loading from '../../Loading';
import _ from 'lodash';
import SubscriptionInfo from './SubscriptionInfo';
import CardDetails from './CardDetails';

export const QUERY_BUSINESS_PLAN_USER = gql`
	query CurrentUser {
		user {
			id
			businessPlan {
				id
				businessname
				latestOrderNumber
				plan
				stripe {
					current_period_start
					current_period_end
					ended_at
					canceled_at
					status
					customer
					Customer {
						id
						default_source {
							id
							last4
							exp_month
							exp_year
							brand
							name
						}
					}
				}
			}
		}
	}
`;

const Home = () => {
	const { data, loading } = useQuery(QUERY_BUSINESS_PLAN_USER);

	if(loading) {
		return <Loading />;
	}

	const businessPlan = _.get(data, 'user.businessPlan');

	if(!businessPlan) { return <div>ERROR! Please Refresh</div>; }

	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Grid stackable>
				<Grid.Column width={4}>
					<BusinessInfo plan={businessPlan} />
				</Grid.Column>

				<Grid.Column width={7}>
					<Segment raised color="blue">
						<Header as='h2' icon textAlign='center'>
							<Icon name='users' circular />
							<Header.Content>Business Dashboard</Header.Content>
							<Header.Subheader>
								Keep up to date with your business subscription on the dashboard!
							</Header.Subheader>
						</Header>
					</Segment>
				</Grid.Column>

				<Grid.Column width={5}>
					<SubscriptionInfo plan={businessPlan} />
					<CardDetails plan={businessPlan} />
				</Grid.Column>
			</Grid>
		</div>
	);
};

export default Home;