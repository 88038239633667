import React, { Component } from 'react';
import styles from '../employee.module.scss';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import queryGetUserTimecard from '../../../queries/employee/getUserTimecard';
import { Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import mutationClockIn from '../../../mutations/employee/clockin';
import mutationClockOut from '../../../mutations/employee/clockout';
import TextInput from '../../FinalForm/TextInput';
import mutationPauseClock from '../../../mutations/employee/pauseclock';
import mutationUnpauseClock from '../../../mutations/employee/unpauseclock';
import { msToTime } from '../timeUtils';

class TimeClock extends Component {
	state = {
		totalTime: 0,
	}

	componentDidMount() {
		if(_.get(this.props, 'data.getUserTimecard')) {
			this.updateTime();
			this.updateTimeInterval = setInterval(this.updateTime, 1000);
		}
	}

	componentDidUpdate(prevProps) {
		if(!_.get(prevProps, 'data.getUserTimecard') && _.get(this.props, 'data.getUserTimecard')) {
			this.updateTime();
			this.updateTimeInterval = setInterval(this.updateTime, 1000);
		} else if(_.get(prevProps, 'data.getUserTimecard') && !_.get(this.props, 'data.getUserTimecard')) {
			clearInterval(this.updateTimeInterval);
		}
	}

	componentWillUnmount() {
		clearInterval(this.updateTimeInterval);
	}

	clockIn = () => {
		this.props.clockIn({refetchQueries: [{ query: queryGetUserTimecard }]});
	}

	clockOut = () => {
		this.props.clockOut({refetchQueries: [{ query: queryGetUserTimecard }]});
	}

	takeBreak = ({ reason }) => {
		this.props.takeBreak({ variables: {reason}, refetchQueries: [{ query: queryGetUserTimecard }]  });
	}

	endBreak = () => {
		this.props.endBreak({ refetchQueries: [{ query: queryGetUserTimecard }]  });
	}

	renderNotActive = () => {
		return (
			<div className={styles.clockInContainer}>
				<Button onClick={this.clockIn}>Clock In</Button>
			</div>
		);
	}


	renderTakeBreak = () => {
		return (
			<FinalForm
				onSubmit={this.takeBreak}
				validate={(values) => {
					const errors = {};
					if(!values.reason) {
						errors.reason = 'Required Field';
					}
					return errors;
				}}
				initialValues={{ id: this.props.id }}
				render={({ handleSubmit }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextInput name='reason' placeholder="Reason for the Break" label="Break Reason" required />
						</Form.Group>
						<Button type="submit">Take a Break</Button>
					</Form>
				)}
			/>
		);
	}

	renderFinishBreak = () => {
		return (
			<div>
				<h4>Currently on a break...</h4>
				<Button onClick={this.endBreak}>End Break</Button>
			</div>
		);
	}

	updateTime = () => {
		const timecard = _.get(this.props.data, 'getUserTimecard');
		const totalTime = getTotalTime(timecard);
		const totalBreakTime = getTotalBreakTime(timecard);
		const totalPaidTime = totalTime - totalBreakTime;
		this.setState({
			totalTime: msToTime(totalTime),
			totalBreakTime: msToTime(totalBreakTime),
			totalPaidTime: msToTime(totalPaidTime),
		});
	}

	renderActive = () => {
		const timecard = _.get(this.props.data, 'getUserTimecard');
		const { id, user, in : inTime, out, paused, isOnBreak, totalTime, totalBreakTime, totalPaidTime } = timecard;
		return (
			<div>
				<h3>Clocked in</h3>
				<p>Start Time: {moment(inTime).format('MMMM Do YYYY, h:mm:ss a')}</p>
				<Button onClick={this.clockOut}>Clock Out</Button>
				<h4>Total Time: {this.state.totalTime}</h4>
				<h4>Total Break Time: {this.state.totalBreakTime}</h4>
				<h4>Total Paid Time: {this.state.totalPaidTime}</h4>
				{!isOnBreak && this.renderTakeBreak()}
				{isOnBreak && this.renderFinishBreak()}
			</div>
		);
	}

	render() {
		if(this.props.data.loading) { return <div></div>; }
		console.log(this.props);
		const timecard = _.get(this.props.data, 'getUserTimecard');
		return (
			<div className={styles.timeclock}>
				<h3>Manage Time Clock</h3>
				{!timecard && this.renderNotActive()}
				{timecard && this.renderActive()}
			</div>
		);
	}
}

export default compose(
	graphql(queryGetUserTimecard),
	graphql(mutationClockIn, { name: 'clockIn' }),
	graphql(mutationClockOut, { name: 'clockOut' }),
	graphql(mutationPauseClock, { name: 'takeBreak' }),
	graphql(mutationUnpauseClock, { name: 'endBreak' }),
)(TimeClock);

function getTotalTime(timecard) {
	if(timecard.out) {
		return new Date(timecard.out).valueOf() - new Date(timecard.in).valueOf();
	} else {
		return new Date() - new Date(timecard.in.valueOf());
	}
}

function getTotalBreakTime(timecard) {
	let total = 0;
	if(timecard.paused) {
		for(let pause of timecard.paused) {
			if(pause.end) {
				total += new Date(pause.end).valueOf() - new Date(pause.start).valueOf();
			} else {
				total += new Date().valueOf() - new Date(pause.start).valueOf();
			}
		}
	}
	return total;
}