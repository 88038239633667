import gql from 'graphql-tag';

const QUERY_GET_ORDER_CONFIRMATION = gql`
	query GetOrderConfirmation($id: String!) {
		getOrder(id: $id) {
			id
			orderInformation {
				firstname
				lastname
				email
			}
			requestedPhysicalCard
			date
		}
	}
`;

export default QUERY_GET_ORDER_CONFIRMATION;