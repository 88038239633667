import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import ChangeReferenceField from '../../../ChangeField/ChangeReference';
import ChangeCheckboxField from '../../../ChangeField/ChangeCheckbox';
import gql from 'graphql-tag';
import ChangeTextArea from '../../../ChangeField/ChangeTextArea';

export const MUTATION_MODIFY_BUSINESS_PLAN = gql`
	mutation ChangeBusinessPlan(
		$id: String!,
		$property: String!,
		$businessname: String,
		$reference: String,
		$account: String,
		$presentation: Presentation,
		$socialMedia: SocialMedia,
		$info: ShippingAddress,
		) {
		changeBusinessPlan(
			id: $id,
			property: $property,
			businessname: $businessname,
			reference: $reference,
			account: $account,
			presentation: $presentation,
			socialMedia: $socialMedia,
			info: $info,
		)
	}
`;

export const ChangeTextFieldBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeTextField);

export const ChangeDateFieldBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeDateField);

export const ChangeSelectFieldBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeSelectField);

export const ChangeReferenceFieldBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeReferenceField);

export const ChangeCheckboxFieldBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeCheckboxField);

export const ChangeTextAreaBusinessPlan = compose(
	graphql(MUTATION_MODIFY_BUSINESS_PLAN),
	withStateMutation(),
)(ChangeTextArea);