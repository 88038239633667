import { useEffect, useState } from 'react';
import { firebaseAuth } from '../config/firebase';


function useFirebaseAuth() {
	const [authed, setAuthed] = useState(false);
	const [user, setUser] = useState(null);
	const [initial, setInitial] = useState(false);

	useEffect(() => {
		const removeListener = firebaseAuth().onAuthStateChanged(async (user) => {
			if(user) {
				setAuthed(true); setUser(user);
			} else {
				setAuthed(false); setUser(null);
			}
			setInitial(true);
		});

		return () => {
			removeListener();
		};
	}, []);

	return { authed, user, initial };
}

export default useFirebaseAuth;