import gql from 'graphql-tag';

const MUTATION_CHANGE_PAYMENT_INFORMATION = gql`

	mutation ChangePaymentInformation($type: Int!, $street1: String, $street2: String, $city: String, $state: String, $zip: String, $country: String, $name: String, $rtn: String, $acn: String, $at: Int, $bName: String) {
		changePaymentInformation(type: $type, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, country: $country, name: $name, rtn: $rtn, acn: $acn, at: $at, bName: $bName)
	}

`;

export default MUTATION_CHANGE_PAYMENT_INFORMATION;