/* eslint no-console: 0 */
import 'semantic-ui-css/semantic.min.css';
import 'react-table/react-table.css';
import 'cross-fetch/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './config/fontawesome';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { ApolloProvider } from '@apollo/react-hoc';

import App from './components/App';
import axios from 'axios';

import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.scss';
import './scss/core/_dropdown-menu-right.scss';

import client from './utils/apolloClient';
import { firebaseAuth } from './config/firebase';

axios.interceptors.request.use(async (config) => {
	const token = firebaseAuth().currentUser ? await firebaseAuth().currentUser.getIdToken() : null;
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	return config;
});

const store = createStore(
	reducers,
	composeWithDevTools(applyMiddleware(reduxThunk))
);


ReactDOM.render(
	<Provider store={store}>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</Provider>,
	document.querySelector('#root')
);
