import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../FinalForm/TextInput';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_ADD_FOLDER from './graphql/addFolder';
import GraphQLError from '../Message/GraphQLError';
import _ from 'lodash';
import QUERY_GET_ROOT_FOLDERS from './graphql/getRootFolders';
import QUERY_GET_FOLDER from './graphql/getFolder';

const AddFolder = ({ slug, tag }) => {
	const [modal, setModal] = React.useState(false);
	return (
		<>
			<AddFolderModal
				open={modal}
				parentSlug={slug}
				onClose={() => setModal(false)}
				tag={tag}
			/>
			<Button
				content="Add Folder"
				onClick={() => setModal(true)}
				color="violet"
			/>
		</>
	);
};

const AddFolderModal = ({ open, onClose, parentSlug, tag }) => {
	const [addFolder, { data, loading, error }] = useMutation(MUTATION_ADD_FOLDER);

	React.useEffect(() => {
		if(_.get(data, 'addFolder.id')) {
			onClose();
		}
	}, [data]);
	
	const onSubmit = (variables) => {
		addFolder({
			variables,
			refetchQueries: parentSlug ?
				[{ query: QUERY_GET_FOLDER, variables: { slug: parentSlug, tag }}] :
				[{ query: QUERY_GET_ROOT_FOLDERS, variables: { tag } }],
		});
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>Add Folder</Modal.Header>
			<Modal.Content>
				<FinalForm
					validate={validator(schema)}
					onSubmit={onSubmit}
					initialValues={{ parentSlug, tag }}
					render={({ handleSubmit, valid }) => (
						<Form onSubmit={handleSubmit} loading={loading}>
							<Form.Group widths="equal">
								<FSTextInput
									name="name"
									label="Folder Name"
									placeholder="The Name of the Folder"
								/>
							</Form.Group>
							<GraphQLError error={error} />
							<Button
								type="submit"
								fluid
								color="blue"
								content="Add Folder"
								disabled={!valid}
								loading={loading}
							/>
						</Form>
					)}
				/>
			</Modal.Content>
		</Modal>
	);
};

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
});

export default AddFolder;