import React, { useState, useEffect } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import { ReferenceField } from './ReferenceField';
import TreeGraph from '../../Tree';

const AdminTreeGraph = () => {
	const [id, setID] = useState(null);

	const [width, setWidth] = useState(null);
	const [maxHeight, setMaxHeight] = useState('100%');

	React.useLayoutEffect(() => {
		const gContainer = document.querySelector('#rgContainer');
		setWidth(gContainer.clientWidth/2 - 14);

		const bounds = document.querySelector('#groupContainer').getBoundingClientRect();
		let vh = window.innerHeight * 0.01;
		const mHeight = `calc(${100*vh}px - ${bounds.top+10}px)`;
		setMaxHeight(mHeight);
	}, []);

	React.useEffect(() => {
		const onResize = () => {
			const bounds = document.querySelector('#groupContainer').getBoundingClientRect();
			let vh = window.innerHeight * 0.01;
			const mHeight = `calc(${100*vh}px - ${bounds.top+10}px)`;
			setMaxHeight(mHeight);
		};
		document.addEventListener('resize', onResize);
		return () => document.removeEventListener('resize', onResize);
	}, []);

	return (
		<div className="animated fadetwoIn dnp-page" style={{ maxHeight }} id="groupContainer">
			<Menu attached="top">
				<Menu.Menu position='right'>
					<Menu.Item>
						<ReferenceField onChange={userID => setID(userID)} />
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			<Segment attached="bottom" className="flex1" id="rgContainer">
				{(id && width !== null) ? <TreeGraph id={id} x={width} /> : <p style={{ textAlign: 'center', width: '100%', fontSize: '25px' }}>Please select a representative</p>}
			</Segment>
		</div>
	);
};

export default AdminTreeGraph;