import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './OrderDetails.module.scss';

const calculateDiscount = (total, code) => {
	if(code) {
		if(code.amount_off !== null) {
			return (code.amount_off/100).toFixed(2);
		} else if(code.percent_off !== null) {
			return (total * (code.percent_off / 100)).toFixed(2);
		}
	}
	return (0).toFixed(2);
}

const calculatePrice = (total, code) => {
	if(code) {
		if(code.amount_off !== null) {
			return (total - (code.amount_off/100)).toFixed(2);
		} else if (code.percent_off !== null) {
			return (total * ((100-code.percent_off)/100)).toFixed(2); 
		}
	}
	return total.toFixed(2); 
}

const Item = ({ name, quantity, price }) => {
	if(!quantity) { return null; }
	return (
		<div className={styles.row}>
			<span>{quantity}x {name}</span>
			<span>${(quantity * price).toFixed(2)}</span>
		</div>
	);
};

const OrderDetails = ({ items, code }) => {
	const total = items.reduce((previous, item) => previous + (item.price * item.quantity), 0);
	return (
		<div className={styles.orderDetails}>
			<h3>Order Details</h3>
			{items.map(item => <Item {...item} key={item.name} />)}
			<div className={styles.divider} />
			<div className={styles.row}>
				<span>Subtotal</span>
				<span>${total.toFixed(2)}</span>
			</div>
			{code && <div className={styles.row}>
				<span>Discount</span>
				<span>-${calculateDiscount(total, code)}</span>
			</div>}
			<div className={styles.divider} />
			<div className={styles.row}>
				<span>Total</span>
				<span>${calculatePrice(total, code)}</span>
			</div>
		</div>
	);
};

OrderDetails.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		price: PropTypes.number.isRequired,
		quantity: PropTypes.number.isRequired,
	})),
	code: PropTypes.object,
}

OrderDetails.defaultProps = {
	items: [],
	code: null,
}

export default OrderDetails;