import gql from 'graphql-tag';

const QUERY_GET_ROOT_FOLDERS = gql`
	query GetRootFolders($tag: String) {
		getRootFolders(tag: $tag) {
			id
			name
			slug
			order
			color
			files {
				id
			}
		}
	}
`;

export default QUERY_GET_ROOT_FOLDERS;