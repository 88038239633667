import React, { useState } from 'react';
import TreeGraph from '../Tree';
import { Segment } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';
import _ from 'lodash';
import Loading from '../Loading';

const UserTreeGraph = () => {
	const { data, loading } = useQuery(QUERY_CURRENT_USER);
	const [width, setWidth] = useState(null);
	const [maxHeight, setMaxHeight] = useState('100%');

	React.useLayoutEffect(() => {
		const gContainer = document.querySelector('#gContainer');
		setWidth(gContainer.clientWidth/2 - 14);

		const bounds = document.querySelector('#groupContainer').getBoundingClientRect();
		let vh = window.innerHeight * 0.01;
		const mHeight = `calc(${100*vh}px - ${bounds.top+10}px)`;
		setMaxHeight(mHeight);
	}, []);

	React.useEffect(() => {
		const onResize = () => {
			const bounds = document.querySelector('#groupContainer').getBoundingClientRect();
			let vh = window.innerHeight * 0.01;
			const mHeight = `calc(${100*vh}px - ${bounds.top+10}px)`;
			setMaxHeight(mHeight);
		};
		document.addEventListener('resize', onResize);
		return () => document.removeEventListener('resize', onResize);
	}, []);

	if(loading) {
		return <Loading />;
	}

	const id = _.get(data, 'user.id');

	if(!id) {
		return (
			<div>Something went wrong.</div>
		);
	}

	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group className="flex1" id="groupContainer" style={{ maxHeight }}>
				<Segment className="tableHeaderContainer">
					<h2>Tree Graph</h2>
				</Segment>
				<Segment className="flex1" id="gContainer">
					{(loading || width === null) ? <Loading /> : <TreeGraph id={id} x={width} />}
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default UserTreeGraph;