import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import ReactTable from 'react-table';
import columns from './cols';
import { Segment, Button } from 'semantic-ui-react';
import QUERY_GET_REFERRAL_TABLE from '../../../queries/getReferralTable';
import ConfirmModal from './ConfirmModal';
import moment from 'moment';

const ReferralTable = () => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [modalInfo, setModalInfo] = useState(null);
	const [datePopup, setDatePopup] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [vars, setVars] = useState({});

	const [loadInitialData, { data: qData, loading, called, refetch, networkStatus }] = useLazyQuery(QUERY_GET_REFERRAL_TABLE, {
		fetchPolicy: 'network-only',
	});

	const { data, count } = _.get(qData, 'getReferralTable', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		const date = [];
		if(startDate) { date.push( { id: 'fromDate', value: startDate.valueOf() } ); }
		if(endDate) { date.push( { id: 'toDate', value: endDate.valueOf() } ); }
		const variables = {
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered: [
				...filtered,
				...date,
			],
		};
		setVars(variables);
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const refresh = () => {
		refetch(vars);
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				if(!info.completed) {
					info && openModal(info);
				}
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	const openModal = (info) => {
		setModalInfo(info);
	};

	return (
		<>
			<ConfirmModal 
				info={modalInfo}
				onClose={() => setModalInfo(null)}
				onMarkedComplete={() => {
					refresh();
					setModalInfo(null);
				}}
			/>
			<Segment color='orange' className="tableHeaderContainer">
				<div></div>
				<h2>Referrals</h2>
				<Button basic onClick={() => refresh()}>
					<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
				</Button>
			</Segment>
			<Segment>
				<div className="animated fadetwoIn">
					<ReactTable
						data={data}
						columns={columns({
							datePopup,
							triggerDatePopup: () => setDatePopup(!datePopup),
							startDate,
							endDate,
							changeStartDate: (date) => { 
								setStartDate( date ? moment(date).startOf('day').toDate() : null);
							},
							changeEndDate: (date) => {
								setEndDate( date ? moment(date).startOf('day').subtract('minute', 1).toDate() : null);
							},
						})}
						pageSizeOptions={[10,15,20,50,100]}
						defaultPageSize={pageSize}
						pages={pages}
						onFetchData={onFetchData}
						manual
						loading={loading}
						getTrProps={rowProps}
					/>
					<h4 style={{ textAlign: 'center', marginTop: 10 }}>
						Total Referrals: {count}
					</h4>
				</div>
			</Segment>
		</>
	);
};

export default ReferralTable;