import gql from 'graphql-tag';

const QUERY_GET_SEMINAR_TABLE = gql`
	query GetSeminarTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getSeminarTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				name
				email
				phone
				date
				type
				completed
				registeredFor
			}
		}
	}
`;

export default QUERY_GET_SEMINAR_TABLE;