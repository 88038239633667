import React, { Component } from 'react';
import { Form, Button, Icon, Message } from 'semantic-ui-react';
import { firebaseAuth } from '../../../config/firebase';


class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: null,
			loading: false,
			finished: false,
			error: false,
		};

		this.handleChangeEmail = this.handleChangeEmail.bind(this);
		this.renderError = this.renderError.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChangeEmail(e, {value}) {
		this.setState({email: value});
	}

	async handleSubmit() {
		this.setState({loading: true});
		if(this.state.email) {

			try {
				await firebaseAuth().sendPasswordResetEmail(this.state.email);
				this.setState({finished: true});
			} catch (error) {
				this.setState({error: true});
			}

		}
		this.setState({loading: false});
	}

	renderError() {
		if(this.state.error) {
			return(
				<Message
					error
					header="We couldn't reset your password"
					list={[
						'Make sure you have your email correct',
						'You can try submitting it again',
						'Contact Customer Support if it continues not to work'
					]}
				/>
			);
		}
	}

	render() {

		if(this.state.finished) {
			return(
				<div>
					<h3>Success!</h3>
					<p>If the email you typed in has an account, you should be recieving an email with instructions to reset your password in a few minutes</p>
				</div>
			);
		}

		return(
			<div>
				<p>If you forgot your password, we can send you an email to reset it.</p>
				<p>Please type your email below</p>
				{this.renderError()}
				<Form size='large' loading={this.state.loading}>
					<Form.Input
						fluid
						icon='mail'
						iconPosition='left'
						placeholder='E-mail address to reset'
						value={this.state.email}
						onChange={this.handleChangeEmail}
						action
					>
						<Icon name="mail" />
						<input />
						<Button type='button' onClick={this.handleSubmit}>Submit</Button>
					</Form.Input>
				</Form>
			</div>
		);
	}
}

export default PasswordReset;
