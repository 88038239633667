import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import queryGetSalesOfUser from './getSalesOfUser';
import _ from 'lodash';
import moment from 'moment';
import ReactTable from 'react-table';
import generateCols from './cols';
import Sale from './Sale';

const defaultSorted = [{
	id: 'date',
	desc: true
}];

const SalesTable = forwardRef((props, ref) => {
	const [loadResults, { loading, data, refetch }] = useLazyQuery(queryGetSalesOfUser, { fetchPolicy: 'network-only' });
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [pages, setPages] = useState(1);
	const [count, setCount] = useState(0);
	const [latestVariables, setLatestVariables] = useState(null);
	const [pageSize, setPageSize] = useState(20);
	const [datePopup, setDatePopup] = useState(false);
	const [saleID, setSaleID] = useState(null);

	useEffect(() => {
		if(data) {
			const count = _.get(data, 'getSalesOfUser.count', 0);
			setPages(Math.ceil(count / pageSize));
			setCount(count);
		}
	}, [data]);

	useEffect(() => {
		latestVariables && loadResults({ variables: latestVariables });
	}, [latestVariables]);

	useImperativeHandle(ref, () => ({
		refresh() { refetch(); }
	}));

	const columns = generateCols({
		datePopup,
		triggerDatePopup: () => { setDatePopup(!datePopup); },
		startDate,
		endDate,
		changeStartDate: (date) => { 
			setStartDate( date ? moment(date).startOf('day').toDate() : null);
		},
		changeEndDate: (date) => {
			setEndDate( date ? moment(date).startOf('day').subtract('minute', 1).toDate() : null);
		},
	});

	return (
		<Fragment>
			<Sale
				onClose={() => setSaleID(null)}
				id={saleID}
			/>
			<ReactTable
				data={_.get(data, 'getSalesOfUser.sales', [])}
				columns={columns}
				pageSizeOptions={[10,15,20,50,100]}
				defaultPageSize={20}
				pages={pages}
				onFetchData={(state, instance) => {
					const date = [];
					if(startDate) { date.push( { id: 'fromDate', value: startDate.valueOf() } ); }
					if(endDate) { date.push( { id: 'toDate', value: endDate.valueOf() } ); }
					const variables = {
						limit: state.pageSize,
						skip: state.page * state.pageSize,
						sortBy: state.sorted,
						filtered: [
							...state.filtered,
							...date,
						],
					};
					setPageSize(state.pageSize);
					setLatestVariables(variables);
				}}
				manual
				loading={loading}
				defaultSorted={defaultSorted}
				getTrProps={(state, rowInfo) => {
					return {
						onClick: (e, handleOriginal) => {
							const id = _.get(rowInfo, 'original.id');
							id && setSaleID(id);
							handleOriginal && handleOriginal();
						},
						className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
					};
				}}
			/>
			<h4 style={{ textAlign: 'center', marginTop: 10 }}>
				Total Sales: {count}
			</h4>
		</Fragment>
	);
});

export default SalesTable;