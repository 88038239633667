function determineFileType(mimetype, external, link=false) {
	if(link) {
		return { type: 'link', icon: ['fas', 'browser'], color: '#9b59b6' };
	} else if(external) {
		return { type: 'youtube', icon: ['fab', 'youtube'], color: '#c4302b' };
	}

	switch(mimetype) {
	case 'image/png':
		return { type: 'png', icon: ['fas', 'file-image'], color: '#2ecc71' };
	case 'image/jpeg':
		return { type: 'jpeg', icon: ['fas', 'file-image'], color: '#2ecc71' };
	case 'text/csv':
		return { type: 'csv', icon: ['fas', 'file-excel'], color: '#27ae60' };
	case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
		return { type: 'docx', icon: ['fas', 'file-word'], color: '#3498db' };
	case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
		return { type: 'pptx', icon: ['fas', 'file-powerpoint'], color: '#eb4320' };
	case 'application/pdf':
		return { type: 'pdf', icon: ['fas', 'file-pdf'], color: '#c0392b' };
	default:
		return { type: 'file', icon: ['fas', 'file'], color: '#151b1e'};
	}
}

export default determineFileType;