import React from 'react';
import OrderTable from '../../OrderTable';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../../queries/user';
import Loading from '../../Loading';
import _ from 'lodash';
import { Segment, Button } from 'semantic-ui-react';

const Orders = () => {
	const { loading, data } = useQuery(QUERY_CURRENT_USER);
	let tableRef = React.useRef(null);

	if(loading) {
		return <Loading />;
	}

	const account = _.get(data, 'user.id', null);

	if(!account) {
		return (
			<div>
				<strong>We could not find your account id. Please refresh the page to try again</strong>
			</div>
		);
	}

	return (
		<div className="animated fadetwoIn padded-top dnp-page">
			<Segment.Group stacked>
				<Segment color='green' className="tableHeaderContainer">
					<div />
					<h2>Personal Sales</h2>
					<Button
						onClick={() => {
							if(tableRef && tableRef.current) {
								tableRef.current.refresh();
							}
						}}
						content={<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true" />}
					/>
				</Segment>
				<Segment placeholder>
					<OrderTable
						ref={tableRef}
						fixedParams={[
							{ id: 'account', value: account },
						]}
						hideCols={['id', 'reference']}
					/>
				</Segment>
			</Segment.Group>
		</div>
	);
};

export default Orders;