
import gql from 'graphql-tag';

const MUTATION_BUSINESS_ADD_MEMBERSHIP = gql`

	mutation BusinessAddMembership($id: String!, $firstname: String!, $lastname: String!, $phone: String!, $email: String!, $familyPlan: Boolean, $spouseFirstName: String, $spouseLastName: String, $members: [FamilyMemberInput]) {
		businessAddMembership(id: $id, firstname: $firstname, lastname: $lastname, phone: $phone, email: $email, familyPlan: $familyPlan, spouseFirstName: $spouseFirstName, spouseLastName: $spouseLastName, members: $members)
	}

`;

export default MUTATION_BUSINESS_ADD_MEMBERSHIP;