import gql from 'graphql-tag';

const MUTATION_ORDER_RESOURCES = gql`

	mutation OrderResources($slug: String, $ids: [String]!) {
		orderResources(slug: $slug, ids: $ids)
	}

`;

export default MUTATION_ORDER_RESOURCES;