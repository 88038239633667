/* eslint no-console: 0 */
import React, { useState, useEffect } from 'react';
import getRenewalsQuery from '../../../queries/getRenewals';
import Loading from '../../Loading';
import ReportTable from './ReportTable';
import DateSelector from './DateSelector';
import { Segment, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink, CSVDownload } from 'react-csv';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';

const formatAddress = ({street1, street2}) => {
	let address = street1;
	address = street2 ? (address + ' ' + street2) : address;
	return address;
};

function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint no-useless-escape: 0 */
	return re.test(email);
}

/*
Hey... how much of a headache would it be to make the file
ONLY include the information in the following columns?
A - FIRST AND LAST NAME,
F - Address,
H - City,
I - State,
J - ZIP, and
K - Country
*/


const headers = [
	{ label: 'A', key: 'name' },
	{ label: 'F', key: 'address'},
	{ label: 'H', key: 'city' },
	{ label: 'I', key: 'state' },
	{ label: 'J', key: 'zip' },
	{ label: 'K', key: 'country' },
];

const Report = ({ month, year, onChangeDate }) => {
	const [dataToDownload, setDataToDownload] = useState([]);
	const { data, loading,  } = useQuery(getRenewalsQuery, {
		variables: { month, year },
	});

	const renewals = _.get(data, 'getRenewals', []) || [];

	useEffect(() => {

	}, [month, year]);

	const onDownload = async (event, done) => {
		if(!renewals || renewals.length <= 0) {
			alert('There isn\'t any data to download.');
		} else {
			const noEmailList = renewals.filter(ob => {
				const email = _.get(ob, 'orderInformation.email');
				return (!email || !validateEmail(email));
			});

			if(noEmailList.length <= 0) {
				alert('All renewals have emails');
			} else {
				const formattedList = noEmailList.map(({ orderInformation }) => {
					const { firstname, lastname, shippingAddress } = orderInformation;
					return {
						name: `${firstname} ${lastname}`,
						...shippingAddress,
						address: formatAddress(shippingAddress),
					};
				});
				await setDataToDownload(formattedList);
				done();
				return true;
			}
		}
		done(false);
	};
	
	return (
		<>
			<div className="dnp-pageHeader">Renewal Report</div>
			<Segment attached='top' style={{display: 'flex', justifyContent: 'space-between'}}>
				<DateSelector onSubmit={onChangeDate} />
				<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
					<h5 style={{ textAlign: 'center', marginBottom: '5px' }}>Excludes Customers<br />with Emails</h5>
					<CSVLink
						asyncOnClick={true}
						onClick={onDownload}
						data={dataToDownload}
						headers={headers}
						filename={`renewals-${month}-${year}.csv`}
					>
						<Button>
							<FontAwesomeIcon icon={['fas', 'download']} size="lg" />
						</Button>
					</CSVLink>
				</div>
			</Segment>
			<Segment attached style={{flex: '1'}}>
				<div className="table-segment">
					{ loading ? <Loading /> : <ReportTable data={renewals} /> }
				</div>
			</Segment>
		</>
	);
};

export default Report;