import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Button, Table } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import GraphQLError from '../Message/GraphQLError';
import TextInput from '../FinalForm/TextInput';
import TextAreaInput from '../FinalForm/TextAreaInput';

class ChangeTextArea extends Component {

	renderText = (property, value) => {
		const { table, basic } = this.props;
		if(table || basic) {
			return (
				<span
					className="changeInformation__value"
					style={{ marginLeft: 0 }}
				>
					{value || 'N/A'}
				</span>
			);
		}
		return (
			<span className="changeInformation__value">{value || 'N/A'}</span>
		);
	}

	onSubmit = (values) => {
		const value = _.get(values, this.props.property, '');
		const additionalProperties = _.get(this.props, 'additionalProperties', {});

		const newValues = _.set({
			property: this.props.property,
			id: this.props.id,
		}, this.props.property, value);

		this.props.mutate({
			variables: { ...newValues, ...additionalProperties },
			refetchQueries: this.props.refetch,
		});
	}

	renderForm = (property, value) => {
		const initialValues = _.set({}, property, value);
		return(
			<FinalForm
				onSubmit={this.onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextAreaInput name={property} placeholder="New Value" label="New Value" required />
						</Form.Group>
						<Button fluid type='submit'>Change</Button>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</Form>
				)}
			/>
		);
	}

	renderField = (property, value) => {
		return  <Popup
			trigger={this.renderText(property, value)}
			content={this.renderForm(property, value)}
			on='click'
		/>;
	}

	render() {
		const { label, property, table, basic } = this.props;
		const redone = _.get(this.props, 'value', '') || '';

		if(table) {
			return(
				<Table.Row>
					<Table.Cell>{label}</Table.Cell>
					<Table.Cell>{this.renderField(property, redone)}</Table.Cell>
				</Table.Row>
			);
		}

		if(basic) {
			return this.renderField(property, redone);
		}

		return (
			<p><strong>{label}:</strong>{this.renderField(property, redone)}</p>
		);
	}
}

export default ChangeTextArea;