import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import axios from 'axios';


const Print = ({ id }) => {
	const [loading, setLoading] = useState(false); 

	const print = async () => {
		setLoading(true);
		try {
			const res = await axios.post('/api/admin/renewal/print', { id });
			var w = window.open();
			w.document.write(res.data);
		} catch (error) {
			alert('Could not generate document');
		}
		setLoading(false);
	};

	return (
		<Button 
			content="Print"
			onClick={print}
			loading={loading}
			color="blue"
		/>
	);
};

export default Print;