import React from 'react';


const Page404 = () => {
	return(
		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px', flex: '1'}}>
			<div style={{textAlign: 'center'}}>
				<h1>404</h1>
				<h2>This page does not exist</h2>
			</div>
		</div>
	);
};

export default Page404;
