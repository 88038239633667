import gql from 'graphql-tag';

const addTrainingMutation = gql`

	mutation AddTraining($title: String!, $youtubeID: String!, $description: String!) {
		addTraining(title: $title, youtubeID: $youtubeID, description: $description) {
			id
		}
	}

`;

export default addTrainingMutation;