import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import TimeClock from './TimeClock';
import ViewHistory from './ViewHistory';

class Employee extends Component {
	state = {
		activeItem: 'times'
	}
	
	renderMenu = () => {
		const { activeItem } = this.state;
		return (
			<Menu secondary>
				<Menu.Item
					name='clock'
					active={activeItem === 'clock'}
					onClick={() => this.setState({ activeItem: 'clock' })}
				>Clock In/Out</Menu.Item>
				<Menu.Item
					name='times'
					active={activeItem === 'times'}
					onClick={() => this.setState({ activeItem: 'times' })}
				/>
			</Menu>
		);
	}

	renderArea = () => {
		if(this.state.activeItem === 'clock') {
			return <TimeClock />;
		} else if (this.state.activeItem === 'times') {
			return <ViewHistory />;
		}
	}

	render() {
		return (
			<div>
				{this.renderMenu()}
				{this.renderArea()}
			</div>
		);
	}
}

export default Employee;