import gql from 'graphql-tag';

const mutationDeleteSale = gql`

	mutation DeleteSale($id: String!) {
		deleteSale(id: $id)
	}

`;

export default mutationDeleteSale;