/* eslint no-unused-vars: 0 no-console: 0  */
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/react-hoc';
import SignatureCanvas from 'react-signature-canvas';

const mutation = gql`
	mutation($file: Upload) {
		testUpload(file: $file) {
			id
			filename
			encoding
			mimetype
			path
		}
	}
`;

class TestArea extends Component {
	state = {
		file: null
	}

	upload = () => {

	}

	onChange = ({ target: { validity, files: [file] } }) => {
		console.log('FILE', file);
		this.props.mutate({
			variables: {
				file
			},
		});
	}

	render() {
		return (
			<div className="flex-column flex-container">
				{/* <CorporatePlanPage /> */}
				<div style={{width: '100%', marginTop: '30px'}}>
					
					Testing
					<input
						type="file"
						required
						onChange={this.onChange}
					/>
					<button onClick={this.upload}>Test Upload</button>

					<div style={{ width: '100%', height: 200 }}>
						<SignatureCanvas penColor='black' canvasProps={{ style: { width: '100%', height: 200, backgroundColor: 'grey' } }} />
					</div>

				</div>
			</div>
		);
	}
}

export default graphql(mutation)(TestArea);