import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import queryGetTraining from '../../queries/getTraining';
import classnames from 'classnames';
import YouTube from 'react-youtube';
import modifyUserTrainingMutation from '../../mutations/modifyUserTraining';
import queryGetWatchedTraining from '../../queries/getWatchedTraining';

class SelectedTraining extends Component {

	onPlay = () => {
		const { id } = this.props.data.getTraining;
		this.props.mutate({
			variables: {
				trainingID: id,
				dateStarted: true,
			},
		});
	}

	onEnd = () => {
		const { id } = this.props.data.getTraining;
		this.props.mutate({
			variables: {
				trainingID: id,
				dateCompleted: true,
			},
			refetchQueries: [{
				query: queryGetWatchedTraining,
			}]
		});
	}

	render() {
		if(!this.props.id) { return <div></div>; }

		if(this.props.data.loading) {
			return <div></div>;
		}

		if(!this.props.data.getTraining) {
			return <div></div>;
		}


		const opts = {
			height: '390',
			width: '640',
			playerVars: { // https://developers.google.com/youtube/player_parameters
				autoplay: 0,
			}
		};

		const { created, createdBy, description, id, title, type, youtubeID } = this.props.data.getTraining;

		return (
			<div className="training__video">
				<hr style={{ width: '90%' }}/>
				<YouTube
					videoId={youtubeID}
					opts={opts}
					onReady={() => console.log('Video is Ready!')}
					onPlay={this.onPlay}
					onStateChange={() => console.log('State Changed!')}
					onEnd={this.onEnd}
				/>
				<h2>{title}</h2>
				<p>{description}</p>
			</div>
		);
	}
}

export default compose (
	graphql(queryGetTraining, {
		options: (props) => { return { variables: { id: props.id } }; }
	}),
	graphql(modifyUserTrainingMutation),
)(SelectedTraining);