import React from 'react';
import DetailsTable from '../../../Table/DetailsTable';
import {
	ChangeTextFieldBusinessPlan,
	ChangeSelectFieldBusinessPlan,
} from '../helpers/BusinessPlanChangeFields';

export const PresentationFutureOptions = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
	{ label: 'Maybe', value: 'maybe' },
];

const PresentationInfo = ({ data, refetch }) => {
	const { presentation } = data;
	const {
		presentationContactName,
		presentationTime,
		presentationContactEmail,
		presentationFuture,
	} = presentation;

	return (
		<DetailsTable>
			<ChangeTextFieldBusinessPlan
				label='Contact Name'
				value={presentationContactName}
				property={'presentation.presentationContactName'}
				refetch={refetch}
				id={data.id}
				table
			/>
			<ChangeTextFieldBusinessPlan
				label='Contact Email'
				value={presentationContactEmail}
				property={'presentation.presentationContactEmail'}
				refetch={refetch}
				id={data.id}
				table
			/>
			<ChangeTextFieldBusinessPlan
				label='Not available to present'
				value={presentationTime}
				property={'presentation.presentationTime'}
				refetch={refetch}
				id={data.id}
				table
			/>
			<ChangeSelectFieldBusinessPlan
				options={PresentationFutureOptions}
				label={'Interested in making more than one presentation this year?'}
				value={presentationFuture}
				property={'presentation.presentationFuture'}
				refetch={refetch}
				id={data.id}
				table
			/>
		</DetailsTable>
	);
};

export default PresentationInfo;
