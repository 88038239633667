import React, { useState, useRef } from 'react';
import styles from './EditLogo.module.scss';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import slugify from '../../../utils/slugify';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_ADD_STORE_LOGO from './addStoreLogo';
import MUTATION_REMOVE_STORE_LOGO from './removeStoreLogo';

// 69 / 25

// 70 / 25 = 14 / 5

// 280 x 100

const iW = 280;
const iH = 100;

function blobToFile(theBlob, fileName){
	//A Blob() is almost a File() - it's just missing the two properties below which we will add
	theBlob.lastModifiedDate = new Date();
	theBlob.name = fileName;
	return theBlob;
}

const EditModal = ({ onClose, src, businessname, onLogoChanged }) => {
	const [crop, setCrop] = useState({
		aspect: 14 / 5,
		unit: '%',
		width: 50,
		height: 50,
		x: 25,
		y: 25
	});
	const imageRef = useRef(null);
	const fileURL = useRef(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const onCropComplete = async (c) => {
		if (imageRef.current && c.width && c.height) {
			const { file, url } = await getCroppedImg(
				imageRef.current,
				c,
				`${slugify(businessname)}-logo.png`,
			);
			setCroppedImage(url);
			onLogoChanged(file);
		}
	};

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = iW;
		canvas.height = iH;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			iW,
			iH
		);

		return new Promise(resolve => {
			canvas.toBlob(blob => {
				if (!blob) {
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(fileURL.current);
				fileURL.current = window.URL.createObjectURL(blob);
				resolve({ url: fileURL.current, file: blobToFile(blob, fileName) });
			}, 'image/png', 1);
		});
	};

	return (
		<Modal 
			size="large"
			closeIcon
			closeOnDimmerClick={false}
			open={!!src}
			onClose={onClose}
			basic
		>
			<Modal.Header>Crop the Image</Modal.Header>
			<Modal.Content>
				<div className={styles.imageSelector}>
					<ReactCrop 
						src={src}
						crop={crop}
						onChange={newCrop => setCrop(newCrop)}
						// onComplete={onCropComplete}
						onImageLoaded={image => {

							imageRef.current = image;
						}}
					/>
					<Button
						style={{ marginTop: '30px' }}
						color="blue"
						type="button"
						content="Change Logo"
						onClick={() => onCropComplete(crop)}
					/>
					{/* { croppedImage && <img src={croppedImage} />} */}
				</div>
			</Modal.Content>
		</Modal>
	);
};

const EditLogo = ({ businessname, storeID, src: imageSRC, query, refetchQueries }) => {
	const [src, setSrc] = useState(null);
	const [imageLoading, setImageLoading] = useState(!!imageSRC);
	const [addLogo, { data, loading, error }] = useMutation(MUTATION_ADD_STORE_LOGO);
	const [removeLogo, { data: data2, loading: loading2, error: error2 }] = useMutation(MUTATION_REMOVE_STORE_LOGO);

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () =>
				setSrc(reader.result)
			);
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	let refetch = React.useMemo(() => {
		if(refetchQueries) {
			return { refetchQueries, awaitRefetchQueries: true };
		} else if (query) {
			return {
				refetchQueries: [{ query }],
				awaitRefetchQueries: true
			};
		}
	}, [refetchQueries, query]);


	return (
		<div className={styles.logoBox}>
			<div className={styles.box}>
				<Dimmer active={loading || loading2 || imageLoading}>
					<Loader content='Loading' />
				</Dimmer>
				<label htmlFor="logo">Picture</label>
				<input id="logo" type="file" accept="image/*" onChange={onSelectFile} />
				{ imageSRC && <img src={imageSRC} draggable={false} onLoad={() => setImageLoading(false)} /> }
				{ !!imageSRC && <span onClick={() => {
					removeLogo({
						variables: { storeID },
						...refetch,
					});
				}}>Remove Logo</span> }
			</div>
			<EditModal
				src={src}
				onClose={() => setSrc(false)}
				businessname={businessname}
				onLogoChanged={(file) => {
					setSrc(null);
					addLogo({
						variables: {
							storeID,
							file,
						},
						...refetch,
					});
				}}
			/>
		</div>
	);
};

export default EditLogo;