import React from 'react';
import Promotion from './Promotion';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';

const PromotionList = ({ data }) => {
	const promotions = _.get(data, 'promotions', []);

	if(!promotions.length) {
		return (
			<Segment placeholder textAlign="center">
				<h4>This Business has no Promotions</h4>
			</Segment>
		);
	}

	return promotions.map((p, i) => <Promotion key={p.id} data={p} index={i+1} parentid={data.id} />);
};

export default PromotionList;