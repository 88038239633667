

const getStripeBaseURL = () => {
	if(process.env.NODE_ENV === 'production') {
		return 'https://dashboard.stripe.com';
	} else {
		return 'https://dashboard.stripe.com/test';
	}
};

export default getStripeBaseURL;