import gql from 'graphql-tag';

const mutationUnpauseClock = gql`

	mutation UnpauseClock {
		unPauseClock {
			id
			user
			in
			out
			paused {
				id
				start
				end
				reason
			}
			isOnBreak
			totalTime
			totalBreakTime
			totalPaidTime
		}
	}

`;

export default mutationUnpauseClock;