import React from 'react';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import { OrderDetailsTable, OrderDetailsRow } from '../helpers';
import plans from '../../../../config/plans';

const getFamilyMembers = (family) => {
	if(family && Array.isArray(family) && family.length) {
		return family.reduce((prev, curr, index) => {
			if(index) {
				if(curr.name) {
					return prev + ', ' + curr.name;
				}
			} else {
				return prev + _.get(curr, 'name', '');
			}
		}, '');
	}
	return 'None';
};

const getSpouseName = (spouseFirstName, spouseLastName) => {
	if(!spouseFirstName && !spouseLastName) {
		return 'N/A';
	} else {
		return `${spouseFirstName || ''} ${spouseLastName || ''}`;
	}
};

const MembershipCard = ({ membership }) => {
	const { id, membershipID, firstname, lastname, email, phone, additionalFamilyMembers=[], spouseFirstName, spouseLastName, plan } = membership;
	const isFamily = _.get(plans, `[${plan}].isFamily`, false);
	return (
		<Segment>
			<OrderDetailsTable>
				<OrderDetailsRow
					property={'Database ID'}
					value={<a
						href={`/admin/memberships/${id}`}
						target="_blank"
						rel="noreferrer"
					>{id}</a>}
				/>
				<OrderDetailsRow
					property={'Membership ID'}
					value={membershipID}
				/>
				<OrderDetailsRow
					property={'Name'}
					value={`${firstname} ${lastname}`}
				/>
				<OrderDetailsRow
					property={'Email'}
					value={email}
				/>
				<OrderDetailsRow
					property={'Phone'}
					value={phone}
				/>
				<OrderDetailsRow
					property={'Plan'}
					value={_.get(plans, `[${plan}].name`, 'Unknown')}
				/>
				{
					isFamily &&
					<>
						<OrderDetailsRow
							property={'Spouse Name'}
							value={getSpouseName(spouseFirstName, spouseLastName)}
						/>
						<OrderDetailsRow
							property={'Family Members'}
							value={getFamilyMembers(additionalFamilyMembers)}
						/>
					</>
				}
			</OrderDetailsTable>
		</Segment>
	);
};

const MembershipInfo = ({ data, refetch }) => {
	const li = _.get(data, 'membershipInformation.memberships', []);

	return (
		<div className="admin-sale-info">
			<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Membership Info</h4>
			<p>To edit this information, please go to the membership table</p>
			<hr />
			{li.map((membership) => <MembershipCard key={membership.id} membership={membership} />)}
		</div>
	);
};

export default MembershipInfo;