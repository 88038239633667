import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import AddTrainingVideo from './AddTrainingVideo';
import TrainingList from './TrainingList';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

class ManageTraining extends Component {
	render() {
		return (
			<div className="ui container" style={{ marginTop: 10 }}>
				<h1 style={{ textAlign: 'center' }}>Manage Training</h1>
				<Segment>
					<AddTrainingVideo />
				</Segment>
				<DndProvider backend={HTML5Backend}>
					<TrainingList />
				</DndProvider>
			</div>
		);
	}
}

export default ManageTraining;