/****************************************
Component for Redux Form that searchs for accounts by name, email, or reference code.
****************************************/

import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';

export class ReferenceField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetching: false,
			loading: false,
			searchQuery: null,
			options: [],
			searchType: 'email',
			open: false,
			value: null
		};
	}

	handleChange = (e, { value }) => {
		this.setState( { value });
		this.props.onChange(value);
	}

	handleSearchChange = async (e, { searchQuery }) => {
		if(searchQuery !== '')
		{
			const response = await axios.post('/api/search/reference', { search: searchQuery });

			const d = response.data.map((obj) => {
				return({key: obj._id, text: `${_.capitalize(obj.firstname)} ${_.capitalize(obj.lastname)} - ${obj.email}`, value: obj._id});
			});

			await this.setState({options: d});
		}
		else {
			this.props.input.onChange(null);
			await this.setState({options: []});
		}

	}

	handleSearchTypeChange = (e, { value }) => {
		this.setState({ searchType: value });
	}

	render() {
		return(
			<Form.Field>
				<Dropdown
					fluid
					selection
					search
					options={this.state.options}
					value={this.state.value}
					placeholder='Search by name, email or reference code'
					onChange={this.handleChange}
					onSearchChange={this.handleSearchChange}
					disabled={this.state.isFetching}
					loading={this.state.isFetching}
					style = {{
						minWidth: '300px'
					}}
				/>
			</Form.Field>
		);
	}
}
