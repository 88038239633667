import React from 'react';
import { Button } from 'semantic-ui-react';
import styles from './EditMembership.module.scss';
import GraphQLError from '../../../Message/GraphQLError';
import { DetailsTable, DetailsRow } from './helpers';
import DeleteModal from '../../../common/DeleteModal/DeleteModal';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CANCEL_MEMBERSHIP from '../../../../mutations/cancelMembership';
import QUERY_GET_MEMBERSHIP from '../../../../queries/getMembership';
import _ from 'lodash';
import PurchasePhysicalCard from './helpers/PurchasePhysicalCard';

const Status = ({ membership, onClose }) => {
	const [deleteModal, setDeleteModal] = React.useState(false);
	const {
		id,
		updatedStatus: status,
		canceled,
		createdAt,
		period_start,
		period_end,
		stripe,
		lastInvoiceRefunded,
	} = membership;
	const statusStyle = lastInvoiceRefunded ? 'refunded' : status.replace('_', '');

	const [deleteMembership, { data, loading, error }] = useMutation(MUTATION_CANCEL_MEMBERSHIP);

	React.useEffect(() => {
		if(_.get(data, 'cancelMembership', null) === false) {
			onClose && onClose();
		}
	}, [data]);

	return (
		<div className={styles.statusContainer}>
			<DeleteModal
				open={deleteModal}
				onClose={() => setDeleteModal(false)}
				onDelete={() => {
					setDeleteModal(false);
					deleteMembership({
						variables: { id },
						refetchQueries: [{
							query: QUERY_GET_MEMBERSHIP,
							variables: { id }
						}],
					});
				}}
			/>
			<h2 className={styles[statusStyle]}>
				{
					lastInvoiceRefunded ? (
						<>
						REFUNDED
						{canceled ? <span className={styles.bCanceld}> & CANCELED</span> : <span className={styles.bCanceld}> BUT NOT CANCELED</span>}
						</>
					) : (
						<>
							{status.replace('_', ' ').toUpperCase()}
							{canceled && <span className={styles.bCanceld}> BUT CANCELED</span>}
						</>
					)
				}
			</h2>
			<DetailsTable>
				<DetailsRow
					property='Created'
					value={createdAt}
					date
				/>
				<DetailsRow
					property='Current Period Start'
					value={period_start}
					date
				/>
				<DetailsRow
					property='Current Period End'
					value={period_end}
					date
				/>
				<DetailsRow
					property='Canceled At'
					value={stripe.canceled_at}
					condition={stripe && stripe.id}
					date
				/>
				<DetailsRow
					property='Ended At'
					value={stripe.ended_at}
					condition={stripe && stripe.id}
					date
				/>
			</DetailsTable>
			<GraphQLError error={error}>There was an issue</GraphQLError>
			{
				stripe && stripe.id && !canceled &&
				<div style={{ display: 'flex' }}>
					<PurchasePhysicalCard {...{ membership }} />
					<Button
						fluid
						color='red'
						onClick={() => setDeleteModal(true)}
						loading={loading}
						disabled={loading}
						content="Cancel Auto-Renewal"
					/>
				</div>
			}
		</div>
	);
};

export default Status;