import React, { Component } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	DropdownToggle,
	DropdownMenu,
	Dropdown,
	DropdownItem } from 'reactstrap';
import { firebaseAuth } from '../../config/firebase';
import { connect } from 'react-redux';
import CreateAccountModal from '../User/Complex/CreateAccountModal';
import LoginModal from '../User/Complex/LoginModal';

class CustomNavbar extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.toggleDropDown = this.toggleDropDown.bind(this);
		this.state = {
			isOpen: false,
			dropDownOpen: false,
		};
	}

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	toggleDropDown() {
		this.setState({
			dropDownOpen: !this.state.dropDownOpen
		});
	}

	renderAccount() {
		var firstname;
		var lastname;

		if(this.props.userInfo) {
			firstname = this.props.userInfo.firstname;
			lastname = this.props.userInfo.lastname;
		}

		if(firstname) {
			return (
				<Collapse isOpen={this.state.isOpen} navbar>
					<Nav className="ml-auto" navbar>
						<Dropdown nav isOpen={this.state.dropDownOpen} toggle={this.toggleDropDown}>
							<DropdownToggle nav style={{marginRight: '10px'}}>
								<p>{firstname} {lastname} <i className="fa fa-angle-down" aria-hidden="true"></i></p>
							</DropdownToggle>
							<DropdownMenu right>
								<DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
								<DropdownItem onClick={() => firebaseAuth().signOut()}><i className="fa fa-lock"></i> Logout</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Nav>
				</Collapse>
			);
		}


		return(
			<Collapse isOpen={this.state.isOpen} navbar>
				<Nav className="ml-auto" navbar>
					<NavItem>
						<LoginModal />
					</NavItem>
				</Nav>
				<span className="navbar-text" style={{marginRight: '8px'}}>Or</span>
				<form className="form-inline my-2 my-lg-0">
					<CreateAccountModal buttonbootstrap />
				</form>
			</Collapse>
		);

	}

	render() {




		return(
			<div>
				<Navbar color="faded" light expand="md" style={{
					backgroundColor: '#f2f2f2',
				}}>
					<NavbarBrand href="/">Deluxe Network Program</NavbarBrand>
					<NavbarToggler onClick={this.toggle} />
					{this.renderAccount()}
				</Navbar>
			</div>
		);
	}
}

function mapStateToProps({userInfo}) {
	return({userInfo});
}

export default connect(mapStateToProps)(CustomNavbar);