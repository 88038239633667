import React, { useState, useEffect } from 'react'; import { Segment, Button } from 'semantic-ui-react'; import 
{ FontAwesomeIcon } from '@fortawesome/react-fontawesome'; import AddPromotion from './AddPromotion'; import 
DetailsTable from '../../../Admin/Memberships/EditMembership/helpers/DetailsTable'; import { DetailsRow } from 
'../../../Table'; import DeleteModal from '../../../common/DeleteModal/DeleteModal'; import { useMutation } from 
'@apollo/react-hooks'; import MUTATION_DELETE_PROMOTION from '../../../../mutations/deletePromotion';

const Promotion = ({ promotion, index, onDelete }) => {
	const {
		id,
		code,
		info,
		url,
		additionalURL,
		validUntil,
		online,
		file
	} = promotion;

console.log("id",index,id)
	return (
		<div style={{ marginTop: '10px' }}>
			<Segment attached="top">
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h2>Message #{index}</h2>
					<div>
						<Button
							color="red"
							type="button"
							content={(
								<>
									<FontAwesomeIcon icon={['fas', 'trash']} />
								</>
							)}
							onClick={() => onDelete(id)}
						/>
					</div>
				</div>
			</Segment>
			<Segment attached>
				<DetailsTable header={false}>
					<DetailsRow
						property="Description"
						value={info}
						html
					/>
					<DetailsRow
						property="Code"
						value={code}
						condition={!!code}
					/>
					<DetailsRow
						property="URL"
						value={url}
						condition={!!url}
					/>
					<DetailsRow
						property="Additional URL"
						value={additionalURL}
						condition={!!additionalURL}
					/>
					<DetailsRow
						property="Online Deal?"
						value={'Yes'}
						condition={!!online}
					/>
					<DetailsRow
						property="File"
						value={(
							<a
								href={file ? file.path : ''}
								target="_blank"
							>
								{file ? file.filename : ''}
							</a>
						)}
						condition={!!(file && file.id)}
					/>
				</DetailsTable>
			</Segment>
		</div>
	);
};

const Promotions = ({ info, query, refetch }) => {

	//let promotions = [];
	const [promotions,setPromotions]=useState([]);
	const [total,setTotal]=useState(0);
	//promotions = info?.promotions;
	//const total = promotions ? promotions.length : 0;
	const [dID, setdID] = useState(null);
	const [deletePromotion, { data, loading, error }] = useMutation(MUTATION_DELETE_PROMOTION);
	

	useEffect(() => {
		if(!loading) {
			setdID(false);
		}
	}, [loading]);
	
useEffect(()=>{ 
console.log("Promotions INFO",info)
setPromotions(info?.promotions||[])
setTotal(info?.promotions?.length || 0)
},[info])

	return (
		<div>
			<div>
				<DeleteModal
					open={!!dID}
					onClose={() => {
						!loading && setdID(null);
					}}
					loading={loading}
					onDelete={() => {
						console.log("delete clicked")
						if(dID) {
							console.log("delete promotion")
							deletePromotion({
								variables: { 
									id: info.id,
									promotionID: dID
								},
																awaitRefetchQueries: true,
							});
							refetch()
								window.location.reload();
						}
					}}
				/>
				<AddPromotion refetch={refetch} info={info} query={query} />
			</div>
			{!total && (
				<Segment placeholder style={{ textAlign: 'center' }}>
					<h4>You have no promotions</h4>
				</Segment>
			)}
			{!!total && promotions && (
				<Segment placeholder>
					{promotions.map((p, i) => {
						if(p.id!== null){
							return(<Promotion 
								promotion={p}
								key={p.id}
								index={i+1}
								onDelete={id => setdID(id)}
							/>)
						}
					}
					)}
				</Segment>
			)} 
		</div>
	);
};

export default Promotions;
