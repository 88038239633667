import React, { useEffect } from 'react';
import {
	injectStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	StripeProvider,
	Elements,
} from 'react-stripe-elements';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Divider, Button } from 'semantic-ui-react';
import FSStripe from '../../../FinalForm/FSStripe';
import stylez from './ChangeSource.module.scss';
import * as Yup from 'yup';
import validator from '../../../../utils/validator';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import GraphQLError from '../../../Message/GraphQLError';
import { QUERY_BUSINESS_PLAN_USER } from '../Home';
import _ from 'lodash';

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'Source Code Pro, monospace',
				'::placeholder': {
					color: '#aab7c4'
				},
				padding
			},
			invalid: {
				color: '#9e2146'
			}
		}
	};
};

export const MUTATION_CHANGE_SOURCE_BUSINESS_PLAN = gql`
	mutation ChangeSourceBusinessPlan($id: String!, $token: String!) {
		changeSourceBusinessPlan(id: $id, token: $token)
	}
`;

const _ChangeSourceFormInside = ({ id, stripe, onClose, refetch }) => {
	const [changeSource, { data, loading, error }] = useMutation(MUTATION_CHANGE_SOURCE_BUSINESS_PLAN);

	const onSubmit = ({ stripeName, stripeZip }) => {
		stripe.createToken({
			'name': stripeName,
			'address_zip': stripeZip,
		}).then(({ token, error }) => {
			changeSource({
				variables: { id, token: token.id },
				refetchQueries: refetch ? refetch : [{ query: QUERY_BUSINESS_PLAN_USER }],
				awaitRefetchQueries: true,
			});
		});
	};

	useEffect(() => {
		if(_.get(data, 'changeSourceBusinessPlan')) {
			onClose();
		}
	}, [data]);

	return (
		<div>
			<FinalForm
				validate={validator(schema)}
				onSubmit={onSubmit}
				render={({ handleSubmit, valid }) => (
					<Form onSubmit={handleSubmit} loading={loading}>
						<div className={stylez.section}>
							<div className="stripe">
            
								<Form.Group widths="equal">
            
									<Form.Field>
										<label>Name on Card
											<Field
												name="stripeName"
												component="input"
												style={{width: '100%'}}
												placeholder="Cardholder Name"
											/>
										</label>
									</Form.Field>
            
									<Form.Field>
										<label>Card #
											<FSStripe
												component={CardNumberElement}
												{...createOptions(20)}
												name="stripeCard"
											/>
										</label>
									</Form.Field>
								</Form.Group>
            
								<Form.Group widths="equal">
            
									<Form.Field>
										<label>EXP
											<FSStripe
												{...createOptions(20)}
												component={CardExpiryElement}
												name="stripeExpiry"
											/>
										</label>
									</Form.Field>
            
									<Form.Field>
										<label>CVC
											<FSStripe
												component={CardCvcElement}
												{...createOptions(20)}
												name="stripeCVC"
											/>
										</label>
									</Form.Field>
                                
									<Form.Field>
										<label>
                                        Zip
											<Field
												name="stripeZip"
												component="input"
												style={{width: '100%'}}
												placeholder="Zip Code"
											/>
										</label>
									</Form.Field>
            
								</Form.Group>
							</div>
							<div className={stylez.poweredBy}>
								<a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
									<img src={require('../../../../img/powered_by_stripe.svg')} />
								</a>
							</div>
						</div>
						<Divider />
						<GraphQLError error={error} />
						<div className="containerRight">
							<Button
								content="Change Payment"
								type="submit"
								color="blue"
								disabled={!valid || loading}
								loading={loading}
							/>
						</div>
					</Form>
				)}
			/>
		</div>
	);
};


const schema = Yup.object().shape({
	stripeName: Yup.string().required('Required Field'),
	stripeCard: Yup.object().required().shape({
		complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	}),
	stripeExpiry: Yup.object().required().shape({
		complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	}),
	stripeCVC: Yup.object().required().shape({
		complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
	}),
	stripeZip: Yup.string().required('Required Field'),
});


const ChangeSourceFormInside = injectStripe(_ChangeSourceFormInside);
const ChangeSourceForm = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<ChangeSourceFormInside {...props} />
			</Elements>
		</StripeProvider>
	);
};

export default ChangeSourceForm;