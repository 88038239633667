import React from 'react';
import { Modal, Button, Divider } from 'semantic-ui-react';
import FileViewer from 'react-file-viewer';
import determineFileType from './determineFileType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_DELETE_FILE from './graphql/deleteFile';
import _ from 'lodash';
import styles from './Documents.module.scss';
import YouTube from 'react-youtube';
import QUERY_GET_FOLDER from './graphql/getFolder';
import DeleteModal from '../common/DeleteModal/DeleteModal';
import EditFile from './EditFile';

function youtube_parser(url){
	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	var match = url.match(regExp);
	return (match&&match[7].length==11)? match[7] : false;
}

const opts = {
	height: '390',
	width: '640',
	playerVars: { // https://developers.google.com/youtube/player_parameters
		autoplay: 0,
	}
};

const FileContent = ({ file }) => {
	const { mimetype, external, path, link } = file;
	const { type } = determineFileType(mimetype, external, link);
	if (type === 'youtube') {
		return (
			<div className={styles.centerTextStyle}>
				<YouTube
					videoId={youtube_parser(file.path)}
					opts={opts}
					onReady={() => console.log('Video is Ready!')}
				/>
			</div>
		);
	} else if (type === 'link') {
		return (
			<div className={styles.centerTextStyle}>
				<a href={file.path} target="_blank">{file.path}</a>
				<Button
					content="Open Link in New Window"
					color="blue"
					style={{ marginTop: '20px', marginBottom: '20px' }}
					as="a"
					href={file.path}
					target="_blank"
				/>
			</div>
		);
	} else if (type === 'pdf2') {
		const baseLink = process.env.NODE_ENV === 'production' ? 'https://office.deluxenetworkprogram.com' : 'http://dnphook.ngrok.io';
		const link = escape(`${baseLink}${path}`);
		return (
			<iframe
				src={`https://docs.google.com/viewer?url=https://office.deluxenetworkprogram.com${link}&embedded=true`}
				style={{ width: '100%', height: '500px' }}
				frameBorder="0"
			>
			</iframe>
		);
	} else if (type === 'pptx')  {
		const baseLink = process.env.NODE_ENV === 'production' ? 'https://office.deluxenetworkprogram.com' : 'http://dnphook.ngrok.io';
		const link = escape(`${baseLink}${path}`);
		return (
			<iframe
				src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
				style={{ width: '100%', height: '500px' }}
				frameBorder="0"
			>
			</iframe>
		);
	}
	else {
		return(
			<FileViewer
				fileType={type}
				filePath={`${path}`}
				errorComponent={() => <ErrorComponent />}
				onError={err => console.log(err)}
			/>
		);
	}
};

const FileModal = ({ file, onClose, slug, folderID, admin, folderName, tag }) => {
	const [deleteFile, { data, loading }] = useMutation(MUTATION_DELETE_FILE);
	const [show, setShow] = React.useState(false);
	React.useEffect(() => {
		if(_.get(data, 'deleteFile', false)) {
			onClose();
		}
	}, [data]);
	
	if(!file || !file.id) { return null; }
	const { mimetype, external } = file;
	const { type } = determineFileType(mimetype, external);

	return (
		<>
			<DeleteModal
				open={show}
				onClose={() => setShow(false)}
				onDelete={() => {
					setShow(false);
					deleteFile({
						variables: {
							fileID: file.id,
							folderID,
						},
						refetchQueries: [{ query: QUERY_GET_FOLDER, variables: { slug, tag } }],
					});
				}}
			/>
			<Modal
				open={file && file.id}
				onClose={onClose}
				closeIcon
			>
				<Modal.Header style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
					<span style={{ flex: 1 }}>{file && file.name}</span>
					{ admin && (
						<EditFile {...{ file, folderID, folderName, tag, onFinish: () => onClose() }} />
					)}
					{ admin && <Button
						onClick={() => setShow(true)}
						color="red"
						size="small"
						content={<FontAwesomeIcon icon={['fas', 'trash']} />}
						loading={loading}
					/> }
					{ file && file.id && type !== 'youtube' && (
						<a href={file.path} download={file.filename} style={{ marginLeft: '10px' }}>
							<FontAwesomeIcon icon={['fas', 'download']} size='lg' color='#34495e' className="file__list__item__icon__button" />
						</a>
					)}
				</Modal.Header>
				<Modal.Content>
					<FileContent file={file} />
					{(!!file && !!file.description) && (
						<>
							<Divider horizontal>Description</Divider>
							<p>{file.description}</p>
						</>
					)}
				</Modal.Content>
			</Modal>
		</>
	);
};

const ErrorComponent = () => {
	return (
		<div>
			<p>Could not Load File...</p>
		</div>
	);
};

export default FileModal;