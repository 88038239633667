/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react';
import { Button, Modal, Form, Divider } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_PURCHASE_PHYSICAL_CARD from '../../../../../mutations/purchasePhysicalCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FSTextInput from '../../../../FinalForm/TextInput';
import FSCountry from '../../../../FinalForm/FSCountry';
import FSState from '../../../../FinalForm/FSState';
import FSStripe from '../../../../FinalForm/FSStripe';
import {
	injectStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	StripeProvider,
	Elements
} from 'react-stripe-elements';
import styles from '../../../../SellMemberships/checkoutForm.module.scss';
import GraphQLError from '../../../../Message/GraphQLError';
import _ from 'lodash';
import { Header } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';
import { Container } from 'semantic-ui-react';

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'Source Code Pro, monospace',
				'::placeholder': {
					color: '#aab7c4'
				},
				padding
			},
			invalid: {
				color: '#9e2146'
			}
		}
	};
};

const PaymentInformationSection = () => {
	return (
		<div className={styles.section}>
			<div className="stripe">
				
				<Form.Group widths="equal">

					<Form.Field>
						<label>Name on Card
							<Field
								name="stripeName"
								component="input"
								style={{width: '100%'}}
								placeholder="Cardholder Name"
							/>
						</label>
					</Form.Field>

					<Form.Field>
						<label>Card #
							<FSStripe
								component={CardNumberElement}
								{...createOptions(20)}
								name="stripeCard"
							/>
						</label>
					</Form.Field>
				</Form.Group>

				<Form.Group widths="equal">

					<Form.Field>
						<label>EXP
							<FSStripe
								{...createOptions(20)}
								component={CardExpiryElement}
								name="stripeExpiry"
							/>
						</label>
					</Form.Field>

					<Form.Field>
						<label>CVC
							<FSStripe
								component={CardCvcElement}
								{...createOptions(20)}
								name="stripeCVC"
							/>
						</label>
					</Form.Field>
					
					<Form.Field>
						<label>
							Zip
							<Field
								name="stripeZip"
								component="input"
								style={{width: '100%'}}
								placeholder="Zip Code"
							/>
						</label>
					</Form.Field>
				</Form.Group>
				
			</div>
			<div className={styles.poweredBy}>
				<a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
					<img src={require('../../../../../img/powered_by_stripe.svg')} />
				</a>
			</div>
		</div>
	);
};

const ShippingAddress = () => {
	return (
		<>
			<h3>Shipping Address</h3>
			<Form.Group widths='equal'>
				<FSTextInput
					name="address.street1"
					placeholder="Street 1"
					label="Street"
					required
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'map-marker']} className="inputIcon" /> }
					size='large'
				/>
			</Form.Group>
			<Form.Group widths='equal'>
				<FSTextInput
					name="address.street2"
					placeholder="Street 2"
					label={false}
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'map-marker']} className="inputIcon" /> }
					size='large'
				/>
			</Form.Group>
			<Form.Group widths='equal'>
				<FSTextInput
					name="address.city"
					placeholder="City"
					required
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'city']} className="inputIcon" /> }
					size='large'
				/>
				<FSState
					name="address.state"
					placeholder="State"
					required
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'map-marked']} className="inputIcon" /> }
					size='large'
				/>
			</Form.Group>
			<Form.Group widths='equal'>
				<FSTextInput
					name="address.zip"
					placeholder="Zipcode"
					required
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'location']} className="inputIcon" /> }
					size='large'
				/>
				<FSCountry
					name="address.country"
					placeholder="Country"
					required
					iconPosition="left"
					fontAwesomeIcon={ <FontAwesomeIcon icon={['fas', 'globe-americas']} className="inputIcon" /> }
					size='large'
				/>
			</Form.Group>
		</>
	);
};

const _PurchasePhysicalCardForm = ({ membership, stripe }) => {
	const [purchaseCard, { data, loading, error }] = useMutation(MUTATION_PURCHASE_PHYSICAL_CARD);

	if(_.get(data, 'purchasePhysicalCard.id')) {
		const id = _.get(data, 'purchasePhysicalCard.id');
		return (
			<Container textAlign="center">
				<Header as='h2' icon>
					<Icon name='checkmark' />
					Purchase Completed
					<Header.Subheader>
						If the membership had an email, the membership's owner will be emailed a receipt.
					</Header.Subheader>
				</Header>
				<div style={{ display: 'flex' }}>
					<Button
						content="Open Receipt"
						fluid
						color="blue"
						as="a"
						target="_blank"
						href={`/api/receipt?id=${id}`}
					/>
					<Button
						content="Open Order"
						fluid
						color="purple"
						as="a"
						target="_blank"
						href={`/admin/orders/${id}`}
					/>
				</div>
			</Container>
		);
	}

	const onSubmit = ({ stripeName, stripeZip, membershipID, address }) => {
		stripe.createToken({
			'name': stripeName,
			'address_zip': stripeZip,
		}).then(({ token, error }) => {
			if(error || !token.id) {
				alert('Card Failure');
			} else {
				purchaseCard({
					variables: {
						membershipID, address,
						token: token.id
					}
				});
			}
		});
	};

	return (
		<FinalForm
			initialValues={{ membershipID: membership.id }}
			onSubmit={onSubmit}
			render={({ handleSubmit, valid }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<ShippingAddress />
					<PaymentInformationSection />
					<Divider horizontal />
					<GraphQLError error={error} />
					<Button
						content="Purchase Physical Card ( $10 )"
						color="blue"
						loading={loading}
						disabled={loading || !valid}
						fluid
						type="submit"
					/>
				</Form>
			)}
		/>
	);
};

const PurchasePhysicalCardForm = injectStripe(_PurchasePhysicalCardForm);

const PurchasePhysicalCardFormContainer = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<PurchasePhysicalCardForm {...props} />
			</Elements>
		</StripeProvider>
	);
};

const PurchasePhysicalCardModal = ({ open, onClose, membership }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
			header={<Modal.Header>Purchase Physical Card</Modal.Header>}
			content={(
				<Modal.Content>
					<PurchasePhysicalCardFormContainer {...{ membership }} />
				</Modal.Content>
			)}
		/>
	);
};

const PurchasePhysicalCard = ({ membership }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<PurchasePhysicalCardModal onClose={() => setOpen(false)} {...{ membership, open }}/>
			<Button
				fluid
				color='purple'
				onClick={() => setOpen(true)}
				content="Purchase Physical Card"
			/>
		</>
	);
};

export default PurchasePhysicalCard;