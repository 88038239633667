import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Form  } from 'semantic-ui-react';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { useApolloClient } from '@apollo/react-hooks';
import QUERY_SEARCH_FOR_REFERENCE from '../../queries/searchForReference';

const FSReference = ({ options, name, required, label, placeholder, containerStyle, includeAllUsers=false }) => {
	const client = useApolloClient();
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div style={containerStyle}>
						<AsyncSelect
							cacheOptions
							value={input.value}
							loadOptions={async (value, callback) => {
								const result = await client.query({
									query: QUERY_SEARCH_FOR_REFERENCE,
									variables: { value, includeAllUsers },
								});
								return _.get(result, 'data.searchForReference', []).map(ref => ({
									value: ref.id,
									label: `${ref.firstname} ${ref.lastname} - ${ref.email}`,
								}));
							}}
							onChange={value => input.onChange(value)}
							isClearable
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSReference;