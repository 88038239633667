import gql from 'graphql-tag';


const QUERY_BUSINESS_PLAN_USER = gql`
	query CurrentUser {
		user {
			id
			businessPlan {
				id
				presentation {
					presentationContactName
					presentationTime
					presentationContactEmail
					presentationFuture
					opted
				}
			}
		}
	}
`;

export default QUERY_BUSINESS_PLAN_USER;