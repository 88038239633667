import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../FinalForm/TextInput';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_ADD_FOLDER from './graphql/addFolder';
import GraphQLError from '../Message/GraphQLError';
import _ from 'lodash';
import FSSelectInput from '../FinalForm/SelectInput';
import FSTextAreaInput from '../FinalForm/TextAreaInput';
import FSFileInput from '../FinalForm/FileInput';
import MUTATION_ADD_FILE from './graphql/addFile';
import QUERY_GET_FOLDER from './graphql/getFolder';

const AddFile = ({ slug, tag }) => {
	const [modal, setModal] = React.useState(false);
	return (
		<>
			<AddFileModal
				open={modal}
				onClose={() => setModal(false)}
				slug={slug}
				tag={tag}
			/>
			<Button
				content="Add File"
				onClick={() => setModal(true)}
				color="green"
			/>
		</>
	);
};

const AddFileModal = ({ slug, open, onClose, tag }) => {
	const [addFile, { data, loading, error }] = useMutation(MUTATION_ADD_FILE);

	React.useEffect(() => {
		if(_.get(data, 'addFile.id')) {
			onClose();
		}
	}, [data]);
	
	const onSubmit = (variables) => {
		addFile({
			variables,
			refetchQueries: [{ query: QUERY_GET_FOLDER, variables: { slug, tag }}],
		});
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
		>
			<Modal.Header>Add Folder</Modal.Header>
			<Modal.Content>
				<FinalForm
					initialValues={{ type: 'file', slug }}
					validate={validator(schema)}
					onSubmit={onSubmit}
					render={({ handleSubmit, valid, values }) => (
						<Form onSubmit={handleSubmit} loading={loading}>

							<Form.Group widths="equal">
								<FSSelectInput
									name="type"
									label="Content Type"
									options={options}
									placeholder="The type of the content"
									required
								/>
							</Form.Group>

							<Form.Group widths="equal">
								<FSTextInput
									name="name"
									label="File Name"
									placeholder="The name of the file"
									required
								/>
							</Form.Group>

							<Form.Group widths="equal">
								<FSTextAreaInput
									name="description"
									label="Description"
									placeholder="The description of the file"
									required
									// maxlength="100"
								/>
							</Form.Group>

							{
								values.type === 'file' &&
								<FSFileInput
									name="file"
									label={false}
								/>
							}

							{
								values.type === 'video' &&
								<FSTextInput
									name="url"
									label="Youtube Video URL"
									placeholder="The URL of the youtube video"
									required
								/>
							}
							{
								values.type === 'link' &&
								<FSTextInput
									name="url"
									label="Link to the website"
									placeholder="https://google.com"
									required
								/>
							}
							<GraphQLError error={error} />
							<Button
								type="submit"
								fluid
								color="blue"
								content="Add File"
								disabled={!valid}
								loading={loading}
							/>
						</Form>
					)}
				/>
			</Modal.Content>
		</Modal>
	);
};

const options = [
	{ label: 'File', value: 'file' },
	{ label: 'Video', value: 'video' },
	{ label: 'Link', value: 'link' },
];

var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
	type: Yup.string().required('Required Field').oneOf(['file', 'video', 'link'], 'Invalid Type'),
	url: Yup.string().when('type', {
		is: 'video',
		then: Yup.string().required('Required Field').matches(p, 'Invalid Youtube URL'),
	}),
	file: Yup.mixed().when('type', {
		is: 'file',
		then: Yup.mixed().test('fileName', 'Required Field', (value) => {
			return (value && !!value.name);
		}),
	})
});

export default AddFile;