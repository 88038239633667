/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import { Popup, Button, Table } from 'semantic-ui-react';
import withStateMutation from '../../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import queryGetStore from '../../../../queries/getStore';
import modifyPromotionMutation from '../../../../mutations/modifyPromotion';

class ChangeFile extends Component {
	state = {
		file: null
	}

	renderText = (property, value) => {
		const text = _.get(value, 'filename', 'N/A');
		const path = _.get(value, 'path', null);
		return (
			<span>
				<span className="changeInformation__value">{text}</span>
				{path && <span style={{display: 'block'}}><a href={path} target="_blank">View File</a></span>}
			</span>
		);
	}

	onSubmit = () => {
		if(this.state.file) {
			this.props.mutate({
				variables: {
					id: this.props.parentid,
					promotionID: this.props.id,
					file: this.state.file
				},
				refetchQueries: [{
					query: queryGetStore,
					variables: { id: this.props.parentid }
				}]
			});
		}
	}

	removeFile = () => {
		this.props.mutate({
			variables: {
				id: this.props.parentid,
				promotionID: this.props.id,
				file: 'null',
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	onChange = ({ target: { validity, files: [file] } }) => {
		this.setState({
			file
		});
	}

	renderInput = () => {
		return(
			<input
				type="file"
				required
				onChange={this.onChange}
			/>
		);
	}

	renderStuff = (property, value) => {
		return (
			<div>
				<h3>Upload File</h3>
				{this.renderInput()}
				<div style={{ marginTop: '.5rem', display: 'flex', justifyContent: 'space-between' }}>
					{<Button color='blue' disabled={!this.state.file} onClick={this.onSubmit}>Upload</Button>}
					{value && <Button color="red" onClick={this.removeFile}>Remove File</Button>}
				</div>
			</div>
		);
	}

	renderField = (property, value) => {
		return(
			<Popup
				trigger={this.renderText(property, value)}
				content={this.renderStuff(property, value)}
				on='click'
				position='top right'
			/>
		);
	}

	render() {
		const { children, property } = this.props;
		const redone = _.get(this.props, 'value', '') || '';
		return (
			<Table.Row>
				<Table.Cell>{children}</Table.Cell>
				<Table.Cell>{this.renderField(property, redone)}</Table.Cell>
			</Table.Row>
		);
	}
}

export default compose(
	graphql(modifyPromotionMutation),
	withStateMutation()
)(ChangeFile);