import ChangeTextField from '../../../ChangeField/ChangeTextField';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import ChangeDateField from '../../../ChangeField/ChangeDateField';
import ChangeSelectField from '../../../ChangeField/ChangeSelectField';
import modifyPromotionMutation from '../../../../mutations/modifyPromotion';
import ChangeTextArea from '../../../ChangeField/ChangeTextArea';
import ChangeRichText from '../../../ChangeField/ChangeRichText';

export const ChangeTextFieldPromotion = compose(
	graphql(modifyPromotionMutation),
	withStateMutation(),
)(ChangeTextField);

export const ChangeSelectFieldPromotion = compose(
	graphql(modifyPromotionMutation),
	withStateMutation(),
)(ChangeSelectField);

export const ChangeDateFieldPromotion = compose(
	graphql(modifyPromotionMutation),
	withStateMutation(),
)(ChangeDateField);

export const ChangeTextAreaPromotion = compose(
	graphql(modifyPromotionMutation),
	withStateMutation(),
)(ChangeTextArea);

export const ChangeRichTextPromotion = compose(
	graphql(modifyPromotionMutation),
	withStateMutation(),
)(ChangeRichText);