import React from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import Page401 from '../Page401/';
import Page404 from '../Page404/';
import Loading from '../Loading/';

import AddSale from './Sales/AddSale';
import NewCommissionReport from './NewCommissionReport';
import AdminTreeGraph from './TreeGraph';
import AddCorporatePlan from './Sales/AddCorporatePlan';
import ManagersPage from './Managers/ManagersPage';
import RenewalReport from './RenewalReport';
import Webinar from './Webinar';
import ManageTraining from './ManageTraining.js';
import AdminSalesPage from './NewSales';
import PromoCodes from './PromoCodes';
import AdminUsersPage from './Users';
import ManageStores from './Stores/ManageStores';
import ManageMemberships from './Memberships/ManageMemberships';
import SellCorporateMembership from './Sales/SellCorporateMembership';
import ReferralsPage from './Referrals';
import SeminarPage from './Seminars';
import Orders from './Orders';
import { useQuery } from '@apollo/react-hooks';
import QUERY_CURRENT_USER from '../../queries/user';
import BusinessPlans from './BusinessPlans';

function AdminRoute({
  component: Component,
  accountType,
  minLevel = 1,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        accountType >= minLevel === true ? (
          <Component {...props} />
        ) : (
          <Page401 />
        )
      }
    />
  );
}

const Admin = () => {
  const { data, loading } = useQuery(QUERY_CURRENT_USER);

  const user = _.get(data, 'user', {});
  const adminLevel = _.get(user, 'adminLevel', 0);

  if (loading) {
    return <Loading />;
  }

  return (
    <Switch>
      {/*Admin Protected Routes**/}

      {/* Level 1 Min */}
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/users/treegraph'
        component={AdminTreeGraph}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={3}
        path='/admin/users/managers'
        component={ManagersPage}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/users'
        component={AdminUsersPage}
      />
      <AdminRoute
        accountType={adminLevel}
        minLevel={1}
        path='/admin/users/:id'
        component={AdminUsersPage}
      />

      {/* <AdminRoute exact accountType={adminLevel} minLevel={1} path='/admin/sales' component={AdminSalesPage} /> */}
      {/* <AdminRoute exact accountType={adminLevel} minLevel={1} path='/admin/stores/add' component={AddBusiness} /> */}
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/stores'
        component={ManageStores}
      />
      <AdminRoute
        accountType={adminLevel}
        minLevel={1}
        path='/admin/stores/:id'
        component={ManageStores}
      />

      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/memberships'
        component={ManageMemberships}
      />
      <AdminRoute
        accountType={adminLevel}
        minLevel={1}
        path='/admin/memberships/:id'
        component={ManageMemberships}
      />

      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/referrals'
        component={ReferralsPage}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/seminar'
        component={SeminarPage}
      />

      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/orders'
        component={Orders}
      />
      <AdminRoute
        accountType={adminLevel}
        minLevel={1}
        path='/admin/orders/:id'
        component={Orders}
      />

      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={1}
        path='/admin/businessplans'
        component={BusinessPlans}
      />
      <AdminRoute
        accountType={adminLevel}
        minLevel={1}
        path='/admin/businessplans/:id'
        component={BusinessPlans}
      />

      {/* Level 2 Min */}
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={2}
        path='/admin/sales/add'
        component={AddSale}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={2}
        path='/admin/sales/addcorporateplan'
        component={AddCorporatePlan}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={2}
        path='/admin/sales/sellcorporateplan'
        component={SellCorporateMembership}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={2}
        path='/admin/sales/renewals'
        component={RenewalReport}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={2}
        path='/admin/tools/commissionreport'
        component={NewCommissionReport}
      />

      {/* Level 3 Min */}
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={3}
        path='/admin/sales/promocodes'
        component={PromoCodes}
      />
      {/* <AdminRoute exact accountType={adminLevel} minLevel={3} path='/admin/tools/users' component={ConnectedUser} /> */}
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={3}
        path='/admin/tools/webinar'
        component={Webinar}
      />
      <AdminRoute
        exact
        accountType={adminLevel}
        minLevel={3}
        path='/admin/tools/training'
        component={ManageTraining}
      />

      <Route component={Page404} />
    </Switch>
  );
};

export default Admin;
