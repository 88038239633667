import gql from 'graphql-tag';

const QUERY_GET_COMMISSION_REPORT = gql`
	query GetCommissionReport($month: Int!, $year: Int!) {
		getCommissionReport(month: $month, year: $year) {
			id
			calculated
			month
			year
			totals {
				sales
				commission
				cards
				individual
				family
				corporate
				otherSales
				basicBusinessPlan
				premiumBusinessPlan
			}
		}
	}
`;

export default QUERY_GET_COMMISSION_REPORT;