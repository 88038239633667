import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Table, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import GraphQLError from '../Message/GraphQLError';
import FSReference from '../FinalForm/FSReference';

class ChangeReference extends Component {
	state = {
		isOpen: false,
	}

	triggerPopup = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	renderText = (property, value) => {
		const { table } = this.props;
		const label = `${_.get(value, 'firstname', 'N/A')} ${_.get(value, 'lastname', '')}`;
		if(table) {
			return (
				<span
					className="changeInformation__value"
					style={{ marginLeft: 0 }}
					onClick={this.triggerPopup}
				>
					{label || 'N/A'}
				</span>
			);
		}
		return (
			<span className="changeInformation__value"  onClick={this.triggerPopup}>{label || 'N/A'}</span>
		);
	}

	onSubmit = (values) => {
		const value = _.get(values, `${this.props.property}.value`, null);
		const additionalProperties = _.get(this.props, 'additionalProperties', {});
		const newValues = _.set({
			property: this.props.property,
			id: this.props.id,
		}, this.props.property, value);
		this.props.mutate({
			variables: { ...newValues, ...additionalProperties },
			refetchQueries: this.props.refetch,
		});
	}

	renderForm = (property, value) => {
		const initialValues = _.set({}, property, {
			value: _.get(value, 'id'),
			label: value ? `${_.get(value, 'firstname')} ${_.get(value, 'lastname')} - ${_.get(value, 'email')}` : 'N/A',
		});
		return(
			<FinalForm
				onSubmit={this.onSubmit}
				initialValues={initialValues}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<FSReference
								name={property}
								placeholder="New Value"
								label="New Value"
								required
								containerStyle={{ minWidth: '200px' }}
								includeAllUsers={this.props.includeAllUsers || false}
							/>
							<Form.Field>
								<label>{'---'}</label>
								<Button>Submit</Button>
							</Form.Field>
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</Form>
				)}
			/>
		);
	}

	renderField = (property, value) => {
		return  <Popup
			trigger={this.renderText(property, value)}
			content={this.renderForm(property, value)}
			on='click'
			open={this.state.isOpen}
			fluid
		/>;
	}

	render() {
		const { label, property, table } = this.props;
		const redone = _.get(this.props, 'value');

		if(table) {
			return(
				<Table.Row>
					<Table.Cell>{label}</Table.Cell>
					<Table.Cell>{this.renderField(property, redone)}</Table.Cell>
				</Table.Row>
			);
		}

		return (
			<p><strong>{label}:</strong>{this.renderField(property, redone)}</p>
		);
	}
}

export default ChangeReference;