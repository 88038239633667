import React from 'react';
import { Segment } from 'semantic-ui-react';
import OrderDetailsTable from '../helpers/OrderDetailsTable';
import { OrderDetailsRow } from '../helpers';
import _ from 'lodash';
import moment from 'moment';
import plans from '../../../../config/plans';
import getStripeBaseURL from '../../../../config/getStripeBaseURL';
import getProductID from '../../../../config/getProductID';

const StripeCard = ({ product }) => {
  const { id, amount, period, plan, quantity, type } = product;
  console.log(product);
  const planID = _.get(plan, 'id', null);
  const productID = _.get(plan, 'product', null);
  let productName;

  if (planID !== null && !isNaN(planID) && Number(planID) <= 4) {
    productName = 'The Everything Card';
  } else {
    const i = !isNaN(planID) ? Number(planID) : planID;
    productName = _.get(plans, `[${getProductID(i)}].name`, 'Unknown Product');
  }

  return (
    <Segment>
      <OrderDetailsTable noHeader>
        <OrderDetailsRow property={'Stripe Line ID'} value={id} />
        <OrderDetailsRow property={'Amount ( in cents )'} value={amount} />
        {period && (period.start || period.end) && (
          <>
            <OrderDetailsRow
              property={'Period Start'}
              value={moment(period.start).format('MMMM Do YYYY, h:mm:ss a')}
            />
            <OrderDetailsRow
              property={'Period End'}
              value={moment(period.end).format('MMMM Do YYYY, h:mm:ss a')}
            />
          </>
        )}
        {planID !== null && (
          <>
            <OrderDetailsRow property={'Stripe Plan ID'} value={planID} />
            <OrderDetailsRow property={'Product Name'} value={productName} />
          </>
        )}
        {!!productID && (
          <OrderDetailsRow
            property={'Stripe Product ID'}
            value={
              <a
                href={`${getStripeBaseURL()}/subscriptions/products/${productID}`}
                target='_blank'
              >
                {productID}
              </a>
            }
          />
        )}
        <OrderDetailsRow property={'Quantity'} value={quantity} />
        <OrderDetailsRow property={'Type'} value={type} />
      </OrderDetailsTable>
    </Segment>
  );
};

const NonStripeCard = ({ product, stripe }) => {
  const { id, quantity, total } = product;
  return (
    <Segment>
      <OrderDetailsTable noHeader>
        <OrderDetailsRow
          property={'Product'}
          value={_.get(plans, `[${id}].name`, 'Unknown Product')}
        />
        <OrderDetailsRow property={'Quantity'} value={quantity || 0} />
        <OrderDetailsRow property={'Total'} value={(total || 0).toFixed(2)} />
        <OrderDetailsRow
          property={'Payment Type'}
          value={'Not Stripe'}
          condition={!stripe && !stripe.id}
        />
      </OrderDetailsTable>
    </Segment>
  );
};

const StripeInformation = ({ stripe, refunded = false }) => {
  const { id, object, total, customer, status, subscription, amount } = stripe;

  const isCharge = object === 'charge';

  return (
    <Segment>
      <h3>Stripe Information</h3>
      <OrderDetailsTable noHeader>
        <OrderDetailsRow
          property={`Stripe ${isCharge ? 'Charge' : 'Invoice'} ID`}
          value={
            <a
              href={`${getStripeBaseURL()}/${
                isCharge ? 'payments' : 'invoices'
              }/${id}`}
              target='_blank'
            >
              {id}
            </a>
          }
        />
        <OrderDetailsRow
          property={'Stripe Customer ID'}
          condition={!!stripe.customer}
          value={
            <a
              href={`${getStripeBaseURL()}/customers/${customer}`}
              target='_blank'
            >
              {customer}
            </a>
          }
        />
        {subscription && (
          <OrderDetailsRow
            property={'Stripe Subscription ID'}
            condition={!!subscription}
            value={
              <a
                href={`${getStripeBaseURL()}/subscriptions/${subscription}`}
                target='_blank'
              >
                {subscription}
              </a>
            }
          />
        )}
        <OrderDetailsRow
          property={'Total ( in cents )'}
          value={total || amount}
        />
        <OrderDetailsRow
          property={'Status'}
          value={refunded ? 'REFUNDED' : status}
        />
      </OrderDetailsTable>
    </Segment>
  );
};

const PaypalInformation = ({ totalAmount, paypal, refunded = false }) => {
  const { id, total, customer, status, subscription, billing_info } = paypal;
  console.log(paypal);
  //   const isCharge = object === 'charge';

  return (
  <>
  {status &&   <Segment>
      <h3>Paypal Information</h3>
      <OrderDetailsTable noHeader>
        <OrderDetailsRow
          property={'Paypal Subscription ID'}
          condition={!!id}
          value={id}
        />
        <OrderDetailsRow
          property={'Total ( in USD )'}
          value={billing_info.last_payment.amount.value || totalAmount}
        />
        <OrderDetailsRow
          property={'Status'}
          value={refunded ? 'REFUNDED' : status}
        />
      </OrderDetailsTable>
    </Segment>}
    </>
  );
};

const SaleInfo = ({ data, refetch }) => {
  const { stripe, paypal, products, refunded, total } = data;

  return (
    <div className='admin-sale-info'>
      <h4
        style={{
          textTransform: 'uppercase',
          fontWeight: '600',
          letterSpacing: '0.7px',
          color: 'rgba(0,0,0,.85)',
        }}
      >
        Sale Information
      </h4>
      <hr />
      {!!stripe && !!stripe.id && (
        <StripeInformation stripe={stripe} refunded={refunded} />
      )}
      {!!paypal && !!paypal.id && (
        <PaypalInformation
          paypal={paypal}
          totalAmount={total}
          refunded={refunded}
        />
      )}
      {products && Array.isArray(products) && !!products.length && (
        <>
          {!!stripe && !!stripe.id && (
            <>
              <h4
                style={{
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  letterSpacing: '0.7px',
                  color: 'rgba(0,0,0,.85)',
                }}
              >
                Products
              </h4>
              <hr />
            </>
          )}
          {products.map((product, i) => (
            <NonStripeCard
              product={product}
              key={`${product.id}-${i}`}
              stripe={stripe}
            />
          ))}
        </>
      )}
      {!!stripe &&
        !!stripe.id &&
        !!stripe.lines &&
        !!stripe.lines.data &&
        !!Array.isArray(stripe.lines.data) &&
        !!stripe.lines.data.length && (
          <>
            <h4
              style={{
                textTransform: 'uppercase',
                fontWeight: '600',
                letterSpacing: '0.7px',
                color: 'rgba(0,0,0,.85)',
              }}
            >
              Products
            </h4>
            <hr />
            {stripe.lines.data.map((product) => (
              <StripeCard product={product} key={product.id} />
            ))}
          </>
        )}
    </div>
  );
};

export default SaleInfo;
