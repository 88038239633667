import gql from 'graphql-tag';

const QUERY_GET_ORDER_ADMIN = gql`
  query GetOrderAdmin($id: String!) {
    getOrder(id: $id) {
      id
      account {
        id
        firstname
        lastname
        email
      }
      reference {
        id
        firstname
        lastname
        email
      }
      orderInformation {
        firstname
        lastname
        email
        phone
        shippingAddress {
          name
          street1
          street2
          city
          state
          zip
          country
        }
      }
      product
      total
      date
      requestedPhysicalCard
      physicalCardSent
      corporatePlanInfoID
      paidForByCompany

      products {
        id
        quantity
        total
      }

      membershipInformation {
        ids
        memberships {
          id
          membershipID
          firstname
          lastname
          email
          phone
          additionalFamilyMembers {
            name
          }
          spouseFirstName
          spouseLastName
          plan
        }
        plans {
          individual
          family
          corporateIndividual
          corporateFamily
        }
      }

      refunded
      paypal {
        id
        status
        plan_id
        start_time
        quantity
        subscriber {
          payer_id
          email_address
        }
        billing_info {
          next_billing_time
          last_payment {
            time
            amount {
              value
            }
          }
        }
      }

      stripe {
        id
        object
        amount
        amount_due
        amount_paid
        customer
        discount {
          start
          end
          coupon {
            id
            name
            amount_off
            percent_off
            valid
          }
        }
        lines {
          data {
            id
            amount
            period {
              start
              end
            }
            plan {
              id
              product
            }
            quantity
            subscription
            type
          }
        }
        paid
        status
        subscription
        total
      }
    }
  }
`;

export default QUERY_GET_ORDER_ADMIN;
