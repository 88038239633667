/* eslint no-console: 0 */
import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './Login';
import Office from './Office/';
import * as actions from '../actions';
import Loading from './Loading/';
import MembershipSearch from './MembershipSearch/MembershipSearch';
import QUERY_CURRENT_USER from '../queries/user';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import _ from 'lodash';
import withAuth from '../hoc/withAuth';
import { useLazyQuery } from '@apollo/react-hooks';
import LinkAccount from './LinkAccount';
import LoginWithToken from './LoginWithToken';

const AuthedOffice = withAuth(Office);

//Special Route for the Login/Register. If a logged in user tries to access it, they get sent to the dashboard.
function LoginRoute ({component: Component, authed, ...rest}) {
	return (
		<Route
			{...rest}
			render={(props) => authed === false
				? <Component {...props} />
				: <Redirect to='/office' />}
		/>
	);
}

const App = ({ auth, changeAuth }) => {
	const { authed, initial } = useFirebaseAuth();
	const [loadUser, { data, refetch, called, loading }] = useLazyQuery(QUERY_CURRENT_USER);
	useEffect(() => {
		if(initial) {
			authed ? changeAuth(true, false) : changeAuth(false, false);
			called ? refetch() : loadUser();
		}
	}, [authed, initial]);


	if(!initial || _.get(auth, 'auth.loading', false)) {
		return <Loading />;
	}

	return(
		<Fragment>
			<BrowserRouter>
				<Fragment>
					<Switch>
						<LoginRoute exact authed={!authed} path="/login" component={Login} />
						<LoginRoute exact authed={!authed} path="/logintoken" component={LoginWithToken} />
						<Route exact path="/membershipsearch" component={MembershipSearch} />
						<Route path="/setup/membership/:id" component={LinkAccount} />
						<Route
							path="/"
							component={(props) => (
								<AuthedOffice 
									{...props}
									loading={loading}
									called={called}
									user={data ? data.user : null}
								/>
							)}
						/>
					</Switch>
				</Fragment>
			</BrowserRouter>
		</Fragment>
	);
};


function mapStateToProps({auth, socket}) {
	return({ auth, socket });
}

export default connect(mapStateToProps, actions)(App);
