import React from 'react';
import { Segment } from 'semantic-ui-react';
import SeminarTable from './SeminarTable';

const SeminarPage = () => {
	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group>
				<SeminarTable />
			</Segment.Group>
		</div>
	);
};

export default SeminarPage;