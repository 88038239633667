import React from 'react';
import { Table, Input, Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import GraphQLError from '../../Message/GraphQLError';
import _ from 'lodash';

const query = gql`
	query GetCustomToken($id: String!) {
		getCustomToken(id: $id)
	}
`;

const CreateToken = () => {
	const [text, setText] = React.useState('');
	const [getCustomToken, { data, loading, error }] = useLazyQuery(query);

	console.log(data);

	return (
		<Table.Row>
			<Table.Cell>
				<Input
					placeholder="UID"
					onChange={(event, data) => setText(data.value)}
					value={text}
					label="Get Login Token"
					action={(
						<Button
							loading={loading}
							attached="right"
							color="blue"
							content="Submit"
							onClick={() => {
								getCustomToken({
									variables: { id: text }
								});
							}}
						/>
					)}
				/>
			</Table.Cell>
			<Table.Cell>
				<GraphQLError error={error} />
				<h4>{_.get(data, 'getCustomToken')}</h4>
			</Table.Cell>
		</Table.Row>
	);
};

export default CreateToken;