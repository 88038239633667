import React from 'react';
import { DetailsTable, DetailsRow } from '../../../Table';
import { 
	ChangeReferenceFieldBusinessPlan,
	ChangeCheckboxFieldBusinessPlan,
	ChangeTextFieldBusinessPlan,
	ChangeSelectFieldBusinessPlan,
} from '../helpers/BusinessPlanChangeFields';
import { states, countries } from '../../../../config/supported_areas';
import { Button, Divider } from 'semantic-ui-react';
import DeleteBusinessPlan from './DeleteBusinessPlan';

const plans = {
	6: 'Basic Business Plan',
	7: 'Premium Business Plan',
};

const BasicInfo = ({ data, refetch }) => {
	const {
		id,
		businessname,
		plan,
		info,
		latestOrderNumber,
		accountEmail,
		storeID,
	} = data;

	const renderFormated = () => {
		const { street1, street2, city, state, zip, country } = info;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{businessname}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<>
			<DetailsTable>
				<DetailsRow
					property="Database ID"
					value={id}
				/>
				<ChangeTextFieldBusinessPlan
					label="Business Name"
					value={businessname}
					property={'businessname'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<DetailsRow
					property="Plan"
					value={plans[plan]}
				/>
				<DetailsRow
					property="Account Email"
					value={accountEmail}
				/>
				<ChangeReferenceFieldBusinessPlan
					label="Reference"
					value={data.reference}
					property="reference"
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeReferenceFieldBusinessPlan
					label="User Account"
					value={data.account}
					property="account"
					refetch={refetch}
					id={data.id}
					table
					includeAllUsers={true}
				/>
				<ChangeCheckboxFieldBusinessPlan
					label="Opted for Presentation"
					value={!!data.presentation.opted}
					property="presentation.opted"
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeCheckboxFieldBusinessPlan
					label="Opted for Social Media"
					value={!!data.socialMedia.opted}
					property="socialMedia.opted"
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeTextFieldBusinessPlan
					label={'Phone #'}
					value={info.phone}
					property={'info.phone'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeTextFieldBusinessPlan
					label={'Street Line 1'}
					value={info.street1}
					property={'info.street1'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeTextFieldBusinessPlan
					label={'Street Line 2'}
					value={info.street2}
					property={'info.street2'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeTextFieldBusinessPlan
					label={'City'}
					value={info.city}
					property={'info.city'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeSelectFieldBusinessPlan
					options={states}
					label={'State'}
					value={info.state}
					property={'info.state'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeTextFieldBusinessPlan
					label={'Zip Code'}
					value={info.zip}
					property={'info.zip'}
					refetch={refetch}
					id={data.id}
					table
				/>
				<ChangeSelectFieldBusinessPlan
					options={countries}
					label={'Country'}
					value={info.country}
					property={'info.country'}
					refetch={refetch}
					id={data.id}
					table
				/>
			</DetailsTable>
			<div>
				<Button
					content="Open Latest Order"
					as="a"
					href={`/admin/orders/${latestOrderNumber}`}
					color="blue"
					target="_blank"
				/>
				{ !!storeID && <Button
					content="Open Store"
					as="a"
					href={`/admin/stores/${storeID}`}
					color="purple"
					target="_blank"
				/> }
				<DeleteBusinessPlan
					id={data.id}
					refetch={refetch}
				/>
			</div>
			<Divider horizontal>Formatted Address</Divider>
			{renderFormated()}
		</>
	);
};

export default BasicInfo;