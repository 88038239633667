import React from 'react';
import { Segment, Divider, Form, Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../../../FinalForm/TextInput';
import FSMultiSelectInput from '../../../FinalForm/MultiSelect';
import { StoreTypeOptions } from '../../../../config/storeTypes';
import formatPhoneNumber from '../../../../utils/formatPhoneNumber';
import * as Yup from 'yup';
import validator from '../../../../utils/validator';
import { useQuery, useMutation } from '@apollo/react-hooks';
import QUERY_GET_CONTRACT from '../../../../queries/getContract';
import Loading from '../../../Loading/index';
import _ from 'lodash';
import FSCheckbox from '../../../FinalForm/StyledCheckbox';
import GraphQLError from '../../../Message/GraphQLError';

const MUTATION_CREATE_STORE = gql`
	mutation BusinessCreateStore($businessname: String!, $url: String, $category: [Int]!, $contactname: String, $contactemail: String, $contactphone: String) {
		businessCreateStore(businessname: $businessname, url: $url, category: $category, contactname: $contactname, contactemail: $contactemail, contactphone: $contactphone)
	}
`;

const CreateStore = ({ query }) => {
	const { data, loading } = useQuery(QUERY_GET_CONTRACT, {
		variables: { type: 'BUSINESSTERMS' },
	});
	const [createStore, { loading: mLoading, error: mError }] = useMutation(MUTATION_CREATE_STORE);

	return (
		<Segment style={{ flex: 1 }}>
			<h3>Enter Profile Details</h3>
			<p>
				It looks like you haven't set up your profile yet. Please fill out this basic information.<br />
				After you setup your profile, you can add locations and promotions.
			</p>
			<Divider horizontal>Enter Information</Divider>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ maxWidth: '1000px', flex: 1 }}>
					<FinalForm
						onSubmit={variables => {
							createStore({
								variables,
								refetchQueries: [{ query }],
							});
						}}
						validate={validator(schema)}
						render={({ handleSubmit, valid }) => (
							<Form onSubmit={handleSubmit} loading={mLoading}>
								<Form.Group widths="equal">
									<FSTextInput
										name="businessname"
										label="Profile Name"
										placeholder="Enter your profile name"
										required
									/>
								</Form.Group>
								<Form.Group widths="equal">
									<FSMultiSelectInput
										name="category"
										label="Categories"
										placeholder="Categories"
										required
										options={StoreTypeOptions}
										isMulti
									/>
								</Form.Group>
								<Form.Group widths="equal">
									<FSTextInput
										name="url"
										label="Website"
										placeholder="Website for your business"
									/>
									<FSTextInput
										name="contactname"
										label="Name of Person to be Contacted"
										placeholder="Contact Name"
										// required
									/>
								</Form.Group>
								<Form.Group widths="equal">
									<FSTextInput
										name="contactemail"
										label="Contact Email"
										placeholder="Contact Email"
										// required
									/>
									<FSTextInput
										name="contactphone"
										label="Contact Phone"
										onChange={v => formatPhoneNumber(v, true)}
										placeholder="Contact Phone"
										// required
									/>
								</Form.Group>

								{ loading && (
									<div className="contract2">
										<Loading />
									</div>
								)}
								{ !loading && (
									<div 
										className="contract2"
										dangerouslySetInnerHTML={{ __html: _.get(data, 'getContract') }}
									/>
								)}
								<FSCheckbox
									name="contractAgree"
									label="Do you agree to the terms & conditions listed above?"
								/>
								<div style={{ marginTop: '20px' }} />

								<GraphQLError error={mError} />
								<Button
									type="submit"
									content="Create Store"
									fluid
									color="blue"
									disabled={!valid}
									loading={mLoading}
								/>
							</Form>
						)}
					/>
				</div>
			</div>

		</Segment>
	);
};


const schema = Yup.object().shape({
	businessname: Yup.string().required('Please enter a business name'),
	category: Yup.array().required('Please choose at least one category').min(1, 'Please choose at least one category'),
	// contactname: Yup.string().required('Required Field'),
	// contactemail: Yup.string().required('Required Field'),
	// contactphone: Yup.string().required('Required Field'),
	contractAgree: Yup.boolean().required('Please Accept').equals([true], 'Please Accept'),
});

export default CreateStore;