import React, { Component } from 'react';
import { Form, Input} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

export default class InputDate extends Component {
	state = {
		value: null,
	}

	handleChange = (value) => {
		this.props.input.onChange(value);
	}

	render() {
		const { placeholder, label, meta: { touched, error }, input: { value } } = this.props;
		return(
			<Form.Field required error={touched && !!error} className="form-date">
				<label>{label}</label>
				<DatePicker
					isClearable={true}
					selected={value}
					onChange={this.handleChange}
					placeholderText={placeholder}
					showMonthDropdown
					showYearDropdown
					customInput={<Input/>}
				/>
			</Form.Field>
		);
	}
}