import gql from 'graphql-tag';

const QUERY_GET_USER_FOR_PAYMENT = gql`
	query GetUserForPayment {
		user {
			id
			paymentType
			representativeAddress {
				street1
				street2
				city
				state
				zip
				country
			}
			directDepositInfo {
				name,
				rtn,
				acn,
				at,
				bName,
			}
		}
	}
`;

export default QUERY_GET_USER_FOR_PAYMENT;