import React, { useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../../../FinalForm/TextInput';
import SelectInput from '../../../FinalForm/SelectInput';
import { Form, Button, Modal } from 'semantic-ui-react';
import GraphQLError from '../../../Message/GraphQLError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import MUTATION_ADD_LOCATION from '../../../../mutations/addLocation';
import { countries, states } from '../../../../config/supported_areas';
import * as Yup from 'yup';
import validator from '../../../../utils/validator';

const AddLocationContent = ({ id, query, onComplete }) => {
	const [addLocation, { data, loading, error }] = useMutation(MUTATION_ADD_LOCATION);

	useEffect(() => {
		if(_.get(data, 'addLocation.id')) {
			onComplete && onComplete();
		}
	}, [data]);

	return (
		<FinalForm
			onSubmit={variables => {
				addLocation({
					variables,
					refetchQueries: [{ query }],
				});
			}}
			validate={validator(schema)}
			initialValues={{ id }}
			render={({ handleSubmit, valid }) => (
				<Form onSubmit={handleSubmit} loading={loading}>

					<Form.Group widths='equal'>
						<FSTextInput name='street1' placeholder="Street 1" label="Street" required />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name='street2' placeholder="Street 2" label={false} />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name='city' placeholder="City" required />
						<SelectInput name='state' placeholder="State" required options={states} />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name='zip' placeholder="Zipcode" required />
						<SelectInput name='country' placeholder="Country" required options={countries} />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name='name' placeholder="Contact Name" />
						<FSTextInput name='phone' placeholder="Phone Number" />
					</Form.Group>

					<GraphQLError error={error} />
					<Button 
						type="submit"
						content="Add Location"
						loading={loading}
						color="blue"
						fluid
						disabled={!valid}
					/>
				</Form>
			)}
		/>
	);
};

const schema = Yup.object().shape({
	street1: Yup.string().required('Required Field'),
	city: Yup.string().required('Required Field'),
	state: Yup.string().required('Required Field'),
	country: Yup.string().required('Required Field'),
	zip: Yup.string().required('Required Field'),
});

const AddLocation = ({ info, query }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Button
				content={(
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FontAwesomeIcon icon={['fas', 'plus']} />
						<div style={{ marginLeft: '5px' }}>Add Location</div>
					</div>
				)}
				onClick={() => setOpen(true)}
			/>
			<Modal 
				open={open}
				closeIcon
				closeOnDimmerClick={false}
				onClose={() => setOpen(false)}
			>
				<Modal.Header>Add Location</Modal.Header>
				<Modal.Content>
					<AddLocationContent 
						id={info.id}
						query={query}
						onComplete={() => setOpen(false)}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
};

export default AddLocation;