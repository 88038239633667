/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import SimpleSelect from '../../Form/SimpleSelect';

const options = [
	{ value: '0', label: 'Customer' },
	{ value: '1', label: 'Influencer' },
	{ value: '-10', label: 'Friend' },
	{ value: '2', label: 'Sharing Provider' },
	{ value: '3', label: 'Educator' },
	{ value: '10', label: 'Admin' },
	{ value: '42', label: 'Developer' },
];

const representativeOptions = [
	{ value: '0', label: 'Educator' },
	{ value: '1', label: 'Manager' },
	{ value: '2', label: 'Authority' },
	{ value: '3', label: 'Leader' },
];

const columns = () => [
	{
		Header: 'Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.firstname} ${d.lastname}`,
		foldable: true,
	},
	{
		Header: 'Email',
		id: 'email',
		filterable: true,
		minWidth: 150,
		accessor: 'email',
		foldable: true,
	},
	{
		Header: 'Account Type',
		id: 'accountType',
		filterable: true,
		minWidth: 100,
		accessor: d => {
			let extra = '';
			if(d.influencer && d.accountType !== 1) {
				extra += ' & Influencer';
			}
			if(d.friend) {
				if(d.accountType === 0) {
					return 'Friend';
				} else {
					extra += ' & Friend';
				}
			}
			return _.get(accountTypes, `${d.accountType}`, 'Invalid') + extra;
		},
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		},
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => {
						if(value) {
							onChange(value.value);
						} else {
							onChange();
						}
					}}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Educator ID',
		id: 'representativeID',
		filterable: true,
		minWidth: 150,
		accessor: 'representativeID',
		foldable: true,
	},
	{
		Header: 'Representative Level',
		id: 'representativeLevel',
		filterable: true,
		minWidth: 100,
		accessor: d => {
			const { accountType, representativeLevel: { name } } = d;
			_.get(accountTypes, `${d.accountType}`, 'Invalid');
			return accountType > 0 ? name : '';
		},
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		},
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={representativeOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => {
						onChange(value ? value.value : null);
					}}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Database ID',
		id: 'id',
		filterable: true,
		minWidth: 100,
		accessor: 'id',
		foldable: true,
	},
];

export default columns;

const accountTypes = {
	0: 'Customer',
	1: 'Influencer',
	3: 'Educator',
	2: 'Sharing Provider',
	10: 'Admin',
	42: 'Developer',
};