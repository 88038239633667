import React from 'react';
import QUERY_GET_ORDER from './getOrder';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../Loading';
import { Menu, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import BasicInfo from './BasicInfo';
import ShippingInfo from './ShippingInfo';
import MembershipInfo from './MembershipInfo';

const OrderContentRep = ({ id }) => {
	const { data, loading } = useQuery(QUERY_GET_ORDER, {
		variables: { id },
	});
	const [activeItem, setActiveItem] = React.useState('info');

	if(loading) {
		return <Loading />;
	}

	if(!_.get(data, 'getOrder.id')) {
		return <div>Error loading data. Please try again.</div>;
	}

	const membership = !!(_.get(data, 'getOrder.membershipInformation.ids', []).length);

	return (
		<div>
			<Menu attached='top' tabular>
				<Menu.Item name='Info' active={activeItem === 'info'} onClick={() => setActiveItem('info')} />
				<Menu.Item name='Shipping' active={activeItem === 'shippinginfo'} onClick={() => setActiveItem('shippinginfo')} />
				{ membership && <Menu.Item name='Membership' active={activeItem === 'minfo'} onClick={() => setActiveItem('minfo')} /> }
			</Menu>

			<Segment attached='bottom'>
				{ activeItem === 'info' && <BasicInfo data={data.getOrder} /> }
				{ activeItem === 'shippinginfo' && <ShippingInfo data={data.getOrder} /> }
				{ (activeItem === 'minfo' && membership) && <MembershipInfo data={data.getOrder} /> }
			</Segment>
		</div>
	);
};

export default OrderContentRep;