import React, { Component } from 'react';
import { Form, Icon, Checkbox, Input } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

export const TextField = ({
	input,
	label,
	required,
	placeholder,
	icon,
	disabled,
	iconPosition,
	meta: { touched, error }
}) => (
	<Form.Field required={required} error={touched && !!error}>
		<label>{label}</label>
		<Input placeholder={placeholder || label} disabled={disabled} {... input} icon={icon} iconPosition={iconPosition}/>
		<p>{touched && error}</p>
	</Form.Field>
);

export const TextFieldWithAction = ({
	input,
	label,
	required,
	placeholder,
	icon,
	disabled,
	iconPosition,
	action,
	meta: { touched, error }
}) => (
	<Form.Field required={required} error={touched && !!error}>
		<label>{label}</label>
		<Input placeholder={placeholder || label} disabled={disabled} {... input} icon={icon} iconPosition={iconPosition} action={action} />
		<p>{touched && error}</p>
	</Form.Field>
);

export const TextFieldWithLabel = ({
	input,
	label,
	required,
	placeholder,
	front,
	meta: { touched, error }
}) => (
	<Form.Field required={required} error={touched && !!error}>
		<label>{label}</label>
		<Input label={front || ''} placeholder={placeholder || ''} {... input} />
		<p>{touched && error}</p>
	</Form.Field>
);

export const AsyncTextFieldWithLabel = ({
	input,
	label,
	required,
	placeholder,
	front,
	meta: { asyncValidating, error, touched, active }
}) => {

	var icon = false;
	if(input.value && !active) {
		icon = error ? <Icon name='warning circle'/> : <Icon name='checkmark' color='green'/>;
	}

	return(
		<Form.Field required={required} error={touched && !!error}>
			<label>{label}</label>
			<Input label={front || ''} placeholder={placeholder || ''} icon={icon} {... input} loading={asyncValidating}/>
			<p>{touched && error}</p>
		</Form.Field>
	);
};



export const AsyncTextField = ({ input, label, required, placeholder, meta: {asyncValidating, error, touched, active} }) => {

	var icon = false;

	if(input.value && !active) {
		icon = error ? <Icon name='warning circle'/> : <Icon name='checkmark' color='green'/>;
	}

	return (
		<Form.Field required={required} error={touched && !!error} >
			<label>{label}</label>
			<Input placeholder={placeholder || label} {... input} icon={icon} loading={asyncValidating}/>
			<p>{touched && error}</p>
		</Form.Field>
	);
};

export class PasswordTextField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hidden: true
		};
		this.changeHidden = this.changeHidden.bind(this);
	}

	changeHidden() {
		this.setState({hidden: !this.state.hidden});
	}

	render() {
		const { input, label, required, placeholder, meta: { touched, error } } = this.props;

		const type = this.state.hidden ? 'password' : 'text';
		const iconType = this.state.hidden ? 'unhide'   : 'hide';
		const icon = <Icon name={iconType} link onClick={this.changeHidden} />;

		return (
			<Form.Field required={required} error={touched && !!error}>
				<label>{label}</label>
				<Form.Input placeholder={placeholder || label} {... input} type={type} icon={icon}/>
				<p>{touched && error}</p>
			</Form.Field>
		);
	}
}

export class CustomCheckbox extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}


	handleChange(e, {value}) {
		this.props.input.onChange(value);
	}

	render() {
		const { input, label, meta: {error, touched} } = this.props;

		return (
			<div>
				<Checkbox label={label} {...input} />
				<p className="error">{touched && error}</p>
			</div>
		);
	}

}

export class SpecifySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {other: false, otherValue: ''};

		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	componentDidMount() {
		this.props.list.push({ key: 'o', text: 'Other', value: 'other' });
	}

	onChangeSelect(e, {value}) {
		if(value === 'other') {
			this.setState({other: true});
			this.props.input.onChange(this.state.otherValue);
		} else {
			this.setState({other: false});
			this.props.input.onChange(value);
		}

	}

	onChangeInput(e, {value}) {
		if(this.state.other) {
			this.setState({otherValue: value});
			this.props.input.onChange(value);
		}
	}

	render() {
		const { label, list, placeholder, otherPlaceholder, otherLabel, meta: {error, touched} } = this.props;

		return(
			<div>
				<Form.Select options={list} placeholder={placeholder || ''} label={label || ''} onChange={this.onChangeSelect} error={error && touched} value={this.props.input.value}/>
				<Form.Input  placeholder={otherPlaceholder || ''} label={otherLabel || ''} disabled={!this.state.other} onChange={this.onChangeInput} error={error && touched && this.state.other}/>
			</div>
		);
	}
}

export class ReactNumber extends Component {

	constructor(props) {
		super(props);
		this.onType = this.onType.bind(this);
	}

	onType(e, {value}) {
		this.props.input.onChange(value);
	}

	render() {
		const { input, label, name, placeholder, mask, f, meta: {error, touched} } = this.props;

		var classnames = 'field required';
		if(touched && !!error) {
			classnames += ' error';
		}
		return (
			<div className={classnames}>
				<label>{this.props.label}</label>
				<NumberFormat value={this.props.input.value}
					placeholder={placeholder}
					{... input}
					mask={mask}
					type='text'
					name = {name}
					format={f} onValueChange={(values) => {
						const { formattedValue } = values;
						this.props.input.onChange(formattedValue);
					}}/>
				<p style={{color: '#9f3a38'}}>{touched && error}</p>
			</div>
		);
	}

}
