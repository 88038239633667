/* eslint-disable react/display-name */
/* eslint no-unused-vars: 0 no-console: 0 */
import React from 'react';
import { Button, Table, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

const Promotion = ({ data, index, parentid }) => {

	const TextRow = ({ title, value, renderValue }) => {
		const v = _.get(data, `${value}`, 'N/A') || 'N/A';
		return (
			<Table.Row>
				<Table.Cell>{title}</Table.Cell>
				<Table.Cell>{renderValue ? renderValue(v === 'N/A' ? false : v) : v}</Table.Cell>
			</Table.Row>
		);
	};

	return (
		<div className="store__location changeInformation">
			<Table striped definition attached='top'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							<h3>Promotion #{index}</h3>
						</Table.HeaderCell>
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{ TextRow({ title: 'URL', value: 'url' }) }
					{ TextRow({ title: 'Additional URL', value: 'additionalURL' }) }
					{ TextRow({ title: 'Promo Code', value: 'code' }) }
					{ TextRow({ title: 'Online Deal?', value: 'online', renderValue: v => v ? 'Yes' : 'No' })}
					{ TextRow({ title: 'Valid Until', value: 'validUntil', renderValue: v => v ? moment(v).format('MMM Do YYYY') : 'N/A' })}
					{ TextRow({ title: 'Info', value: 'info' })}
					{ TextRow({ title: 'File', value: 'file', renderValue: v => !v ? 'N/A' : <a href={_.get(v, 'path')}>{v.filename}</a> })}
				</Table.Body>
			</Table>	
		</div>
	);
};


export default Promotion;