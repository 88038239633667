import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { Form, Input, Popup, Button, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLazyQuery } from '@apollo/react-hooks';
import QUERY_IS_PROMO_CODE_VALID from '../../queries/isPromoCodeValid';
import _ from 'lodash';

const FSPromoCode = ({ placeholder, validateFields, name, label, required, validate, size, onApplied=()=>{} }) => {
	const [checkPromoCode, { data, loading, error }] = useLazyQuery(QUERY_IS_PROMO_CODE_VALID, {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	useEffect(() => {
		onApplied(_.get(data, 'isPromoCodeValid', null));
	}, [data]);

	return (
		<Field name={name} validate={validate} validateFields={validateFields}>
			{({ input, meta }) => (
				<Form.Field required={!!required} error={meta.error && meta.touched}>
					{label !== false && <label>{label || placeholder}</label>}
					<Input
						name={name}
						type="text"
						placeholder={placeholder}
						action='Apply'
						iconPosition="left"
						size={size}
						loading={loading}
					>
						<FontAwesomeIcon icon={['fas', 'tags']} className="inputIcon" />
						<input
							{ ...input }
							onChange={(e) => input.onChange(e.target.value)}
							value={input.value}
							type="text"
							onKeyDown={(e) => e.key === 'Enter' && checkPromoCode({variables: { id: input.value }})}
						/>
						<Button
							type='button'
							content="Apply"
							loading={loading}
							onClick={() => checkPromoCode({variables: { id: input.value }})}
						/>
					</Input>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
					{error && <span className="error">Invalid Promo Code</span>}
					{_.get(data, 'isPromoCodeValid.valid') && <span className="success">Promo Code Applied!</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSPromoCode;