import React, { Component } from 'react';
import PCTable from './PCTable';

class AdminSalesPage extends Component {
	render() {
		return (
			<div className="animated fadetwoIn dnp-page">
				<PCTable />
			</div>
		);
	}
}

export default AdminSalesPage;