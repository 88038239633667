import React, { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import ReactTable from 'react-table';
import generateCols from '../Admin/Stores/ManageStores/cols';
import QUERY_GET_STORES from '../../queries/getStores';
import BusinessAffiliateModal from './BusinessAffiliate/BusinessAffiliateModal';

const defaultSorted = [{
	id: 'date',
	desc: true
}];

const BusinessAffiliatesTable = forwardRef(({ parent }, ref) => {
	const [loadResults, { loading, data, refetch }] = useLazyQuery(QUERY_GET_STORES,
		{ fetchPolicy: 'network-only'});
	const [pages, setPages] = useState(1);
	const [count, setCount] = useState(0);
	const [latestVariables, setLatestVariables] = useState(null);
	const [pageSize, setPageSize] = useState(20);
	const [storeInfo, setStoreInfo] = useState(null);

	useEffect(() => {
		if(data) {
			const count = _.get(data, 'getStores.count', 0);
			setPages(Math.ceil(count / pageSize));
			setCount(count);
		}
	}, [data]);

	useEffect(() => {
		latestVariables && loadResults({ variables: latestVariables });
	}, [latestVariables]);

	useImperativeHandle(ref, () => ({
		refresh() { refetch(); }
	}));

	return (
		<Fragment>
			<BusinessAffiliateModal
				onClose={() => setStoreInfo(null)}
				{...storeInfo}
			/>
			<ReactTable
				data={_.get(data, 'getStores.data', [])}
				columns={generateCols(() => {})}
				pageSizeOptions={[10,15,20,50,100]}
				defaultPageSize={20}
				pages={pages}
				onFetchData={state => {
					const date = [];
					const variables = {
						limit: state.pageSize,
						skip: state.page * state.pageSize,
						sortBy: state.sorted,
						parent,
						filtered: [
							...state.filtered,
							...date,
						],
					};
					setPageSize(state.pageSize);
					setLatestVariables(variables);
				}}
				manual
				loading={loading}
				defaultSorted={defaultSorted}
				getTrProps={(state, rowInfo) => {
					return {
						onClick: (e, handleOriginal) => {
							const id = _.get(rowInfo, 'original.id');
							id && setStoreInfo(rowInfo.original);
							handleOriginal && handleOriginal();
						},
						className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
					};
				}}
			/>
			<h4 style={{ textAlign: 'center', marginTop: 10 }}>
				Total Businesses: {count}
			</h4>
		</Fragment>
	);
});

export default BusinessAffiliatesTable;