import React from 'react';
import { Button } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

const query = gql`
	query GetOrder($id: String!) {
		getOrder(id: $id) {
			id
			total
			account {
				id
				firstname
				representativeAddress {
					street1
					street2
				}
			}
			orderInformation {
				firstname
				email
			}
		}
	}
`;

const TestComponent = () => {
	const [getOrder, { data, loading }] = useLazyQuery(query, {
		variables: { id: '5eb206e27f90973352a6e5e4' }
	});

	React.useEffect(() => {
		console.log('The Data', data);
	}, [data]);

	return (
		<div>
			<Button
				content="Test Query"
				onClick={() => {
					getOrder();
				}}
				loading={loading}
			/>
		</div>
	);
};

export default TestComponent;