import React from 'react';
import { Divider } from 'semantic-ui-react';
import { ChangeTextFieldOrder, ChangeSelectFieldOrder } from '../helpers/OrderChangeFields';
import { OrderDetailsTable } from '../helpers';
import { countries, states } from '../../../../config/supported_areas';

const ShippingInformation = ({ data, refetch }) => {

	const { firstname, lastname, shippingAddress={} } = data.orderInformation;

	const renderFormated = () => {
		const { street1, street2, city, state, zip, country } = shippingAddress;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{firstname} {lastname}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	};

	return (
		<div className="admin-sale-info">
			<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Shipping Information</h4>
			<hr />

			<OrderDetailsTable>
				<ChangeTextFieldOrder
					label={'Firstname'}
					value={firstname}
					property={'firstname'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeTextFieldOrder
					label={'Lastname'}
					value={lastname}
					property={'lastname'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeTextFieldOrder
					label={'Street 1'}
					value={shippingAddress.street1}
					property={'shippingAddress.street1'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeTextFieldOrder
					label={'Street 2'}
					value={shippingAddress.street2}
					property={'shippingAddress.street2'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeTextFieldOrder
					label={'City'}
					value={shippingAddress.city}
					property={'shippingAddress.city'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeSelectFieldOrder
					options={states}
					label={'State'}
					value={shippingAddress.state}
					property={'shippingAddress.state'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeTextFieldOrder
					label={'Zip'}
					value={shippingAddress.zip}
					property={'shippingAddress.zip'}
					refetch={refetch}
					id={data.id}
					table
				/>

				<ChangeSelectFieldOrder
					options={countries}
					label={'Country'}
					value={shippingAddress.country}
					property={'shippingAddress.country'}
					refetch={refetch}
					id={data.id}
					table
				/>
			</OrderDetailsTable>
			<Divider horizontal>Formated Address</Divider>
			{renderFormated()}
		</div>
	);
};

export default ShippingInformation;