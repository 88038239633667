import React from 'react';
import { Table } from 'semantic-ui-react';

const DetailsTable = ({ children, noHeader }) => {
	return (
		<Table definition striped>
			{!noHeader && <Table.Header>
				<Table.Row>
					<Table.HeaderCell />
					<Table.HeaderCell>Value</Table.HeaderCell>
				</Table.Row>
			</Table.Header>}
			<Table.Body>
				{children}
			</Table.Body>
		</Table>
	);
};

export default DetailsTable;