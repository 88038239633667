import React from 'react';
import AddMembership from './AddMembership';
import GroupList from './GroupList';

const Group = ({ membership }) => {
	const { id, subMemberships=[] } = membership;
	return (
		<div>
			<AddMembership planID={id} />
			<div style={{ marginBottom: '10px' }} />
			<GroupList memberships={subMemberships} baseID={id} />
		</div>
	);
};

export default Group;