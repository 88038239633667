import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddMembershipModal from './AddMembershipModal';

const AddMembership = ({ id, onComplete }) => {
	const [modal, setModal] = useState(false);

	return (
		<>
			<AddMembershipModal
				id={id}
				open={modal}
				onClose={() => setModal(false)}
				onComplete={onComplete}
			/>
			<Button
				onClick={() => setModal(true)}
				content={(
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h4 style={{ margin: '0 10px 0 0' }}>Add</h4>
						<div>
							<FontAwesomeIcon icon={['fas', 'plus']} />
						</div>
					</div>
				)}
			/>
		</>
	);
};

export default AddMembership;