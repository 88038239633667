import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import DeleteModal from '../../common/DeleteModal/DeleteModal';
import MUTATION_CANCEL_BUSINESS_PLAN from '../graphql/cancelBusinessPlan';
import { useMutation } from '@apollo/react-hooks';
import { QUERY_BUSINESS_PLAN_USER } from './Home';

const CancelMembership = ({ id, refetch }) => {
	const [open, setOpen] = useState(false);
	const [cancelMembership, { data, loading, error }] = useMutation(MUTATION_CANCEL_BUSINESS_PLAN);

	return (
		<>
			<DeleteModal
				open={open}
				loading={loading}
				onClose={() => setOpen(false)}
				onDelete={() => {
					cancelMembership({
						variables: { id },
						refetchQueries: refetch,
						awaitRefetchQueries: true
					}).then(() => setOpen(false));
				}}
				text={'You will be able to use your membership until the end of your current period. After that you will need to resubscribe to access your membership.'}
			/>
			<Button
				fluid
				content="Cancel Membership"
				color="red"
				onClick={() => setOpen(true)}
			/>
		</>
	);
};

export default CancelMembership;