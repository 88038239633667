import React from 'react';
import Loading from '../../Loading';
import { Menu, Segment, Label } from 'semantic-ui-react';
import _ from 'lodash';
import GeneralInfo from './GeneralInfo';
import PromotionList from './PromotionList';
import LocationsList from './LocationsList';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_STORE from '../../../queries/getStore';

const BusinessAffiliate = ({ id }) => {
	const [activeItem, setActiveItem] = React.useState('general');
	const { data, loading } = useQuery(QUERY_GET_STORE, {
		variables: { id },
	});

	if(loading) {
		return(<Loading />);
	}
	const store = _.get(data, 'getStore', {});
	const { promotionsCount, storeLocationsCount } = store;

	return(
		<div>
			<Menu attached='top' tabular>
				<Menu.Item name='General Info' active={activeItem === 'general'} onClick={() => setActiveItem('general')} />
				<Menu.Item	name='Promotions' active={activeItem === 'promotions'} onClick={() => setActiveItem('promotions')}>
					Promotions<Label color={promotionsCount ? 'blue' : 'grey'}>{promotionsCount}</Label>
				</Menu.Item>
				<Menu.Item name='Locations' active={activeItem === 'locations'} onClick={() => setActiveItem('locations')}>
					Locations<Label color={storeLocationsCount ? 'green' : 'grey'}>{storeLocationsCount}</Label>
				</Menu.Item>
			</Menu>

			<Segment attached='bottom'>
				{ activeItem === 'general' && <GeneralInfo data={store} /> }
				{ activeItem === 'promotions' && <PromotionList data={store} /> }
				{ activeItem === 'locations' && <LocationsList data={store} /> }
			</Segment>
		</div>
	);
};



export default BusinessAffiliate;
