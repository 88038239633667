import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import TextInput from '../../../FinalForm/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'semantic-ui-react';
import styles from './corporate.module.scss';

const AdditionalFamilyMembers = ({ name }) => {
	return (
		<FieldArray name={name}>
			{({ fields }) => (
				<div>
					{fields.map((name, index) => (
						<div key={name} className={styles.familyMember}>
							<h4>Family Member #{index + 1}</h4>
							<TextInput name={`${name}.name`} placeholder="Full Name" />
							<span onClick={() => fields.remove(index)} className={styles.familyMember__delete}>
								<FontAwesomeIcon icon={['fas', 'times']} size='2x' />
							</span>
						</div>
					))}
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button type="button" onClick={() => fields.push({ name: '' })}>
							<FontAwesomeIcon icon={['fas', 'plus']} /> Add Family Member
						</Button>
					</div>
				</div>
			)}
		</FieldArray>
	);
};

export default AdditionalFamilyMembers;