import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import ReactTable from 'react-table';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import createColumns from './createColumns';
import { useHistory, useParams } from 'react-router-dom';
import QUERY_GET_MEMBERSHIP_TABLE from '../../../../queries/getMembershipsTable';
import MembershipDetails from '../MembershipModal/MembershipDetails';

const skipColumns = (cols, toSkip=[]) => {
	for (let col of toSkip) {
		const index = cols.findIndex(obj => obj.id === col);
		if(index !== null) {
			cols.splice(index, 1);  
		}
	}
	return cols;
};

const MembershipTable = forwardRef(({ account, hideCols=[], path }, ref) => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [datePopups, setDatePopups] = useState({});
	const [dates, setDates] = useState({});
	// const [modalInfo, setModalInfo] = useState(null);
	const [vars, setVars] = useState({});
	const history = useHistory();
	const [modalID, setModalID] = useState();
	const params = useParams();

	useEffect(() => {
		setModalID(_.get(params, 'id'));
	}, [params]);

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refetch(vars);
		},
	}));

	const [loadInitialData, {
		data: qData,
		loading,
		called,
		refetch
	}] = useLazyQuery(QUERY_GET_MEMBERSHIP_TABLE, {});

	const { data, count } = _.get(qData, 'getMembershipsTable', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		const date = [];
		for(let ob in dates) {
			if(dates[ob]) {
				date.push({ id: ob, value: dates[ob].valueOf() });
			}
		}
		const required = [];
		if(account) {
			required.push({ id: 'account', value: account });
		}
		const variables = {
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered: [
				...filtered,
				...date,
				...required,
			],
		};
		setVars(variables);
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				if(info) {
					if(path) {
						history.push(`${path}/${info.id}`);
					} else {
						setModalID(info.id);
					}
				}
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	return (
		<div className="animated fadetwoIn">
			<MembershipDetails
				id={modalID}
				onClose={() => {
					setModalID();
					refetch(vars);
					if(path) {
						history.push(path);
					}
				}}
			/>
			<ReactTable
				data={data}
				columns={
					skipColumns(createColumns({
						dates,
						datePopups,
						triggerDatePopup: (id) => setDatePopups(prev => ({ ...prev, [id]: !prev[id] })),
						changeDate: (id, value) => setDates(prev => ({ ...prev, [id] : value})),
					}), hideCols)
				}
				pageSizeOptions={[10,15,20,50,100]}
				defaultPageSize={pageSize}
				pages={pages}
				onFetchData={onFetchData}
				manual
				defaultSorted={[{
					id: 'date',
					desc: true
				}]}
				loading={loading}
				getTrProps={rowProps}
			/>
			<h4 style={{ textAlign: 'center', marginTop: 10 }}>
				Total Memberships: {count}
			</h4>
		</div>
	);
});

export default MembershipTable;

// class MembershipsTableContainer extends Component {
// 	state = {
// 		loading: false,
// 		pages: 0,
// 		index: null,
// 		modal: false,
// 		current_period_end_popup: false,
// 		current_period_end_start_date: null,
// 		current_period_end_end_date: null,

// 		current_period_start_popup: false,
// 		current_period_start_start_date: null,
// 		current_period_start_end_date: null,

// 		ended_at_popup: false,
// 		ended_at_start_date: null,
// 		ended_at_end_date: null,

// 		created_popup: false,
// 		created_start_date: null,
// 		created_end_date: null,

// 		count: 0,
// 		data: [],
// 		latestQuery: {},
// 		pageSize: 20,
// 	}

// 	changeDate = (property, value, startdate=true) => {
// 		if(value) {
// 			this.setState({
// 				[property] : 
// 					startdate ?
// 						moment(value).startOf('day').toDate() :
// 						moment(value).startOf('day').subtract('minute', 1).toDate()
// 			});
// 		} else {
// 			this.setState({
// 				[property] : null,
// 			});
// 		}
// 	}

// 	triggerDatePopup = (property) => {
// 		this.setState({
// 			[property] : !this.state[property]
// 		});
// 	}


// 	fetchData = (state, instance) => {
// 		this.setState({ loading: true });

// 		const date = [];
// 		const {
// 			current_period_end_start_date,
// 			current_period_end_end_date,
// 			current_period_start_start_date,
// 			current_period_start_end_date,
// 			ended_at_start_date,
// 			ended_at_end_date,
// 			created_start_date,
// 			created_end_date,
// 		} = this.state;

// 		current_period_end_start_date && date.push({ id: 'current_period_end_start_date', value: current_period_end_start_date.valueOf() });
// 		current_period_end_end_date && date.push({ id: 'current_period_end_end_date', value: current_period_end_end_date.valueOf() });
// 		current_period_start_start_date && date.push({ id: 'current_period_start_start_date', value: current_period_start_start_date.valueOf() });
// 		current_period_start_end_date && date.push({ id: 'current_period_start_end_date', value: current_period_start_end_date.valueOf() });
// 		ended_at_start_date && date.push({ id: 'ended_at_start_date', value: ended_at_start_date.valueOf() });
// 		ended_at_end_date && date.push({ id: 'ended_at_end_date', value: ended_at_end_date.valueOf() });
// 		created_start_date && date.push({ id: 'created_start_date', value: created_start_date.valueOf() });
// 		created_end_date && date.push({ id: 'created_end_date', value: created_end_date.valueOf() });


// 		const query = {
// 			query: queryGetMembershipsTable,
// 			variables: {
// 				limit: state.pageSize,
// 				skip: state.page * state.pageSize,
// 				sortBy: state.sorted,
// 				filtered: [
// 					...state.filtered,
// 					...date,
// 				],
// 			},
// 			fetchPolicy: 'network-only',
// 		};

// 		this.props.client.query(query)
// 			.then(data => {
// 				const count = _.get(data, 'data.getMembershipsTable.count', 0);
// 				this.setState({
// 					count,
// 					loading: false,
// 					pages: Math.ceil(count / state.pageSize),
// 					data: _.get(data, 'data.getMembershipsTable.data', []),
// 					latestQuery: query,
// 					pageSize: state.pageSize,
// 				});
// 			});
// 	}

// 	refresh = () => {
// 		this.setState({ loading: true });

// 		const { latestQuery, pageSize } = this.state;

// 		this.props.client.query(latestQuery)
// 			.then(data => {
// 				const count = _.get(data, 'data.getMembershipsTable.count', 0);
// 				this.setState({
// 					count,
// 					loading: false,
// 					pages: Math.ceil(count / pageSize),
// 					data: _.get(data, 'data.getMembershipsTable.data', []),
// 				});
// 			});

// 	}

// 	closeModalAndReload = async () => {
// 		await this.setState({ modal: false, index: null });
// 		this.refresh();
// 	}

// 	renderModal() {
// 		const { modal, index } = this.state;
// 		if(index && modal) {
// 			return (
// 				<Modal open={this.state.modal} onClose={() => this.setState({ modal: false, index: null })} closeIcon>
// 					<Modal.Header>{`Membership - ${index}`}</Modal.Header>
// 					<Modal.Content>
// 						<EditMembership id={index} onComplete={this.closeModalAndReload} />
// 						{/* <Store id={index} onComplete={this.closeModalAndReload} /> */}
// 					</Modal.Content>
// 				</Modal>
// 			);
// 		}
// 	}

// 	openMembership = (id) => {
// 		this.setState({ index: id, modal: true });
// 	}

// 	rowProps = (state, rowInfo) => {
// 		return {
// 			onClick: (e, handleOriginal) => {
// 				const id = _.get(rowInfo, 'original.id');
// 				if(id) {
// 					this.openMembership(id);
// 				}
// 				if(handleOriginal) {
// 					handleOriginal();
// 				}
// 			},
// 			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
// 		};
// 	}

// 	render() {
// 		const { loading, pages } = this.state;
// 		return (
// 			<Segment.Group stacked>
// 				{this.renderModal()}
// 				<Segment color='green' className="tableHeaderContainer">
// 					<div></div>
// 					<h2>Memberships</h2>
// 					<Button basic onClick={this.refresh}>
// 						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
// 					</Button>
// 				</Segment>
// 				<Segment placeholder>
// 					<ReactTable
// 						data={this.state.data}
// 						columns={columns({
// 							openMembership: this.openMembership,
// 							state: this.state,
// 							changeDate: this.changeDate,
// 							triggerDatePopup: this.triggerDatePopup,
// 						})}
// 						pageSizeOptions={[10,15,20,50,100]}
// 						defaultPageSize={20}
// 						pages={pages}
// 						onFetchData={this.fetchData}
// 						manual
// 						loading={loading}
// 						getTrProps={this.rowProps}
// 					/>
// 					<h4 style={{ textAlign: 'center', marginTop: 10 }}>
// 						Total Memberships: {this.state.count}
// 					</h4>
// 				</Segment>
// 			</Segment.Group>
// 		);
// 	}
// }

// export default withApollo(MembershipsTableContainer);