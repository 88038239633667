import gql from 'graphql-tag';

const MUTATION_MODIFY_MEMBERSHIP = gql`

	mutation modifyMembership($id: String!, $firstname: String, $lastname: String, $email: String, $phone: String, $shippingAddress: ShippingAddress, $property: String!,$spouseFirstName: String, $spouseLastName: String, $additionalFamilyMembers: [String]) {
		modifyMembership(id: $id, firstname: $firstname, lastname: $lastname, email: $email, phone: $phone, shippingAddress: $shippingAddress, property: $property, spouseFirstName: $spouseFirstName, spouseLastName: $spouseLastName additionalFamilyMembers: $additionalFamilyMembers) {
			id
		}
	}

`;

export default MUTATION_MODIFY_MEMBERSHIP;