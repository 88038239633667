import gql from 'graphql-tag';


const MUTATION_PURCHASE_PHYSICAL_CARD = gql`

	mutation PurchasePhysicalCard($membershipID: String!, $address: ShippingAddress!, $token: String!)
	{
		purchasePhysicalCard(membershipID: $membershipID, address: $address, token: $token)
		{
			id
		}
	}

`;

export default MUTATION_PURCHASE_PHYSICAL_CARD;