import gql from 'graphql-tag';


const QUERY_BUSINESS_PLAN_USER = gql`
	query CurrentUser {
		user {
			id
			businessPlan {
				id
				socialMedia {
					facebook
					instagram
					twitter
					snapchat
					pinterest
					linkedin
					socialMediaInfo
					opted
				}
			}
		}
	}
`;

export default QUERY_BUSINESS_PLAN_USER;