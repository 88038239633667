/* eslint react/display-name: 0 */
import React from 'react';
import SimpleSelect from '../Form/SimpleSelect';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';


const options = [
	{ value: 6, label: 'Basic' },
	{ value: 7, label: 'Premium' },
];

const statusOptions = [
	{ value: 'active', label: 'Active' },
	{ value: 'trialing', label: 'Trialing' },
	{ value: 'past_due', label: 'Past Due' },
	{ value: 'canceled', label: 'Canceled' },
	{ value: 'unpaid', label: 'Unpaid' },
	{ value: 'refunded',  label: 'Refunded'  },
];


const createColumns = ({ dates={}, datePopups={}, triggerDatePopup, changeDate  }) => [
	{
		Header: 'ID',
		id: 'id',
		filterable: true,
		minWidth: 100,
		accessor: 'id',
	},
	{
		Header: 'Name',
		id: 'businessname',
		filterable: true,
		minWidth: 100,
		accessor: d => `${_.get(d, 'businessname')}`,
	},
	{
		Header: 'Email',
		id: 'accountEmail',
		filterable: true,
		minWidth: 150,
		accessor: d => _.get(d, 'accountEmail', ''),
		foldable: true,
	},
	{
		Header: 'Reference',
		id: 'referenceName',
		filterable: true,
		minWidth: 150,
		accessor: d => _.get(d, 'referenceName', ''),
		foldable: true,
	},
	{
		Header: 'Plan',
		id: 'plan',
		filterable: true,
		minWidth: 100,
		accessor: d => formatPlan(d.plan),
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Status',
		id: 'stripe.status',
		filterable: true,
		minWidth: 150,
		accessor: d => d.lastInvoiceRefunded ?  'REFUNDED' : _.get(d, 'stripe.status', ''),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={statusOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Created',
		id: 'stripe.created',
		filterable: true,
		minWidth: 105,
		accessor: d => d.stripe.created ? moment(d.stripe.created).format('ddd MMM Do YYYY') : '',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('created')} fluid>Filter Date</Button>}
					open={datePopups.created}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.createdFromDate}
								selectsStart
								endDate={dates.createdToDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('createdFromDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.createdToDate}
								selectsEnd
								startDate={dates.createdFromDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('createdToDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div>
	},
	{
		Header: 'Current Period Start',
		id: 'stripe.current_period_start',
		filterable: true,
		minWidth: 105,
		accessor: d => d.stripe.current_period_start ? moment(d.stripe.current_period_start).format('ddd MMM Do YYYY') : '',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('current_period_start')} fluid>Filter Date</Button>}
					open={datePopups.current_period_start}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.currentPeriodStartFromDate}
								selectsStart
								endDate={dates.currentPeriodStartToDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('currentPeriodStartFromDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.currentPeriodStartToDate}
								selectsEnd
								startDate={dates.currentPeriodStartFromDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('currentPeriodStartToDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div> 
	},
	{
		Header: 'Current Period End',
		id: 'stripe.current_period_end',
		filterable: true,
		minWidth: 105,
		accessor: d => d.stripe.current_period_end ? moment(d.stripe.current_period_end).format('ddd MMM Do YYYY') : '',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('current_period_end')} fluid>Filter Date</Button>}
					open={datePopups.current_period_end}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.currentPeriodEndFromDate}
								selectsStart
								endDate={dates.currentPeriodEndToDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('currentPeriodEndFromDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.currentPeriodEndToDate}
								selectsEnd
								startDate={dates.currentPeriodEndFromDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('currentPeriodEndToDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div>
	},
	{
		Header: 'Canceled At',
		id: 'stripe.canceled_at',
		filterable: true,
		minWidth: 105,
		accessor: d => d.stripe.canceled_at ? moment(d.stripe.canceled_at).format('ddd MMM Do YYYY') : '',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('canceled_at')} fluid>Filter Date</Button>}
					open={datePopups.canceled_at}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.canceledAtFromDate}
								selectsStart
								endDate={dates.canceledAtToDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('canceledAtFromDate', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.canceledAtToDate}
								selectsEnd
								startDate={dates.canceledAtFromDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('canceledAtToDate', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div>
	},
];

function formatPlan(plan) {
	switch(plan) {
	case 6:
		return 'Basic';
	case 7:
		return 'Premium';
	default:
		return 'Unknown';
	}
}

export default createColumns;