/* eslint no-unused-vars: 0 no-console: 0 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxUsd, faIdCard, faFileContract, faGraduationCap, faPiggyBank, faSearch, faChartNetwork, faEnvelope, faQuestion, faUsersCog, faSitemap, faUserTie, faFileInvoiceDollar, faSync, faPlus, faBriefcase, faMoneyBillWaveAlt, faFileChartPie, faWarehouseAlt, faUsdSquare, faUsersClass, faVideo, faVial, faTreasureChest, faTirePressureWarning, faHammer, faExclamationSquare, faEnvelopeOpenDollar, faTimes, faTrash, faThumbsUp, faBars, faAngleLeft, faUser, faPhone, faGlobeAmericas, faMapMarked, faMapMarker, faCity, faLocation, faTags, faPaperPlane, faHashtag, faUserPlus, faImages, faFolder, faMapMarkerAlt, faFilePowerpoint, faBrowser, faEdit as fasEdit, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faFile, faFileWord, faFileVideo, faFileAudio, faFilePdf, faFileImage, faDownload, faFileExcel, faUsers, faUserAlt,   } from '@fortawesome/free-solid-svg-icons';
import { faEye, faHomeAlt, faMoneyCheckAlt, faTools, faUsersCrown, faStore, faCreditCardBlank, faQuestion as farQuestion, faUsers as farUsers, faSackDollar, faMailbox, faWebcam, faLongArrowLeft, faEdit, } from '@fortawesome/pro-regular-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';



library.add(
	faFile,
	faFileWord,
	faFileVideo,
	faFileVideo,
	faFileAudio,
	faFilePdf,
	faFileImage,
	faDownload,
	faEye,
	faFileExcel,
	faFilePowerpoint,
	faUsers,
	faUserAlt,
	faBoxUsd,
	faIdCard,
	faFileContract,
	faGraduationCap,
	faPiggyBank,
	faSearch,
	faChartNetwork,
	faEnvelope,
	faQuestion,
	faUsersCog,
	faSitemap,
	faUserTie,
	faSackDollar, faFileInvoiceDollar, faPlus, faBriefcase, faMoneyBillWaveAlt, faFileChartPie, faWarehouseAlt, faStore, faUsdSquare, faUsersClass, faVideo,
	faVial, faTreasureChest, faTirePressureWarning, faHammer, faExclamationSquare, faEnvelopeOpenDollar, faTimes, faTrash, faThumbsUp, faCreditCardBlank,
	faUserTie,
	faUserPlus,
	faImages,
	
	
	
	// Sidebar
	faHomeAlt,
	faMoneyCheckAlt,
	faTools,
	faUsersCrown,
	farQuestion,
	farUsers,
	faPaperPlane,
	faMailbox,
	faWebcam,
	faHashtag,
	faAngleLeft,
	faEdit,
	
	// Header
	faBars,

	// Refresh Icon Tables
	faSync,

	// Checkout Inputs
	faUser, faPhone, faGlobeAmericas, faMapMarked, faMapMarker, faCity, faLocation, faTags, faMapMarkerAlt,

	// Documents
	faFolder, faLongArrowLeft, faYoutube, faBrowser, fasEdit,

	// Support
	faThumbsUp,

	// Manage Business
	faCheck,
	
);