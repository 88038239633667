import React, { Component } from 'react';
import styles from './employee.module.scss';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import queryCurrentUser from '../../queries/user';
import Manager from './Manager';
import Employee from './Employee';

class EmployeeArea extends Component {
	state = {
		managerArea: false,
	}

	renderSwitchArea = () => {
		const { employee, eManager } = this.props.data.user;

		if(eManager) {
			return (
				<div className={styles.switchArea} onClick={() => this.setState({ managerArea: !this.state.managerArea })}>
					<span>Switch to {this.state.managerArea ? 'Employee' : 'Manager'}</span>
				</div>
			);
		}
	}

	renderArea = () => {
		const { managerArea } = this.state;
		if(managerArea) {
			return <Manager />;
		} else {
			return <Employee />;
		}
	}

	render() {
		if(this.props.data.loading) { return; }

		const { employee, eManager } = this.props.data.user;

		return (
			<div className={styles.container}>
				{this.renderSwitchArea()}
				{this.renderArea()}
			</div>
		);
	}
}

export default compose(
	graphql(queryCurrentUser),
)(EmployeeArea);