import React, { useState, useEffect } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import SelectMonth from './SelectMonth';
import Selected from './Selected';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_GENERATE_COMMISSION_REPORT from '../../../mutations/generateCommissionReport';
import Loading from '../../Loading';
import QUERY_GET_COMMISSION_REPORT from '../../../queries/getCommissionReport';

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

const CommissionReport = ({ location: { search='?' }, history }) => {
	const q = qs.parse(search.substr(1));
	const [year, setYear] = useState(isNaN(q.year) ? null : +(q.year));
	const [month, setMonth] = useState(isNaN(q.month) ? null : +(q.month));
	const [recalc, setRecalc] = useState(false);
	const [generate, { data, loading }] = useMutation(MUTATION_GENERATE_COMMISSION_REPORT);


	useEffect(() => {
		setMonth(isNaN(q.month) ? null : +(q.month));
		setYear(isNaN(q.year) ? null : +(q.year));
	}, [search]);

	const doRecalculation = async () => {
		const result = await generate({
			variables: { month, year },
			refetchQueries: [{
				query: QUERY_GET_COMMISSION_REPORT,
				variables: { month, year },
			}],
			awaitRefetchQueries: true,
		});
		setRecalc(false);
		if(!_.get(result, 'data.generateCommissionReport')) {
			alert('Something went wrong and the report failed to recalculate.');
		}
	};

	useEffect(() => {
		if(recalc) {
			doRecalculation();
		}
	}, [recalc]);

	const onDateChange = ({ month, year }) => {
		q.year = year;
		q.month = month;		
		const n = _.omitBy(q, _.isNil);
		history.push({
			pathname: '/admin/tools/commissionreport',
			search: `?${qs.stringify(n)}`,
		});
	};

	if(loading) {
		return (
			<div className="animated fadetwoIn dnp-page">
				<Segment.Group stacked>
					<Segment className="tableHeaderContainer">
						<h2>Commission Report{ (year && month !== null) && ` - ${months[month]} ${year}` }</h2>
					</Segment>
					<Segment textAlign="center">
						<h3>Generating Commission Report</h3>
						<p>This may take a while</p>
						<Loading />
					</Segment>
				</Segment.Group>
			</div>
		);
	}

	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group stacked>
				<Segment className="tableHeaderContainer">
					<h2>Commission Report{ (year && month !== null) && ` - ${months[month]} ${year}` }</h2>
				</Segment>
				{(!year || month === null) ?
					<SelectMonth 
						onSubmit={onDateChange}
						month={month}
						year={year}
					/>
					:
					<Selected 
						month={month}
						year={year}
						onRecalculate={() => setRecalc(true)}
					/>
				}
			</Segment.Group>
		</div>
	);
};

export default CommissionReport;