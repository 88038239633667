import gql from 'graphql-tag';

const MUTATION_CANCEL_BUSINESS_PLAN = gql`

	mutation CancelBusinessPlan($id: String!) {
		cancelBusinessPlan(id: $id)
	}

`;

export default MUTATION_CANCEL_BUSINESS_PLAN;