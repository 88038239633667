import React, { Component, Fragment } from 'react';
import { Divider, Table } from 'semantic-ui-react';
import { ChangeTextFieldSale, ChangeSelectFieldSale } from './SaleChangeFields';
import queryGetSaleAdmin from '../../../../queries/getSaleAdmin';
import AdditionalFamilyMembers from './AdditionalFamilyMembers';
import { countries, states } from '../../../../config/supported_areas';

class ShippingInfoTab extends Component {

	renderMembers = (arr) => {
		return(
			arr.map(ob => (<p key="ob.name">{ob.name}</p>))
		);
	}

	renderAdditionalFamilyMembers = (data) => {
		const { additionalFamilyMembers, plan } = data;
		if(plan === 1 || plan === 4) {
			return (
				<div>
					<Divider horizontal>Additional Family Members</Divider>
					<AdditionalFamilyMembers data={additionalFamilyMembers} id={this.props.data.id} />
				</div>
			);
		}
	}

	renderSpouse = (refetch, data, id) => {
		const { plan } = data;
		if(plan === 1 || plan === 4) {
			return (
				<Fragment>
					<ChangeTextFieldSale
						label={'Spouse Firstname'}
						value={data.spouseFirstName}
						property={'spouseFirstName'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldSale
						label={'Spouse Lastname'}
						value={data.spouseLastName}
						property={'spouseLastName'}
						refetch={refetch}
						id={id}
						table
					/>
				</Fragment>
			);
		}
	}

	refetchQueries = () => {
		return [{
			query: queryGetSaleAdmin,
			variables: { id: this.props.data.id }
		}];
	}

	renderFormated = (data) => {
		const { firstname, lastname, shippingAddress: { street1, street2, city, state, zip, country } } = data;
		return (
			<div style={{ textAlign: 'center', fontWeight: '500' }}>
				<div>{firstname} {lastname}</div>
				<div>{street1}</div>
				{street2 && <div>{street2}</div>}
				<div>{city}, {state} {zip}</div>
				<div>{country}</div>
			</div>
		);
	}
	
	render() {
		const data = this.props.data;
		const refetch = this.refetchQueries();
		const { id } = data;

		return (
			<div className="admin-sale-info">
				<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Shipping Information</h4>
				<hr />

				<Table definition striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell />
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<ChangeTextFieldSale
							label={'Firstname'}
							value={data.firstname}
							property={'firstname'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'Lastname'}
							value={data.lastname}
							property={'lastname'}
							refetch={refetch}
							id={id}
							table
						/>

						{this.renderSpouse(refetch, data, id)}

						<ChangeTextFieldSale
							label={'Street 1'}
							value={data.shippingAddress.street1}
							property={'shippingAddress.street1'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'Street 2'}
							value={data.shippingAddress.street2}
							property={'shippingAddress.street2'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'City'}
							value={data.shippingAddress.city}
							property={'shippingAddress.city'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeSelectFieldSale
							options={states}
							label={'State'}
							value={data.shippingAddress.state}
							property={'shippingAddress.state'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'Zip'}
							value={data.shippingAddress.zip}
							property={'shippingAddress.zip'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeSelectFieldSale
							options={countries}
							label={'Country'}
							value={data.shippingAddress.country}
							property={'shippingAddress.country'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'Email'}
							value={data.email}
							property={'email'}
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldSale
							label={'Phone #'}
							value={data.phone}
							property={'phone'}
							refetch={refetch}
							id={id}
							table
						/>
					</Table.Body>
				</Table>

				{this.renderAdditionalFamilyMembers(data)}

				<Divider horizontal>Formated Address</Divider>

				{this.renderFormated(data)}

			</div>
		);
	}
}

export default ShippingInfoTab;