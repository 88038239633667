
export const StoreTypeOptions = [
	{ label: 'Medical', value: 1 },
	{ label: 'Dental', value: 2 },
	{ label: 'Technology', value: 3 },
	{ label: 'Retail', value: 4 },
	{ label: 'Travel', value: 5 },
	{ label: 'Entertainment', value: 6 },
	{ label: 'Home & Garden', value: 7 },
	{ label: 'Health & Beauty', value: 8 },
	{ label: 'Food & Drink', value: 9 },
	{ label: 'Family Fun', value: 10 },
	{ label: 'Chiropractor', value: 12 },
	{ label: 'Therapy', value: 13 },
	{ label: 'Optometry', value: 14  },
	{ label: 'Community', value: 15 },
	{ label: 'Auto', value: 16 },
	{ label: 'Real Estate/Finance', value: 17 },
	{ label: 'Pet Services', value: 18 },
	{ label: 'Find Employment', value: 19 },
	{ label: 'Personal', value: 11 },
	{ label: 'Other', value: 11 },
];

export const StoreTypeNames = {
	1: 'Medical',
	2: 'Dental',
	3: 'Technology',
	4: 'Retail',
	5: 'Travel',
	6: 'Entertainment',
	7: 'Home & Garden',
	8: 'Health & Beauty',
	9: 'Food & Drink',
	10: 'Family Fun',
	12: 'Chiropractor',
	13: 'Therapy',
	14: 'Optometry',
	15: 'Community',
	16: 'Auto',
	17: 'Real Estate/Finance',
	18: 'Pet Services',
	19: 'Find Employment',
	11: 'Other',
};