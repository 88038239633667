import React, { useState, useEffect } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import DetailsRow from '../../../Admin/Memberships/EditMembership/helpers/DetailsRow';
import DetailsTable from '../../../Table/DetailsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteModal from '../../../common/DeleteModal/DeleteModal';
import AddLocation from './AddLocation';
import MUTATION_DELETE_LOCATION from '../../../../mutations/deleteLocation';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';

const FormattedAddress = ({ address }) => {
	const { street1, street2, city, state, zip, country } = address;
	return (
		<div style={{ fontWeight: '500' }}>
			<div>{street1}</div>
			{street2 && <div>{street2}</div>}
			<div>{city}, {state} {zip}</div>
			<div>{country}</div>
		</div>
	);
};

const Location = ({ location, index, onDelete }) => {
	const {
		id,
		locationInformation,
	} = location;

	const hasCoord = !!_.get(location, 'location.coordinates[0]', false);
	const lon = hasCoord ? location.location.coordinates[0]: '';
	const lat = hasCoord ? location.location.coordinates[1]: '';
	const coordText = `${lat}, ${lon}`;
	let coordurl = `https://www.google.com/maps/search/${lat},${lon}`;

	console.log(coordurl);

	return (
		<div style={{ marginTop: '10px' }}>
			<Segment attached="top">
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h2>Promotion #{index}</h2>
					<div>
						<Button
							color="red"
							type="button"
							content={(
								<>
									<FontAwesomeIcon icon={['fas', 'trash']} />
								</>
							)}
							onClick={() => onDelete(id)}
						/>
					</div>
				</div>
			</Segment>
			<Segment attached>
				<DetailsTable header={false}>
					<DetailsRow
						property="Address"
						value={<FormattedAddress address={locationInformation} />}
					/>
					<DetailsRow
						property="Coords"
						value={(
							<a href={coordurl} target="_blank">
								{coordText}
							</a>
						)}
						condition={hasCoord}
					/>
					<DetailsRow
						property="Contact Name"
						value={locationInformation.name}
						condition={!!locationInformation.name}
					/>
					<DetailsRow
						property="Contact Phone"
						value={locationInformation.phone}
						condition={!!locationInformation.phone}
					/>
				</DetailsTable>
			</Segment>
		</div>
	);
};

const Locations = ({ info, query }) => {
	const { locations, storeLocationsCount } = info;
	const total = storeLocationsCount;
	const [dID, setdID] = useState(null);
	const [deleteLocation, { loading }] = useMutation(MUTATION_DELETE_LOCATION);

	useEffect(() => {
		if(!loading) {
			setdID(false);
		}
	}, [loading]);

	return (
		<div>
			<div>
				<DeleteModal
					open={!!dID}
					onClose={() => {
						!loading && setdID(null);
					}}
					loading={loading}
					onDelete={() => {
						if(dID) {
							deleteLocation({
								variables: { 
									storeinfoID: info.id,
									id: dID
								},
								refetchQueries: [{ query }],
								awaitRefetchQueries: true,
							});
						}
					}}
				/>
				<AddLocation info={info} query={query} />
			</div>
			{!total && (
				<Segment placeholder style={{ textAlign: 'center' }}>
					<h3>You have no locations</h3>
					<h4>Your business is considered a National Location</h4>
					<h4>To change that, add a location</h4>
				</Segment>
			)}
			{!!total && (
				<Segment placeholder>
					{locations.filter(i => !i.nationalLocationPlaceholder).map((l, i) => (
						<Location 
							location={l}
							key={l.id}
							index={i+1}
							onDelete={id => setdID(id)}
						/>
					))}
				</Segment>
			)} 
		</div>
	);
};

export default Locations;