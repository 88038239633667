import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ChangeTextFieldUser, ChangeSelectFieldUser } from './UserChangeFields';
import queryGetUserAdmin from '../../../../queries/getUserAdmin';
import _ from 'lodash';
import queryCurrentUser from '../../../../queries/user';
import { states, countries } from '../../../../config/supported_areas';
import DetailsTable from '../../../Table/DetailsTable';
import DetailsRow from '../../../Table/DetailsRow';
import moment from 'moment';

const W9Tab = (props) => {
  const { data } = useQuery(queryCurrentUser);
  const { user } = props;
  if (!user)
    return <div>No Information of this User</div>;

  const { id } = user;
  const adminLevel = _.get(data, 'user.adminLevel', 0);

  const refetch = [
    {
      query: queryGetUserAdmin,
      variables: { id: user.id },
    },
  ];

  const termsDate = _.get(user, 'w9.termsDate');

  return (
    <div style={{ margin: '10px' }}>
      <DetailsTable>
        <DetailsRow
          property='US Person'
          value={_.get(user, 'w9.usPerson', false) ? 'Yes' : 'No'}
        />

        <ChangeTextFieldUser
          label={'Name'}
          value={_.get(user, 'w9.legalNameOfBusiness', null)}
          property={'w9.legalNameOfBusiness'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeTextFieldUser
          label={'Street 1'}
          value={_.get(user, 'w9.street1', null)}
          property={'w9.street1'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeTextFieldUser
          label={'Street 2'}
          value={_.get(user, 'w9.street2', null)}
          property={'w9.street2'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeTextFieldUser
          label={'City'}
          value={_.get(user, 'w9.city', null)}
          property={'w9.city'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeSelectFieldUser
          options={states}
          label={'State'}
          value={_.get(user, 'w9.state', null)}
          property={'w9.state'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeTextFieldUser
          label={'Zipcode'}
          value={_.get(user, 'w9.zip', null)}
          property={'w9.zip'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeSelectFieldUser
          options={countries}
          label={'Country'}
          value={_.get(user, 'w9.country', null)}
          property={'w9.country'}
          refetch={refetch}
          id={id}
          table
        />

        <ChangeSelectFieldUser
          label={'Tax Classification'}
          value={_.get(user, 'w9.taxClassification', null)}
          property={'w9.taxClassification'}
          refetch={refetch}
          id={id}
          options={TaxClassificationOptions}
          table
        />

        <ChangeSelectFieldUser
          label={'Identification Type'}
          value={_.get(user, 'w9.idType', null)}
          property={'w9.idType'}
          refetch={refetch}
          id={id}
          options={IDTypeOptions}
          table
        />

        {adminLevel >= 3 && (
          <ChangeTextFieldUser
            label={'ID #'}
            value={_.get(user, 'w9.idNumber', null)}
            property={'w9.idNumber'}
            refetch={refetch}
            id={id}
            table
          />
        )}
        <DetailsRow
          property='Accepted Terms'
          value={_.get(user, 'w9.acceptedTerms', 'N/A') ? 'Yes' : 'No'}
        />

        <DetailsRow
          property='Terms Date'
          value={termsDate ? moment(termsDate).format('YYYY-MM-DD') : 'N/A'}
        />
      </DetailsTable>
    </div>
  );
};

export default W9Tab;

const IDTypeOptions = [
  { label: 'Social Security Number (SSN)', value: 'ssn' },
  { label: 'Employer Identification Number (EIN)', value: 'ein' },
];

export const TaxClassificationOptions = [
  {
    label: 'Individual/sole proprietor or single-member LLC',
    value: 'individual',
  },
  { label: 'Limited liability company', value: 'llc' },
  { label: 'C Corporation', value: 'c_corporation' },
  { label: 'S Corporation', value: 's_corporation' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Trust/estate', value: 'trust_estate' },
];
