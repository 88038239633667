import gql from 'graphql-tag';

const getRenewalsQuery = gql`

	query GetRenewals($month: Int!, $year: Int!) {
		getRenewals(month: $month, year: $year) {
			id

			orderInformation {
				firstname
				lastname
				email
				phone
				shippingAddress {
					street1
					street2
					city
					state
					zip
					country
      	}
			}
			date
			membershipInformation {
				ids
			}
			sentRenewalEmail
		}
	}

`;

export default getRenewalsQuery;