import React, {Component} from 'react';
import axios from 'axios';
import Loading from '../../../Loading';
import { Menu, Segment, Button, Modal } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import ChangeParent from '../../Reps/ChangeParent';
import ChangeCheckbox from '../../ChangeFields/ChangeCheckbox';
import ChangeGeneric from '../../ChangeFields/ChangeGeneric';
import ChangePopup from '../../Reps/ChangePopup';
import ChangeName from '../../ChangeFields/ChangeName';
import queryGetSaleAdmin from '../../../../queries/getSaleAdmin';
import ShippingInfoTab from './ShippingInfoTab';
import SaleInfoTab from './SaleInfoTab';
import CustomerInfoTab from './CustomerInfoTab';
import ToolsTab from './ToolsTab';
import _ from 'lodash';

class SaleInfo extends Component {
	state = {
		loading: false,
		data: null,
		activeItem: 'saleinfo',
		deleteSaleLoading: false,
		deleteSaleModalOpen: false,
	}

	handleItemClick = (value) => {
		this.setState({ activeItem: value });
	}

	renderCustomerInfo = () => {
		if(this.state.activeItem === 'cinfo') {
			return <CustomerInfoTab data={this.props.data.getSaleAdmin} />;
		}
	}

	onSuccessTemp = () => {
		this.props.data.refetch({ id: this.props.data.getSaleAdmin.id });
	}

	renderSaleInfo = () => {
		if(this.state.activeItem === 'saleinfo') {
			return <SaleInfoTab data={this.props.data.getSaleAdmin} onSuccessTemp={this.onSuccessTemp} />;
		}
	}

	renderShippingInfo = () => {
		if(this.state.activeItem === 'shippinginfo') {
			return <ShippingInfoTab data={this.props.data.getSaleAdmin} />;
		}
	}

	renderCustomerItem = () => {
		const { activeItem } = this.state;
		const customerExists = _.get(this.props, 'data.getSaleAdmin.customer', null);
		if(customerExists) {
			return(
				<Menu.Item name='Account Info' active={activeItem === 'cinfo'} onClick={() => this.handleItemClick('cinfo')} />
			);
		}
	}

	renderTools = () => {
		if(this.state.activeItem === 'tools') {
			return <ToolsTab sale={this.props.data.getSaleAdmin} onDeleteComplete={this.props.onDeleteComplete} />;
		}
	}

	render = () => {
		const { activeItem } = this.state;

		if(this.props.data.loading) {
			return(<Loading />);
		}

		return(
			<div>
				<Menu attached='top' tabular>
					<Menu.Item name='Sale Info' active={activeItem === 'saleinfo'} onClick={() => this.handleItemClick('saleinfo')} />
					{/* {this.renderCustomerItem()} */}
					<Menu.Item name='Shipping Info' active={activeItem === 'shippinginfo'} onClick={() => this.handleItemClick('shippinginfo')} />
					<Menu.Item name='Tools' active={activeItem === 'tools'} onClick={() => this.handleItemClick('tools')} />
				</Menu>

				<Segment attached='bottom'>
					{this.renderCustomerInfo()}
					{this.renderSaleInfo()}
					{this.renderShippingInfo()}
					{this.renderTools()}
				</Segment>
			</div>
		);
	}
}

export default graphql(queryGetSaleAdmin, {
	options: (props) => {
		return {
			variables: {
				id: props.id
			}
		};
	}
})(SaleInfo);
