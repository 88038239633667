import React, { Component, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import ChangeParent from '../../Reps/ChangeParent';
import ChangeCheckbox from '../../ChangeFields/ChangeCheckbox';
import { ChangeDateFieldSale } from './SaleChangeFields';
import queryGetSaleAdmin from '../../../../queries/getSaleAdmin';
import moment from 'moment';
import settings from '../../../../config/settings';


const baseURL = `https://dashboard.stripe.com/${settings.production ? '' : 'test/'}`;
const bCustomerURL = baseURL + 'customers/';
const bSubURL = baseURL + 'subscriptions/';
const bInvoiceURL = baseURL + 'invoices/';
const bChargeURL = baseURL + 'invoices/';

class SaleInfoTab extends Component {

	renderTextItem = (property, value) => {
		return (
			<Table.Row>
				<Table.Cell>{property}</Table.Cell>
				<Table.Cell>{value}</Table.Cell>
			</Table.Row>
		);
	}

	refetchQueries = () => {
		return [{
			query: queryGetSaleAdmin,
			variables: { id: this.props.data.id }
		}];
	}

	getValues = () => {
		this.props.onSuccessTemp && this.props.onSuccessTemp();
	}

	renderDate = () => {
		const data = this.props.data;
		const refetch = this.refetchQueries();
		if(!data.stripeSubscriptionID) {
			return (
				<ChangeDateFieldSale
					label={'Date'}
					value={moment(data.date)}
					text={moment(data.date).format('MMM Do YYYY, h:mm:ss a')}
					property={'date'}
					refetch={refetch}
					id={data.id}
					table
				/>
			);
		} else {
			return this.renderTextItem('Date', moment(data.date).format('MMM Do YYYY, h:mm:ss a'));
		}
	}

	renderStripeInformation = () => {
		const data = this.props.data;

		const subLink = <a href={`${bSubURL}${data.stripeSubscriptionID}`} rel="noopener noreferrer" target="_blank">{data.stripeSubscriptionID}</a>;
		const cusLink = <a href={`${bCustomerURL}${data.stripeCustomerID}`} rel="noopener noreferrer" target="_blank">{data.stripeCustomerID}</a>;
		// const invoiceLink = <a href={`${bInvoiceURL}${data.stripeInvoiceID}`} rel="noopener noreferrer" target="_blank">{data.stripeInvoiceID}</a>;
		// const chargeLink = <a href={`${bInvoiceURL}${data.stripeChargeID}`} rel="noopener noreferrer" target="_blank">{data.stripeChargeID}</a>;

		return (
			<Fragment>
				{this.renderTextItem('Stripe Customer ID', cusLink)}
				{this.renderTextItem('Stripe Subscription ID', subLink)}
				{/* {this.renderTextItem('Stripe Invoice ID', invoiceLink)}
				{this.renderTextItem('Stripe Charge ID', chargeLink)} */}
			</Fragment>
		);
	}

	renderInfo = () => {
		const data = this.props.data;
		var referenceName = null;
		if(data.reference) {
			if(data.reference.firstname) {
				referenceName = `${data.reference.firstname} ${data.reference.lastname}`;
			}
		}

		const refetch = this.refetchQueries();
		const { id } = data;

		return (
			<div className="admin-sale-info">
				<h4 style={{ textTransform: 'uppercase', fontWeight: '600', letterSpacing: '0.7px', color: 'rgba(0,0,0,.85)' }}>Sale Information</h4>
				<hr />
				<Table definition striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell />
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{this.renderDate()}
						{/* <ChangeTextFieldSale
							label={'Membership ID'}
							value={data.membershipID}
							property={'membershipID'}
							refetch={refetch}
							id={id}
							table
						/> */}
						{this.renderTextItem('Membership ID', data.membershipID)}
						{this.renderTextItem('Database ID', data.id)}
						{data.stripeCustomerID && this.renderStripeInformation()}
						{this.renderTextItem('Total Charged', data.total)}
						<Table.Row>
							<Table.Cell>Reference</Table.Cell>
							<Table.Cell>
								<ChangeParent def={referenceName} id={data.id} type={1} onSuccess={() => this.getValues()}/>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Was the sale a Renewal?</Table.Cell>
							<Table.Cell>
								<ChangeCheckbox def={data.renewal} fid={data.id} p={'renewal'} onSuccess={() => this.getValues()} />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Requested Physical Card</Table.Cell>
							<Table.Cell>
								<ChangeCheckbox def={data.requestedPhysicalCard} fid={data.id} p={'requestedPhysicalCard'} onSuccess={() => this.getValues()} />
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Sent Physical Card</Table.Cell>
							<Table.Cell>
								<ChangeCheckbox def={data.physicalCardSent} fid={data.id} p={'physicalCardSent'} onSuccess={() => this.getValues()} />
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.renderInfo()}
			</div>
		);
	}
}

export default SaleInfoTab;