import gql from 'graphql-tag';


const MUTATION_CREATE_TICKET = gql`

	mutation CreateTicket($type: Int!, $subject: String!, $description: String!, $files: [Upload]) {
		createTicket(type: $type, subject: $subject, description: $description, files: $files)
	}

`;

export default MUTATION_CREATE_TICKET;
