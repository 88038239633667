/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import SimpleSelect from '../../Form/SimpleSelect';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';

const options = [
	{ value: 0, label: 'Membership' }, // Membership Email
	{ value: 1, label: 'Corporate Membership' }, // Corporate Plan Email
	{ value: 2, label: 'Provider/Affiliate' }, // Welcome new provider
	{ value: 3, label: 'Representative' }, // Salesman
];

const booleanOptions = [
	{ value: false, label: 'False' },
	{ value: true,  label: 'True'  },
];

const columns = ({ datePopup, triggerDatePopup, startDate, endDate, changeStartDate, changeEndDate }) => [
	{
		Header: 'Sent By',
		id: 'name',
		filterable: false,
		minWidth: 150,
		accessor: d => {
			const user = _.get(d, 'user', {}) || {};
			return `${user.firstname} ${user.lastname}`;
		},
		foldable: true,
		sortable: false,
	},
	{
		Header: 'Sent To',
		id: 'email',
		filterable: true,
		minWidth: 150,
		accessor: d => d.email,
		foldable: true,
	},
	{
		Header: 'Type',
		id: 'type',
		filterable: true,
		minWidth: 150,
		accessor: d => types[d.type],
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { onChange(value !== null ? value.value : null); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Sent On',
		id: 'sent',
		filterable: true,
		minWidth: 150,
		accessor: d => moment(d.sent).format('MMMM Do YYYY, h:mm:ss a'),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={triggerDatePopup} fluid>Filter Date</Button>}
					open={datePopup}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={startDate}
								selectsStart
								endDate={endDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeStartDate(value); onChange(value);}}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={endDate}
								selectsEnd
								startDate={startDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeEndDate(value); onChange(value);}}
							/>
						</div>
					)}
				/>
			</div>
	},
	{
		Header: 'Completed',
		id: 'completed',
		filterable: true,
		minWidth: 150,
		accessor: d => d.completed ? 'True' : 'False',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { onChange(value !== null ? value.value : null); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'ID',
		id: 'id',
		filterable: true,
		minWidth: 150,
		accessor: d => d.id,
		foldable: true,
	},
];

export default columns;

const types = {
	0: 'Membership',
	1: 'Corporate Membership',
	2: 'Provider/Affiliate',
	3: 'Representative',
};