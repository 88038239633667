import React from 'react';
import { DetailsTable, DetailsRow } from './helpers';
import plans from '../../../../config/plans';
import getStripeBaseURL from '../../../../config/getStripeBaseURL';

const GeneralInfo = ({ membership }) => {
	const {
		id,
		firstname, lastname,
		plan,
		membershipID,
		email, phone,
		paidForByCompany,
		stripe,
	} = membership;

	return (
		<DetailsTable>
			<DetailsRow
				property={'Customer Name'}
				value={`${firstname} ${lastname}`}
			/>
			<DetailsRow
				property={'Database ID'}
				value={id}
			/>
			<DetailsRow
				property={'Membership Plan'}
				value={plans[plan].name}
			/>
			<DetailsRow
				property={'Membership ID'}
				value={membershipID}
			/>
			<DetailsRow
				property={'Email'}
				value={email}
			/>
			<DetailsRow
				property={'Phone #'}
				value={phone}
			/>
			<DetailsRow
				property={'Purchased with Stripe?'}
				value={(stripe && stripe.id) ? 'Yes' : 'No'}
			/>
			<DetailsRow
				property={'Purchased by Company?'}
				value={(paidForByCompany) ? 'Yes' : 'No'}
				condition={plan > 2}
			/>
			<DetailsRow
				property="Stripe Customer ID"
				value={(
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${getStripeBaseURL()}/customers/${stripe.customer}`}
					>
						{stripe.customer}
					</a>
				)}
				condition={(stripe && stripe.id && stripe.customer)}
			/>
			<DetailsRow
				property="Stripe Subscription ID"
				value={(
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${getStripeBaseURL()}/subscriptions/${stripe.id}`}
					>
						{stripe.id}
					</a>
				)}
				condition={(stripe && stripe.id)}
			/>
			<DetailsRow
				property="Stripe Latest Invoice ID"
				value={(
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={`${getStripeBaseURL()}/invoices/${stripe.latest_invoice}`}
					>
						{stripe.latest_invoice}
					</a>
				)}
				condition={(stripe && stripe.id && stripe.latest_invoice)}
			/>
		</DetailsTable>
	);
};

export default GeneralInfo;