import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loading from '../../Loading';
import _ from 'lodash';
import CreateStore from './CreateStore';
import { Segment, Divider, Menu } from 'semantic-ui-react';
import styles from './ManageBusiness.module.scss';
import GeneralInfo from './GeneralInfo';
import Promotions from './Promotions';
import Locations from './Locations';
import EditLogo from '../../common/EditLogo/EditLogo';
import Presentation from './Presentation';
import SocialMedia from './SocialMedia';
import Loyalty from './Loyalty';

const QUERY_USER_STORE = gql`

	query GetUserStore {
		user {
			id
			store {
				id
				businessname
				url
				category
				contactname
				contactemail
				contactphone

				logo

				promotions {
					id
					code
					info
					url
					additionalURL
					validUntil
					online
					file {
						id
						path
						filename
					}
				}

				locations {
					id
					locationInformation {
						name
						phone
						street1
						street2
						city
						state
						country
						zip
					}
					location {
						coordinates
					}
					nationalLocationPlaceholder
				}

				nationalLocation
				storeLocationsCount
				premier

			}
		}
	}

`;

const QUERY_GET_STORE = gql`

	query GetStore($id: String!) {
		getStore(id: $id) {
			id
			businessname
			url
			type
			category

			reference
			contactname
			contactemail
			contactphone
			logo
			premier
			user {
				id
				firstname
				lastname
				email
			}

			parent {
				id
				firstname
				lastname
				email
			}

			promotionsCount
			promotions {
				id
				code
				info
				url
				file {
					id
					path
					filename
					mimetype
					encoding
					actualPath
					previousFilename
					ext
				}
				additionalURL
				validUntil
				online
			}

			onlineOnly
			nationalLocation

			storeLocationsCount

			locations {
				id

				locationInformation {
					name
					phone
					street1
					street2
					city
					state
					zip
					country
				}

				location {
					type
					coordinates
				}

				searchName
				type
				approved
			}

			termsAgree
			privacyAgree
			approved

		}
	}

`;

const QUERY_GET_BUSINESS_PLAN_TYPE = gql`
	query User {
		user {
			id
			businessPlan {
				id
				plan
			}
		}
	}
`;

const ManageBusinessContainer = ({ user }) => {
	const { loading, data, error,refetch } = useQuery(QUERY_USER_STORE, { fetchPolicy: 'network-only' });
	const { loading: bLoading, data: bData } = useQuery(QUERY_GET_BUSINESS_PLAN_TYPE, { fetchPolicy: 'cache-and-network' });
const storeId = _.get(data, 'user.store.id');
	const { loading: xloading, data: newData, error: xerror } = useQuery(QUERY_GET_STORE, {
    variables: { id: storeId },
    skip: !storeId, // Skip the query if storeId is not available
    fetchPolicy: 'cache-and-network'
  });
		console.log(_.get(data, 'user.store.id'), newData)
	if(loading || bLoading) { return <Loading />; }

	if(!_.get(data, 'user.store.id', null)) {
		return <CreateStore query={QUERY_USER_STORE} />;
	}

	const info = _.get(data, 'user.store');

	return (
		<ManageBusiness info={info} plan={_.get(bData, 'user.businessPlan.plan', 6)} refetch={refetch} />
	);
};

const ManageBusiness = ({ info, plan, refetch}) => {
	const [tab, setTab] = useState('promotions');

	return (
		<Segment style={{ flex: 1}}>
			<h2 className={styles.header}>Manage your Profile</h2>
			<p>Any fields in <span style={{ color: '#3498db' }}>blue</span> may be edited by clicking on them</p>
			<Divider horizontal>Image</Divider>
			<EditLogo
				businessname={info.businessname}
				storeID={info.id}
				src={info.logo}
				query={QUERY_USER_STORE}
			/>

			<Divider horizontal>Information</Divider>
			<GeneralInfo info={info} query={QUERY_USER_STORE} />
			
			<Divider horizontal>Promotions & Locations</Divider>

			<Menu attached="top" className="scrollMenu">
				<Menu.Item 
					name='Your Message'
					active={tab === 'promotions'}
					onClick={() => setTab('promotions')}
				/>
				<Menu.Item 
					name='Locations'
					active={tab === 'locations'}
					onClick={() => setTab('locations')}
				/>
				{ plan !== 9 && (
					<Menu.Item 
						name='Social Media'
						active={tab === 'sm'}
						onClick={() => setTab('sm')}
					/>
				)}
				{ plan === 7 && (
					<Menu.Item 
						name='Presentation'
						active={tab === 'presentation'}
						onClick={() => setTab('presentation')}
					/>
				)}
				<Menu.Item
					name='Sharing'
					active={tab === 'loyalty'}
					onClick={() => setTab('loyalty')}
				/>
			</Menu>

			<Segment attached='bottom'>
				{ tab === 'promotions' && <Promotions info={info} refetch={refetch}  query={QUERY_USER_STORE} /> }
				{ tab === 'locations' && <Locations info={info} query={QUERY_USER_STORE} /> }
				{ tab === 'presentation' && <Presentation /> }
				{ plan !== 9 &&  tab === 'sm' && <SocialMedia /> }
				{ tab === 'loyalty' && <Loyalty /> }
			</Segment>
		</Segment>
	);
};

export default ManageBusinessContainer;
