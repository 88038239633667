import gql from 'graphql-tag';

const QUERY_GET_CONTRACT = gql`

	query GetContract($type: String!) {
		getContract(type: $type)
	}

`;

export default QUERY_GET_CONTRACT;