import React, { useRef } from 'react';
import { Segment, Form, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import { useMutation } from '@apollo/react-hooks';
import TextInput from '../FinalForm/TextInput';
import SelectInput from '../FinalForm/SelectInput';
import MUTATION_CREATE_REFERRAL from '../../mutations/createReferral';
import GraphQLError from '../Message/GraphQLError';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import _ from 'lodash';

// 1. Membership e-mail (which was in the document I provided in the last call Kenadi) 
// 2. Corporate membership e-mail (I will send that to Kenadi on Thursday to format)
// 3. New Affiliate or provider e-mail ( which Kenadi has completed) 
// 4. Representative e-mail (also Kenadi has completed) 

const options = [
	{ value: 0, label: 'Membership' }, // Membership Email
	{ value: 1, label: 'Corporate Membership' }, // Corporate Plan Email
	{ value: 2, label: 'Provider/Affiliate' }, // Welcome new provider
	{ value: 3, label: 'Representative' }, // Salesman
];

const ReferralForm = () => {
	const [createReferral, { data, loading, error }] = useMutation(MUTATION_CREATE_REFERRAL);
	const formRef = useRef(null);

	const onSubmit = async (variables) => {
		console.log(formRef);
		const res = await createReferral({
			variables,
		});
		
		const result = _.get(res, 'data.createReferral', false);
		if(result) {
			alert('Referral created successfully');
		}
	};

	console.log('ref', formRef);

	return (
		<Segment>
			<h4>Referral Form</h4>
			<p>Please complete the fields below</p>
			<GraphQLError error={error}>
				Could not create referral
			</GraphQLError>
			<FinalForm
				ref={formRef}
				onSubmit={onSubmit}
				validate={validator(schema)}
				render={({ handleSubmit, valid, form: { reset } }) => (
					<Form 
						onSubmit={async (e) => {
							await handleSubmit(e);
							reset();
						}}
						loading={loading}
					>
						<Form.Group widths="equal">
							<TextInput
								name="email"
								label="Recepient's Email"
								placeholder="example@gmail.com"
							/>
							<SelectInput
								name="type"
								label="Email Template"
								options={options}
							/>
						</Form.Group>
						<div style={{ display: 'flex', justifyContent: 'flex-end '}}>
							<Button
								disabled={!valid}
								color="green"
								type="submit"
								content="Submit Referral"
							/>
						</div>
					</Form>
				)}
			/>
		</Segment>
	);
};

const schema = Yup.object().shape({
	email: Yup.string().required('Required Field').email('Not a valid email'),
	type: Yup.number().required('Required Field'),
});

export default ReferralForm;