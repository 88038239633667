import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import QUERY_GET_ORDER_TABLE from './getBusinessPlanMemberships';
import createColumns from './createColumns';
import { useHistory, useParams } from 'react-router-dom';
import MembershipDetails from './MembershipDetails';

const skipColumns = (cols, toSkip=[]) => {
	for (let col of toSkip) {
		const index = cols.findIndex(obj => obj.id === col);
		if(index !== null) {
			cols.splice(index, 1);  
		}
	}
	return cols;
};

const BusinessPlanMembershipsTable = forwardRef(({ fixedParams, hideCols=[], path, businessPlanID }, ref) => {
	const [pages, setPages] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [fetchQuery, setFetchQuery] = useState(false);
	const [datePopups, setDatePopups] = useState({});
	const [dates, setDates] = useState({});
	// const [modalInfo, setModalInfo] = useState(null);
	const [vars, setVars] = useState({});
	const history = useHistory();
	const [modalID, setModalID] = useState();
	const params = useParams();

	useEffect(() => {
		setModalID(_.get(params, 'id'));
	}, [params]);

	useImperativeHandle(ref, () => ({
		refresh: () => {
			refetch(vars);
		},
	}));

	const [loadInitialData, { data: qData, loading, called, refetch }] = useLazyQuery(QUERY_GET_ORDER_TABLE, {});

	const { data, count } = _.get(qData, 'getBusinessPlanMembershipTable', {
		count: 0,
		data: [],
	});

	useEffect(() => {
		if(fetchQuery) {
			loadInitialData({
				variables: fetchQuery,
			});
		}
	}, [fetchQuery]);

	useEffect(() => {
		setPages(Math.ceil(count / pageSize));
	}, [count, pageSize]);

	const onFetchData = ({ pageSize : pSize, page, sorted, filtered }) => {
		const date = [];
		for(let ob in dates) {
			if(dates[ob]) {
				date.push({ id: ob, value: dates[ob].valueOf() });
			}
		}
		const required = [];
		if(fixedParams && Array.isArray(fixedParams)) {
			required.push(...fixedParams);
		}
		const variables = {
			limit: pSize,
			skip: page * pSize,
			sortBy: sorted,
			filtered: [
				...filtered,
				...date,
				...required,
			],
		};
		setVars(variables);
		if(!called) {
			setFetchQuery(variables);
		} else {
			setPageSize(pSize);
			refetch(variables);
		}
	};

	const rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const info = _.get(rowInfo, 'original');
				if(info) {
					if(path) {
						history.push(`${path}/${info.id}`);
					} else {
						setModalID(info.id);
					}
				}
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	};

	return (
		<div className="animated fadetwoIn">
			<MembershipDetails
				id={modalID}
				businessPlanID={businessPlanID}
				onClose={() => {
					setModalID();
					if(path) {
						history.push(path);
					}
				}}
				refetch={[{
					query: QUERY_GET_ORDER_TABLE,
					variables: vars,
				}]}
			/>
			<ReactTable
				data={data}
				columns={
					skipColumns(createColumns({
						dates,
						datePopups,
						triggerDatePopup: (id) => setDatePopups(prev => ({ ...prev, [id]: !prev[id] })),
						changeDate: (id, value) => setDates(prev => ({ ...prev, [id] : value})),
					}), hideCols)
				}
				pageSizeOptions={[10,15,20,50,100]}
				defaultPageSize={pageSize}
				pages={pages}
				onFetchData={onFetchData}
				manual
				defaultSorted={[{
					id: 'date',
					desc: true
				}]}
				loading={loading}
				getTrProps={rowProps}
			/>
			<h4 style={{ textAlign: 'center', marginTop: 10 }}>
				Total Memberships: {count}
			</h4>
		</div>
	);
});


BusinessPlanMembershipsTable.propTypes = {
	fixedParams: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.any,
	})),
};

export default BusinessPlanMembershipsTable;