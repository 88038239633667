import React from 'react';
import { Modal } from 'semantic-ui-react';
import UserInfo from './index';

const UserInfoModal = ({ id, onClose  }) => {
	return (
		<Modal
			open={!!id}
			onClose={onClose}
			size='small'
			closeIcon
			header={(
				<Modal.Header>Viewing User - {id}</Modal.Header>
			)}
			content={(
				<Modal.Content>
					<UserInfo id={id} />
				</Modal.Content>
			)}
		/>
	);
};

export default UserInfoModal;