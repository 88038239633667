/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import GraphQLError from '../../../Message/GraphQLError';
import queryGetStore from '../../../../queries/getStore';
import addLocationMutation from '../../../../mutations/addLocation';
import SelectInput from '../../../FinalForm/SelectInput';
import { countries, states } from '../../../../config/supported_areas';


class AddLocation extends Component {

	onSubmit = (values) => {
		this.props.mutate({
			variables: values,
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.id }
			}]
		});
	}

	renderForm = () => {
		return (
			<FinalForm
				onSubmit={this.onSubmit}
				validate={validate}
				initialValues={{ id: this.props.id }}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextInput name='street1' placeholder="Street 1" label="Street" required />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name='street2' placeholder="Street 2" label={false} />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name='city' placeholder="City" required />
							<SelectInput name='state' placeholder="State" required options={states} />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name='zip' placeholder="Zipcode" required />
							<SelectInput name='country' placeholder="Country" required options={countries} />
						</Form.Group>
						<Form.Group widths='equal'>
							<TextInput name='name' placeholder="Contact Name" />
							<TextInput name='phone' placeholder="Phone Number" />
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						<Button type="submit">Add Location</Button>
					</Form>
				)}
			/>
		);
	}

	render() {
		return (
			<div>
				<Popup
					trigger={<Button color='green' content='Add Location' />}
					content={this.renderForm()}
					on='click'
					position='top right'
				/>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	if(!values.street1) {
		errors.street1 = 'Required Field';
	}

	if(!values.city) {
		errors.city = 'Required Field';
	}

	if(!values.state) {
		errors.state = 'Required Field';
	}

	if(!values.zip) {
		errors.zip = 'Required Field';
	}

	if(!values.country) {
		errors.country = 'Required Field';
	}

	return errors;
}

export default compose(
	graphql(addLocationMutation),
	withStateMutation()
)(AddLocation);