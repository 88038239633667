import gql from 'graphql-tag';

const setSettingMutation = gql`
	mutation SetSetting($key: String!, $value: String) {
		setSetting(key: $key, value: $value) {
			id
			key
			value
		}
	}
`;

export default setSettingMutation;