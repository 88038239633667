/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component, Fragment,} from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import { ChangeTextFieldStore, ChangeSelectFieldStore, ChangeReferenceFieldStore } from '../ManageStores/StoreChangeFields';
import queryGetStore from '../../../../queries/getStore';
import moment from 'moment';
import { ChangeMultiSelectFieldStore } from '../ManageStores/StoreChangeFields';
import { StoreTypeOptions } from '../../../../config/storeTypes';
import EditLogo from '../../../common/EditLogo/EditLogo';
import { ChangeCheckboxFieldStore } from '../ManageStores/StoreChangeFields';

class GeneralInfo extends Component {

	renderField = (property, value, renderValue) => {
		const v = _.get(this.props, `data.${value}`, 'N/A') || 'N/A';
		return (
			<Table.Row>
				<Table.Cell>{property}</Table.Cell>
				<Table.Cell>{renderValue ? renderValue(v) : v}</Table.Cell>
			</Table.Row>
		);
	}

	refetchQueries = () => {
		return [{
			query: queryGetStore,
			variables: { id: this.props.data.id }
		}];
	}

	render() {
		console.log(this.props.data);
		const { id, businessname, category, contactemail, contactname, contactphone, ein, parent, user, logo, premier } = this.props.data;

		const refetch = this.refetchQueries();

		return (
			<div>

				<Table definition striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell />
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>

						<Table.Row>
							<Table.Cell>Logo</Table.Cell>
							<Table.Cell>
								<EditLogo
									businessname={businessname}
									storeID={id}
									src={logo}
									refetchQueries={refetch}
								/>
							</Table.Cell>
						</Table.Row>

						<ChangeTextFieldStore
							label="Business Name"
							value={businessname}
							property='businessname'
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeMultiSelectFieldStore
							label={'Store Type'}
							value={category}
							property={'category'}
							refetch={refetch}
							id={id}
							options={StoreTypeOptions}
							table
						/>


						<ChangeTextFieldStore
							label="Contact Name"
							value={contactname}
							property='contactname'
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldStore
							label="Contact Email"
							value={contactemail}
							property='contactemail'
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeTextFieldStore
							label="Contact Phone"
							value={contactphone}
							property='contactphone'
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeReferenceFieldStore
							label="Parent"
							value={parent}
							property="parent"
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeReferenceFieldStore
							label="Account Holder"
							value={user}
							property="user"
							refetch={refetch}
							id={id}
							table
						/>

						<ChangeCheckboxFieldStore
							label="Is Premier?"
							value={premier}
							property="premier"
							refetch={refetch}
							id={id}
							table
						/>


						{this.renderField('Database ID', 'id')}
						{this.renderField('National Location', 'nationalLocation', (v) => v === 'N/A' ? 'false' : 'true')}
						{this.renderField('Reference', 'reference')}

						<ChangeTextFieldStore
							label="EIN"
							value={ein}
							property='ein'
							refetch={refetch}
							id={id}
							table
						/>

						{this.renderField('Agreed to Terms', 'termsAgree', renderDate)}
						{this.renderField('Agreed to Privacy Terms', 'privacyAgree', renderDate)}

					</Table.Body>

				</Table>

				{/*
					<ModifyType value={type} property='type' id={id}>Type</ModifyType>
				*/}
			</div>
		);
	}
}

function renderDate(date) {
	return date ? moment(date).format('MMMM Do YYYY, h:mm:ss a') : 'N/A';
}

export default GeneralInfo;