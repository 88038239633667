import React, { Component, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { ChangeTextFieldUser, ChangeSelectFieldUser } from './UserChangeFields';
import queryGetUserAdmin from '../../../../queries/getUserAdmin';
import _ from 'lodash';
import queryCurrentUser from '../../../../queries/user';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';

class PaymentInfoTab extends Component {

	renderTextItem = (property, value) => {
		return (
			<Table.Row>
				<Table.Cell>{property}</Table.Cell>
				<Table.Cell>{value}</Table.Cell>
			</Table.Row>
		);
	}

	refetchQueries = () => {
		return [{
			query: queryGetUserAdmin,
			variables: { id: this.props.user.id }
		}];
	}

	renderDirectDeposit = () => {
		const { user } = this.props;
		if (!user)
			return <div>No Information of this User</div>;

		const { id } = user;
		const refetch = this.refetchQueries();

		if (this.props.data.loading || !this.props.data.user) {
			return <div></div>;
		}

		const adminLevel = _.get(this.props, 'data.user.adminLevel', 0);
		const directDepositInfo = _.get(user, 'directDepositInfo', {});

		return (
			<Fragment>
				<ChangeTextFieldUser
					label={'Full Name'}
					value={_.get(directDepositInfo, 'name')}
					property={'directDepositInfo.name'}
					refetch={refetch}
					id={id}
					table
				/>
				{
					adminLevel >= 3 &&
					<ChangeTextFieldUser
						label={'Routing #'}
						value={_.get(directDepositInfo, 'rtn')}
						property={'directDepositInfo.rtn'}
						refetch={refetch}
						id={id}
						table
					/>
				}
				{
					adminLevel >= 3 &&
					<ChangeTextFieldUser
						label={'Account #'}
						value={_.get(directDepositInfo, 'acn')}
						property={'directDepositInfo.acn'}
						refetch={refetch}
						id={id}
						table
					/>
				}
				<ChangeSelectFieldUser
					label={'Account Type'}
					value={_.get(directDepositInfo, 'at')}
					property={'directDepositInfo.at'}
					refetch={refetch}
					id={id}
					options={AccountTypeOptions}
					table
				/>
				<ChangeTextFieldUser
					label={'Bank Name'}
					value={_.get(directDepositInfo, 'bName')}
					property={'directDepositInfo.bName'}
					refetch={refetch}
					id={id}
					table
				/>
			</Fragment>
		);
	}

	getCheckInfo() {
		return this.props.user.representativeAddress;
	}

	renderCheck = () => {
		const user = this.props.user;
		const { street1, street2, city, state, zip, country } = this.getCheckInfo();
		return (
			<Fragment>
				<Table.Row>
					<Table.Cell>
						Mail To:
					</Table.Cell>
					<Table.Cell>
						<div>
							<p>{user.firstname} {user.lastname}</p>
							<p>{street1}</p>
							<p>{street2}</p>
							<p>{city}, {state} {zip} {country}</p>
						</div>
					</Table.Cell>
				</Table.Row>
			</Fragment>
		);
	}

	render() {
		const { user } = this.props;
		const { id } = user;
		const refetch = this.refetchQueries();

		if (this.props.data.loading || !this.props.data.user) {
			return <div></div>;
		}

		const adminLevel = _.get(this.props, 'data.user.adminLevel', 0);

		return (
			<div style={{ margin: '10px' }}>
				<Table definition striped>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell />
							<Table.HeaderCell>Value</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>

						<ChangeSelectFieldUser
							label={'Payment Type'}
							value={user.paymentType}
							property={'paymentType'}
							refetch={refetch}
							id={id}
							options={PaymentOptions}
							table
						/>

						{!user.paymentType && this.renderCheck()}

						{user.paymentType === 1 && this.renderDirectDeposit()}

					</Table.Body>

				</Table>
			</div>
		);
	}
}

export default compose(
	graphql(queryCurrentUser)
)(PaymentInfoTab);


const PaymentOptions = [
	{ label: 'Check', value: 0 },
	{ label: 'Direct Deposit', value: 1 },
];

const AccountTypeOptions = [
	{ key: '0', label: 'Checkings', value: 0 },
	{ key: '1', label: 'Savings', value: 1 }
];