import React, { Component } from 'react';
import { Segment, Button, Divider, Modal } from 'semantic-ui-react';
import { withApollo } from '@apollo/react-hoc';
import moment from 'moment';
import generateColumns from './cols';
import ReactTable from 'react-table';
import queryGetSalesAdmin from '../../../queries/getSalesAdmin';
import _ from 'lodash';
import SaleInfo from './SaleInfo';


// const FoldableTable = FoldableTableHOC(ReactTable);

class SalesTableContainer extends Component {
	state = {
		loading: false,
		pages: 1,
		datePopup: false,
		startDate: null,
		endDate: null,
		saleModal: false,
		saleID: false,
		data: [],
		count: 0,
		latestQuery: {},
		pageSize: 20,
	}

	triggerDatePopup = () => {
		this.setState({ datePopup: !this.state.datePopup });
	}

	changeStartDate = (date) => {
		if(date) {
			this.setState({
				startDate: moment(date).startOf('day').toDate()
			});
		} else {
			this.setState({
				startDate: null,
			});
		}
	}

	changeEndDate = (date) => {
		if(date) {
			this.setState({
				endDate: moment(date).startOf('day').subtract('minute', 1).toDate()
			});
		} else {
			this.setState({
				endDate: null,
			});
		}
	}

	fetchData = (state, instance) => {
		this.setState({ loading: true });

		const date = [];
		const {startDate, endDate} = this.state;
		if(startDate) { date.push( { id: 'fromDate', value: startDate.valueOf() } ); }
		if(endDate) { date.push( { id: 'toDate', value: endDate.valueOf() } ); }

		const query = {
			query: queryGetSalesAdmin,
			variables: {
				limit: state.pageSize,
				skip: state.page * state.pageSize,
				sortBy: state.sorted,
				filtered: [
					...state.filtered,
					...date,
				],
			},
			fetchPolicy: 'network-only',
		};

		this.props.client.query(query)
			.then(data => {
				const count = _.get(data, 'data.getSalesAdmin.count', 0);
				this.setState({
					loading: false,
					pages: Math.ceil(count / state.pageSize),
					data: _.get(data, 'data.getSalesAdmin.sales', []),
					count,
					pageSize: state.pageSize,
					latestQuery: query,
				});
			});
	}

	refresh = () => {
		this.setState({ loading: true });

		const { pageSize, latestQuery } = this.state;

		this.props.client.query(latestQuery)
			.then(data => {
				const count = _.get(data, 'data.getSalesAdmin.count', 0);
				this.setState({
					loading: false,
					pages: Math.ceil(count / pageSize),
					data: _.get(data, 'data.getSalesAdmin.sales', []),
					count,
				});
			});
		
	}

	openSale = (id) => {
		this.setState({ saleID: id, saleModal: true });
	}

	rowProps = (state, rowInfo) => {
		return {
			onClick: (e, handleOriginal) => {
				const id = _.get(rowInfo, 'original.id');
				id && this.openSale(id);
				handleOriginal && handleOriginal();
			},
			className: _.get(rowInfo, 'original.id') ? 'table__row__custom' : '',
		};
	}

	handleClose = () => {
		this.setState({ saleModal: false });
	}

	renderModal = () => {
		if(this.state.saleID) {
			return (
				<Modal
					open={this.state.saleModal}
					onClose={this.handleClose}
					size='small'
					closeIcon
				>
					<Modal.Header>Viewing Sale</Modal.Header>
					<Modal.Content>
						<SaleInfo id={this.state.saleID} onDeleteComplete={() => { this.handleClose();  this.refresh(); }} />
					</Modal.Content>
				</Modal>
			);
		}
	}

	render() {
		const { loading, pages, startDate, endDate } = this.state;
		const columns = generateColumns({
			datePopup: this.state.datePopup,
			triggerDatePopup: this.triggerDatePopup,
			startDate,
			endDate,
			changeStartDate: this.changeStartDate,
			changeEndDate: this.changeEndDate,
			openSale: this.openSale,
		});
		return (
			<Segment.Group stacked={true}>
				{this.renderModal()}
				<Segment color='blue' className="tableHeaderContainer">
					<div></div>
					<h2>Sales Management</h2>
					<Button basic onClick={this.refresh}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>
				<Segment placeholder>
					<ReactTable
						data={this.state.data}
						columns={columns}
						pageSizeOptions={[10,15,20,50,100]}
						defaultPageSize={20}
						style={{  }}
						pages={pages}
						onFetchData={this.fetchData}
						manual
						loading={loading}
						defaultSorted={[
							{
								id: 'date',
								desc: true
							}
						]}
						getTrProps={this.rowProps}
					/>
					<h4 style={{ textAlign: 'center', marginTop: 10 }}>
						Total Sales: {this.state.count}
					</h4>
				</Segment>
			</Segment.Group>
		);
	}
}


export default withApollo(SalesTableContainer);