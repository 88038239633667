import axios from 'axios';
import React, { useState, useEffect } from 'react';
import businessGroups from './businesses';
import { gqlGetUserInfo } from '../../actions';
import { Checkbox, Header, Button, Input, List } from 'semantic-ui-react';

const itemClass = {
  gap: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start', 
};

const BusinessList = () => {
  const [search, setSearch] = useState("");
  const [checkedBoxes, setCheckedBoxes] = useState({
    ids: [],
    showSaveMessage: false,
  });

  const modifyBusinessSearchList = async (checkedBoxes) => {
    let url = '/api/account/settings/business_search_list';
    let body = { businessSearchList: checkedBoxes.ids };
    try {
      await axios.post(url, body);
      setCheckedBoxes({
        showSaveMessage: true,
        ids: [...checkedBoxes.ids],
      });
    } catch (e) {
      console.error('modifyBusinessSearchList', e.toString());
    }
  };

  useEffect(() => {
    async function fetchUserInfo() {
      const res = await gqlGetUserInfo();
      setCheckedBoxes({
        showSaveMessage: false,
        ids: res.data.data.user.businessSearchList,
      });
    }

    fetchUserInfo();
  }, [])

  const handleCheckboxChange = (e, data) => {
    const elementID = e.target.id;
    
    if (data.checked) {
      if (!checkedBoxes.ids.includes(elementID)) {
        checkedBoxes.ids.push(elementID);  
      }
    } else {
      checkedBoxes.ids.splice(checkedBoxes.ids.indexOf(elementID), 1);
    }
    
    setCheckedBoxes({
      showSaveMessage: false,
      ids: [...checkedBoxes.ids],
    });
  };
  
  const getListItems = () => {
    const listItems = [];

    businessGroups.map((group, i) => {
      const groupID = group.name.split(' ').join('_').toLowerCase();
  
      if (group.name.toLowerCase().includes(search.toLowerCase()) || group.items.includes(search.toLowerCase())) {
        listItems.push(
          <List.Item key={"group_" + groupID}>
            <List.Header key={"group_header_" + groupID}>{group.name}</List.Header>
            <hr />
            {group.items.map((item, j) => {
              const itemID = item.split(' ').join('_').toLowerCase();
              const groupItemID = [groupID, itemID].join('_').toLowerCase();
  
              return (
                <List.Item style={itemClass} key={"group_item_" + groupItemID}>
                  <Checkbox toggle id={groupItemID} key={"group_item_checkbox_" + groupItemID} checked={checkedBoxes.ids.includes(groupItemID)} onChange={(e, data) => handleCheckboxChange(e, data)} />
                  <List.Content>
                    <List.Description>{item}</List.Description>
                  </List.Content>
                </List.Item>
              )
            })}
          </List.Item>
        );
      }
    });
  
    return listItems;
  };

  const getSuccessMessage = () => {
    if (checkedBoxes.showSaveMessage) {
      return (
        <p style={{marginTop: '10px'}}>Business listings saved.</p>
      );
    }
  }

	return (
		<div style={{maxHeight: '1024px', overflowY: 'scroll'}}>
      <div style={{width: '100%', position: 'sticky', top: '0', zIndex: '20', backgroundColor: 'white'}}>
        <div>
          <Header>Select the businesses you want to display in your search options.</Header>
        </div>
        <div style={{marginTop: '10px'}}>
          <Input
            icon='search'
            name='search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder='Search by category...' />
          <Button primary style={{marginLeft: '10px' }} onClick={() => modifyBusinessSearchList(checkedBoxes)}>Save</Button>
          {getSuccessMessage()}
        </div>
      </div>
      <List>{getListItems()}</List>
		</div>
	);
};

export default BusinessList;
