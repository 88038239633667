import gql from 'graphql-tag';

const mutationDeletePromoCode = gql`

	mutation DeletePromoCode($id: String!) {
		deletePromoCode(id: $id)
	}

`;

export default mutationDeletePromoCode;