import gql from 'graphql-tag';

const queryGetUserAdmin = gql`

	query queryGetUserAdmin($id: String!) {
		getUserAdmin(id: $id) {
			id
			firstname
			lastname
			email
			zip
			accountType
			representativeLevel {
				typeID
			}
			influencer
			friend
			representativeAddress {
				street1
				street2
				city
				state
				zip
				country
			}
			adminLevel
			referenceCode
			phone
			skipCommissionReqs
			representativeID
			birthday
			businessName
			businessType
			employee
			signature
			eManager
			employeesToContact {
				name
				contact
			}
			parent {
				firstname
				lastname
				referenceCode
				email
				id
			}
			w9 {
				usPerson
				legalNameOfBusiness
				street1
				street2
				city
				state
				zip
				country
				taxClassification
				idType
				idNumber
				acceptedTerms
				termsDate
			}
			paymentType
			directDepositInfo {
				name
				rtn
				acn
				at
				bName
			}
			checkCustom
			checkInfo {
				street1
				street2
				city
				state
				zip
				country
			}
			dateBecameRepresentative
            donating
		}
	}

`;

export default queryGetUserAdmin;