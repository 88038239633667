import React, { Component, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Form, Button, Segment, Table, Divider } from 'semantic-ui-react';
import TextInput from '../../../FinalForm/TextInput';
import Checkbox from '../../../FinalForm/StyledCheckbox';
import Condition from '../../../FinalForm/Condition';
import AdditionalFamilyMembers from './AdditionalFamilyMembers';
import styles from './corporate.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mutationBuyCorporateCards from '../../../../mutations/buyCorporatePlan';
import GraphQLError from '../../../Message/GraphQLError';
import SelectInput from '../../../FinalForm/SelectInput';
import { countries, states } from '../../../../config/supported_areas';
import validate from './validate';
import { useMutation } from '@apollo/react-hooks';
import OrderDetails from './OrderDetails';
import FindPromoCode from '../../../common/FindPromoCode/FindPromoCode';
import { injectStripe } from 'react-stripe-elements';
import { PaymentInformationSection } from '../../../SellMemberships/CheckoutForm';
import cn from 'classnames';


const SellCorporateForm = ({ stripe, onComplete, ...props }) => {
	const [purchase, { data, loading, error }] = useMutation(mutationBuyCorporateCards);
	const [code, setCode] = useState(null);

	React.useEffect(() => {
		if(_.get(data, 'buyCorporateCards.length', -1) >= 1) {
			onComplete(data.buyCorporateCards);
		}
		console.log('data', data);
	}, [data]);

	const onSubmit = async ({ stripeName, stripeZip, ...v }) => {
		stripe.createToken({
			'name': stripeName,
			'address_zip': stripeZip,
		}).then(({ token, error }) => {
			if(error || !token.id) {
				alert('The debit/credit card that was used is invalid.');
			} else {
				const info = getPlanCount(v.plans);
				let values = {};
				values.accountID = 'admin';
				values.employees = v.plans;
				values.planCount = info.individualPlans + info.familyPlans;
				values.planID = v.id;
				values.total = info.p;
				values.totalPlans = { personal: info.individualPlans, family: info.familyPlans };

				purchase({
					variables: {
						...values,
						token: token.id,
						coupon: _.get(code, 'id', null),
					}
				});
			}
		});
	};

	const getPlanCount = (plans) => {
		let individualPlans = 0, familyPlans = 0;
		plans.forEach(ob => {
			if(_.get(ob, 'familyPlan')) {
				familyPlans++;
			} else {
				individualPlans++;
			}
		});
		return { individualPlans, familyPlans };
	};

	return (
		<div>
			<FinalForm
				onSubmit={onSubmit}
				validate={validate}
				mutators={{ ...arrayMutators }}
				initialValues={{ id: props.id }}
				render={({ handleSubmit, form: { mutators: { push }}, values, valid }) => {
					const info = getPlanCount(values.plans || []);
					return (
						<Form onSubmit={handleSubmit} loading={loading}>
							<FieldArray name="plans">
								{({ fields }) => 
									fields.map((name, index) => (
										<>
											<div className={styles.sectionHeader}>{ _.get(values, `plans[${index}].firstname`, `Employee #${index+1}`) } { _.get(values, `plans[${index}].lastname`) }</div>
											<div key={name} className={cn(styles.plan, 'containerized')}>
												<span onClick={() => fields.remove(index)} className={styles.delete}>
													<FontAwesomeIcon icon={['fas', 'trash']} size='2x' />
												</span>
												<Form.Group widths='equal'>
													<TextInput name={`${name}.firstname`} placeholder="First Name" required />
													<TextInput name={`${name}.lastname`} placeholder="Last Name" required />
												</Form.Group>

												<Form.Group widths='equal'>
													<TextInput name={`${name}.phone`} placeholder="Phone" required />
													<TextInput name={`${name}.email`} placeholder="Email" />
												</Form.Group>

												<Form.Group widths='equal'>
													<Checkbox name={`${name}.ownAddress`} label="Ship to an address different than corporate" />
												</Form.Group>

												<Condition when={`${name}.ownAddress`} is={true}>								
													<Form.Group widths='equal'>
														<TextInput name={`${name}.street1`} placeholder="Street 1" label="Street" required />
													</Form.Group>
													<Form.Group widths='equal'>
														<TextInput name={`${name}.street2`} placeholder="Street 2" label={false} />
													</Form.Group>
													<Form.Group widths='equal'>
														<TextInput name={`${name}.city`} placeholder="City" required />
														<SelectInput name={`${name}.state`} placeholder="State" required options={states} />
													</Form.Group>
													<Form.Group widths='equal'>
														<TextInput name={`${name}.zip`} placeholder="Zipcode" required />
														<SelectInput name={`${name}.country`} placeholder="Country" required options={countries} />
													</Form.Group>
												</Condition>

												<Form.Group widths='equal'>
													<Checkbox name={`${name}.familyPlan`} label="Upgrade to family plan - $49.95 Extra" />
												</Form.Group>

												<Condition when={`${name}.familyPlan`} is={true}>
													<Form.Group widths='equal'>
														<TextInput name={`${name}.spouseFirstName`} placeholder="Spouse's First Name" />
														<TextInput name={`${name}.spouseLastName`} placeholder="Spouse's Last Name" />
													</Form.Group>						
													<AdditionalFamilyMembers name={`${name}.family`} />
												</Condition>
											</div>
										</>
									))
								}
							</FieldArray>
							<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
								<Button type='button' onClick={() => push('plans')}>Add Employee Plan</Button>
							</div>
							<Divider horizontal>Purchase Summary</Divider>
							<div className={styles.sectionHeader}>Payment Information</div>
							<PaymentInformationSection />
							<div className={styles.sectionHeader}>Discount Code</div>
							<FindPromoCode
								containerized
								onComplete={code => setCode(code)}
							/>
							<OrderDetails
								code={code}
								items={[
									{ name: 'Individual Card', price: 49.95, quantity: info.individualPlans },
									{ name: 'Family Card', price: 99.90, quantity: info.familyPlans },
								]}
							/>
							<GraphQLError error={error}>There was an issue</GraphQLError>
							<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>
								<Button
									type="Submit"
									content="Submit"
									loading={loading}
									color="green"
									disabled={!valid}
								/>
							</div>
						</Form>
					);
				}}
			/>
		</div>
	);
};

export default injectStripe(SellCorporateForm);