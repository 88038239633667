import gql from 'graphql-tag';

const querySearchUser = gql`
	query SearchUser($name: String!) {
		searchUsers(name: $name) {
			id 
			firstname 
			lastname 
			email 
		}
	}
`;

export default querySearchUser;