/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component, useState } from 'react';
import { Form, Popup, Button, Modal } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import Checkbox from '../../../FinalForm/Checkbox';
import TextAreaInput from '../../../FinalForm/TextAreaInput';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import withStateMutation from '../../../../queries/withStateMutation';
import GraphQLError from '../../../Message/GraphQLError';
import QUERY_GET_STORE from '../../../../queries/getStore';
import MUTATION_ADD_PROMOTION from '../../../../mutations/addPromotion';
import { useMutation } from '@apollo/react-hooks';
import { FSRichText } from '../../../FinalForm';
import _ from 'lodash';


const AddPromotion = ({ id }) => {
	const [modal, setModal] = useState(false);
	const [file, setFile] = useState(null);
	const [addPromotion, { loading, error, data }] = useMutation(MUTATION_ADD_PROMOTION);

	React.useEffect(() => {
		if(!error && _.get(data, 'addPromotion.id')) {
			setModal(false);
		}
	}, [error, data]);

	const onSubmit = (values) => {
		const variables = { file, ...values};
		addPromotion({
			variables,
			refetchQueries: [{
				query: QUERY_GET_STORE,
				variables: { id }
			}],
			awaitRefetchQueries: true
		});
	};

	const onChange = ({ target: { validity, files: [file] } }) => {
		setFile(file);
	};

	return (
		<>
			<Button color='green' content='Add Promotion' onClick={() => setModal(v => !v)} />
			<Modal open={modal} onClose={() => setModal(false)} closeOnDimmerClick={false} centered={false} closeIcon>
				<Modal.Header>Add a Promotion</Modal.Header>
				<Modal.Content>
					<FinalForm
						onSubmit={onSubmit}
						validate={validate}
						initialValues={{ id }}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<Form onSubmit={handleSubmit} loading={loading} className="promotionForm">
								<Form.Group widths='equal'>
									<TextInput name='url' placeholder="Enter website link for promotion" label="URL" />
									<TextInput name='additionalURL' placeholder="Enter website link for promotion" label="Additional URL" />
								</Form.Group>
								<Form.Group widths='equal'>
									<Checkbox name='online' label="Online Deal" />
								</Form.Group>
								<Form.Group widths='equal'>
									<TextInput name='code' placeholder="Promo code" />
								</Form.Group>
								<Form.Group widths='equal'>
									<FSRichText name='info' placeholder="Details about the promotion" label="Promotion Information" required />
								</Form.Group>
								<Form.Group widths='equal'>
									<input
										type="file"
										onChange={onChange}
									/>
								</Form.Group>
								<GraphQLError error={error}>There was an issue</GraphQLError>
								<Button type="submit">Add Promotion</Button>
							</Form>
						)}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
};

function validate(values) {
	const errors = {};

	if(!values.info) {
		errors.info = 'Required Field';
	}

	return errors;
}

export default AddPromotion;
