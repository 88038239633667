import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import styles from './AddMembership.module.scss';
import MembershipForm from './MembershipForm';

const AddMembership = ({ planID }) => {
	const [open, setOpen] = React.useState(false);
	return (
		<div className={styles.buttonContainer}>
			<Button onClick={() => setOpen(true)}>Add Membership</Button>
			<AddMembershipModal
				open={open}
				onClose={() => setOpen(false)}
				planID={planID}
			/>
		</div>
	);
};

const AddMembershipModal = ({ planID, ...props }) => {
	return (
		<Modal {...props} closeOnDimmerClick={false} closeIcon centered={false}>
			<Modal.Header>Add Membership to Group Plan</Modal.Header>
			<Modal.Content>
				<MembershipForm planID={planID} />
			</Modal.Content>
		</Modal>
	);
};

export default AddMembership;


/**
 * firstname
 * lastname
 * phone
 * email
 * shiptoaddressotherthancorporate
 * upgrade to fmaily plan
 *  *spouseFirstName
 *  *spouseLastName
 *  *children - { name }
 */