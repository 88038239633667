import gql from 'graphql-tag';

const MUTATION_PURCHASE_PRODUCTS = gql`
  mutation PurchaseProducts(
    $products: [Product]
    $coupon: String
    $token: String!
    $reference: String
  ) {
    purchaseProducts(
      products: $products
      coupon: $coupon
      token: $token
      reference: $reference
    ) {
      id
    }
  }
`;

export default MUTATION_PURCHASE_PRODUCTS;
