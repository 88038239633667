import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Menu, Segment } from 'semantic-ui-react';
import _ from 'lodash';
import QUERY_GET_MEMBERSHIP_FROM_BUSINESS_PLAN from './getMembershipFromBusinessPlan';
import Loading from '../../../../Loading/index';
import BasicInfo from './BasicInfo';
import FamilyInfo from './FamilyInfo';

const MembershipContent = ({ ids, refetch, onClose }) => {
	const { data, loading } = useQuery(QUERY_GET_MEMBERSHIP_FROM_BUSINESS_PLAN, {
		variables: ids,
	});
	
	const [activeItem, setActiveItem] = React.useState('info');

	if(loading) {
		return <Loading />;
	}

	if(!_.get(data, 'getMembershipFromBusinessPlan.id')) {
		return <div>Error loading data. Please try again.</div>;
	}

	const membership = _.get(data, 'getMembershipFromBusinessPlan');
	const hasFamily = _.get(data, 'getMembershipFromBusinessPlan.plan', 3) === 4;

	return (
		<div>
			<Menu attached='top' tabular>
				<Menu.Item name='Info' active={activeItem === 'info'} onClick={() => setActiveItem('info')} />
				{ hasFamily && (
					<Menu.Item name='Family' active={activeItem === 'family'} onClick={() => setActiveItem('family')} />
				)}
			</Menu>

			<Segment attached='bottom'>
				{ activeItem === 'info' && <BasicInfo {...{ membership, ids, refetch, onClose }} /> }
				{ activeItem === 'family' && <FamilyInfo {...{ membership }} /> }
				{/* { activeItem === 'shippinginfo' && <ShippingInfo data={data.getOrder} /> } */}
				{/* { (activeItem === 'minfo' && membership) && <MembershipInfo data={data.getOrder} /> } */}
			</Segment>
		</div>
	);
};

export default MembershipContent;