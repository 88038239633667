import gql from 'graphql-tag';

const querySearchCorporatePlans = gql`
	query SearchCorporatePlans($query: String) {
		searchCorporatePlans(query: $query) {
			id
			name
			email
		}
	}
`;

export default querySearchCorporatePlans;