import React, { Component } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import AddManagerModel from './AddManagerModel';
import ManagersTable from './ManagersTable';
import axios from 'axios';
import getQuery from './getQuery';
import ManagerModel from './ManagerModel';

class ManagersPage extends Component {
	state = {
		loading: false,
		addManagerOpen: false,
		data: null,
		managerSelected: false
	}

	componentDidMount() {
		this.getValues();
	}

	getValues = async () => {

		this.setState({ loading: true });

		try {
			const res = await axios.get(getQuery());
			this.setState({
				data: res.data.data
			});
		} catch (error) {
			///
		}

		this.setState({ loading: false });
	}

	addManager = () => {
		this.setState({
			addManagerOpen: true
		});
	}

	closeAddManager = () => {
		this.setState({
			addManagerOpen: false
		});
	}

	onAddSuccessful = () => {
		this.closeAddManager();
		this.refresh();
	}

	refresh = () => {
		this.getValues();
	}

	onManagerSelect = (event, index, data) => {
		console.log(data.id); /* eslint no-console: 0 */
		this.setState({
			managerSelected: true,
			selectedData: data
		});
	}

	closeManagerSelected = () => {
		this.setState({
			managerSelected: false,
			selectedData: null
		});
	}

	closeManagerSelectedAndRefresh = () => {
		this.closeManagerSelected();
		this.refresh();
	}

	renderManagerModel = () => {
		const { selectedData, managerSelected } = this.state;
		if(this.state.managerSelected) {
			return(
				<ManagerModel data={selectedData} open={managerSelected} onClose={this.closeManagerSelected} onCloseRefresh={this.closeManagerSelectedAndRefresh} />
			);
		}
	}

	render() {
		const { data } = this.state;
		return (
			<div className="animated fadetwoIn flex-container flex-column dnp-page">

				<AddManagerModel open={this.state.addManagerOpen} onClose={this.closeAddManager} onComplete={this.onAddSuccessful} />
				{this.renderManagerModel()}

				<h1>Managers</h1>


				<Segment attached="top">

					<Button basic onClick={this.addManager}>
						<i className="fa fa-plus fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
					
					<Button basic onClick={this.refresh}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>

				<Segment loading={this.state.loading} attached className="table-segment">
					<ManagersTable data={data} onClick={this.onManagerSelect}/>
				</Segment>
			</div>
		);
	}
}

export default ManagersPage;