import React from 'react';
import { Table } from 'semantic-ui-react';

const DetailsTable = ({ children, header=true }) => {
	return (
		<Table definition striped>
			{ header && (
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
			)}
			<Table.Body>
				{children}
			</Table.Body>
		</Table>
	);
};

export default DetailsTable;