import gql from 'graphql-tag';


const MUTATION_ADD_LOCATION = gql`

	mutation AddLocation($id: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $country: String!, $zip: String!, $name: String, $phone: String) {
		addLocation(id: $id, street1: $street1, street2: $street2, city: $city, state: $state, country: $country, zip: $zip, name: $name, phone: $phone) {
			id
		}
	}

`;

export default MUTATION_ADD_LOCATION;