import React, { useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import TextAreaInput from '../../../FinalForm/TextAreaInput';
import FSTextInput from '../../../FinalForm/TextInput';
import Checkbox from '../../../FinalForm/StyledCheckbox';
import FSFileInput from '../../../FinalForm/FileInput';
import { Form, Button, Modal } from 'semantic-ui-react';
import GraphQLError from '../../../Message/GraphQLError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_ADD_PROMOTION from '../../../../mutations/addPromotion';
import _ from 'lodash';
import { FSRichText } from '../../../FinalForm';

const AddPromotionContent = ({ id, query, onComplete }) => {
	const [addPromotion, { data, loading, error }] = useMutation(MUTATION_ADD_PROMOTION);

	useEffect(() => {
		if(_.get(data, 'addPromotion.id')) {
			onComplete && onComplete();
		}
	}, [data]);

	return (
		<FinalForm
			onSubmit={variables => {
				addPromotion({
					variables
				});
			}}
			// validate={validate}
			initialValues={{ id }}
			render={({ handleSubmit, values }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<Form.Group widths='equal'>
						<FSTextInput name='url' placeholder="Enter website link for promotion" label="URL" />
						<FSTextInput name='additionalURL' placeholder="Enter website link for promotion" label="Additional URL" />
					</Form.Group>
					<Form.Group widths='equal'>
						<Checkbox name='online' label="Online Deal" />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSTextInput name='code' placeholder="Promo code" />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSRichText name='info' placeholder="Details about the message" label="Message Information" required />
					</Form.Group>
					<Form.Group widths='equal'>
						<FSFileInput name="file" />
					</Form.Group>
					<GraphQLError error={error} />
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button 
							type="submit"
							content="Add Message"
							loading={loading}
						/>
					</div>
				</Form>
			)}
		/>
	);
};

const AddPromotion = ({ info, query, refetch }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<Button
				content={(
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<FontAwesomeIcon icon={['fas', 'plus']} />
						<div style={{ marginLeft: '5px' }}>Additional Message</div>
					</div>
				)}
				onClick={() => setOpen(true)}
			/>
			<Modal 
				open={open}
				closeIcon
				closeOnDimmerClick={false}
				onClose={() => setOpen(false)}
			>
				<Modal.Header>Add Message</Modal.Header>
				<Modal.Content>
					<AddPromotionContent 
						id={info.id}
						query={query}
						onComplete={() => {
refetch()
setOpen(false)
window.location.reload();
}}
					/>
				</Modal.Content>
			</Modal>
		</>
	);
};

export default AddPromotion;
