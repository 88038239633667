import React, { useState } from 'react';
import { Form, Popup, Button, Table, Modal } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import _ from 'lodash';
import { FSRichText } from '../FinalForm';

const FormModal = ({ open, onClose, label, property, value, onSubmit, loading }) => {

	return (
		<Modal open={open} onClose={onClose} closeIcon centered={false} closeOnDimmerClick={false}>
			<Modal.Header>Editing {label}</Modal.Header>
			<Modal.Content>
				<FinalForm
					initialValues={{ [property] : value }}
					onSubmit={onSubmit}
					render={({ handleSubmit }) => (
						<Form onSubmit={handleSubmit} loading={loading}>
							<FSRichText name={property} label={label} placeholder="Details about the promotion" required />
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button
									content="Submit Changes"
								/>
							</div>
						</Form>
					)}
				/>
			</Modal.Content>
		</Modal>
	);
};

const ChangeRichText = ({ label, value, property, refetch, id, additionalProperties, table, mutate, mutateLoading, mutateError }) => {
	const [modal, setModal] = useState(false);
	const onSubmit = (values) => {
		const value = _.get(values, property, null);
		console.log('THE VALUE IS', value);

		const newValues = _.set({ property, id }, property, value);

		mutate({
			variables: { ...newValues, ...(additionalProperties || {}) },
			refetchQueries: refetch,
			awaitRefetchQueries: true
		}).then(() => {
			if(!mutateError) {
				setModal(false);
			}
		});
	};

	let content = null;
	if(table) {
		content = (
			<Table.Row>
				<Table.Cell>{label}</Table.Cell>
				<Table.Cell>
					<div dangerouslySetInnerHTML={{__html: value}} className="ql-editor no-con" />
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button color="blue" size="mini" onClick={() => setModal(v => !v)} content="Edit" />
					</div>
				</Table.Cell>
			</Table.Row>
		);
	}

	return (
		<>
            <FormModal
            	open={modal}
            	onClose={() => setModal(false)}
            	{...{ label, property, value, onSubmit, loading: mutateLoading }}
            />
            {content}
		</>
	);
};

export default ChangeRichText;