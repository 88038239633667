import React, { useState } from 'react';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import QUERY_GET_FOLDER from './graphql/getFolder';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../FinalForm/TextInput';
import * as Yup from 'yup';
import validator from '../../utils/validator';
import MUTATION_EDIT_FOLDER from './graphql/editFolder';
import GraphQLError from '../Message/GraphQLError';
import { useHistory } from 'react-router-dom';

const EditFolderForm = ({ folder, refetch, onClose, baseURL }) => {
	const [editFolder, { data, loading, error }] = useMutation(MUTATION_EDIT_FOLDER);
	const history = useHistory();

	const onSubmit = (values) => {
		editFolder({
			variables: {
				id: folder.id,
				...values
			}
		}).then(data => {
			const slug = _.get(data, 'data.editFolder.slug');
			history.push(`${baseURL}/${slug}`);
			onClose();
		});
	};

	return (
		<FinalForm
			onSubmit={onSubmit}
			initialValues={{
				name: folder.name,
				color: folder.color,
			}}
			validate={validator(schema)}
			render={({ handleSubmit, valid }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<FSTextInput name="name" label="Folder Name" />
					<Divider horizontal>Color Requirements</Divider>
					<p>
						The color must be in HEX format ( #000000 )<br />
						<a href="https://www.google.com/search?q=hex+color" target="_blank" rel="noopener noreferrer">Hex Color Picker</a><br />
						<a href="https://flatuicolors.com/" target="_blank" rel="noopener noreferrer">Flat UI Colors</a>
					</p>
					<FSTextInput name="color" label="Folder Icon Color" placeholder="#000000" />
					<Divider />
					<GraphQLError error={error} />
					<Button
						content="Save"
						fluid
						color="blue"
						disabled={!valid || loading}
						loading={loading}
					/>
				</Form>
			)}
		/>
	);
};

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
	color: Yup.string().matches(/#[0-9A-Fa-f]{6}\b/)
});

const EditFolder = ({ slug, baseURL, tag }) => {
	const { data, loading } = useQuery(QUERY_GET_FOLDER, {
		variables: { slug, tag },
	});
	const [open, setOpen] = useState(false);
	const folder = _.get(data, 'getFolder', {});

	return (
		<>
			{ !loading && <Modal
				open={open}
				onClose={() => setOpen(false)}
				closeOnDimmerClick={false}
				closeIcon
				header={<Modal.Header>Editing Folder</Modal.Header>}
				content={
					<Modal.Content>
						<EditFolderForm
							folder={folder}
							refetch={[{ query: QUERY_GET_FOLDER, variables: { slug, tag } }]}
							onClose={() => setOpen(false)}
							baseURL={baseURL}
						/>
					</Modal.Content>
				}
			/>}
			<Button
				color="orange"
				content="Edit Folder"
				onClick={() => setOpen(v => !v)}
			/>
		</>
	);
};

export default EditFolder;