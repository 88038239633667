import React from 'react';
import { Modal } from 'semantic-ui-react';
import BusinessPlanContent from './BusinessPlanContent';

const BusinessPlanDetails = ({ id, onClose }) => {
	return (
		<Modal
			open={!!id}
			onClose={onClose}
			header={<Modal.Header>Business Plan Details</Modal.Header>}
			content={(
				<Modal.Content>
					<BusinessPlanContent id={id} />
				</Modal.Content>
			)}
			closeIcon
			closeOnDimmerClick={false}
		/>
	);
};

export default BusinessPlanDetails;