import React, { Component } from 'react';
import { Button, Modal, Transition, Icon } from 'semantic-ui-react';
import CreateAccount from '../Basic/CreateAccount';

const modalHeaderStyle = {
	//background: '#1e3c72',
	//background: '-webkit-linear-gradient(to right, #1e3c72, #2a5298)',
	background: 'linear-gradient(to right, #1e3c72, #2a5298)',
	minHeight: '80px', color: '#f2f2f2',
	textAlign: 'center',
	borderTopLeftRadius: '2px',
	borderTopRightRadius: '2px',
	borderBottom: '0'
};


class CreateAccountModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			visible: false,
		};

		this.handleOpen = this.handleOpen.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	async handleOpen() {
		await this.setState({open: true});
		this.setState({visible: true});
	}

	handleClose() {
		this.setState({visible: false});
	}

	handleVisible() {
		if(!this.state.visible) {
			this.setState({open: false});
		}
	}

	renderButton() {
		if(this.props.buttonbootstrap) {
			return(
				<button className="btn btn-outline-success my-2 my-sm-0" type="button" onClick={() => this.handleOpen()}>Create an Account</button>
			);
		}

		if(this.props.button) {
			return(
				<Button type="button" onClick={() => this.handleOpen()}>{this.props.buttonText || 'Register'}</Button>
			);
		} else if(this.props.link) {
			return(
				<h4 onClick={() => this.handleOpen()} className="accountLink">Create an Account</h4>
			);
		} else if(this.props.icon) {
			return(
				<Button type='button' onClick={() => this.handleOpen()} icon className='btn-custom-positive'>
					<Icon name='user' size='large'/>
				</Button>
			);
		}
	} 

	render() {
		return(
			<div>
				{this.renderButton()}
				<Transition animation='fly down' duration={800} visible={this.state.visible} onComplete={() => this.handleVisible()}>
					<Modal
						closeIcon
						open={this.state.open}
						onClose={this.handleClose}
						style={{borderRadius: '2px'}}
					>
						<Modal.Header style={modalHeaderStyle}><h2 style={{fontWeight: '800'}}>Create a DNP Account</h2></Modal.Header>
						<Modal.Content style={{
							backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',}}>
							<CreateAccount onContinue={() => {this.handleClose();}}/>
						</Modal.Content>
					</Modal>
				</Transition>
			</div>
		);
	}
}

export default CreateAccountModal;
