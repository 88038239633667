import gql from 'graphql-tag';

const QUERY_CHECK_MEMBERSHIP_UTIL = gql`
	query CheckMembershipUtil($membershipID: String!) {
		checkMembershipUtil(membershipID: $membershipID) {
			id
			firstname
			lastname
			membershipID
			period_end
			stripe {
				current_period_end
				status
			}
			spouseFirstName
			spouseLastName
			plan
			additionalFamilyMembers {
				name
			}
			lastInvoiceRefunded
		}
	}
`;

export default QUERY_CHECK_MEMBERSHIP_UTIL;