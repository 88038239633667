import React from 'react';
import { Modal } from 'semantic-ui-react';
import MembershipContent from './MembershipContent';

const MembershipDetails = ({ id, businessPlanID, onClose, refetch }) => {

	return (
		<Modal
			open={!!id}
			onClose={onClose}
			header={<Modal.Header>Membership Details</Modal.Header>}
			content={(
				<Modal.Content>
					<MembershipContent
						ids={{ membershipID: id, businessPlanID }}
						refetch={refetch}
						onClose={onClose}
					/>
				</Modal.Content>
			)}
			closeIcon
			closeOnDimmerClick={false}
		/>
	);
};

export default MembershipDetails;