import React, { Component } from 'react';
import { Segment, Modal, Button } from 'semantic-ui-react';
import axios from 'axios';
import FilterPopup from './FilterPopup';
import SaleTable from './SaleTable';
import Sale from './Sale';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-virtualized/styles.css';


class Sales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loading: false,
			filterPopup: false,
			saleModal: false,
			index: null,
			values: {}
		};

		this.getValues = this.getValues.bind(this);
		this.triggerFilterPopup = this.triggerFilterPopup.bind(this);
		this.onRowClick = this.onRowClick.bind(this);
		this.saleToggle = this.saleToggle.bind(this);
		this.renderModal = this.renderModal.bind(this);
	}

	componentDidMount() {
		this.forceUpdate();
		this.getValues({});
	}

	async getValues(values) {
		this.setState({loading: true, values});
		var startdate = '';
		var enddate   = '';
		var name = '';
		var email = '';
		var plan = '';
		var id = '';

		if(values.startDate) {
			startdate = `startDate: "${values.startDate.toISOString()}"`;
		}

		if(values.endDate) {
			var d = values.endDate;
			enddate = `endDate: "${d.toISOString()}"`;
		}

		if(values.name) {
			name = `name: "${values.name}"`;
		}

		if(values.email) {
			email = `email: "${values.email}"`;
		}

		if(values.plan != null) {
			plan = `plan: ${values.plan}`;
		}

		if(this.props.id) {
			id = `ByMongo(id: "${this.props.id}")`;
		}

		if(this.props.date) {
			let s = new Date(this.props.date[0]).toISOString();
			let e = new Date(this.props.date[1]).toISOString();
			startdate = `startDate: "${s}"`;
			enddate = `endDate: "${e}"`;
		}

		const response = await axios.get(`/graphql?query={
			user${id} {
				sales(filter: {${startdate} ${enddate} ${name} ${email} ${plan}}) {
					id 
					customer {
						firstname 
						lastname 
						email 
					}
					firstname 
					lastname 
					email 
					membershipID 

					total 
					date 
				}
			}
    	}`);

		if (this.props.id) {
			this.setState({data: response.data.data.userByMongo});
		} else {
			this.setState({data: response.data.data.user});
		}

		this.setState({loading: false});
	}

	triggerFilterPopup() {
		this.setState({filterPopup: !this.state.filterPopup});
	}

	async onRowClick(event, index) {
		await this.setState({index: index});
		this.saleToggle();
	}

	saleToggle() {
		this.setState({saleModal: !this.state.saleModal});
	}

	renderModal() {
		if(this.state.index != null) {
			const data = this.state.data.sales[this.state.index];

			var firstname = data.firstname || '';
			var lastname = data.lastname || '';
			return(
				<Modal open={this.state.saleModal} onClose={() => this.setState({saleModal: false, index: null})} closeIcon>
					<Modal.Header>{firstname} {lastname}</Modal.Header>
					<Modal.Content>
						<Sale id={data.id} />
					</Modal.Content>
				</Modal>
			);
		}
	}

	renderh5() {
		if(!this.props.id) {
			return(
				<h5 style={{color: '#8B8B8B'}}>List of your sales</h5>
			);
		}
	}

	render() {
		let fixedHeight = this.props.fixedHeight ? this.props.fixedHeight : false;
		let classNames = 'animated fadetwoIn';
		let styles = {};
		if(!fixedHeight) {
			classNames += ' flex-container flex-column';
		} else {
			styles.height = (fixedHeight + 50) + 'px';
		}

		return(
			<div style={{marginTop: '10px'}} className={classNames}>

				{this.renderModal()}

				<h1>Sales</h1>
				{this.renderh5()}

				<Segment attached="top">
					<FilterPopup open={this.state.filterPopup} trigger={this.triggerFilterPopup} onChange={(values) => this.getValues(values)}/>
					<Button basic onClick={() => this.getValues(this.state.values)}>
						<i className="fa fa-refresh fa-lg filterIcon" aria-hidden="true"></i>
					</Button>
				</Segment>

				<Segment loading={this.state.loading} attached className="table-segment" style={styles}>
					<SaleTable data={this.state.data} onClick={this.onRowClick} fixedHeight={fixedHeight} />
				</Segment>
			</div>
		);
	}
}

export default Sales;
