import gql from 'graphql-tag';

const queryGetWatchedTraining = gql`

	query GetWatchedTraining {
		getWatchedTraining {
			id
			training {
				trainingID
				dateStarted
				dateCompleted
			}
		}
	}

`;

export default queryGetWatchedTraining;