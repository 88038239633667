import gql from 'graphql-tag';

const modifyLocationMutation = gql`

	mutation ModifyLocation($id: String!, $locationID: String!, $name: String, $phone: String) {
		modifyLocation(id: $id, locationID: $locationID, name: $name, phone: $phone) {
			id
		}
	}

`;

export default modifyLocationMutation;