import React, { Component } from 'react';
import { Modal, Button, TextArea, Form, Accordion, Icon } from 'semantic-ui-react';
import Loading from '../../Loading';
import getQuery from './getQuery';
import axios from 'axios';
import ChangeAccount from './ChangeAccount';
import _ from 'lodash';
import ZipTable from './ZipTable';

class ManagerModel extends Component {
	state = {
		loading: false,
		data: null,
		zipField: '',
		activeIndex: -1,
		unusedZipCodes: null,
	}

	componentDidMount() {
		this.getValues();
	}

	getValues = async () => {
		this.setState({loading: true});
		const id = this.props.data.id;

		try {
			const res = await axios.get(getQuery(id, true));
			const z = await axios.get('/api/admin/managers/zip');
			this.setState({ unusedZipCodes: z.data });
			if(res.data.data.manager) {
				this.setState({
					data: res.data.data.manager
				});
			}
		} catch (error) {
			//
		}
		
		this.setState({loading: false});
	}

	removeManager = async () => {
		try {
			await axios.post('/api/admin/managers/remove', { id: this.props.data.id });
			this.props.onCloseRefresh();
		} catch (error) {
			alert('Could not remove Manager. Please try again.');
		}
	}

	handleZipFieldChange = (e) => {
		var reg = new RegExp('^[0-9,]+$');
		if(reg.test(e.target.value) || e.target.value === '') {
			this.setState({
				zipField: e.target.value
			});
		}
	}

	addZipCodes = async () => {
		const { zipField } = this.state;
		if(!zipField) {
			return;
		}

		const zips = zipField.split(',');

		const prev = this.state.data.zipCodes;

		const values = {
			id: this.state.data.id,
			values: {
				zipCodes: _.uniq([...prev, ...zips])
			}
		};

		try {
			await axios.post('/api/admin/managers/change', values);
			this.setState({
				zipField: ''
			});

			this.getValues();
		} catch (error) {
			alert('Error: Could not update zip codes');
		}

	}

	removeZipCode = async (event, index) => {

		const zipcodes = this.state.data.zipCodes;

		const removed = zipcodes.splice(index, 1);

		const values = {
			id: this.state.data.id,
			values: {
				zipCodes: _.uniq(zipcodes)
			}
		};

		try {
			axios.post('/api/admin/managers/change', values);
			this.setState({
				data: {
					...this.state.data,
					zipCodes: zipcodes
				}
			});
		} catch(error) {
			alert(`Could not remove zipcode: ${removed[0]}`);
		}


	}

	addZipCode = (zip) => {
		const { zipField } = this.state;
		let newField = zipField;
		if(zipField) {
			newField += ',';
		}
		newField += zip;
		this.setState({
			zipField: newField
		});
	}

	handleAccordianClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;
		this.setState({ activeIndex: newIndex });
	}

	renderUnused = () => {
		const { activeIndex, unusedZipCodes } = this.state;
		return(
			<Accordion style={{marginBottom: '20px'}}>
				<Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordianClick}>
					<Icon name='dropdown' /> Unused Zipcodes
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 0}>
					<p style={{fontSize: 20}}>
						{
							unusedZipCodes.map(zip => {
								return <span key={zip} className='unused-zip' onClick={() => this.addZipCode(zip)}>{zip + ', '}</span>;
							})
						}
					</p>
				</Accordion.Content>
			</Accordion>
		);
	}

	renderContent() {
		const { data, loading, zipField } = this.state;

		if(!data || loading) {
			return(
				<Loading />
			);
		}

		return(
			<div>
				<div>
					<strong>Account: </strong>
					<ChangeAccount def={`${data.accountID.firstname} ${data.accountID.lastname}`} id={data.id} onSuccess={() => this.getValues()}/>
				</div>
				<div style={{marginTop: '10px', marginBottom: '10px'}}>
					<Button className="custom-btn-negative" onClick={this.removeManager}>Remove Manager</Button>
				</div>
				<div style={{
					height: '350px',
					marginBottom: '30px',
				}}>
					<ZipTable data={this.state.data.zipCodes} onClick={this.removeZipCode}/>
				</div>
				<p>Click on Zip Code To Delete</p>
				<Form style={{
					marginBottom: '10px'
				}}>
					<TextArea placeholder="Add Zipcodes. Seperate with comma. 55792,83440,99999" value={zipField} onChange={this.handleZipFieldChange} />
				</Form>
				{this.renderUnused()}
				<Button type='button' onClick={this.addZipCodes} fluid>Add</Button>
			</div>
		);
	}

	render() {
		const { firstname, lastname } = this.props.data.accountID;

		return (
			<Modal open={this.props.open} onClose={this.props.onClose} closeIcon size='tiny'>
				<Modal.Header>Manager - {firstname} {lastname} </Modal.Header>
				<Modal.Content>
					{this.renderContent()}
				</Modal.Content>
			</Modal>
		);
	}
}

export default ManagerModel;