import gql from 'graphql-tag';

const queryGetSalesOfUser = gql`
	query GetSalesOfUser($userID: String, $skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getSalesOfUser(userID: $userID, skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			sales {
				id
				firstname
				lastname
				email
				membershipID
				total
				date
				plan
				requestedPhysicalCard
				physicalCardSent
			}
		}
	}
`;

export default queryGetSalesOfUser;