import gql from 'graphql-tag';

const MUTATION_DELETE_LOCATION = gql`

	mutation DeleteLocation($id: String!, $storeinfoID: String!) {
		deleteLocation(id: $id, storeinfoID: $storeinfoID) {
			id
		}
	}

`;

export default MUTATION_DELETE_LOCATION;