import gql from 'graphql-tag';

const MUTATION_ADD_PROMOTION = gql`

	mutation AddPromotion($id: String!, $code: String, $info: String, $url: String, $file: Upload, $additionalURL: String, $validUntil: Date, $online: Boolean) {
		addPromotion(id: $id, code: $code, info: $info, url: $url, file: $file, additionalURL: $additionalURL, validUntil: $validUntil, online: $online) {
			id
		}
	}

`;

export default MUTATION_ADD_PROMOTION;