import React from 'react';
import { Form } from 'semantic-ui-react';
import { countries, states } from '../../config/supported_areas';
import FSSelectInput from '../FinalForm/SelectInput';
import FSTextInput from '../FinalForm/TextInput';

const CheckForm = () => {
	return (
		<>
			<Form.Group widths="equal">
				<FSTextInput
					name="street1"
					label="Street"
					required
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<FSTextInput 
					name="street2" 
					label={false}
					placeholder="Address Line 2 ( APT #, Building #, Etc. )"
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<FSTextInput
					name="city"
					label="City"
					required
				/>
				<FSSelectInput
					name="state"
					label="State"
					options={states}
					required
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<FSTextInput
					name="zip"
					label="Zip"
					required
				/>
				<FSSelectInput
					name="country"
					label="Country"
					options={countries}
					required
				/>
			</Form.Group>
		</>
	);
};

export default CheckForm;


