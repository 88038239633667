import gql from 'graphql-tag';

const addWebinarCodeMutation = gql`

	mutation AddWebinarCode($code: String!, $name: String, $email: String, $identifier: String, $dateExpired: Date) {
		addWebinarCode(code: $code, name: $name, email: $email, identifier: $identifier, dateExpired: $dateExpired) {
			id
		}
	}

`;

export default addWebinarCodeMutation;