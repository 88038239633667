/* eslint no-unused-vars: 0 no-console: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Popup, Button } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import ChangeAddress from './ChangeAddress';
import withStateMutation from '../../../../queries/withStateMutation';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import modifyLocationMutation from '../../../../mutations/modifyLocation';
import GraphQLError from '../../../Message/GraphQLError';
import queryGetStore from '../../../../queries/getStore';

class ModifyLocationPopup extends Component {

	renderText = (property, value) => {
		return (
			<span className="changeInformation__value">{value || 'N/A'}</span>
		);
	}

	onSubmit = (values) => {
		values[this.props.property] = values[this.props.property] || '';
		this.props.mutate({
			variables: {
				[this.props.property]: '',
				...values,
				id: this.props.id
			},
			refetchQueries: [{
				query: queryGetStore,
				variables: { id: this.props.parentid }
			}]
		});
	}

	renderForm = (property, value) => {
		return(
			<FinalForm
				onSubmit={this.onSubmit}
				initialValues={{ [property] : value }}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextInput name={property} placeholder="New Value" label="New Value" />
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
						<Button type="submit">Submit Changes</Button>
						{/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
					</Form>
				)}
			/>
		);
	}

	renderField = (property, value) => {
		return  <Popup
			trigger={this.renderText(property, value)}
			content={this.renderForm(property, value)}
			on='click'
			position='top right'
		/>;
	}

	render() {
		const { children, property } = this.props;
		const redone = _.get(this.props, 'value', '') || '';
		return (
			<p><strong>{children}:</strong>{this.renderField(property, redone)}</p>
		);
	}
}

export default compose(
	graphql(modifyLocationMutation),
	withStateMutation()
)(ModifyLocationPopup);