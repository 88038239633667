import React from 'react';
import { Form } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../../../FinalForm/TextInput';

const MemberPopup = ({ value, onComplete }) => {

	const onSubmit = ({ value }) => {
		onComplete && onComplete(value);
	};

	return (
		<FinalForm
			onSubmit={onSubmit}
			initialValues={{ value }}
			render={({ handleSubmit }) => (
				<Form onSubmit={handleSubmit}>
					<Form.Group widths='equal'>
						<TextInput name='value' placeholder="New Value" label="New Value" action actionText={'Change'} required />
					</Form.Group>
				</Form>
			)}
		/>
	);
};

export default MemberPopup;