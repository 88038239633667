import gql from 'graphql-tag';


const modifyWebinarCodeMutation = gql`

	mutation ModifyWebinarCode($code: String!, $incentiveFulfilled: Boolean)
	{
		modifyWebinarCodeAdmin(code: $code, incentiveFulfilled: $incentiveFulfilled)
		{
			id
		}
	}

`;

export default modifyWebinarCodeMutation;