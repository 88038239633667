import React, { useEffect } from "react";
import { Modal, Button } from "semantic-ui-react";
import AddMembershipForm from "./AddMembershipForm";
import MUTATION_BUSINESS_ADD_MEMBERSHIP from "./businessAddMembership";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import styles from './AddMembership.module.scss'

const AddMembershipModal = ({ id, open, onClose, onComplete }) => {
	const [addMembership, { data, loading, error }] = useMutation(
		MUTATION_BUSINESS_ADD_MEMBERSHIP
	);

	useEffect(() => {
		if (_.get(data, "businessAddMembership", false)) {
			onComplete();
			onClose();
		}
	}, [data]);

	const onSubmit = (variables) => {
		addMembership({
			variables,
		});
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
			size='small'
		>
			<Modal.Header>Add Membership</Modal.Header>
			<p className={styles.subheader}>
        Only enter business owners and employee information in the space below.
        If you are found entering anyone other than those who work for your
        organization, you will lose all ability to engage with Deluxe
        Network Program.
			</p>
			<Modal.Content>
				<AddMembershipForm
					id={id}
					onSubmit={onSubmit}
					loading={loading}
					error={error}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button
					content='Add Membership'
					type='submit'
					form='addMembership'
					color='blue'
					loading={loading}
					disabled={loading}
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default AddMembershipModal;
