import React from 'react';
import Location from './Location';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';

const LocationsList = ({ data }) => {
	const locations = _.get(data, 'locations', []);

	if(!data.storeLocationsCount) {
		return (
			<Segment placeholder textAlign="center">
				<h4>This Store is currently a National Location</h4>
			</Segment>
		);
	}

	return locations.map((l, i) => <Location key={l.id} data={l} index={i+1} parentid={data.id} />);
};

export default LocationsList;