import gql from 'graphql-tag';

const MUTATION_CHANGE_MEMBERSHIP_ACCOUNT = gql`

	mutation ChangeMembershipAccount($membershipID: String!, $accountID: String!) {
		changeMembershipAccount(membershipID: $membershipID, accountID: $accountID)
	}

`;

export default MUTATION_CHANGE_MEMBERSHIP_ACCOUNT;