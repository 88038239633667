import gql from 'graphql-tag';

const MUTATION_DELETE_PROMOTION = gql`

	mutation DeletePromotion($id: String!, $promotionID: String!) {
		deletePromotion(id: $id, promotionID: $promotionID) {
			id
		}
	}
`;

export default MUTATION_DELETE_PROMOTION;