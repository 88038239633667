import React from 'react';
import { DetailsTable } from '../../Table';
import _ from 'lodash';
import { StoreTypeOptions } from '../../../config/storeTypes';
import { ChangeTextFieldStore, ChangeMultiSelectFieldStore } from '../../Admin/Stores/ManageStores/StoreChangeFields';

const GeneralInfo = ({ info, query }) => {
	const refetch =  [{ query }];
	const { id, businessname, url, category, contactname, contactphone, contactemail } = info;

	return (
		<div>
			<DetailsTable noHeader>

				<ChangeTextFieldStore
					label="Name"
					value={businessname}
					property='businessname'
					refetch={refetch}
					id={id}
					table
				/>

				<ChangeTextFieldStore
					label="URL"
					value={url}
					property='url'
					refetch={refetch}
					id={id}
					table
				/>

				<ChangeMultiSelectFieldStore
					label={'Profile Type'}
					value={category}
					property={'category'}
					refetch={refetch}
					id={id}
					options={StoreTypeOptions}
					table
				/>


				<ChangeTextFieldStore
					label="Contact Name"
					value={contactname}
					property='contactname'
					refetch={refetch}
					id={id}
					table
				/>

				<ChangeTextFieldStore
					label="Contact Email"
					value={contactemail}
					property='contactemail'
					refetch={refetch}
					id={id}
					table
				/>

				<ChangeTextFieldStore
					label="Contact Phone"
					value={contactphone}
					property='contactphone'
					refetch={refetch}
					id={id}
					table
				/>

			</DetailsTable>
		</div>
	);
};

export default GeneralInfo;