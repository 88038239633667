import React from 'react';
import DetailsTable from '../../../Table/DetailsTable';
import DetailsRow from '../../../Table/DetailsRow';
import moment from 'moment';
import getStripeBaseURL from '../../../../config/getStripeBaseURL';
import { Divider, Grid } from 'semantic-ui-react';
import CancelMembership from '../../../BusinessPlan/Home/CancelMembership';

const StripeInfo = ({ data, refetch }) => {
	const { stripe, lastInvoiceRefunded } = data;
	const {
		id,
		latest_invoice,
		current_period_start,
		current_period_end,
		canceled_at,
		cancel_at_period_end,
		status,
		customer
	} = stripe;

	return (
		<div>
			<DetailsTable noHeader>
				<DetailsRow
					property="Status"
					value={lastInvoiceRefunded ? 'Refunded' : status}
				/>
				<DetailsRow
					property="Subscription ID"
					value={(
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${getStripeBaseURL()}/subscriptions/${id}`}
						>
							{id}
						</a>
					)}
				/>
				<DetailsRow
					property="Latest Invoice"
					value={(
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${getStripeBaseURL()}/invoices/${latest_invoice}`}
						>
							{latest_invoice}
						</a>
					)}
				/>
				<DetailsRow
					property="Customer ID"
					value={(
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${getStripeBaseURL()}/customers/${customer}`}
						>
							{customer}
						</a>
					)}
				/>
				<DetailsRow
					property={'Current Period Start'}
					value={moment(current_period_start).format('MMMM Do YYYY, h:mm:ss a')}
					condition={!!current_period_start}
				/>
				<DetailsRow
					property={'Current Period End'}
					value={moment(current_period_end).format('MMMM Do YYYY, h:mm:ss a')}
					condition={!!current_period_end}
				/>
				<DetailsRow
					property={'Canceled?'}
					value={cancel_at_period_end ? 'Yes' : 'No'}
				/>
				<DetailsRow
					property={'Canceled At'}
					value={moment(canceled_at).format('MMMM Do YYYY, h:mm:ss a')}
					condition={!!canceled_at}
				/>
			</DetailsTable>
			<Divider horizontal>Tools</Divider>
			<Grid stackable>
				<Grid.Column width="5">
					{ !cancel_at_period_end && <CancelMembership id={data.id} refetch={refetch} /> }
				</Grid.Column>
			</Grid>
		</div>
	);
};

export default StripeInfo;