import * as Yup from 'yup';


const checkoutValidationSchema = Yup.object().shape({
	firstname: Yup.string().required('Required Field'),
	lastname: Yup.string().required('Required Field'),
	phone: Yup.string().required('Required Field'),
	email: Yup.string().required('Required Field').email('Invalid Email'),
	street1: Yup.string().required('Required Field'),
	city: Yup.string().required('Required Field'),
	zip: Yup.string().required('Required Field'),
	state: Yup.string().required('Required Field'),
	country: Yup.string().required('Required Field'),
	contractAgree: Yup.boolean().required().oneOf([true], 'Please Accept the Terms & Conditions'),

	members: Yup.array().of(Yup.object().shape({
		name: Yup.string().required('Required Field').min(1, 'Required Field'),
	})),

	// Stripe Information
	stripeName: Yup.string().when('isFree', {
		is: false,
		then: Yup.string().required('Required Field'),
	}),
	stripeCard: Yup.object().when('isFree', {
		is: false,
		then: Yup.object().required().shape({
			complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
		}),
	}),
	stripeExpiry: Yup.object().when('isFree', {
		is: false,
		then: Yup.object().required().shape({
			complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
		}),
	}),
	stripeCVC: Yup.object().when('isFree', {
		is: false,
		then: Yup.object().required().shape({
			complete: Yup.boolean().required().oneOf([true], 'Please Complete this Field'),
		}),
	}),
	stripeZip: Yup.string().when('isFree', {
		is: false,
		then: Yup.string().required('Required Field'),
	}),
});

export default checkoutValidationSchema;