import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { Grid, Form, Button, Message, Segment, Icon, Modal } from 'semantic-ui-react';
import { TextField } from '../../Form/CustomFields';
import { ReferenceField, ReferenceFieldAsyncValidate } from '../../Form/ReferenceField';
import { UserField, UserFieldAsyncValidate } from '../../Form/UserField';
import _ from 'lodash';
import { CorporatePlanField } from '../../Form/CorporatePlanField';
import InputDate from '../../Form/DateField';
import Checkbox from '../../Form/Checkbox';
import SelectField from '../../Form/SelectField';
import { countries, states } from '../../../config/supported_areas';
import ReactSelectField from '../../Form/ReactSelectField';

const InputFamilyMember = ({ remove, input }) => (
	<Form.Input icon placeholder='Name of Family Member' >
		<input {...input}/>
		<Icon name="trash" link onClick={ remove } color='red'/>
	</Form.Input>
);

const renderMembers = ({ fields, meta: { touched, error, submitFailed } }) => (
	<div>
		<h4>Additional Family Members</h4>
		<hr />
		{fields.map((member, index) =>
			<div key={index}>
				<Field
					name={`${member}.name`}
					type="text"
					component={InputFamilyMember}
					label="Name"
					remove={() => fields.remove(index)}/>
				<hr />
			</div>
		)}
		<Button type="button" onClick={() => fields.push({})}>Add Member</Button>
		{(touched || submitFailed) && error && <span>{error}</span>}
		<hr />
	</div>
);

const options = [
	{ key: '0', text: 'Individual', value: 0 },
	{ key: '1', text: 'Family', value: 1 },
	{ key: '3', text: 'Individual - Corporate', value: 3 },
	{ key: '4', text: 'Family - Corporate', value:  4 }
];


class AddSale extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			loading: false,
			finished: false,
			error: null,
			confirmAddSaleModal: false,
			values: null
		};
	}

	async sendToServer(values)
	{
		await this.setState({loading: true, error: null, finished: false});
		console.log(values);
		values.d = values.date.valueOf();
		try {
			await axios.post('/api/admin/addsale', values);
			await this.setState({loading: false, finished: true});
			this.setState({ show: true });
			this.props.reset();
		} catch(error) {
			var errorText = error.response.statusText || 'There was an error. Please try again or contact customer support.';
			this.setState({error: errorText, loading: false, finished: false});
		}

		this.onConfirmAddSaleModalClose();

		this.setState({loading: false});
	}

	renderFinished = () => {
		if(this.state.finished)
		{
			return(
				<Message positive onDismiss={() => this.setState({finished: false})}>
					<Message.Header>Sale added Successfully!</Message.Header>
				</Message>
			);
		}
	}

	renderError() {
		if(this.state.error) {
			return(
				<Message negative onDismiss={() => this.setState({error: null})}>
					<Message.Header>There was an error adding the sale.</Message.Header>
					<p>{this.state.error || ''}</p>
				</Message>
			);
		}
	}

	renderFamily() {
		let v = _.get(this.props.myform, 'values.plan', null);
		if(v === 1 || v === 4) {
			return(
				<FieldArray name="members" component={renderMembers}/>
			);
		}

	}

	onConfirmAddSaleModalClose = () => {
		this.setState({ confirmAddSaleModal: false });
	}

	renderConfirmAddSale = () => {
		const { values } = this.state;
		return(
			<Modal size="tiny" open={this.state.confirmAddSaleModal} onClose={this.onConfirmAddSaleModalClose}>
				<Modal.Header>Are you sure?</Modal.Header>
				<Modal.Content>
					<p><strong>Are you sure you want to add this sale?</strong></p>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={this.onConfirmAddSaleModalClose} className="btn-custom-deny">No</Button>
					<Button onClick={() => this.sendToServer(values)} className="btn-custom-confirm">Yes</Button>
				</Modal.Actions>
			</Modal>
		);
	}

	renderShippingAddress = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) { 
			return(
				<div>
					<h3>Shipping Address</h3>
					<Form.Group widths='equal'>
						<Field name="firstname"   component={TextField} label="First name" required/>
						<Field name="lastname"    component={TextField} label="Last name" required/>
					</Form.Group>
					<Form.Group widths='equal'>
						<Field name="email"   component={TextField} label="Email" />
						<Field name="phone"   component={TextField} label="Phone" />
					</Form.Group>
					<Field name="street1"   component={TextField} placeholder="Street 1" label="Street Address" required/>
					<Field name="street2"   component={TextField} placeholder="Street 2" />

					<Form.Group widths='equal'>
						<Field name="city"      component={TextField} label="City" required />
						<Field name="state"     component={ReactSelectField} label="State" required options={states} />
					</Form.Group>

					<Form.Group widths='equal'>
						<Field name="zip"       component={TextField} label="Zip" required />
						<Field name="country"   component={ReactSelectField} label="Country" required options={countries} />
					</Form.Group>
				</div>
			);
		}
	}

	renderCustomer = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) {
			return (
				<Field name="customer"      component={UserField} label="Customer (Email or Reference Code)" />
			);
		}
	}

	renderReference = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null && plan < 3) {
			return(
				<Field name="reference"     component={ReferenceField} label="Reference" required />
			);
		}
	}

	renderReferenceNextRow = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null && plan >= 3) {
			return(
				<Field name="reference"     component={ReferenceField} label="Reference" />
			);
		}
	}

	renderStripeFields = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) {
			return(
				<Form.Group widths='equal'>
					<Field name="stripeCustomerID"     component={TextField} label="Stripe Customer ID" />
					<Field name="stripeSubscriptionID" component={TextField} label="Stripe Subscription ID" />
				</Form.Group>
			);
		}
	}

	renderCorporatePlanID = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null && plan > 2) {
			return(
				<Field name="companyPlanID" component={CorporatePlanField} label="Corporate Plan" required />
			);
		}
	}

	renderPaidForByCompany = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null && plan > 2) {
			return(
				<Field name="paidForByCompany" component={Checkbox} label="Did the company pay for this card?" />
			);
		}
	}

	renderRenewal = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) {
			return(
				<Field name="renewal" component={Checkbox} label="Was the sale a renewal?"/>
			);
		}
	}

	renderMembershipID = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) {
			return(
				<Field name="membershipID"     component={TextField} label="Membership ID - Leave blank to auto create." />
			);
		}
	}

	renderDate = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		if(plan != null) {
			return(
				<Field name="date" placeholder="Date of Sale" component={InputDate} label="Date" />
			);
		}
	}

	renderCustomPrice = () => {
		let plan = _.get(this.props.myform, 'values.plan', null);
		let cPrice = _.get(this.props.myform, 'values.hasCustomPrice', null);
		if(plan !== null) {
			return (
				<Form.Group widths='equal'>
					<Field name="hasCustomPrice" component={Checkbox} label="Use a Custom Price"/>
					<Field name="price" disabled={!cPrice} component={TextField} label="Custom Price" />
				</Form.Group>
			);
		}
	}

	render() {
		return(
			<div style={{flex: '1'}}>
				{this.renderConfirmAddSale()}
				<Grid style={{marginTop: '20px'}}>
					<Grid.Row centered>
						<Grid.Column width={13}>
							<Segment attached='top'>
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
									<h2>Add a Sale</h2>
									<Button type='button' onClick={() => this.props.reset()} icon className='btn-custom-negative'>
										<Icon name='trash' size='large' style={{ color: 'white' }} />
									</Button>
								</div>
							</Segment>
							<Segment fluid attached>
								<Form onSubmit={this.props.handleSubmit(values => this.setState({values, confirmAddSaleModal: true}))} loading={this.state.loading}>
									<Form.Group widths='equal'>
										<Field name="plan" component={SelectField} label="Plan" options={options} />
									</Form.Group>

									{this.renderCustomPrice()}

									<Form.Group widths='equal'>
										{this.renderCustomer()}
										{this.renderReference()}
										{this.renderCorporatePlanID()}
									</Form.Group>

									{this.renderReferenceNextRow()}

									<Form.Group widths='equal'>
										{this.renderMembershipID()}
										{this.renderDate()}
									</Form.Group>
									<Form.Group widths='equal'>
										{this.renderRenewal()}
										{this.renderPaidForByCompany()}
									</Form.Group>

									{this.renderFamily()}
									{this.renderShippingAddress()}
									{this.renderFinished()}
									{this.renderError()}
									<Button className="btn-custom-primary" fluid type="submit">Add Sale</Button>
								</Form>
							</Segment>
						</Grid.Column>
					</Grid.Row>

				</Grid>

			</div>
		);
	}
}

function validate(form) {
	var errors = {};

	if(!form.date) {
		errors.date = 'Required Field';
	}

	if(!form.firstname) {
		errors.firstname = 'Required Field';
	}

	if(!form.lastname) {
		errors.lastname = 'Required Field';
	}

	if(form.plan === undefined) {
		errors.plan = 'Required Field';
	}

	if(form.plan < 3) {
		if(!form.reference) {
			errors.reference = 'Required Field';
		}
	}

	if(!form.companyPlanID) {
		errors.companyPlanID= 'Required Field';
	}

	if(!form.street1) {
		errors.street1 = 'Required Field';
	}

	if(!form.street1) {
		errors.street1 = 'Required Field';
	}

	if(!form.city) {
		errors.city= 'Required Field';
	}

	if(!form.state) {
		errors.state = 'Required Field';
	}

	if(!form.zip) {
		errors.zip = 'Required Field';
	}

	if(!form.country) {
		errors.country = 'Required Field';
	}

	if(form.hasCustomPrice) {
		if(isNaN(form.price)) {
			errors.price = 'Please enter a number. Do not use any symbols. Example: 49.90';
		}
	}

	return errors;
}

const asyncValidate = async (values, dispatch, props, field) => {
	if(field === 'customer' && values.customer) {
		return axios.post('/api/addsale/check', { type: 1, id: values.customer })
			.then((res) => {
				if(!res.data.exists) {
					const previousErrors = props.asyncErrors;
					throw Object.assign({}, previousErrors, { customer: 'Cannot find account' });
				}
			});
	}

	ReferenceFieldAsyncValidate(values, dispatch, props, field);

	UserFieldAsyncValidate(values, dispatch, props, field);

	// if(field === 'membership' && values.membership) {
	//   return axios.post('/api/addsale/check', { type: 3, id: values.membership })
	//     .then((res) => {
	//       console.log(res);
	//       if(!res.data.exists) {
	//         const previousErrors = props.asyncErrors;
	//         throw Object.assign({}, previousErrors, { membership: 'Can not find membership' })
	//       }
	//     });
	// }
};

function mapStateToProps({ form }) {
	return(
		{
			myform: form.addSaleAdminForm
		}
	);
}

export default reduxForm({
	destroyOnUnmount: true,
	validate,
	asyncValidate,
	asyncBlurFields: ['reference', 'membership', 'user'],
	form: 'addSaleAdminForm'
})(connect(mapStateToProps)(AddSale));
