import React, { Component } from 'react';
import { ChangeTextFieldSale } from './SaleChangeFields';

class CustomerInfoTab extends Component {
	render() {
		const data = this.state.data.sale;

		return(
			<div className="admin-sale-info">
				<h3>Account Information</h3>
				{/* <hr />
				<div>
					<strong>Name: </strong>
					<ChangePopup def={data.customer.firstname} p="firstname" fid={data.customer.id} onSuccess={() => this.getValues()}/>{' '}
					<ChangePopup def={data.customer.lastname} p="lastname" fid={data.customer.id} onSuccess={() => this.getValues()}/>
				</div>
				<div>
					<strong>Email: </strong>
					{data.customer.email}
				</div>
				<div>
					<strong>Zipcode: </strong>
					<ChangePopup def={data.customer.zip} p="zip" fid={data.customer.id} onSuccess={() => this.getValues()}/>
					<ChangeTextFieldSale
						label={'Membership ID'}
						value={data.membershipID}
						property={'membershipID'}
						refetch={refetch}
						id={id}
					/>
				</div>
				<div>
					<strong>Representative: </strong>{(data.customer.accountType > 0) ? 'Yes' : 'No'}
				</div> */}
			</div>
		);
	}
}

export default CustomerInfoTab;