import React, { Component } from 'react';
import _ from 'lodash';
import { Form } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import TextInput from '../../../FinalForm/TextInput';
import GraphQLError from '../../../Message/GraphQLError';

class AddMember extends Component {

	onSubmit = ({ value }) => {
		this.props.onComplete && this.props.onComplete(value);
	}

	render() {
		return (
			<FinalForm
				onSubmit={this.onSubmit}
				validate={validate}
				initialValues={{
					value: this.props.value,
				}}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<Form onSubmit={handleSubmit} loading={this.props.mutateLoading}>
						<Form.Group widths='equal'>
							<TextInput name='value' placeholder="New Value" label="New Value" action actionText={'Change'} required />
						</Form.Group>
						<GraphQLError error={this.props.mutateError}>There was an issue</GraphQLError>
					</Form>
				)}
			/>
		);
	}
}

function validate(values) {
	const errors = {};
	if(!values.value) {
		errors.value = 'Required Field';
	}
	return errors;
}

export default AddMember;