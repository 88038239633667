import React, { useState } from 'react';
import Report from './Report';

const RenewalReport = () => {
	const [month, setMonth] = useState( 0 );
	const [year, setYear] = useState(`${(new Date()).getFullYear()-1}`);

	return (
		<div className="dnp-page">
			<Report
				month={month}
				year={year}
				onChangeDate={({ month, year }) => {
					setMonth(month);
					setYear(year);
				}}
			/>
		</div>
	);
};

export default RenewalReport;