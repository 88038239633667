import React from 'react';
import { Button } from 'semantic-ui-react';
import DeleteModal from '../../../common/DeleteModal/DeleteModal';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import moment from 'moment';

const MUTATION_REFUND_ORDER = gql`
  mutation RefundOrder($id: String!) {
    refundOrder(id: $id)
  }
`;

const canRefund = (date) => {
  const add30Days = moment(date).add(45, 'day').valueOf();
  return new Date().valueOf() <= add30Days;
};

const RefundOrder = ({ order, refetch, date }) => {
  const [refundOrder, { data, loading, error }] = useMutation(
    MUTATION_REFUND_ORDER
  );
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (_.get(data, 'refundOrder') === true) {
      setOpen(false);
    }
  }, [data]);

  if ((!order.stripe.id && !order.paypal.id) || order.refunded) {
    return null;
  }

  return (
    <>
      <DeleteModal
        open={open}
        onClose={() => setOpen(false)}
        onDelete={() => {
          refundOrder({
            variables: { id: order.id },
            awaitRefetchQueries: true,
            refetchQueries: refetch,
          });
        }}
        error={error}
        loading={loading}
        text='Are you sure you want to refund this order? This action CANNOT be reversed.'
      />
      {canRefund(date) && (
        <Button
          color='orange'
          content='Refund Order'
          onClick={() => setOpen(true)}
        />
      )}
    </>
  );
};

export default RefundOrder;
