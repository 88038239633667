import React from 'react';
import SimpleSelect from './SimpleSelect';
import { Form } from 'semantic-ui-react';

const ReactSelectField = ({ options, label, input, placeholder, meta, required }) => {
	return (
		<Form.Field required={!!required}>
			{label !== false && <label>{label || placeholder}</label>}
			<div>
				<SimpleSelect
					options={options}
					value={input.value}
					onChange={value => {
						if(value) {
							input.onChange(value.value);
						} else {
							input.onChange(null);
						}
					}}
					searchable={true}
				/>
			</div>
			{meta.error && meta.touched && <span className="error">{meta.error}</span>}
		</Form.Field>
	);
};

export default ReactSelectField;