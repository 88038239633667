/* eslint react/display-name: 0 */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import SimpleSelect from '../../Form/SimpleSelect';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';

const options = [
	{ value: '0', label: 'Individual' },
	{ value: '1', label: 'Family' },
	{ value: '3', label: 'Corporate - Individual' },
	{ value: '4', label: 'Corporate - Family' },
];

const booleanOptions = [
	{ value: 'false', label: 'False' },
	{ value: 'true',  label: 'True'  },
];

const columns = ({ datePopup, triggerDatePopup, startDate, endDate, changeStartDate, changeEndDate, openSale }) => [
	{
		Header: 'Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.firstname} ${d.lastname}`,
		foldable: true,
	},
	{
		Header: 'Email',
		id: 'email',
		filterable: true,
		minWidth: 150,
		accessor: 'email',
		foldable: true,
	},
	{
		Header: 'Total',
		id: 'total',
		filterable: false,
		minWidth: 100,
		accessor: d => `${d.total.toFixed(2)}`,
		foldable: true,
	},
	{
		Header: 'Plan',
		id: 'plan',
		filterable: true,
		minWidth: 100,
		accessor: d => formatPlan(d.plan),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => {
						if(value) {
							onChange(value.value);
						} else {
							onChange();
						}
					}}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Membership #',
		id: 'membershipID',
		filterable: true,
		minWidth: 100,
		accessor: 'membershipID',
		foldable: true,
	},
	{
		Header: 'Reference',
		id: 'reference',
		filterable: false,
		minWidth: 150,
		accessor: d => `${_.get(d.reference, 'firstname', '')} ${_.get(d.reference, 'lastname', '')}`,
		foldable: true,
		sortable: false,
	},
	{
		Header: 'Requested Physical Card',
		id: 'requestedPhysicalCard',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.requestedPhysicalCard ? moment(d.requestedPhysicalCard).format('MMM Do YYYY') : ''}`,
		foldable: true,
		sortable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Sent Physical Card',
		id: 'physicalCardSent',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.physicalCardSent ? moment(d.physicalCardSent).format('MMM Do YYYY') : ''}`,
		foldable: true,
		sortable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Date',
		id: 'date',
		filterable: true,
		minWidth: 105,
		accessor: d => moment(d.date).format('ddd MMM Do YYYY'),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={triggerDatePopup} fluid>Filter Date</Button>}
					open={datePopup}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={startDate}
								selectsStart
								endDate={endDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeStartDate(value); onChange(value);}}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={endDate}
								selectsEnd
								startDate={startDate}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeEndDate(value); onChange(value);}}
							/>
						</div>
					)}
				/>
			</div> 
	}
];

export default columns;

function formatPlan(plan) {
	switch(plan) {
	case 0:
		return 'Individual';
	case 1:
		return 'Family';
	case 3:
		return 'Corporate - Individual';
	case 4:
		return 'Corporate - Family';
	default:
		return 'Unknown';
	}
}