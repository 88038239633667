import gql from 'graphql-tag';

const QUERY_GET_DISCOUNT = gql`
	query GetMembership($id: String!) {
		getMembership(id: $id) {
			id
			newCorporatePlan
			discount {
				coupon {
					id
					amount_off
					percent_off
					valid
				}
			}
		}
	}
`;

export default QUERY_GET_DISCOUNT;