import gql from 'graphql-tag';


const mutationChangeLocationAddress = gql`

	mutation ChangeLocationAddress($id: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $country: String!, $zip: String!) {
		changeLocationAddress(id: $id, street1: $street1, street2: $street2, city: $city, state: $state, country: $country, zip: $zip) {
			id
		}
	}

`;

export default mutationChangeLocationAddress;