import gql from 'graphql-tag';

const QUERY_GET_BUSINESS_PLAN_TABLE = gql`

	query GetBusinessPlanTable($skip: Int!, $limit: Int!, $sortBy: [SortBy], $filtered: [TableFilter]) {
		getBusinessPlanTable(skip: $skip, limit: $limit, sortBy: $sortBy, filtered: $filtered) {
			count
			data {
				id
				businessname
				plan
				referenceName
				accountEmail

				stripe {
					status
					created
					canceled_at
					current_period_start
					current_period_end
				}
				lastInvoiceRefunded
			}
		}
	}

`;

export default QUERY_GET_BUSINESS_PLAN_TABLE;