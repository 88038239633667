/* eslint react/display-name: 0 */
import React from 'react';
import SimpleSelect from '../Form/SimpleSelect';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';

const options = [
  { value: 0, label: 'Individual' },
  { value: 1, label: 'Family' },
  { value: 3, label: 'Corporate - Individual' },
  { value: 4, label: 'Corporate - Family' },
  { value: 5, label: 'Group Corporate' },
  { value: 6, label: 'Business Plan - Basic' },
  { value: 7, label: 'Business Plan - Premium' },
  { value: 8, label: 'Networking Plan - Basic' },
  { value: 9, label: 'Networking Plan - Premier' },
  { value: -1, label: 'Other' },
];

const booleanOptions = [
  { value: false, label: 'False' },
  { value: true, label: 'True' },
];

const statusOptions = [
  { value: 'paid', label: 'Paid' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'non-stripe', label: 'Non-Stripe' },
];

const createColumns = ({
  dates = {},
  datePopups = {},
  triggerDatePopup,
  changeDate,
}) => [
  {
    Header: 'ID',
    id: 'id',
    filterable: true,
    minWidth: 100,
    accessor: 'id',
  },
  {
    Header: 'Name',
    id: 'name',
    filterable: true,
    minWidth: 100,
    accessor: (d) =>
      `${_.get(d, 'orderInformation.firstname')} ${_.get(
        d,
        'orderInformation.lastname'
      )}`,
  },
  {
    Header: 'Email',
    id: 'email',
    filterable: true,
    minWidth: 100,
    accessor: (d) => _.get(d, 'orderInformation.email', 'N/A'),
  },
  {
    Header: 'Total',
    id: 'total',
    filterable: true,
    minWidth: 100,
    accessor: (d) => `$${_.get(d, 'total', 0).toFixed(2)}`,
  },
  {
    Header: 'Reference',
    id: 'reference',
    filterable: true,
    minWidth: 150,
    accessor: (d) => _.get(d, 'referenceName', ''),
    foldable: true,
  },
  {
    Header: 'Product',
    id: 'product',
    filterable: true,
    minWidth: 100,
    accessor: (d) => formatPlan(d.product),
    headerStyle: {
      overflow: 'visible',
    },
    Filter: ({ filter, onChange }) => (
      <div style={{ textAlign: 'left' }}>
        <SimpleSelect
          options={options}
          isClearable
          value={_.get(filter, 'value')}
          onChange={(value) => {
            value ? onChange(value.value) : onChange();
          }}
          searchable={true}
        />
      </div>
    ),
  },
  {
    Header: 'Status',
    id: 'status',
    filterable: true,
    minWidth: 150,
    accessor: (d) =>
      d.refunded
        ? 'REFUNDED'
        : `${formatStatus(_.get(d, 'paypal.status', 'NA') || 'NA')}` ||
          `${formatStatus(
            _.get(d, 'stripe.status', 'Non-Stripe') || 'Non-Stripe'
          )}`,
    foldable: true,
    headerStyle: {
      overflow: 'visible',
    },
    Filter: ({ filter, onChange }) => (
      <div style={{ textAlign: 'left' }}>
        <SimpleSelect
          options={statusOptions}
          isClearable
          value={_.get(filter, 'value')}
          onChange={(value) => {
            value ? onChange(value.value) : onChange();
          }}
          searchable={true}
        />
      </div>
    ),
  },
  {
    Header: 'Requested Physical Card',
    id: 'requestedPhysicalCard',
    filterable: true,
    minWidth: 150,
    accessor: (d) =>
      `${
        d.requestedPhysicalCard
          ? moment(d.requestedPhysicalCard).format('MMM Do YYYY')
          : ''
      }`,
    foldable: true,
    sortable: true,
    headerStyle: {
      overflow: 'visible',
    },
    Filter: ({ filter, onChange }) => (
      <div style={{ textAlign: 'left' }}>
        <SimpleSelect
          options={booleanOptions}
          isClearable
          value={_.get(filter, 'value')}
          onChange={(value) => {
            value ? onChange(value.value) : onChange();
          }}
          searchable={true}
        />
      </div>
    ),
  },
  {
    Header: 'Sent Physical Card',
    id: 'physicalCardSent',
    filterable: true,
    minWidth: 150,
    accessor: (d) =>
      `${
        d.physicalCardSent
          ? moment(d.physicalCardSent).format('MMM Do YYYY')
          : ''
      }`,
    foldable: true,
    sortable: true,
    headerStyle: {
      overflow: 'visible',
    },
    Filter: ({ filter, onChange }) => (
      <div style={{ textAlign: 'left' }}>
        <SimpleSelect
          options={booleanOptions}
          isClearable
          value={_.get(filter, 'value')}
          onChange={(value) => {
            value ? onChange(value.value) : onChange();
          }}
          searchable={true}
        />
      </div>
    ),
  },
  {
    Header: 'Date',
    id: 'date',
    filterable: true,
    minWidth: 105,
    accessor: (d) => moment(d.date).format('ddd MMM Do YYYY'),
    foldable: true,
    headerStyle: {
      overflow: 'visible',
    },
    Filter: ({ onChange }) => (
      <div style={{ textAlign: 'left' }}>
        <Popup
          trigger={
            <Button onClick={() => triggerDatePopup('date')} fluid>
              Filter Date
            </Button>
          }
          open={datePopups.date}
          content={
            <div>
              <DatePicker
                isClearable={true}
                placeholderText='Date From'
                selected={dates.fromDate}
                selectsStart
                endDate={dates.toDate}
                showMonthDropdown
                showYearDropdown
                customInput={<Input />}
                onChange={(value) => {
                  changeDate('fromDate', value);
                  onChange(value);
                }}
              />
              <DatePicker
                isClearable={true}
                placeholderText='Date To'
                selected={dates.toDate}
                selectsEnd
                startDate={dates.fromDate}
                showMonthDropdown
                showYearDropdown
                customInput={<Input />}
                onChange={(value) => {
                  changeDate('toDate', value);
                  onChange(value);
                }}
              />
            </div>
          }
        />
      </div>
    ),
  },
];

const replaceNames = {
  succeeded: 'paid',
};

function formatStatus(status) {
  status = replaceNames[status] || status;
  status = status.replace('_', ' ');
  return status.charAt(0).toUpperCase() + status.substring(1);
}

function formatPlan(plan) {
  switch (plan) {
    case 0:
      return 'Individual';
    case 1:
      return 'Family';
    case 3:
      return 'Corporate - Individual';
    case 4:
      return 'Corporate - Family';
    case 5:
      return 'Group Corporate';
    case 6:
      return 'Business Plan - Basic';
    case 7:
      return 'Business Plan - Premium';
    case 8:
      return 'Networking Plan - Basic';
    case 9:
      return 'Networking Plan - Premier';
    case -1:
      return 'Other';
    default:
      return 'Unknown';
  }
}

export default createColumns;
