import gql from 'graphql-tag';

const mutationAddPromoCode = gql`

	mutation AddPromoCode($id: String!, $duration: String, $amount_off: Float, $duration_in_months: Int, $max_redemptions: Int, $name: String, $percent_off: Float, $redeem_by: Date) {
		addPromoCode(id: $id, duration: $duration, amount_off: $amount_off, duration_in_months: $duration_in_months, max_redemptions: $max_redemptions, name: $name, percent_off: $percent_off, redeem_by: $redeem_by) {
			id
			object
			amount_off
			created
			currency
			duration
			duration_in_months
			livemode
			max_redemptions
			name
			percent_off
			redeem_by
			times_redeemed
			valid
		}
	}

`;

export default mutationAddPromoCode;