import gql from 'graphql-tag';

const queryGetTrainingOrder = gql`

	query GetTrainingOrder {
		getTrainingOrder {
			id
			key
			value
		}
	}

`;

export default queryGetTrainingOrder;