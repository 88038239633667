import React from 'react';
import { Segment } from 'semantic-ui-react';
import ReferralTable from './ReferralTable';

const ReferralsPage = () => {
	return (
		<div className="animated fadetwoIn dnp-page">
			<Segment.Group>
				<ReferralTable />
			</Segment.Group>
		</div>
	);
};

export default ReferralsPage;