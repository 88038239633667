import React, { useState, useEffect } from 'react';
import styles from './Documents.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_ROOT_FOLDERS from './graphql/getRootFolders';
import QUERY_GET_FOLDER from './graphql/getFolder';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import File from './File';
import FileModal from './FileModal';
import Loading from '../Loading/index';
import Folder from './Folder';

const BackButton = ({ slug, baseURL }) => {
	const history = useHistory();

	return (
		<div
			onClick={() => {
				const basePath = baseURL;
				const split = slug.split('/');
				const previous = slug.replace(split[split.length-1], '');
				history.push(basePath + '/' + previous);
			}}
			className={styles.backButton}
		>
			<FontAwesomeIcon
				style={{ fontSize: '20px' }}
				icon={['far', 'long-arrow-left']}
			/>
		</div>
	);
};

const DocumentList = React.forwardRef(({ slug='', ordering, reorder, admin, fileID, tag, baseURL }, ref) => {
	const { data, loading } = useQuery(slug ? QUERY_GET_FOLDER : QUERY_GET_ROOT_FOLDERS, {
		variables: slug ? { slug, tag } : { tag },
		fetchPolicy: 'cache-and-network',
	});
	const hasOrdered = React.useRef(false);
	const [localItems, setLocalItems] = useState([]);
	const history = useHistory();

	const setItems = () => {
		if(slug) {
			const files = _.get(data, 'getFolder.files', []) || [];
			const folders = _.get(data, 'getFolder.folders', []) || [];
			setLocalItems(() =>
				([
					...files.map(file => ({ ...file, type: 'file' })),
					...folders.map(folder => ({ ...folder, type: 'folder' })),
				].sort((a,b) => a.order - b.order))
			);
		} else {
			const folders = _.get(data, 'getRootFolders', []) || [];
			setLocalItems(() => folders.map(folder => ({ ...folder, type: 'folder' })));
		}
	};

	React.useImperativeHandle(ref, () => ({
		getQuery: () => [{
			query: slug ? QUERY_GET_FOLDER : QUERY_GET_ROOT_FOLDERS,
			variables: slug ? { slug, tag } : { tag }
		}],
		setOrder: () => setItems(),
	}));

	useEffect(() => {
		if(ordering) {
			hasOrdered.current = true;
		} else if (hasOrdered.current && !ordering) {
			const ids = localItems.map(obj => obj.id);
			reorder && reorder(slug, ids);
		}
	}, [ordering]);

	useEffect(() => {
		setItems();
	}, [data]);

	if(!loading && (!localItems || !localItems.length)) {
		return (
			<>
				{ slug && <BackButton {...{ slug, baseURL }} /> }
				<div className={styles.centerTextStyle}>
					<h2>There are no {slug ? 'files' : 'folders'}</h2>
					<h4>To get started, add a {slug ? 'file' : 'folder'} using the button at the top right</h4>
				</div>
			</>
		);
	}

	const onDropped = (fID, sID) => {
		const firstIndex = localItems.findIndex(obj => obj.id === fID);
		const secondIndex = localItems.findIndex(obj => obj.id === sID);
		const backup = localItems[secondIndex];
		localItems[secondIndex] = localItems[firstIndex];
		localItems[firstIndex] = backup;
		setLocalItems([...localItems]);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			{ slug && <BackButton {...{ slug, baseURL }} /> }
			<FileModal
				folderID={_.get(data, 'getFolder.id')}
				slug={slug}
				file={localItems.find(item => item.id === fileID)}
				onClose={() => {
					history.push(baseURL + '/' + slug);
				}}
				admin={admin}
				folderName={_.get(data, 'getFolder.name', '')}
				tag={tag}
			/>
			{ loading ? <div className={styles.centerTextStyle}><Loading /></div> : <div className={styles.list}>
				{localItems.map(item => {
					if(item.type === 'folder') {
						return <Folder key={item.id} {...{ folder: item, history, onDropped, ordering, baseURL }} />;
					} else {
						return (
							<File
								key={item.id}
								{...{ file: item, ordering, onDropped }}
								onSelect={() => {
									history.push(`${history.location.pathname}/${item.id}`);
								}}
							/>
						);
					}
				})}
			</div>
			}
		</div>
	);
});

export default DocumentList;