import gql from 'graphql-tag';

const MUTATION_CANCEL_SUB_CORPORATE_MEMBERSHIP = gql`

	mutation CancelSubCorporateMembership($id: String!) {
		cancelSubCorporateMembership(id: $id)
	}

`;

export default MUTATION_CANCEL_SUB_CORPORATE_MEMBERSHIP;