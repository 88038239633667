import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Form  } from 'semantic-ui-react';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const QUERY = gql`
	query SearchForFolder($search: String) {
		searchForFolder(search: $search) {
			id
			name
		}
	}
`;

const FSResourceFolder = ({ name, required, label, placeholder, containerStyle }) => {
	const client = useApolloClient();
	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field required={!!required}>
					{label !== false && <label>{label || placeholder}</label>}
					<div style={containerStyle}>
						<AsyncSelect
							cacheOptions
							value={input.value}
							loadOptions={async (value, callback) => {
								const result = await client.query({
									query: QUERY,
									variables: { search: value },
								});
								return _.get(result, 'data.searchForFolder', []).map(ref => ({
									value: ref.id,
									label: ref.name,
								}));
							}}
							onChange={value => input.onChange(value)}
							isClearable
							noOptionsMessage={() => <p>Start Typing to Search</p>}
						/>
					</div>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

export default FSResourceFolder;