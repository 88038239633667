import React from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import BasicInfo from './BasicInfo';
import { useQuery } from '@apollo/react-hooks';
import QUERY_GET_ORDER_ADMIN from './getOrder';
import Loading from '../../../Loading';
import _ from 'lodash';
import ShippingInformation from './ShippingInformation';
import MembershipInfo from './MembershipInfo';
import SaleInfo from './SaleInfo';

const OrderContentAdmin = ({ id }) => {
	const { data, loading } = useQuery(QUERY_GET_ORDER_ADMIN, {
		variables: { id },
	});
	const [activeItem, setActiveItem] = React.useState('info');

	if(loading) {
		return <Loading />;
	}

	if(!_.get(data, 'getOrder.id')) {
		return <div>Error loading data. Please try again.</div>;
	}

	const refetch = [{
		query: QUERY_GET_ORDER_ADMIN,
		variables: { id }
	}];

	const membership = !!(_.get(data, 'getOrder.membershipInformation.ids', []).length);

	return (
		<div>
			<Menu attached='top' tabular>
				<Menu.Item name='Info' active={activeItem === 'info'} onClick={() => setActiveItem('info')} />
				<Menu.Item name='Shipping' active={activeItem === 'shippinginfo'} onClick={() => setActiveItem('shippinginfo')} />
				{ membership && <Menu.Item name='Membership' active={activeItem === 'minfo'} onClick={() => setActiveItem('minfo')} /> }
				<Menu.Item name='Sale' active={activeItem === 'sale'} onClick={() => setActiveItem('sale')} />
			</Menu>

			<Segment attached='bottom'>
				{ activeItem === 'info' && <BasicInfo data={data.getOrder} refetch={refetch} /> }
				{ activeItem === 'shippinginfo' && <ShippingInformation data={data.getOrder} refetch={refetch} /> }
				{ (activeItem === 'minfo' && membership) && <MembershipInfo data={data.getOrder} refetch={refetch} /> }
				{ activeItem === 'sale' && <SaleInfo data={data.getOrder} refetch={refetch} /> }
			</Segment>
		</div>
	);
};

export default OrderContentAdmin;