import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Input, Form, Button, Icon } from 'semantic-ui-react';
import { Field } from 'react-final-form';

const FSFileInput = ({ name, remove }) => {

	const onChange = ({ target: { files: [file] } }, input) => {
		console.log(file);
		input.onChange(file);
	};

	return (
		<Field name={name}>
			{({ input, meta }) => (
				<Form.Field error={meta.error && meta.touched} style={{ marginBottom: '10px'}}>
					<Input
						name={name}
						type="file"
						onChange={stuff => onChange(stuff, input)}
						icon={(
							<i 
								aria-hidden="true"
								className="trash circular link icon"
								onClick={() => remove()}
								style={{ top: '.6rem' }}
							/>
						)}
					/>
					{meta.error && meta.touched && <span className="error">{meta.error}</span>}
				</Form.Field>
			)}
		</Field>
	);
};

const renderInputs = ({ fields, meta: { touched, error } }) => {
	return (
		<>
			{fields.map((text, index) =>
				<div key={index}>
					<FSFileInput
						name={text}
						remove={() => fields.remove(index)}
					/>
				</div>
			)}
		</>
	);
};

const FSFileInputArray = ({ name }) => {
	return (
		<FieldArray name={name} render={renderInputs} />
	);
};

export default FSFileInputArray;