import gql from 'graphql-tag';

const QUERY_SEARCH_FOR_REFERENCE = gql`

	query SearchForReference($value: String, $includeAllUsers: Boolean) {
		searchForReference(value: $value, includeAllUsers: $includeAllUsers) {
			id
			email
			firstname
			lastname
		}
	}

`;

export default QUERY_SEARCH_FOR_REFERENCE;