import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Modal } from 'semantic-ui-react';
import Loading from '../Loading';
import _ from 'lodash';
import UserInfo from '../Admin/Users/UserInfo';
import DownlineInfo from './DownlineInfo';

const QUERY_ADMIN_LEVEL = gql `
	query GetAdminLevel {
		user {
			id
			adminLevel
			accountType
		}
	}
`;

const UserInformationModal = ({ info, onClose }) => {
	const { data, loading } = useQuery(QUERY_ADMIN_LEVEL);
	console.log(info);
	return (
		<Modal
			open={!!info}
			onClose={onClose}
			closeIcon
			closeOnDimmerClick={false}
		>
			{info &&
				<>
					<Modal.Header className="tableHeaderContainer">
						<h2>Showing Information for <span style={{ textTransform: 'none'}}>{info.name}</span></h2>
					</Modal.Header>
					<Modal.Content>
						{loading ? <Loading /> : <UserModalContent currentUser={_.get(data, 'user', {})} userID={info.dataID} />}
					</Modal.Content>
				</>
			}
		</Modal>
	);
};

const UserModalContent = ({ currentUser, userID }) => {
	if(currentUser.accountType >= 10) {
		return (
			<UserInfo id={userID} />
		);
	}

	return (
		<DownlineInfo id={userID} />
	);
};

export default UserInformationModal;