import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { graphql } from '@apollo/react-hoc';
import { flowRight as compose } from 'lodash';
import mutationDeleteSale from '../../../../mutations/deleteSale';
import withStateMutation from '../../../../queries/withStateMutation';
import queryCurrentUser from '../../../../queries/user';
import _ from 'lodash';

class ToolsTab extends Component {
	state = {
		deleteSaleModal: false,
		deleteSaleLoading: false,
	}

	printReceipt = async () => {
		const data = this.props.sale;
		if(data.id) {
			const production = process.env.NODE_ENV === 'production';
			const id = data.id;
			const url = production
				? 'https://office.deluxenetworkprogram.com/api/receipt?id=' + id
				: 'http://localhost:5002/api/receipt?id=' + id;
			window.open(url, '_blank');
		}
	}

	deleteSale = async () => {
		this.props.mutate({
			variables: {
				id: this.props.sale.id,
			},
		}).then(() => {
			this.setState({ deleteSaleModal: false });
			if(this.props.mutateError) {
				const err = _.get(this.props.mutateError, 'graphQLErrors[0]message', 'Error: Could not delete sale');
				alert(`Error: ${err}`);
			} else {
				this.props.onDeleteComplete && this.props.onDeleteComplete();
			}
		});
	}

	onDeleteSaleModalClose = () => {
		this.setState({ deleteSaleModal: false });
	}

	renderDeleteSaleConfirm = () => {
		return(
			<Modal size="tiny" open={this.state.deleteSaleModal} onClose={this.onDeleteSaleModalClose}>
				<Modal.Header>Are you sure?</Modal.Header>
				<Modal.Content>
					<h3 style={{color: '#ff7675'}}>This action cannot be reversed!</h3>
					<p><strong>Are you sure you want to delete this sale?</strong> <br />
					This will not affect any data Stripe has! If the user has been charged you will need to reverse the charge on Stripe's website.</p>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={this.onDeleteSaleModalClose} className="btn-custom-deny">No</Button>
					<Button onClick={this.deleteSale} className="btn-custom-confirm">Yes</Button>
				</Modal.Actions>
			</Modal>
		);
	}

	render() {
		const adminLevel = _.get(this.props, 'data.user.adminLevel', 0);
		return (
			<div>
				{this.renderDeleteSaleConfirm()}
				<Button onClick={() => this.printReceipt()}>View/Print Invoice</Button>
				{ adminLevel >= 2 && <Button color="red" loading={this.props.mutateLoading} onClick={() => this.setState({deleteSaleModal: true})}>Delete Sale</Button> }
			</div>
		);
	}
}

export default compose(
	graphql(mutationDeleteSale),
	graphql(queryCurrentUser),
	withStateMutation(),
)(ToolsTab);