import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import { Form, Input, Button } from 'semantic-ui-react';
import QUERY_IS_PROMO_CODE_VALID from '../../../queries/isPromoCodeValid';

const FindPromoCodeContent = ({ onComplete, onError }) => {
	const [checkPromoCode, { data,  loading, error }] = useLazyQuery(QUERY_IS_PROMO_CODE_VALID, {
		fetchPolicy: 'network-only'
	});
	const [code, setCode] = useState('');

	useEffect(() => {
		if(data && data.isPromoCodeValid) {
			onComplete(data.isPromoCodeValid);
		}
	}, [data]);

	useEffect(() => {
		onError(_.get(error, 'graphQLErrors[0]message'));
		if(error) {
			onComplete(null);
		}
	}, [error]);

	return (
		<Form.Field error={!!_.get(error, 'graphQLErrors[0]message')}>
			<label htmlFor="promocode">Enter Promo Code</label>
			<Input placeholder='Enter Promo Code...' id="promocode" loading={loading} action>
				<input 
					onChange={event => setCode(event.target.value)}
					value={code}
					onKeyDown={(event) => {
						if (event.keyCode === 13) {
							if(!loading) {
								checkPromoCode({ variables: { id: code }})
							}
							event.preventDefault();
						}
					}}
					onKeyPress={e => {
						if(e.keyCode === 13) {
							e.preventDefault();
						}
					}}
				/>
				<Button 
					type="button"
					color="blue"
					onClick={() => checkPromoCode({ variables: { id: code }})}
					content="Apply Code"
					disabled={!code}
					loading={loading}
					style={{ fontSize: 14 }}
				/>
			</Input>		
			{error && <span className="error">{_.get(error, 'graphQLErrors[0]message')}</span>}
			{!!_.get(data, 'isPromoCodeValid.valid') && <span className="success">Promo Code Applied!</span>}
		</Form.Field>
	);
}

const FindPromoCode = ({ containerized, ...props }) => {
	if(containerized) {
		return (
			<div className="containerized">
				<FindPromoCodeContent
					{...props}
				/>
			</div>
		);
	} else {
		return <FindPromoCodeContent {...props} />;
	}
};

FindPromoCode.propTypes = {
	onComplete: PropTypes.func,
	onError: PropTypes.func,
	containerized: PropTypes.bool
};

FindPromoCode.defaultProps = {
	onComplete: () => {},
	onError: () => {},
	containerized: false,
};

export default FindPromoCode;