import React, {Component} from 'react';
import { AutoSizer, Table, Column} from 'react-virtualized';
import ViewInfo from './ViewInfo';
import Print from './Print';
import moment from 'moment';
import _ from 'lodash';

class ReportTable extends Component {
	render() {
		console.log(this.props.data); /* eslint no-console: 0 */
		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						width={width}
						height={height}
						headerHeight={20}
						rowHeight={40}
						rowClassName="test"
						rowCount={this.props.data? this.props.data.length : 0}
						rowGetter={({ index }) => this.props.data[index]}
					>
						<Column
							cellDataGetter={({ rowData }) => {
								const firstname = _.get(rowData, 'orderInformation.firstname', 'N/A');
								const lastname = _.get(rowData, 'orderInformation.lastname', 'N/A');

								return `${firstname} ${lastname}`;
							}}
							label='Name'
							dataKey='name'
							width={200}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => {
								return rowData.date ?
									moment(rowData.date).add(1, 'year').format('MMM Do YYYY') :
									'N/A';
							}}
							label='Renewal Date'
							dataKey='date'
							width={200}
							className="test2"
						/>

						<Column
							cellDataGetter={({ rowData }) => {
								return _.get(rowData, 'orderInformation.email', 'N/A');
							}}
							label='Email'
							dataKey='firstname'
							width={200}
							className="test2"
						/>


						<Column 
							cellDataGetter={({ rowData }) => {
								return rowData.sentRenewalEmail ?
									moment(rowData.sentRenewalEmail).format('MMM Do YYYY')
									: 'NO';
							}}
							label='Renewal Email Sent'
							dataKey='sentRenewalEmail'
							width={200}
							className="test2"
						/>

						<Column
							cellRenderer={({ rowData }) => {
								return(
									<div>
										<Print id={rowData.id}/>
										<ViewInfo id={rowData.id} />
									</div>
								);
							}}
							label='Actions'
							dataKey='firstname'
							width={300}
							className="test2"
						/>
					</Table>
				)}
			</AutoSizer>
		);
	}
}

export default ReportTable;