import React, { useState } from 'react';
import { Segment, Button, Modal, Form } from 'semantic-ui-react';
import { useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import fileDownload from 'js-file-download';
import QUERY_GENERATE_COMMISSION_REPORT_FILE from '../../../queries/generateCommissionReportFile';
import TextInput from '../../FinalForm/TextInput';

const Actions = ({ reportID }) => {
	const client = useApolloClient();
	const [loadingChecks, setLoadingChecks] = useState(false);
	const [loadingBank, setLoadingBank] = useState(false);
	const [loadingACH, setLoadingACH] = useState(false);
	const [achModal, setACHModal] = useState(false);

	const generateCheckFile = async () => {
		setLoadingChecks(true);
		const result = await client.query({
			query: QUERY_GENERATE_COMMISSION_REPORT_FILE,
			variables: {
				reportID,
				type: 0,
			},
		});

		const data = _.get(result, 'data.generateCommissionReportFile');
		fileDownload(data, `checks-${reportID}.csv`);
		setLoadingChecks(false);
	};

	const generateACHFile = async (values) => {
		setLoadingACH(true);
		const result = await client.query({
			query: QUERY_GENERATE_COMMISSION_REPORT_FILE,
			variables: {
				reportID,
				type: 1,
				depositInfo: values,
			},
		});

		const data = _.get(result, 'data.generateCommissionReportFile');
		fileDownload(data, `ach-${reportID}.txt`);
		setLoadingACH(false);
	};

	const generateBankExcelFile = async () => {
		setLoadingBank(true);
		const result = await client.query({
			query: QUERY_GENERATE_COMMISSION_REPORT_FILE,
			variables: {
				reportID,
				type: 2
			},
		});
		const data = _.get(result, 'data.generateCommissionReportFile');
		fileDownload(data, `bankinfo-${reportID}.csv`);
		setLoadingBank(false);
	};

	return (
		<Segment>
			<Modal
				open={achModal}
				onClose={() => setACHModal(false)}
				closeIcon
				closeOnDimmerClick={false}
				dimmer="blurring"
			>
				<Modal.Header className="tableHeaderContainer">
					<h2>Enter Information to Proceed</h2>
				</Modal.Header>
				<Modal.Content>
					<FinalForm
						validate={validate}
						onSubmit={generateACHFile}
						render={({ handleSubmit }) => (
							<Form onSubmit={handleSubmit}>
								<Form.Group widths='equal'>
									<TextInput
										label="Company Name"
										name="companyName"
									/>
									<TextInput
										label="Company EIN ( No dashes )"
										name="companyEIN"
									/>
								</Form.Group>
								<Form.Group widths='equal'>
									<TextInput
										label="Bank Name"
										name="bankName"
									/>
									<TextInput
										label="Bank Routing Number"
										name="bankNumber"
									/>
								</Form.Group>
								<Button 
									type='submit'
									content="Download ACH File"
									loading={loadingACH}
									color='green'
									fluid
								/>
							</Form>
						)}
					/>
				</Modal.Content>
			</Modal>
			<Button 
				color="blue"
				onClick={() => generateCheckFile()}
				content={'Download Excel File for Checks'}
				loading={loadingChecks}
			/>
			<Button 
				content="Download ACH File for Direct Deposit"
				color="green"
				onClick={() => setACHModal(true)}
			/>
			<Button 
				content="Download Excel for Direct Deposit"
				color="purple"
				onClick={() => generateBankExcelFile()}
			/>
		</Segment>
	);
};

function validate(values) {
	const errors = {};
	if(!values.companyName) {
		errors.companyName = 'Required Field';
	}
	if(!values.bankName) {
		errors.bankName = 'Required Field';
	}
	if(!values.companyEIN) {
		errors.companyEIN = 'Required Field';
	} else if(isNaN(values.companyEIN)) {
		errors.companyEIN = 'Invalid Number';
	}
	if(!values.bankNumber) {
		errors.bankNumber = 'Required Field';
	} else if(isNaN(values.bankNumber)) {
		errors.bankNumber = 'Invalid Number';
	}
	return errors;
}

export default Actions;