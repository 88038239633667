import React from 'react';
import moment from 'moment-timezone';
import { Table, Button, Popup, Segment } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CHANGE_SEMINAR_SIGNUP from '../../../../mutations/changeSeminarSignup';
import MUTATION_DELETE_SEMINAR_SIGNUP from '../../../../mutations/deleteSeminarSignup';

const DatePopup = ({ text, date, onChange }) => {
	const [currentDate, setCurrentDate] = React.useState(date);

	return (
		<Popup
			trigger={<span className="changeInformation__value">{text}</span>}
			on='click'
			onOpen={() => setCurrentDate(date)}
			content={() => (
				<div>
					<ReactDatePicker
						onChange={date => setCurrentDate(date)}
						selected={currentDate}
						inline
						timeInputLabel="Time:"
						showTimeInput
					/>
					<Button 
						fluid
						color='blue'
						disabled={currentDate === date}
						onClick={() => onChange(currentDate)}
						content="Change"
					/>
				</div>
			)}
			basic
		/>
	);
};

const SeminarModalContent = ({ id, registeredFor, completed, onClose=()=>{}, onRefresh=()=>{} }) => {
	const [loading3, setLoading3] = React.useState(false);
	const [changeSeminarSignup, { loading }] = useMutation(MUTATION_CHANGE_SEMINAR_SIGNUP);
	const [deleteSeminarSignup, { loading: loading2 }] = useMutation(MUTATION_DELETE_SEMINAR_SIGNUP);

	const onChangeDate = async (date) => {
		setLoading3(true);
		await changeSeminarSignup({
			variables: {
				id,
				property: 'registeredFor',
				value: JSON.stringify(date),
			}
		});
		await onRefresh();
		setLoading3(false);
	};

	const onChangeCompleted = async () => {
		setLoading3(true);
		await changeSeminarSignup({
			variables: {
				id,
				property: 'completed',
				value: !completed,
			}
		});
		await onRefresh();
		setLoading3(false);
	};


	return (
		<Segment loading={loading || loading2 || loading3}>
			<p>Click on a value to change it</p>
			<Table definition celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row>
						<Table.Cell>Completed</Table.Cell>
						<Table.Cell>
							<span className="changeInformation__value" onClick={onChangeCompleted}>
								{completed ? 'Yes' : 'No'}
							</span>
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell>Zoom Seminar Date</Table.Cell>
						<Table.Cell>
							<DatePopup 
								text={registeredFor ? moment(registeredFor).tz('America/Boise').format('MMMM Do YYYY, h:mm a z') : 'Not Assigned'}
								date={registeredFor ? new Date(registeredFor) : moment().toDate()}
								onChange={date => onChangeDate(date)}
							/>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
			<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
				<Button 
					color='red'
					floated="right"
					icon="trash"
					content="Delete"
					onClick={async () => {
						await deleteSeminarSignup({ variables: { id }});
						onRefresh();
						onClose();
					}}
				/>
			</div>
		</Segment>
	);
};

export default SeminarModalContent;