import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import { ReferenceField } from '../../Form/ReferenceField';
import Error from '../../Message/Error';
import axios from 'axios';

class AddManagerModel extends Component {
	state = {
		error: null,
		loading: false,
	}

	onClose = () => {
		this.props.onClose();
	}

	handleSubmit = async (values) => {
		this.setState({ loading: true, error: null });
		try {
			await axios.post('/api/admin/managers/add', values);
			this.props.onComplete();
		} catch (error) {
			this.setState({
				error: error.response.data.message
			});
		}
		this.setState({loading: false});
	}

	render() {
		const { loading, error } = this.state;
		return (
			<Modal open={this.props.open} onClose={this.onClose} closeIcon>
				<Modal.Header>Add Manager</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Error error={error}>Manager was not added!</Error>
						<Form onSubmit={this.props.handleSubmit(values => this.handleSubmit(values))}>
							<Field name="id" component={ReferenceField} label="Representative Account" />
							<Button type='submit' loading={loading}>Add</Button>
						</Form>
					</Modal.Description>
				</Modal.Content>
			</Modal>
		);
	}
}

export default reduxForm({
	form: 'addManager'
})(AddManagerModel);
