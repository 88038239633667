import React, {Component} from 'react';
import { AutoSizer, Table, Column} from 'react-virtualized';

class ZipTable extends Component {
	render() {
		return (
			<AutoSizer>
				{({ height, width }) => (
					<Table
						width={width}
						height={height}
						headerHeight={20}
						rowHeight={40}
						onRowClick={({event, index, rowData}) => this.props.onClick(event, index, rowData)}
						rowClassName="test"
						rowCount={ this.props.data ? this.props.data.length : 0 }
						rowGetter={({ index }) => this.props.data[index]}
					>
						<Column
							cellDataGetter={({ rowData }) => {
								return rowData;
							}}
							label='Zip Code'
							width={200}
							className="test2"
						/>

					</Table>
				)}
			</AutoSizer>
		);
	}
}

export default ZipTable;
