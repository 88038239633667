import React from 'react';
import { Segment, Header, Grid, Button } from 'semantic-ui-react';
import { DetailsTable, DetailsRow } from '../../Table';
import moment from 'moment';
import CancelMembership from './CancelMembership';
import { QUERY_BUSINESS_PLAN_USER } from './HomeA';

const SubscriptionInfo = ({ plan }) => {
  const paypal = plan.paypal || {};

  const { status, start_time } = paypal;

  return (
    <Segment.Group>
      <Segment secondary color='green'>
        <Header as='h3' textAlign='center'>
          Subscription Info
        </Header>
      </Segment>
      <Segment>
        <DetailsTable noHeader>
          <DetailsRow property='Status' value={status} />
          <DetailsRow
            property='Start Date'
            value={moment(start_time).format('MMMM Do YYYY')}
          />
        </DetailsTable>
      </Segment>
      <Segment>
        <Grid stackable>
          {/* <Grid.Column width="7">
						<Button
							fluid
							content="View Last Receipt"
							color="blue"
							as="a"
							href={`/api/receipt?id=${latestOrderNumber}`}
							target="_blank"
						/>
					</Grid.Column> */}
          <Grid.Column width='5' />
          <Grid.Column width='7'>
            {status === 'ACTIVE' && (
              <CancelMembership
                id={plan.id}
                refetch={[{ query: QUERY_BUSINESS_PLAN_USER }]}
              />
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};

export default SubscriptionInfo;
