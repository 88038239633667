import React from 'react';
import { Modal, Container, Header, Divider } from 'semantic-ui-react';
import Loading from '../../../Loading';
import ShippingAddress from '../../../Format/ShippingAddress';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import DetailsTable from '../../../Table/DetailsTable';
import DetailsRow from '../../../Table/DetailsRow';
import moment from 'moment';

const QUERY_GET_ORDER = gql`
	query GetOrder($id: String!) {
		getOrder(id: $id) {
			id
			date
			orderInformation {
				firstname
				lastname
				email
				phone
				shippingAddress {
					name    
					street1 
					street2 
					city    
					state   
					country 
					zip
				}
			}
			membershipInformation {
				ids
			}
		}
	}
`;

const ViewInfoContent = ({ data }) => {
	const {
		id,
		orderInformation: {
			firstname,
			lastname,
			phone,
			email,
			shippingAddress,
		},
		membershipInformation: {
			ids,
		},
		date,
	} = data;
	return (
		<Container>
			<Header as="h2" textAlign="center" style={{ marginTop: '10px' }}>
				{firstname} {lastname}
			</Header>
			<Divider horizontal>Info</Divider>
			<DetailsTable noHeader>
				<DetailsRow
					property="Email"
					value={email || 'N/A'}
				/>
				<DetailsRow
					property="Phone"
					value={phone || 'N/A'}
				/>
				<DetailsRow
					property="Date of Sale"
					value={moment(date).format('MMMM Do YYYY, h:mm:ss a')}
				/>
				<DetailsRow
					property="Membership ID #'s"
					value={(ids || []).filter((prev, curr, index)  => {
						return prev + (index ? ', ' : '') + curr;
					})}
				/>
				<DetailsRow
					property="Sale ID"
					value={id}
				/>
			</DetailsTable>
			
			<Divider horizontal>Shipping Address</Divider>
			<Header 
				as="h3"
				textAlign="center"
				style={{
					marginTop: '10px',
					marginBottom: '10px'
				}}
			>
				<ShippingAddress 
					address={shippingAddress}
					firstname={firstname}
					lastname={lastname}
				/>
			</Header>
		</Container>
	);
};

const ViewInfoModal = ({ id, open, onClose  }) => {
	const { data, loading } = useQuery(QUERY_GET_ORDER, {
		variables: { id },
	});

	const info = _.get(data, 'getOrder');

	return (
		<Modal 
			open={open}
			onClose={onClose}
			closeIcon
			header={<Modal.Header>Viewing Renewal Information</Modal.Header>}
			content={loading ? <Loading /> : (
				<ViewInfoContent
					data={info}
				/>
			)}
		/>
	);
};

export default ViewInfoModal;