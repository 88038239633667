import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const GraphQLError = ({ error: pError, children }) => {
	if (!pError) { return null; }
	let error = _.get(pError, 'graphQLErrors[0]message', 'Please contact customer support');

	return (
		<Message negative>
			<Message.Header>{children || 'An Error Occurred'}</Message.Header>
			<Message.Content>{error}</Message.Content>
		</Message>
	);
};

GraphQLError.propTypes = {
	error: PropTypes.any,
	child: PropTypes.node,
};

export default GraphQLError;