import gql from 'graphql-tag';

const queryFindMemberships = gql`

	query FindMemberships($value: String) {
		findMemberships(value: $value) {
			id
			membershipID
			firstname
			lastname
		}
	}

`;

export default queryFindMemberships;