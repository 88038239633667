import React from 'react';
import AddPromotion from './AddPromotion';
import Promotion from './Promotion';

const PromotionList = ({ data }) => {

	const renderPromotions = () => {
		return data.promotions.map((promotion, index) => {
			return <Promotion key={promotion.id} data={promotion} index={index+1} parentid={data.id} />;
		});
	};

	return (
		<div>
			{renderPromotions()}
			<span style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<AddPromotion id={data.id} />
			</span>
		</div>
	);
};

export default PromotionList;