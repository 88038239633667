/* eslint react/display-name: 0 */
import React from 'react';
import SimpleSelect from '../../../Form/SimpleSelect';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, Popup, Input } from 'semantic-ui-react';


const options = [
	{ value: 0, label: 'Individual' },
	{ value: 1, label: 'Family' },
	{ value: 3, label: 'Corporate - Individual' },
	{ value: 4, label: 'Corporate - Family' },
	{ value: 5, label: 'Group Corporate' },
];

const booleanOptions = [
	{ value: false, label: 'False' },
	{ value: true,  label: 'True'  },
];

const statusOptions = [
	{ value: 'active', label: 'Active' },
	{ value: 'past_due',  label: 'Past due'  },
	{ value: 'canceled',  label: 'Canceled'  },
	{ value: 'unpaid',  label: 'Unpaid'  },
	{ value: 'refunded',  label: 'Refunded'  },
];

const createColumns = ({ dates={}, datePopups={}, triggerDatePopup, changeDate }) => [
	{
		Header: 'Membership ID',
		id: 'membershipID',
		filterable: true,
		minWidth: 100,
		accessor: 'membershipID',
		foldable: true,
	},
	{
		Header: 'Customer Name',
		id: 'name',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.firstname} ${d.lastname}`,
		foldable: true
	},
	{
		Header: 'Account Email',
		id: 'accountEmail',
		filterable: true,
		minWidth: 150,
		accessor: d => _.get(d, 'accountEmail'),
		foldable: true,
		Cell: props => {
			return (
				<span style={props.value ? {} : {color: 'red'}}>
					{props.value || 'No Connected Account'}
				</span>
			);
		}
	},
	{
		Header: 'Stripe Membership?',
		id: 'stripeMembership',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.stripeMembership}`,
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Plan',
		id: 'plan',
		filterable: true,
		minWidth: 150,
		accessor: d => formatPlan(d.plan),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Latest Sale',
		id: 'period_start',
		filterable: true,
		minWidth: 150,
		accessor: d => `${moment(d.period_start).format('MMMM Do YYYY, h:mm:ss a')}`,
		foldable: true,
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('periodStart')} fluid>Filter Date</Button>}
					open={datePopups.periodStart}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.fromPeriodStart}
								selectsStart
								endDate={dates.toPeriodStart}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('fromPeriodStart', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.toPeriodStart}
								selectsEnd
								startDate={dates.fromPeriodStart}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('toPeriodStart', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div> 
	},
	{
		Header: 'Period Ends At',
		id: 'period_end',
		filterable: true,
		minWidth: 150,
		accessor: d => `${moment(d.period_end).format('MMMM Do YYYY, h:mm:ss a')}`,
		foldable: true,
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('periodEnd')} fluid>Filter Date</Button>}
					open={datePopups.periodEnd}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.fromPeriodEnd}
								selectsStart
								endDate={dates.toPeriodEnd}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('fromPeriodEnd', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.toPeriodEnd}
								selectsEnd
								startDate={dates.fromPeriodEnd}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('toPeriodEnd', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div> 
	},
	{
		Header: 'Status',
		id: 'status',
		filterable: true,
		minWidth: 150,
		accessor: d => d.lastInvoiceRefunded ? 'REFUNDED' : `${formatStatus( _.get(d, 'stripe.status', 'Non-Stripe') || 'Non-Stripe' )}`,
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={statusOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Canceled',
		id: 'canceled',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.canceled}`,
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) => 
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={booleanOptions}
					isClearable
					value={_.get(filter, 'value')}
					onChange={value => { value ? onChange(value.value) : onChange(); }}
					searchable={true}
				/>
			</div>
	},
	// {
	// 	Header: 'Ended At',
	// 	id: 'ended_at',
	// 	filterable: true,
	// 	minWidth: 150,
	// 	accessor: d => `${d.ended_at ? moment(d.ended_at).format('MMMM Do YYYY, h:mm:ss a') : ''}`,
	// 	foldable: true,
	// 	Filter: ({ onChange }) => renderDateFilter({ onChange, state, triggerDatePopup, changeDate, property: 'ended_at'})
	// },
	{
		Header: 'Created',
		id: 'createdAt',
		filterable: true,
		minWidth: 150,
		accessor: d => `${moment(d.createdAt).fromNow()}`,
		foldable: true,
		Filter: ({ onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<Popup
					trigger={<Button onClick={() => triggerDatePopup('created')} fluid>Filter Date</Button>}
					open={datePopups.created}
					content={(
						<div>
							<DatePicker
								isClearable={true}
								placeholderText="Date From"
								selected={dates.fromCreated}
								selectsStart
								endDate={dates.toCreated}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('fromCreated', value); onChange(value); }}
							/>
							<DatePicker
								isClearable={true}
								placeholderText="Date To"
								selected={dates.toCreated}
								selectsEnd
								startDate={dates.fromCreated}
								showMonthDropdown
								showYearDropdown
								customInput={<Input/>}
								onChange={value => { changeDate('toCreated', value); onChange(value); }}
							/>
						</div>
					)}
				/>
			</div> 
	}
];

function formatPlan(plan) {
	switch(plan) {
	case 0:
		return 'Individual';
	case 1:
		return 'Family';
	case 3:
		return 'Corporate - Individual';
	case 4:
		return 'Corporate - Family';
	case 5:
		return 'Group Corporate';
	default:
		return 'Unknown';
	}
}

function formatStatus(status) {
	status = status.replace('_', ' ');
	return status.charAt(0).toUpperCase() + status.substring(1);
}

export default createColumns;