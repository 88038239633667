import React from 'react';
import _ from 'lodash';
import { ChangeTextFieldMembership, ChangeSelectFieldMembership } from './MembershipChangeFields';
import { Table, Divider } from 'semantic-ui-react';
import { states, countries } from '../../../../../config/supported_areas';
import QUERY_GET_MEMBERSHIP from '../../../../../queries/getMembership';
import AdditionalFamilyMembers from './AdditionalFamilyMembers';

const Edit = ({ membership }) => {
	const {
		id,
		firstname,
		lastname,
		shippingAddress,
		email,
		phone,
		spouseFirstName,
		spouseLastName,
		additionalFamilyMembers,
		plan,
	} = membership;

	const refetch = [{
		query: QUERY_GET_MEMBERSHIP,
		variables: { id: membership.id }
	}];

	return (
		<div>
			<h3>Edit Membership</h3>
			<p>Changes will take place on renewal</p>

			<Table definition striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell />
						<Table.HeaderCell>Value</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<ChangeTextFieldMembership
						label={'First name'}
						value={firstname}
						property={'firstname'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Last name'}
						value={lastname}
						property={'lastname'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Email'}
						value={email}
						property={'email'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Phone #'}
						value={phone}
						property={'phone'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Street 1'}
						value={_.get(shippingAddress, 'street1')}
						property={'shippingAddress.street1'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Street 2'}
						value={_.get(shippingAddress, 'street2')}
						property={'shippingAddress.street2'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'City'}
						value={_.get(shippingAddress, 'city')}
						property={'shippingAddress.city'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeSelectFieldMembership
						options={states}
						label={'State'}
						value={_.get(shippingAddress, 'state')}
						property={'shippingAddress.state'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeTextFieldMembership
						label={'Zip'}
						value={_.get(shippingAddress, 'zip')}
						property={'shippingAddress.zip'}
						refetch={refetch}
						id={id}
						table
					/>

					<ChangeSelectFieldMembership
						options={countries}
						label={'Country'}
						value={_.get(shippingAddress, 'country')}
						property={'shippingAddress.country'}
						refetch={refetch}
						id={id}
						table
					/>

					{
						(plan === 1 || plan === 4) && (
							<>
								<ChangeTextFieldMembership
									label={'Spouse First Name'}
									value={spouseFirstName}
									property={'spouseFirstName'}
									refetch={refetch}
									id={id}
									table
								/>
								<ChangeTextFieldMembership
									label={'Spouse Last Name'}
									value={spouseLastName}
									property={'spouseLastName'}
									refetch={refetch}
									id={id}
									table
								/>
							</>
						)
					}
				</Table.Body>
			</Table>
			{
				(plan === 1 || plan === 4) && (
					<div>
						<Divider horizontal>Additional Family Members</Divider>
						<AdditionalFamilyMembers
							data={additionalFamilyMembers}
							id={membership.id}
						/>
					</div>
				)
			}
		</div>
	);
};

export default Edit;