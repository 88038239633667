import flatten from 'flat';

const validator = (schema) => async formValues => {
	try {
		await schema.validate(formValues, { abortEarly: false });
		return {};
	} catch (errors) {
		const l = errors.inner.reduce(
			(errors, err) => ({
				...errors,
				[err.path.replace('[', '.').replace(']', '')]: err.message
			}),
			{}
		);
		return flatten.unflatten(l);
	}
};

export default validator;