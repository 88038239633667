import React, { Component } from 'react';
import TrainingList from './TrainingList';
import SelectedTraining from './SelectedTraining';

class Training extends Component {
	state = {
		selected: null,
	}

	componentDidMount() {
		document.querySelector('#myapp').classList.add('maxheight100');
	}

	componentWillUnmount() {
		document.querySelector('#myapp').classList.remove('maxheight100');
	}

	onSelection = (id) => {
		this.setState({ selected: id });
	}

	render() {
		const { selected } = this.state;
		
		return (
			<div className='training__container'>
				<TrainingList selected={selected} onSelection={this.onSelection}/>
				{ selected && <SelectedTraining id={selected} /> }
			</div>
		);
	}
}

export default Training;