import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import ChangeSourceForm from './ChangeSourceForm';

const ChangeSource = ({ id, refetch }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				closeOnDimmerClick={false}
				closeIcon
				header={<Modal.Header>Change Payment Information</Modal.Header>}
				content={(
					<Modal.Content>
						<ChangeSourceForm 
							onClose={() => setOpen(false)}
							id={id}
							refetch={refetch}
						/>
					</Modal.Content>
				)}
			/>
			<Button
				content="Change Payment"
				color="purple"
				onClick={() => setOpen(true)}
			/>
		</>
	);
};

export default ChangeSource;