import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Form as SemanticForm, Segment, Header } from 'semantic-ui-react';
import MembershipResult from './MembershipResult';
import SimpleSelect from '../Form/SimpleSelect';
import _ from 'lodash';
import queryFindMemberships from '../../queries/findMemberships';
import { graphql } from '@apollo/react-hoc';

const CheckMembershipPage = ({ data }) => {
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState(null);

	const t = React.useRef(_.throttle((value) => {
		data.refetch({ value }).then(result => {
			const { findMemberships } = result.data;
			if(findMemberships) {
				const newOptions = findMemberships.map(ob => {
					return { value: ob.membershipID, label: `${ob.firstname} ${ob.lastname} - ${ob.membershipID}` };
				});
				setOptions(newOptions);
			} else {
				setOptions(options);
			}
		});
	}, 1000)).current;

	const onInputChange = (value) => { console.log(value); t(value); };

	const onChange = (input) => {
		setValue(_.get(input, 'value'));
	};

	return (
		<div style={{ flex: 1, flexDirection: 'column', height: '100%', backgroundColor: '#f2f2f2' }}>
			<Segment inverted className="checkmembership__jumbotron">
				<Header
					as="h1"
					style={{ fontSize: '40px', textTransform: 'uppercase', textAlign: 'center', margin: 0 }}
					content="Check Membership"
				/>
			</Segment>
			<div className="ui container" style={{ marginTop: 30 }}>
				<Header
					as="h2"
					content="Search by membership # or account holder name"
				/>
				<Form
					onSubmit={() => {}}
					validate={validate}>
					{({ handleSubmit  }) => (
						<SemanticForm loading={false} onSubmit={handleSubmit}>
							<SemanticForm.Group widths='equal' style={{ margin: '0' }}>
								<SimpleSelect
									isClearable
									onChange={onChange}
									onInputChange={onInputChange}
									value={value}
									options={options}
									styles={{
										container: () => { return { flex: '1' }; }
									}}
								/>
							</SemanticForm.Group>
						</SemanticForm>
					)}
				</Form>

				<div className="checkmembership__results">
					{ value && <MembershipResult membershipID={value} /> }
				</div>
			</div>
		</div>
	);
};


function validate(values) {
	const errors = {};

	if(!values.membershipID) {
		errors.membershipID = 'Required Field';
	}

	return errors;
}

export default graphql(queryFindMemberships, {
	options: () => { return { variables: { value: '' }}; } 
})(CheckMembershipPage);