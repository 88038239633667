import React, { useEffect, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Form, Modal, Header, Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';
import TextInput from '../../FinalForm/TextInput';
import validator from '../../../utils/validator';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_CREATE_ACCOUNT_WITHOUT_PASSWORD from '../../../mutations/createAccountWithoutPassword';
import GraphQLError from '../../Message/GraphQLError';

let resetForm = () => console.log('Reset Form is not Set');

const CreateUser = () => {
	const [createUser, { data, loading, error }] = useMutation(MUTATION_CREATE_ACCOUNT_WITHOUT_PASSWORD);

	console.log(resetForm);

	useEffect(() => {
		if(_.get(data, 'createAccountWithoutPassword', false)) {
			alert('Account created! User should receive an email with password and a link to change their password.');
			resetForm();
		}
	}, [data]);

	return (
		<div>
			<FinalForm
				onSubmit={variables => createUser({ variables })}
				validate={validator(schema)}
				render={({ handleSubmit, form: { reset }  }) => {
					resetForm = reset;
					return (
						<Form onSubmit={handleSubmit} loading={loading}>
							<GraphQLError error={error}>An Error Occurred</GraphQLError>
							<Form.Group widths="equal">
								<TextInput
									name="firstname"
									label="First name"
									placeholder="First name"
								/>
								<TextInput
									name="lastname"
									label="Last name"
									placeholder="Last name"
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<TextInput
									name="zip"
									label="Zip Code"
									placeholder="Zip Code"
								/>
								<TextInput
									name="email"
									label="E-mail"
									placeholder="E-mail"
								/>
							</Form.Group>
							<Button color="green" fluid type="submit">Submit</Button>
						</Form>
					);
				}}
			/>
		</div>
	);
};

const schema = Yup.object().shape({
	firstname: Yup.string().required('Required Field'),
	lastname: Yup.string().required('Required Field'),
	zip: Yup.string().required('Required Field').matches(/^(?!00000)(?<zip>(?<zip5>\d{5})(?:[ -](?=\d))?(?<zip4>\d{4})?)$/, 'Invalid Zip Code'),
	email: Yup.string().required('Required Field').email('Invalid E-mail'),
});

export const CreateUserModal = (props) => {
	return (
		<Modal centered={false} size='small' closeIcon closeOnDimmerClick={false} {...props}>
			<Header icon='user' content='Create an Account' />
			<Modal.Content>
				<CreateUser />
			</Modal.Content>
			<Modal.Actions>
				<p style={{ textAlign: 'center'}}>The user will receive an email with their password as well as a link to reset it.</p>
			</Modal.Actions>
		</Modal>
	);
};

export default CreateUser;