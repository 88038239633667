import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import classNames from 'classnames';
import {mapToCssModules} from 'reactstrap/lib/utils';

const ranks = {
	0: 'Representative',
	1: 'Basic',
	2: 'Silver',
	3: 'Gold',
	4: 'Platinum'
};

const RepRankWidget = ({className, cssModule, color, value, variant, info, ...attributes}) => {
	const progress = {style: '', color: color, value: value};
	const card = {style: '', bgColor: ''};

	if (variant === 'inverse') {
		progress.style = 'progress-white';
		progress.color = '';
		card.style = 'text-white';
		card.bgColor = 'bg-' + color;
	}

	const classes = mapToCssModules(classNames(className, card.style, card.bgColor, 'my-card'), cssModule);
	progress.style = classNames('progress-xs my-3', progress.style);

	const { name, percentage } = info;

	return (
		<Card className={ classes } {...attributes}>
			<CardBody>
				<div className="h4 m-0">Representative Rank</div>
				{/* <div>{ranks[this.props.childcount]}</div> */}
				<div>{name}</div>
				<div style={{ fontSize: 14 }}><em>Earning <strong>{percentage * 100}%</strong> Downline Commission</em></div>
			</CardBody>
		</Card>
	);
};

RepRankWidget.propTypes = {
	header: PropTypes.string,
	color: PropTypes.string,
	value: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	cssModule: PropTypes.object,
	variant: PropTypes.string,
	info: PropTypes.object,
};

RepRankWidget.defaultProps = {
	header: '89.9%',
	value: '25',
	variant: ''
};

export default RepRankWidget;
