import React from 'react';
import { Modal, Button, Form, Divider } from 'semantic-ui-react';
import { Form as FinalForm } from 'react-final-form';
import FSTextInput from '../FinalForm/TextInput';
import FSTextAreaInput from '../FinalForm/TextAreaInput';
import FSResourceFolder from '../FinalForm/FSResourceFolder';
import _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import MUTATION_EDIT_FILE from './graphql/editFile';
import GraphQLError from '../Message/GraphQLError';
import QUERY_GET_FOLDER from './graphql/getFolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import validator from '../../utils/validator';

const EditFileContent = ({ file, folderID, folderName, onClose, tag }) => {
	const [editFile, { data, loading, error }] = useMutation(MUTATION_EDIT_FILE);

	const onSubmit = (values) => {
		let variables = { ...values, parent: values.parent.value};

		editFile({
			variables,
			awaitRefetchQueries: true,
			refetchQueries: [{ query: QUERY_GET_FOLDER, variables: { id: folderID, tag }}],
		}).then((data) => onClose(_.get(data, 'data.editFile', false)));

	};

	return (
		<FinalForm
			initialValues={{
				id: file.id,
				name: file.name,
				description: file.description,
				parent: {
					value: folderID,
					label: folderName,
				},
			}}
			validate={validator(schema)}
			onSubmit={onSubmit}
			render={({ handleSubmit, valid }) => (
				<Form onSubmit={handleSubmit} loading={loading}>
					<Form.Group widths="equal">
						<FSTextInput
							name="name"
							label="File Name"
							placeholder="The name of the file"
							required
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSTextAreaInput
							name="description"
							label="Description"
							placeholder="The description of the file"
							required
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSResourceFolder
							name="parent"
							label="Folder"
							required
						/>
					</Form.Group>
					<Divider />
					<GraphQLError error={error} />
					<Button
						content="Submit Changes"
						type="submit"
						fluid
						color="blue"
						disabled={loading || !valid}
						loading={loading}
					/>

				</Form>
			)}
		/>
	);
};

const EditFile = ({ file, folderID, folderName, onFinish, tag }) => {
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<Button
				content={<FontAwesomeIcon icon={['fas', 'edit']} />}
				size="small"
				onClick={() => setOpen(true)}
				color="green"
			/>
			<Modal
				open={open}
				closeIcon
				closeOnDimmerClick={false}
				onClose={() => setOpen(false)}
				header={<Modal.Header>Editing File - {file.name}</Modal.Header>}
				content={(
					<Modal.Content>
						<EditFileContent 
							onClose={(shouldCloseRoot) => {
								setOpen(false);
								if(shouldCloseRoot) {
									onFinish();
								}
							}}
							{...{ file, folderID, folderName, tag }}
						/>
					</Modal.Content>
				)}
			/>
		</>
	);
};

const schema = Yup.object().shape({
	name: Yup.string().required('Required Field'),
	description: Yup.string().required('Required Field'),
	parent: Yup.object().shape({
		value: Yup.string().required('Required Field'),
	})
});


export default EditFile;