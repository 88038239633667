import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Button, Grid, Message, Icon, Transition} from 'semantic-ui-react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';

import { TextField, PasswordTextField, AsyncTextField } from '../../Form/CustomFields';


class CreateAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {loading: false, error: null, finished: false, show: false};
		this.sendToServer = this.sendToServer.bind(this);
		this.returnToLogin = this.returnToLogin.bind(this);
		this.display = this.display.bind(this);
		this.displayForm = this.displayForm.bind(this);
		this.displayError = this.displayError.bind(this);
		this.displayFinished = this.displayFinished.bind(this);
	}

	async sendToServer(values) {
		await this.setState({loading: true, error: null});

		try {
			await axios.post('/api/account/create', values);
			await this.setState({loading: false, finished: true});
			this.setState({show: true});
		} catch(error) {
			var errorText = error.response.statusText || 'There was an error. Please try again or contact customer support.';
			this.setState({error: errorText, loading: false});
		}

	}

	returnToLogin() {
		this.toggleModal();
		if( this.props.onSuccess ) { this.props.onSuccess(); }
	}

	displayForm() {
		return(
			<Form loading={this.state.loading} onSubmit={this.props.handleSubmit(values => this.sendToServer(values))}>
				<Form.Group widths='equal'>
					<Field name="firstname" component={TextField} label="First Name" required/>
					<Field name="lastname"  component={TextField} label="Last Name"  required/>
				</Form.Group>
				<Form.Group widths='equal'>
					<Field name="zip"       component={TextField}      label="Zip"   required/>
					<Field name="email"     component={AsyncTextField} label="Email" required/>
				</Form.Group>

				<Form.Group widths='equal'>
					<Field name="password1" component={PasswordTextField} label="Password"         required/>
					<Field name="password2" component={PasswordTextField} label="Confirm Password" required/>
				</Form.Group>

				<hr />

				<div className="d-flex justify-content-between">
					<div></div>
					<Button color="blue" type="submit">Submit</Button>
				</div>
			</Form>
		);
	}

	displayError() {
		if(this.state.error) {
			return(
				<Message negative>
					<Message.Header>There was an error creating your account.</Message.Header>
					<p>{this.state.error || ''}</p>
				</Message>
			);
		}
	}

	displayFinished() {
		return(
			<div>
				<Grid centered>
					<Grid.Row><Transition visible={this.state.show} animation='drop' duration={1000}><Icon name='checkmark' size='massive' color="green"/></Transition></Grid.Row>
					<Grid.Row><h2>Your account was successfully made!</h2></Grid.Row>
					<Grid.Row><Button onClick={() => this.props.onContinue() || ''}>Return to Login</Button></Grid.Row>
				</Grid>
			</div>
		);
	}

	display() {

		if(this.state.finished) {
			return(
				this.displayFinished()
			);
		}

		return(
			<div>
				{this.displayError()}
				{this.displayForm()}
			</div>
		);
	}

	render() {
		return(
			<div className="container">
				{this.display()}
			</div>
		);
	}
}

function validateEmail(email) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if(re.test(email) === false) {
		return true;
	}
	return false;
}

const asyncValidate = async (values, dispatch, props, field) => {
	if(field === 'email') {
		return axios.post('/api/register/check', {email: values.email})
			.then((res) => {
				if(res.data.emailExists) {
					const previousErrors = props.asyncErrors;
					throw Object.assign({}, previousErrors, {email: 'Email already has an account!'});
				}
			});
	}
};

function validate(form) {
	const errors = {};

	if(!form.firstname) {
		errors.firstname = 'Required Field';
	}

	if(!form.lastname) {
		errors.lastname = 'Required Field';
	}

	if(!form.zip) {
		errors.zip = 'Required Field';
	}

	if(!form.email) {
		errors.email = 'Required Field';
	} else if(validateEmail(form.email)){
		errors.email = 'Invalid Email';
	}

	if(!form.password1) {
		errors.password1 = 'Required Field';
	}

	if(form.password1 !== form.password2) {
		errors.password2 = 'Passwords do not match!';
	}

	if(form.password1) {
		if(form.password1.length < 6) {
			errors.password1 = 'Password must be at least 6 characters!';
		}
	}

	return errors;
}

export default reduxForm({
	validate,
	asyncValidate,
	form: 'createuseraccount'
})(withRouter(CreateAccount));
