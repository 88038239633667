/* eslint react/display-name: 0 */
import React from 'react';
import SimpleSelect from '../../../Form/SimpleSelect';
import _ from 'lodash';
import { StoreTypeNames } from '../../../../config/storeTypes';

const options = [
	{ label: 'Medical', value: 1 },
	{ label: 'Dental', value: 2 },
	{ label: 'Technology', value: 3 },
	{ label: 'Retail', value: 4 },
	{ label: 'Travel', value: 5 },
	{ label: 'Entertainment', value: 6 },
	{ label: 'Home & Garden', value: 7 },
	{ label: 'Health & Beauty', value: 8 },
	{ label: 'Food & Drink', value: 9 },
	{ label: 'Family Fun', value: 10 },
	{ label: 'Chiropractor', value: 12 },
	{ label: 'Therapy', value: 13 },
	{ label: 'Optometry', value: 14  },
	{ label: 'Community', value: 15 },
	{ label: 'Auto', value: 16 },
	{ label: 'Real Estate/Finance', value: 17 },
	{ label: 'Pet Services', value: 18 },
	{ label: 'Find Employment', value: 19 },
	{ label: 'Other', value: 11 },
];

const BooleanOptions = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

const columns = ({}, hideCols) => [
	{
		Header: 'Name',
		id: 'businessname',
		filterable: true,
		minWidth: 150,
		accessor: 'businessname',
		foldable: true,
	},
	{
		Header: 'Type',
		id: 'type',
		filterable: true,
		minWidth: 150,
		accessor: d => d.category.reduce((prev, type, i) => prev + (i > 0 ? ', ' : '') + _.get(StoreTypeNames, `${type}`, 'INVALID'), ''),
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={options}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => {
						if(value) {
							onChange(value.value);
						} else {
							onChange();
						}
					}}
					searchable={true}
				/>
			</div>
	},
	{
		Header: 'Store Locations',
		id: 'storeLocationsCount',
		filterable: true,
		minWidth: 150,
		accessor: d => d.nationalLocation ? 'National Location' : `${d.storeLocationsCount}`,
		foldable: true
	},
	{
		Header: '# of Promotions',
		id: 'promotionsCount',
		filterable: true,
		minWidth: 150,
		accessor: d => `${d.promotionsCount}`,
		foldable: true,
		sortable: false,
	},
	{
		Header: 'Is Premier?',
		id: 'premier',
		filterable: true,
		minWidth: 150,
		accessor: d => d.premier ? 'Yes' : 'No',
		foldable: true,
		headerStyle: {
			overflow: 'visible',
		}, 
		Filter: ({ filter, onChange }) =>
			<div style={{ textAlign: 'left' }}>
				<SimpleSelect
					options={BooleanOptions}
					isClearable
					value={filter ? Number(filter.value) : null}
					onChange={value => {
						if(value) {
							onChange(value.value);
						} else {
							onChange();
						}
					}}
					searchable={true}
				/>
			</div>
	}
];

export default columns;